import React, {useEffect, useRef, useState} from 'react';
import {ActivityIndicator, Dimensions, Pressable, StyleSheet, Text, TextInput,} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {View} from "dripsy";
import {Ionicons, MaterialCommunityIcons} from '@expo/vector-icons';
import {addDoc, collection, doc, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import uuid from "react-native-uuid";
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import TypingIndicator from "../TypingIndicator";
import * as Clipboard from "expo-clipboard";
import {getAuth} from "firebase/auth";
import base64 from 'base64-js';

const PromptAIConversation = ({
                                isBeforePromptRun,
                                setIsBeforePromptRun,
                                messages,
                                setMessages,
                                tokenCost,
                                remainingTokens,
                                hasConvoBeenSaved,
                                fetchingGPT,
                                userInput,
                                setUserInput,
                                isGPT4,
                                setFetchingGPT,
                                userDoc,
                                logConvoId,
                                setLogConvoId,
                                gpt3UserAPI,
                                gpt4UserAPI,
                                setHasConvoBeenSaved,
                                setRemainingTokens,
                                fullUserDoc
                              }: any) => {
  const auth = getAuth();
  const scrollViewRef = useRef(null);
  const [windowHeight, setWindowHeight] = useState();
  const [copiedMessageStatus, setCopiedMessageStatus] = useState([]);
  const [realSaveActive, setRealSaveActive] = useState(false);
  const [saveConvoName, setSaveConvoName] = useState("");
  const [isAfterFirstLoad, setIsAfterFirstLoad] = useState(false);
  const {user} = useAuthentication();

  const saveConversation = async () => {
    if (!saveConvoName) return;

    const timestamp = new Date().getTime();

    let modelType;

    if (isGPT4) {
      modelType = 'gpt4';
    } else {
      modelType = 'gpt3';
    }

    const docRef = await addDoc(collection(db, "conversations"), {
      conversationHistory: messages,
      name: saveConvoName,
      createdBy: user?.uid,
      deleted: false,
      timestamp: timestamp,
      model: modelType,
    });

    const docId = docRef.id;

    await updateDoc(docRef, {
      id: docId,
    });

    setSaveConvoName("");
    setRealSaveActive(false);
    setHasConvoBeenSaved(true);
  };

  const getGPTConvo = async () => {
    if (fetchingGPT) return;

    let tempUserInput = userInput;
    let modelToggle = 'gpt3';
    let currentlyRemainingTokens = remainingTokens;
    let accountType = 'basic';

    // @ts-ignore
    if (fullUserDoc?.accessLevel) {
      // @ts-ignore
      accountType = fullUserDoc?.accessLevel;
    }

    if (isGPT4) {
      modelToggle = 'gpt4';
    }

    if (isBeforePromptRun) {
      // @ts-ignore
      tempUserInput = promptContent;
    }

    if (!tempUserInput) return;
    setUserInput("");

    setFetchingGPT(true);
    setIsBeforePromptRun(false);

    const newMessages = [
      ...messages,
      {role: "user", content: tempUserInput},
      {role: "assistant", content: "One moment please..."},
    ];
    // @ts-ignore
    setMessages(newMessages);

    // @ts-ignore
    const authTokenValue = await auth.currentUser.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    //@ts-ignore
    let convoId;

    if (logConvoId) {
      convoId = logConvoId;
    } else {
      convoId = uuid.v4();
    }

    //@ts-ignore
    let APIStatus;

    if (modelToggle === 'gpt4') {
      APIStatus = gpt4UserAPI;
    }

    if (modelToggle === 'gpt3') {
      APIStatus = gpt3UserAPI;
    }

    let socket = new WebSocket("wss://ws.highperplexity.com");

    socket.addEventListener('open', (event) => {
      socket.send(JSON.stringify({
        modelToggle: modelToggle,
        conversation: newMessages.slice(0, -1),
        firebase_token_value: authTokenValue.token,
        accountType: accountType,
        //@ts-ignore
        convoId: convoId,
        //@ts-ignore
        APIStatus: APIStatus,
      }));
    });

    let completionContent = '';

    socket.addEventListener('message', (event) => {
      const result = JSON.parse(event.data);

      setLogConvoId(result?.convoId);

      if (result?.response === '[DONE]') {
        const GPTMessage = completionContent.trim();
        const updatedMessages = [
          ...newMessages.slice(0, -1),
          {role: "assistant", content: GPTMessage},
        ];
        setMessages(updatedMessages);
        setHasConvoBeenSaved(false);
        setRemainingTokens(currentlyRemainingTokens - tokenCost);
        setFetchingGPT(false);
      } else if (result?.message === "No remaining tokens") {
        const GPTMessage =
          "You have no remaining tokens, go to the Settings page to get more tokens for free!";
        const updatedMessages = [
          ...newMessages.slice(0, -1),
          {role: "assistant", content: GPTMessage},
        ];
        setMessages(updatedMessages);
        setHasConvoBeenSaved(false);
        setRemainingTokens(currentlyRemainingTokens - tokenCost);
        setFetchingGPT(false);
      } else {
        let data = result?.response?.data;
        let chunkData;
        if (Array.isArray(data)) {
          chunkData = String.fromCharCode.apply(null, data);
        } else if (typeof data === 'string') {
          if (data.length % 4 !== 0) {
            data += '='.repeat(4 - data.length % 4);
          }
          const byteArray = base64.toByteArray(data);
          chunkData = new TextDecoder().decode(byteArray);
        }

        //@ts-ignore
        const chunkDataArray = chunkData.split('\n');

        chunkDataArray.forEach((chunkData, index) => {
          if (index !== 0) {
            chunkData = 'data: ' + chunkData;
          }

          const jsonStart = chunkData.indexOf('{');
          const jsonEnd = chunkData.lastIndexOf('}');
          if (jsonStart >= 0 && jsonEnd >= 0) {
            const jsonStr = chunkData.substring(jsonStart, jsonEnd + 1).trim();

            const sanitizedJsonStr = jsonStr.replace(/[\n\r]+|[\s]{2,}/g, ' ');

            const chunkJson = JSON.parse(sanitizedJsonStr);

            const chunk = chunkJson?.choices[0]?.delta?.content;
            if (chunk) {
              completionContent += chunk;

              const updatedMessages = [
                ...newMessages.slice(0, -1),
                {role: "assistant", content: completionContent},
              ];
              setMessages(updatedMessages);
            }
          }
        });
      }
    });

    socket.addEventListener('close', (event) => {
      console.log('Server connection closed: ', event.code);
    });

    socket.addEventListener('error', (event) => {
      console.log('WebSocket error: ', event);
    });
  }

  const renderInput = () => {
    if (fetchingGPT) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="small" color="#FFF"/>
          <Text style={styles.loadingIndicator}>Waiting for response...</Text>
        </View>
      );
    }

    if (!isAfterFirstLoad) {
      setIsAfterFirstLoad(true);
    }

    return (
      <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%'}}>
        <TextInput
          style={[styles.input, {flex: 1, width: '100%'}]}
          value={userInput}
          onChangeText={setUserInput}
          placeholder="Type your message..."
          placeholderTextColor="#999"
          multiline={true}
        />
        <Pressable style={styles.sendButton} onPress={getGPTConvo}>
          <Text style={styles.sendButtonText}>Send</Text>
          <Ionicons name="send" size={20} color="#FFF"/>
        </Pressable>
      </View>
    );
  };

  const renderItem = (item: any, isMessageCopied: any, handleCopyMessage: any, key: any) => {
    const itemContent = item.content;
    const showCopyIcon = itemContent !== "One moment please...";

    return (
      <Pressable key={key}
                 onPress={handleCopyMessage}>
        <View
          style={[
            styles.messageWrapper,
            item.role === "assistant" ? styles.assistantMessageWrapper : null,
          ]}
        >
          <View style={
            [
              styles.labelWrapper,
              item.role === "assistant" && styles.highPerplexityLabel,
              {marginRight: 8},
            ]
          }>
            <Text
              style={[
                styles.label,
                item.role === "assistant"
                  ? styles.assistantLabel
                  : styles.userLabel,
              ]}
            >
              {item.role === "assistant" ? "hP" : "You"}
            </Text>
          </View>
          <View
            style={[
              styles.messageBubble,
              item.role === "assistant" ? styles.assistantMessageBubble : null,
              {paddingRight: itemContent === "One moment please..." ? 10 : 30}
            ]}
          >
            <View style={{flex: 1}}>
              {itemContent === "One moment please..." ? (
                <View style={{flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                  <TypingIndicator/>
                </View>
              ) : (
                <Text
                  style={[
                    styles.messageText,
                    item.role === "assistant" ? styles.assistantMessageText : null,
                  ]}
                >
                  {itemContent}
                </Text>
              )}
            </View>
            {showCopyIcon && (
              <View style={{position: 'absolute', bottom: 5, right: 5}}>
                <MaterialCommunityIcons
                  name="content-copy"
                  size={20}
                  color={isMessageCopied ?
                    "#4A90E2" : item.role === "assistant" ?
                      "#000" : "#FFF"
                  }
                />
              </View>
            )}
          </View>
        </View>
      </Pressable>
    );
  };

  useEffect(() => {
    // @ts-ignore
    setWindowHeight(Dimensions.get('window').height);
  }, [Dimensions]);

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      // @ts-ignore
      scrollViewRef.current.scrollToEnd({animated: true});
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 25);
  }, [messages]);

  const handleCopyMessage = async (itemContent: any, index: any) => {
    if (itemContent) {
      await Clipboard.setStringAsync(itemContent);
      const newCopiedMessageStatus = [...copiedMessageStatus];
      // @ts-ignore
      newCopiedMessageStatus[index] = true;
      setCopiedMessageStatus(newCopiedMessageStatus);
      setTimeout(() => {
        const updatedCopiedMessageStatus = [...copiedMessageStatus];
        // @ts-ignore
        updatedCopiedMessageStatus[index] = false;
        setCopiedMessageStatus(updatedCopiedMessageStatus);
      }, 75);
    }
  };

  return (
    <View sx={{width: ['85%', '90%', '100%']}}>
      <View style={[styles.chatContentContainer]}>
        <View
          style={{
            marginBottom: 10,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View>
            <Text style={{color: '#9FA8DA', textAlign: 'center', fontSize: 22}}>
              Your conversation with
            </Text>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: '#9FA8DA',
              borderRadius: 50,
              paddingHorizontal: 7,
              paddingVertical: 7,
              marginLeft: 6,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text style={[styles.label, {color: '#9FA8DA'}]}>hP</Text>
          </View>
        </View>
        <View
          sx={{
            // @ts-ignore
            maxHeight: [(windowHeight || 0) * 0.5, (windowHeight || 0) * 0.6, (windowHeight || 0) * 0.7],
          }}
        >
          <ScrollView
            ref={scrollViewRef}
            contentContainerStyle={styles.chatContainer}
            showsVerticalScrollIndicator={false}
          >
            {messages.map((item: any, index: any) => (
              // @ts-ignore
              renderItem(item, copiedMessageStatus[index], () => handleCopyMessage(item.content, index), index)
            ))}
          </ScrollView>
        </View>
        <View style={{marginVertical: 10}}>
          <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 10}}>
            Token Cost: {tokenCost}
          </Text>
          <Text style={{color: '#FFF', textAlign: 'center'}}>
            Remaining Tokens: {remainingTokens}
          </Text>
        </View>
        <View style={[styles.inputWrapper]} sx={{width: ['100%', '100%', '70%']}}>
          {renderInput()}
        </View>
      </View>
      {hasConvoBeenSaved ? (
        <SavedConversationButton/>
      ) : realSaveActive ? (
        <SaveConversationForm
          styles={styles}
          fetchingGPT={fetchingGPT}
          saveConvoName={saveConvoName}
          setSaveConvoName={setSaveConvoName}
          saveConversation={saveConversation}
        />
      ) : (
        <SaveConversationButton styles={styles} fetchingGPT={fetchingGPT} setRealSaveActive={setRealSaveActive}/>
      )}
    </View>
  );
};

const SavedConversationButton = () => (
  <View style={{alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
    <Pressable
      style={({pressed}) => [
        {
          backgroundColor: pressed ? '#3D6DCC' : 'transparent',
          borderColor: '#fff',
          borderWidth: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
          borderRadius: 5,
        },
      ]}
      onPress={() => {
      }}
    >
      <Text style={{color: '#fff', marginRight: 5}}>Conversation Saved</Text>
      <MaterialCommunityIcons name="bookmark-check" size={20} color="#fff"/>
    </Pressable>
  </View>
);

const SaveConversationButton = ({styles, fetchingGPT, setRealSaveActive}: any) => (
  <View style={{alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
    <Pressable
      disabled={fetchingGPT}
      style={({pressed}) => [
        {
          backgroundColor: pressed ? '#3D6DCC' : 'transparent',
          borderColor: '#fff',
          borderWidth: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
          borderRadius: 5,
        },
        fetchingGPT ? styles.disabledButton : null,
      ]}
      onPress={fetchingGPT ? () => {
      } : () => setRealSaveActive(true)}
    >
      <Text style={{color: '#fff', marginRight: 5}}>Save Conversation</Text>
      <Ionicons name="md-save" size={20} color="#FFF"/>
    </Pressable>
  </View>
);

const SaveConversationForm = ({styles, fetchingGPT, saveConvoName, setSaveConvoName, saveConversation}: any) => (
  <View style={{alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
    <TextInput
      style={styles.convoNameInput}
      value={saveConvoName}
      onChangeText={setSaveConvoName}
      placeholder="Conversation Name"
      placeholderTextColor="#999"
    />
    <Pressable
      disabled={fetchingGPT}
      style={({pressed}) => [
        {
          backgroundColor: pressed ? '#3D6DCC' : 'transparent',
          borderColor: '#fff',
          borderWidth: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
          borderRadius: 5,
        },
        fetchingGPT ? styles.disabledButton : null,
      ]}
      onPress={fetchingGPT ? () => {
      } : saveConversation}
    >
      <Text style={{color: '#fff', marginRight: 5}}>Save Conversation</Text>
      <Ionicons name="md-save" size={20} color="#FFF"/>
    </Pressable>
  </View>
);

const styles = StyleSheet.create({
  chatContentContainer: {
    flex: 1,
    marginVertical: 15,
    borderColor: '#FFF',
    borderWidth: 1,
    borderRadius: 15,
    borderStyle: 'dashed',
    paddingVertical: 15,
    paddingRight: 15,
    paddingLeft: 10,
  },
  label: {
    fontWeight: 'bold',
  },
  chatContainer: {
    flexGrow: 1,
    alignItems: 'flex-start',
    maxWidth: 600,
    width: '100%',
  },
  inputWrapper: {
    flexDirection: 'row',
    padding: 10,
    alignSelf: 'center',
  },
  loadingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: '100%'
  },
  loadingIndicator: {
    color: "#FFF",
    fontSize: 16,
    marginLeft: 8,
  },
  input: {
    backgroundColor: '#EFEFEF',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
    color: '#202124',
  },
  sendButton: {
    backgroundColor: "#4E88DE",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButtonText: {
    color: "#FFF",
    marginRight: 8,
    fontSize: 20,
    fontWeight: 'bold',
  },
  messageWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 5,
    width: '100%',
  },
  assistantMessageWrapper: {
    justifyContent: 'flex-end',
  },
  labelWrapper: {
    width: 40,
    alignItems: 'center',
  },
  highPerplexityLabel: {
    borderWidth: 1,
    borderColor: "#9FA8DA",
    borderRadius: 50,
    paddingVertical: 9,
  },
  userLabel: {
    color: '#4E88DE',
  },
  assistantLabel: {
    color: '#9FA8DA',
  },
  messageBubble: {
    backgroundColor: '#4E88DE',
    borderRadius: 5,
    padding: 10,
    marginBottom: 5,
    flex: 1,
  },
  assistantMessageBubble: {
    backgroundColor: '#EFEFEF',
    borderRadius: 5,
    padding: 10,
    marginBottom: 5,
    flex: 1,
    flexDirection: 'row',
  },
  messageText: {
    color: '#FFF',
    fontWeight: 'bold',
  },
  assistantMessageText: {
    color: '#202124',
    fontStyle: 'italic',
  },
});

export default PromptAIConversation;
