import React, {FC, useCallback, useEffect, useState} from 'react';
import {Text} from 'react-native-paper';
import {ActivityIndicator, RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../config/firebase";
import PromptCard from "../../components/PromptCard";
import {useFocusEffect} from '@react-navigation/native';

const ViewUserPromptsScreen: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [userPrompts, setUserPrompts] = useState([]);
  const [userNameParam, setUserNameParam] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userDoc, setUserDoc] = useState();
  const [userDocData, setUserDocData] = useState();
  const [userName, setUserName] = useState();

  useFocusEffect(
    useCallback(() => {
      // @ts-ignore
      setUserNameParam(decodeURI(route?.params?.userName));
    }, [route?.params?.userName])
  );

  const onRefresh = useCallback(() => {
    if (!userNameParam) return;
    setRefreshing(true);
    (async () => {
      const querySnapshot = await getDocs(
        query(
          collection(db, "prompts"),
          where("deleted", "==", false),
          where("postedById", "==", userNameParam)
        )
      );
      const userPromptDocs = querySnapshot.docs.map((doc) => doc.data());
      // @ts-ignore
      setUserPrompts(userPromptDocs);

      const usersCollection = collection(db, 'users');
      const q = query(usersCollection, where('uid', '==', userNameParam));
      const querySnap = await getDocs(q);

      let userDocument;
      querySnap.forEach(doc => {
        userDocument = {id: doc.id, data: doc.data()};
      });

      // @ts-ignore
      setUserDoc(userDocument.id);
      // @ts-ignore
      setUserDocData(userDocument.data);
      // @ts-ignore
      setUserName(userDocument.data.userName);

      setRefreshing(false);
    })();
  }, []);

  useEffect(() => {
    if (!userNameParam) return;
    (async () => {
      const querySnapshot = await getDocs(
        query(
          collection(db, "prompts"),
          where("deleted", "==", false),
          where("postedById", "==", userNameParam)
        )
      );
      const userPromptDocs = querySnapshot.docs.map((doc) => doc.data());
      // @ts-ignore
      setUserPrompts(userPromptDocs);

      const usersCollection = collection(db, 'users');
      const q = query(usersCollection, where('uid', '==', userNameParam));
      const querySnap = await getDocs(q);

      let userDocument;
      querySnap.forEach(doc => {
        userDocument = {id: doc.id, data: doc.data()};
      });

      // @ts-ignore
      setUserDoc(userDocument.id);
      // @ts-ignore
      setUserDocData(userDocument.data);
      // @ts-ignore
      setUserName(userDocument.data.userName);

      setLoading(false);
    })();
  }, [userNameParam]);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <View sx={{
            marginBottom: [20, 20, 12],
            flexDirection: ['column', 'column', 'row'],
            justifyContent: ['center', 'center', 'flex-start'],
            alignItems: ['center', 'center', 'flex-start'],
          }}>
            <Text style={{color: '#FFF', fontSize: 25}}>
              Prompts For: {userName}
            </Text>
          </View>
          <View>
            {loading ? (
              <View style={{marginVertical: 15}}>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            ) : (
              <View sx={{
                flexDirection: ['column', 'column', 'row'],
                justifyContent: 'center',
                alignItems: ['center', 'center', 'flex-start'],
                flexWrap: 'wrap',
                maxWidth: 1450,
              }}>
                {userPrompts?.map((prompt: any) =>
                  <PromptCard key={prompt.promptId}
                              promptId={prompt.promptId}
                              navigation={navigation}
                              promptImage={prompt?.promptImage?.imageURL}
                              promptTitle={prompt.title}
                              promptUserName={prompt.userName}
                              promptRatings={prompt.ratings}
                              promptComments={prompt.comments?.length}
                  />
                )}
              </View>
            )}
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  cardTitleContainer: {
    backgroundColor: '#222236',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  cardTitleText: {
    color: '#FFF',
  },
  cardBackgroundImage: {
    flex: 1,
    resizeMode: "cover",
    width: "100%",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
});

export default ViewUserPromptsScreen;
