import React, {useState} from 'react';
import {Ionicons} from '@expo/vector-icons';
import {StyleSheet, TextInput} from 'react-native';
import {Text, View} from "dripsy";

export default function PromptImageHeader({
                                          hideOriginalImagePrompt,
                                          generating,
                                          canEditPrompt,
                                          promptContent,
                                          setPromptContent,
                                        }: any) {

  return (
    <>
      <View style={{marginBottom: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
        <View>
          <Text sx={{color: '#9FA8DA', textAlign: 'center', fontSize: [18, 20, 22]}}>
            Your image conversation with
          </Text>
        </View>
        <View style={{
          borderWidth: 1,
          borderColor: "#9FA8DA",
          borderRadius: 50,
          paddingHorizontal: 7,
          paddingVertical: 7,
          marginLeft: 6,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Text style={[styles.label, {color: '#9FA8DA'}]}>hP</Text>
        </View>
      </View>
      {
        !hideOriginalImagePrompt &&
        <View style={styles.inputContainer} sx={{width: [300, 400, 500]}}>
          <Ionicons name="pencil" size={20} color="#9E9E9E" style={styles.inputIcon}/>
          {(!generating && canEditPrompt) &&
            <TextInput
              style={styles.textInput}
              onChangeText={(text) => setPromptContent(text)}
              value={promptContent}
              placeholder="Enter your prompt"
              placeholderTextColor="#9E9E9E"
              editable={canEditPrompt}
            />
          }
          {(generating || !canEditPrompt) &&
            <Text style={{color: '#FFF'}}>
              {promptContent}
            </Text>
          }
        </View>
      }
    </>
  );
}

const styles = StyleSheet.create({
  label: {
    fontWeight: 'bold',
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  inputIcon: {
    marginRight: 10,
  },
  textInput: {
    width: "100%",
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: "#FFFFFF",
    marginBottom: 10,
  },
});
