import {useEffect} from 'react';
import {Platform} from 'react-native';

const useAdCashScript = (navigation: any) => {
  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    const runScript = () => {
      const existingScript = document.querySelector('#custom-script');
      if (existingScript) {
        return;
      }

      const script = document.createElement('script');
      script.id = 'custom-script';
      script.type = 'text/javascript';
      script.setAttribute('data-cfasync', 'false');
      script.setAttribute('data-adel', 'atag');
      script.src = '//acacdn.com/script/atg.js';
      script.setAttribute('czid', 'wfyeqgcbk9');
      document.body.insertBefore(script, document.body.lastChild);
    };

    runScript();

    const unsubscribe = navigation.addListener('focus', runScript);

    return () => {
      navigation.removeListener('focus', runScript);
    };
  }, [navigation]);
};

export default useAdCashScript;
