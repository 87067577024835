import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {MaterialCommunityIcons} from '@expo/vector-icons';

const PromptTokenInfo = ({tokenCost, remainingTokens, setTooltipId, setTooltipVisible}: any) => {

  const showTooltip = (id: any) => {
    setTooltipId(id);
    setTooltipVisible(true);
  };

  return (
    <View
      style={{
        backgroundColor: '#2a2a2a',
        borderRadius: 5,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 15,
      }}
    >
      <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 5}}>
        <Text
          style={{
            color: '#CCC',
            textAlign: 'center',
            fontSize: 18,
            fontWeight: 'bold',
            marginRight: 4,
          }}
        >
          Token Cost: {tokenCost}
        </Text>
        <TouchableOpacity onPress={() => showTooltip('tokenCost')}>
          <MaterialCommunityIcons name="information-outline" size={20} color="#ccc"/>
        </TouchableOpacity>
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Text
          style={{
            color: '#CCC',
            textAlign: 'center',
            fontSize: 18,
            fontWeight: 'bold',
            marginRight: 4,
          }}
        >
          Remaining Tokens: {remainingTokens}
        </Text>
        <TouchableOpacity onPress={() => showTooltip('promptTokens')}>
          <MaterialCommunityIcons name="information-outline" size={20} color="#ccc"/>
        </TouchableOpacity>
      </View>
      <Text
        style={{
          color: '#CCC',
          textAlign: 'center',
          fontStyle: 'italic',
          fontSize: 14,
          marginTop: 8,
        }}
      >
        Different AI response types cost different amounts of tokens. Tap the tooltip for more info.
      </Text>
    </View>
  );
};

export default PromptTokenInfo;
