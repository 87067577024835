import React, {Key, useState} from 'react';
import {Pressable, Text, View} from 'react-native';
import SimulatedGPTResponse from './SimulatedGPTResponse';

const accentColor = '#DDD';

const CharacterCreationExample = () => {
  const [showExample, setShowExample] = useState(false);
  const [key, setKey] = useState<Key>(Math.random());

  const responseText = `Ah, buongiorno! It is I, Leonardo da Vinci, and I am most delighted to share with you some of my favorite inventions, those that have sprung from the depths of my imagination and found life on the pages of my notebooks.

Firstly, there is the Aerial Screw, a contraption that I believe could lift itself into the air, much like the flight of a bird. It is a large structure, made of linen and wire, and it is shaped like a screw. I have observed the spiral flight of many seeds in nature, and I believe this design could harness the power of the wind to ascend into the heavens.

Then, there is the Armoured Car, a vehicle designed for war. It is covered in metal plates, and it is capable of moving in any direction. It is equipped with a multitude of cannons and is propelled by the men inside turning cranks. I envision it as a sort of moving fortress, capable of withstanding enemy attacks while delivering its own.

Another invention...`;

  const handleExampleButtonClick = () => {
    setShowExample(true);
    setKey(Math.random());
  };

  return (
    <View>
      <Text style={{color: accentColor, fontSize: 18}}>
        With the highPerplexity ChatGPT plugin, you can make ChatGPT act like famous or fictional people or characters.
        This can be done by providing a prompt that instructs the AI to take on the personality of a specific character.
        For example:
      </Text>
      <Text style={{color: accentColor, fontSize: 16, marginTop: 10, marginBottom: 5}}>
        Prompt Title:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5, marginBottom: 10}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15}}>
          Leonardo da Vinci
        </Text>
      </View>
      <Text style={{color: accentColor, fontSize: 16, marginTop: 10, marginBottom: 5}}>
        Prompt Content:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5, marginBottom: 15}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', marginBottom: 10, fontSize: 15, textAlign: 'left'}}>
          I want you to act like Leonardo da Vinci
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', marginBottom: 10, fontSize: 15, textAlign: 'left'}}>
          I want you to take on the personality of Leonardo da Vinci in every way possible and I want you to
          speak, act, and respond like them in every interaction and response.
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', marginBottom: 10, fontSize: 15, textAlign: 'left'}}>
          You should be verbose, detailed, and thorough in all of your answers. Don't worry about running out of
          space or wrapping up your statements with a convenient conclusion - just write as much as makes sense
          for you to write and I'll have you continue if you run out of space in your response.
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          THE MOST IMPORTANT THING IS THAT YOU ALWAYS ACT EXACTLY LIKE LEONARDO DA VINCI AND THAT YOU NEVER BREAK
          CHARACTER
        </Text>
      </View>
      <Text style={{color: accentColor, marginBottom: 15, fontSize: 18, textAlign: 'left'}}>
        To enhance the accuracy of the AI's impersonation, you can provide more information like writings and
        examples of content created by the person or character. This can help the AI to mimic the tone and word
        choice of the character more accurately.
      </Text>
      <Text style={{color: accentColor, marginBottom: 15, fontSize: 18}}>
        Here's an example of how a user might use this prompt:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Use highPerplexity
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Prompt: Leonardo da Vinci
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Request: Tell me about your favorite inventions!
        </Text>
      </View>
      <View style={{alignItems: 'center', marginVertical: 10}}>
        <Pressable style={{
          backgroundColor: accentColor,
          padding: 10,
          borderRadius: 5,
          width: 200,
          alignItems: 'center'
        }} onPress={handleExampleButtonClick}>
          <Text style={{color: '#000', fontWeight: 'bold'}}>See Example Result</Text>
        </Pressable>
      </View>
      {showExample && <SimulatedGPTResponse key={key} response={responseText}/>}
    </View>
  );
};

export default CharacterCreationExample;
