import React from 'react';
import {ActivityIndicator, Dimensions, Pressable, StyleSheet, Text, View} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';

const screenWidth = Dimensions.get('window').width;

const ProductList = ({products, onSelectProduct, selectedProduct}: any) => {
  if (!products || products.length === 0) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#FFF"/>
        <Text style={styles.loadingText}>Loading products...</Text>
      </View>
    )
  }

  const ProductIcon = ({name}: { name: string }) => {
    if (name === 'highPerplexity Trophy (Consumable)') {
      return <FontAwesome name="trophy" size={24} color="gold" style={{marginBottom: 5}}/>;
    }
    return <FontAwesome name="star" size={24} color="gold" style={{marginBottom: 5}}/>;
  };

  const sortedProducts = products.sort((a: any, b: any) => {
    if (a.name.toLowerCase().includes('monthly')) return -1;
    if (b.name.toLowerCase().includes('monthly')) return 1;
    return 0;
  });

  return (
    <View style={styles.productsContainer}>
      {//@ts-ignore
        sortedProducts.map((product) => {
          const price = product.prices[0];
          const formattedPrice = '$' + (price.unit_amount / 100).toFixed(2) + ' ' + price.currency.toUpperCase();

          return (
            <Pressable
              key={product.id}
              onPress={() => onSelectProduct(product)}
              style={({pressed}) => [
                styles.product,
                selectedProduct && selectedProduct.id === product.id && styles.selectedProduct,
                pressed && styles.pressedProduct,
              ]}
            >
              <ProductIcon name={product.name}/>
              <Text style={styles.productTitle}>{product.name}</Text>
              <Text style={styles.productDescription}>{product.description}</Text>
              <Text style={styles.productPrice}>Price: {formattedPrice}</Text>
            </Pressable>
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  productsContainer: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  product: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    backgroundColor: '#333',
    borderRadius: 10,
    padding: 10,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  selectedProduct: {
    borderColor: '#87ceeb',
    borderWidth: 2,
    backgroundColor: '#555',
  },
  pressedProduct: {
    opacity: 0.5,
  },
  productTitle: {
    color: '#FFF',
    fontSize: screenWidth < 768 ? 20 : 24,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  productDescription: {
    color: '#FFF',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  productPrice: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#CCC',
    textAlign: 'center',
  },
  loadingContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 30,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 10,
  },
});

export default ProductList;
