import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import Onboarding from 'react-native-onboarding-swiper';
import {Ionicons} from '@expo/vector-icons';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../config/firebase";

const ViewPromptMobileOnboarding = ({setModalVisible, setHasOnboarded, userDoc}: any) => {

  const toggleOnboardingStatus = async () => {
    try {
      //@ts-ignore
      const userRef = doc(db, "users", userDoc);
      await updateDoc(userRef, {promptViewOnboardedStatus: true});
    } catch (error) {
      console.error("Error updating onboarded status: ", error);
    }
    setModalVisible(false);
    setHasOnboarded(true);
  }

  return (
    <Onboarding
      onSkip={() => toggleOnboardingStatus()}
      onDone={() => toggleOnboardingStatus()}
      pages={[
        {
          backgroundColor: '#202124',
          image: (
            <View style={styles.iconContainer}>
              <Ionicons name="md-rocket" size={96} color="#FFFFFF"/>
            </View>
          ),
          title: (
            <View style={{paddingHorizontal: 15, marginBottom: 15}}>
              <Text
                style={{
                  color: "#FFFFFF",
                  fontSize: 24,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Your First highPerplexity Prompt
              </Text>
            </View>
          ),
          subtitle: (
            <View style={{paddingHorizontal: 15}}>
              <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left", marginBottom: 8}}>
                We're thrilled to have you here! Get ready to unleash the power of our advanced AI prompts.
              </Text>
              <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left"}}>
                Tap next to learn how to use and customize prompts for a tailored experience.
              </Text>
            </View>
          ),
        },
        {
          backgroundColor: '#202124',
          image: (
            <MaterialCommunityIcons name="book-open-page-variant" size={96} color="#FFFFFF"/>
          ),
          title: (
            <View style={{paddingHorizontal: 15}}>
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 24,
                  fontWeight: 'bold',
                  marginBottom: 25,
                  textAlign: 'center'
                }}>
                Step 1: Reading Prompt Descriptions
              </Text>
            </View>
          ),
          subtitle: (
            <View style={{paddingHorizontal: 15}}>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                Each prompt comes with a concise, natural language description that clarifies its purpose and
                functionality. This handy guide should help simplify complex prompts, making them more accessible to
                you.
              </Text>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                For instance, a description like "Craft a story on any topic of your choice" conveys the essence of
                the prompt. Just swap the topic variable with your desired subject, and you're all set for a
                creative journey.
              </Text>
            </View>
          ),
        },
        {
          backgroundColor: '#202124',
          image: (
            <MaterialCommunityIcons name="lightbulb-on-outline" size={96} color="#FFFFFF"/>
          ),
          title: (
            <View style={{paddingHorizontal: 15}}>
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 24,
                  fontWeight: 'bold',
                  marginBottom: 25,
                  textAlign: 'center'
                }}>
                Step 2: Understand Prompt Content
              </Text>
            </View>
          ),
          subtitle: (
            <View style={{paddingHorizontal: 15}}>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                The key to an immersive AI-driven experience lies in understanding the content of each prompt.
                Familiarize yourself with the available variables to fully harness the potential of highPerplexity.
              </Text>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                For example, a prompt might have options that allow you to customize a story or message. You can
                modify these values to create a personalized experience tailored to your preferences.
              </Text>
            </View>
          ),
        },
        {
          backgroundColor: '#202124',
          image: (
            <Ionicons name="md-settings-outline" size={96} color="#FFFFFF"/>
          ),
          title: (
            <View style={{paddingHorizontal: 15}}>
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 24,
                  fontWeight: 'bold',
                  marginBottom: 25,
                  textAlign: 'center',
                }}
              >
                Step 3: Customizing Prompts
              </Text>
            </View>
          ),
          subtitle: (
            <View style={{paddingHorizontal: 15}}>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                highPerplexity encourages you to modify and experiment with the prompts to bring your ideas to life.
                Adjust variables, add new elements, or combine multiple prompts to create a unique outcome.
              </Text>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                Don't hesitate to get creative! Our platform is designed to support your vision and provide a
                seamless user experience.
              </Text>
            </View>
          ),
        },
        {
          backgroundColor: '#202124',
          image: (
            <Ionicons name="md-flask-outline" size={96} color="#FFFFFF"/>
          ),
          title: (
            <View style={{paddingHorizontal: 15}}>
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 24,
                  fontWeight: 'bold',
                  marginBottom: 25,
                  textAlign: 'center',
                }}
              >
                Step 4: Test and Experiment
              </Text>
            </View>
          ),
          subtitle: (
            <View style={{paddingHorizontal: 15}}>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                highPerplexity offers a playground for you to test, tweak, and experiment with prompts before fully
                committing to them. Make use of this feature to optimize your ideas and uncover new possibilities.
              </Text>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                Embrace the trial-and-error process and be open to refining your prompts for the best results.
              </Text>
            </View>
          ),
        },
        {
          backgroundColor: '#202124',
          image: (
            <Ionicons name="md-share-outline" size={96} color="#FFFFFF"/>
          ),
          title: (
            <View style={{paddingHorizontal: 15}}>
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 24,
                  fontWeight: 'bold',
                  marginBottom: 25,
                  textAlign: 'center',
                }}
              >
                Step 5: Share Your Creations
              </Text>
            </View>
          ),
          subtitle: (
            <View style={{paddingHorizontal: 15}}>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                Showcase your creativity and share your personalized prompts with the highPerplexity community.
                Engage with others, exchange ideas, and discover new approaches to using AI-driven prompts.
              </Text>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                Together, we can inspire each other and unlock the full potential of AI!
              </Text>
            </View>
          ),
        },
        {
          backgroundColor: '#202124',
          image: (
            <View
              style={{
                borderWidth: 2,
                borderColor: '#FFFFFF',
                borderRadius: 50,
                paddingHorizontal: 20,
                paddingVertical: 20,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 35}}>hP</Text>
            </View>
          ),
          title: (
            <View style={{paddingHorizontal: 15}}>
              <Text
                style={{
                  color: '#FFFFFF',
                  fontSize: 24,
                  fontWeight: 'bold',
                  marginBottom: 25,
                  textAlign: 'center',
                }}
              >
                You're All Set!
              </Text>
            </View>
          ),
          subtitle: (
            <View style={{paddingHorizontal: 15}}>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 15}}>
                Congratulations! You're now ready to dive into the world of custom prompts with highPerplexity. Use
                your newfound knowledge to explore endless possibilities.
              </Text>
              <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                Remember, practice makes perfect. Keep experimenting and have fun!
              </Text>
            </View>
          ),
        },
      ]}
    />
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
});

export default ViewPromptMobileOnboarding;
