import React, {useState} from 'react';
import {View, Text, Pressable} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {doc, getDocs, collection, updateDoc} from 'firebase/firestore';
import {db} from "../../config/firebase";
import {getAuth} from "firebase/auth";

const PromptTrophyInformation = ({
                                   fullUserDoc,
                                   promptId,
                                   userDoc,
                                   trophyCount,
                                   setTrophyCount,
                                   matchingPrompt
                                 }: any) => {
  const [showRealTrophyReward, setShowRealTrophyReward] = useState(false);
  const [rewardingTrophy, setRewardingTrophy] = useState(false);
  const auth = getAuth();

  const playgroundPromptId = 'b148c38f-0cc3-435d-8ccc-93e59fe028e3';

  return (
    <>
      {(!!fullUserDoc?.trophyCount &&
        fullUserDoc?.trophyCount > 0 &&
        !rewardingTrophy &&
        promptId !== playgroundPromptId) && (
          <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <Pressable
              style={({pressed}) => [
                {
                  backgroundColor: pressed ? '#4CAF50' : 'transparent',
                  borderColor: '#FFF',
                  borderWidth: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 12,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                  borderRadius: 8,
                },
              ]}
              onPress={() => setShowRealTrophyReward(true)}
            >
              <MaterialCommunityIcons
                name="trophy"
                size={16}
                color="#FFF"
                style={{marginRight: 8}}
              />
              <Text style={{color: '#FFF', fontSize: 16}}>Give Trophy Reward</Text>
            </Pressable>
          </View>
        )}
      {(showRealTrophyReward && !rewardingTrophy) && (
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
          <Pressable
            style={({pressed}) => [
              {
                backgroundColor: pressed ? '#4CAF50' : '#4CAF50',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 12,
                paddingHorizontal: 8,
                paddingVertical: 4,
                borderRadius: 8,
              },
            ]}
            onPress={async () => {
              setRewardingTrophy(true);
              const authTokenValue = await auth?.currentUser?.getIdTokenResult();
              const userRef = doc(db, 'users', userDoc);
              //@ts-ignore
              await updateDoc(userRef, {currentJWT: authTokenValue.token});

              const response = await fetch(
                'https://highperplexity-trophy-manager-middleware-mjp27ovqaq-uc.a.run.app',
                {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    firebase_token_value: authTokenValue?.token,
                    action: 'reset',
                    postedById: matchingPrompt?.postedById,
                    promptId: matchingPrompt?.promptId,
                  }),
                }
              );

              await response.json();

              const querySnapshot = await getDocs(collection(db, 'prompts'));
              const matchingDoc = querySnapshot.docs.find(
                (doc) => promptId === doc.data().promptId
              );
              if (matchingDoc) {
                setTrophyCount(
                  matchingDoc.data().trophyCount ? matchingDoc.data().trophyCount : 0
                );
              }
              setShowRealTrophyReward(false);
              setRewardingTrophy(false);
            }}
          >
            <MaterialCommunityIcons
              name="check-circle"
              size={16}
              color="#FFF"
              style={{marginRight: 8}}
            />
            <Text style={{color: '#FFF', fontSize: 16}}>Confirm Trophy Reward</Text>
          </Pressable>
        </View>
      )}
      {(!!trophyCount && trophyCount > 0) && (
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 12}}>
          <MaterialCommunityIcons
            name="trophy-award"
            size={20}
            color="#FFF"
            style={{marginRight: 6}}
          />
          <Text style={{color: '#FFF', fontSize: 16}}>
            Trophies Received: {trophyCount}
          </Text>
        </View>
      )}
    </>
  );
};

export default PromptTrophyInformation;
