import React, {useState} from 'react';
import {ActivityIndicator, Pressable, StyleSheet, Text, TextInput, View} from 'react-native';

const CommentInput = ({onLeaveComment}: any) => {
  const [comment, setComment] = useState('');
  const [isPosting, setIsPosting] = useState(false);

  const handleLeaveComment = async () => {
    setIsPosting(true);
    await onLeaveComment(comment);
    setComment('');
    setIsPosting(false);
  };

  return (
    <View style={styles.container}>
      {isPosting ? (
        <ActivityIndicator size="small" color="#fff" style={styles.activityIndicator}/>
      ) : (
        <>
          <TextInput
            style={styles.textInput}
            onChangeText={setComment}
            value={comment}
            placeholder="Leave a comment..."
            placeholderTextColor="#888"
            multiline={true}
          />
          <Pressable onPress={handleLeaveComment} style={styles.button}>
            <Text style={styles.buttonText}>Post</Text>
          </Pressable>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#222',
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
  },
  textInput: {
    width: '100%',
    backgroundColor: '#333',
    color: '#fff',
    borderColor: '#444',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  button: {
    backgroundColor: '#4a4',
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  activityIndicator: {
    flex: 1,
  },
});

export default CommentInput;
