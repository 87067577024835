import React, {useEffect, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import uuid from "react-native-uuid";
import {getAuth, sendEmailVerification} from "firebase/auth";
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import base64 from 'base64-js';

const RunPromptButton = ({
                           fullUserDoc,
                           userInput,
                           setUserInput,
                           remainingTokens,
                           setRemainingTokens,
                           messages,
                           setMessages,
                           userDoc,
                           gpt3UserAPI,
                           gpt4UserAPI,
                           imagesUserAPI,
                           setHasConvoBeenSaved,
                           setImageLoading,
                           setGenerating,
                           promptContent,
                           image,
                           setImage,
                           setCanEditPrompt,
                           setImageHistory,
                           responseType,
                           setResponseType,
                           isGPT4,
                           setIsGPT4,
                           logConvoId,
                           setLogConvoId,
                           tokenCost,
                           isBeforePromptRun,
                           setIsBeforePromptRun,
                           isBeforeImagePromptRun,
                           setIsBeforeImagePromptRun,
                           customPromptInput,
                           inputMethod,
                           promptId,
                         }: any) => {
  const auth = getAuth();
  const [fetchingGPT, setFetchingGPT] = useState(false);
  const [hasVerifiedEmail, setHasVerifiedEmail] = useState();
  const [hasSentVerifyEmail, setHasSentVerifyEmail] = useState(false);
  const {user} = useAuthentication();
  const promptGPTPromptID = '6c53fa59-d28c-4fe5-9e64-c1ad56c2cac8';

  const toggleSwitch = () => {
    setIsGPT4((previousState: any) => !previousState);
  };

  const addToImageHistory = (newImage: any) => {
    setImageHistory((prevState: any) => {
      // Check if the new image is already in the history
      if (prevState.includes(newImage)) {
        return prevState;
      }
      return [...prevState, newImage];
    });
  };

  const getGPTConvo = async () => {
    if (fetchingGPT) return;

    const isPromptGPTPrompt = (promptId === promptGPTPromptID);
    let tempUserInput = userInput;
    let modelToggle = 'gpt3';
    let currentlyRemainingTokens = remainingTokens;
    let accountType = 'basic';

    // @ts-ignore
    if (fullUserDoc?.accessLevel) {
      // @ts-ignore
      accountType = fullUserDoc?.accessLevel;
    }

    if (isGPT4) {
      modelToggle = 'gpt4';
    }

    if (isBeforePromptRun) {
      // @ts-ignore
      tempUserInput = promptContent;
    }

    if (!tempUserInput) return;
    setUserInput("");

    setFetchingGPT(true);
    setIsBeforePromptRun(false);

    if (customPromptInput) {
      tempUserInput = `${tempUserInput}
    
CUSTOM USER INPUT: ${customPromptInput}`;
    }

    if (isPromptGPTPrompt) {
      tempUserInput = `${tempUserInput}
    
CUSTOM USER INPUT: Write a prompt that would make ChatGPT act like or have the properties of: ${customPromptInput}`;
    }

    const newMessages = [
      ...messages,
      {role: "user", content: tempUserInput},
      {role: "assistant", content: "One moment please..."},
    ];
    // @ts-ignore
    setMessages(newMessages);

    // @ts-ignore
    const authTokenValue = await auth.currentUser.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    //@ts-ignore
    let convoId;

    if (logConvoId) {
      convoId = logConvoId;
    } else {
      convoId = uuid.v4();
    }

    //@ts-ignore
    let APIStatus;

    if (modelToggle === 'gpt4') {
      APIStatus = gpt4UserAPI;
    }

    if (modelToggle === 'gpt3') {
      APIStatus = gpt3UserAPI;
    }

    let customSystemPromptMod = '';

    if (inputMethod === 'custom') {
      customSystemPromptMod = `
      - FOR THIS HIGHPERPLEXITY PROMPT, WE WILL BE USING A CUSTOM USER INPUT INSTEAD OF THE VARIABLES. TRY TO USE THE SECTION BELOW (LABELED "CUSTOM USER INPUT") TO ATTEMPT TO DETERMINE THE VALUE OF THIS HIGHPERPLEXITY PROMPT'S VARIABLES.
      - AS A REMINDER, THE VARIABLES ARE INDICATED BY %| AND |% SYMBOLS LIKE THIS: %|EXAMPLE|%
      - TRY TO USE THIS CUSTOM USER INPUT TO DETERMINE WHAT THE VALUES OF THOSE VARIABLES SHOULD BE BEFORE YOU RESPOND:
      - THIS IS THE CUSTOM USER INPUT: ${customPromptInput}`;
    }

    let webSocketLocation = "wss://ws.highperplexity.com";

    if (isPromptGPTPrompt) {
      webSocketLocation = "wss://pgpt-ws.highperplexity.com";
    }

    let socket = new WebSocket(webSocketLocation);

    socket.addEventListener('open', (event) => {
      socket.send(JSON.stringify({
        modelToggle: modelToggle,
        conversation: newMessages.slice(0, -1),
        firebase_token_value: authTokenValue.token,
        accountType: accountType,
        //@ts-ignore
        convoId: convoId,
        //@ts-ignore
        APIStatus: APIStatus,
        customSystemPromptMod: customSystemPromptMod,
        isPromptGPTPrompt: isPromptGPTPrompt,
      }));
    });

    let completionContent = '';

    socket.addEventListener('message', (event) => {
      const result = JSON.parse(event.data);

      //@ts-ignore
      setLogConvoId(result?.convoId);

      if (result?.response === '[DONE]') {
        const GPTMessage = completionContent.trim();

        const updatedMessages = [
          ...newMessages.slice(0, -1),
          {role: "assistant", content: GPTMessage},
        ];
        setMessages(updatedMessages);
        setHasConvoBeenSaved(false);
        setRemainingTokens(currentlyRemainingTokens - tokenCost);
        setFetchingGPT(false);
      } else if (result?.message === "No remaining tokens") {
        const GPTMessage =
          "You have no remaining tokens, go to the Settings page to get more tokens for free!";
        const updatedMessages = [
          ...newMessages.slice(0, -1),
          {role: "assistant", content: GPTMessage},
        ];
        setMessages(updatedMessages);
        setHasConvoBeenSaved(false);
        setRemainingTokens(currentlyRemainingTokens - tokenCost);
        setFetchingGPT(false);
      } else {
        let data = result?.response?.data;
        let chunkData;
        if (Array.isArray(data)) {
          chunkData = String.fromCharCode.apply(null, data);
        } else if (typeof data === 'string') {
          if (data.length % 4 !== 0) {
            data += '='.repeat(4 - data.length % 4);
          }
          const byteArray = base64.toByteArray(data);
          chunkData = new TextDecoder().decode(byteArray);
        }

        //@ts-ignore
        const chunkDataArray = chunkData.split('\n');

        chunkDataArray.forEach((chunkData, index) => {
          if (index !== 0) {
            chunkData = 'data: ' + chunkData;
          }

          const jsonStart = chunkData.indexOf('{');
          const jsonEnd = chunkData.lastIndexOf('}');
          if (jsonStart >= 0 && jsonEnd >= 0) {
            const jsonStr = chunkData.substring(jsonStart, jsonEnd + 1).trim();

            const sanitizedJsonStr = jsonStr.replace(/[\n\r]+|[\s]{2,}/g, ' ');

            const chunkJson = JSON.parse(sanitizedJsonStr);

            const chunk = chunkJson?.choices[0]?.delta?.content;
            if (chunk) {
              completionContent += chunk;

              const updatedMessages = [
                ...newMessages.slice(0, -1),
                {role: "assistant", content: completionContent},
              ];
              setMessages(updatedMessages);
            }
          }
        });
      }
    });

    socket.addEventListener('close', (event) => {
      console.log('Server connection closed: ', event.code);
    });

    socket.addEventListener('error', (event) => {
      console.log('WebSocket error: ', event);
    });
  }

  const getGPTImageConvo = async (imageData: any) => {
    if (fetchingGPT) return;

    let tempUserInput = userInput;
    let currentlyRemainingTokens = remainingTokens;
    let accountType = 'basic';

    // @ts-ignore
    if (fullUserDoc?.accessLevel) {
      // @ts-ignore
      accountType = fullUserDoc?.accessLevel;
    }

    if (isBeforeImagePromptRun) {
      // @ts-ignore
      tempUserInput = promptContent;
    }

    if (!tempUserInput) return;
    setUserInput("");

    setFetchingGPT(true);
    setIsBeforeImagePromptRun(false);
    setImageLoading(true);
    setGenerating(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    let convoId;

    if (logConvoId) {
      convoId = logConvoId;
    } else {
      convoId = uuid.v4();
    }

    if (customPromptInput) {
      promptContent = `${promptContent}
- CUSTOM INPUT TO USE IN PLACE OF VARIABLES: ${customPromptInput}`;
    }

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      prompt: promptContent,
      accountType: accountType,
      convoId: convoId,
      APIStatus: imagesUserAPI,
    };

    if (imageData) {
      // @ts-ignore
      requestBody.input_image_url = imageData;
    }

    try {
      const response = await fetch('https://highperplexity-dalle-middleware-mjp27ovqaq-uc.a.run.app', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      //@ts-ignore
      if (data && data.response) {
        if (image) {
          addToImageHistory(image);
        }
        //@ts-ignore
        const newImage = `data:image/png;base64,${data.response.b64_json}`;
        setImage(newImage);
        addToImageHistory(newImage);
      } else {
        console.log('Unexpected response format');
      }
    } catch (error) {
      console.error('Error generating images:', error);
    }
    setImageLoading(false);
    setCanEditPrompt(false);
    // @ts-ignore
    setRemainingTokens(currentlyRemainingTokens - tokenCost);
    setFetchingGPT(false);
  }

  const ToggleButton = ({value, label, icon}: any) => (
    <Pressable
      onPress={() => setResponseType(value)}
      style={({pressed}) => [
        styles.segmentedButton,
        responseType === value ? styles.activeSegmentedButton : {},
        pressed && styles.pressedSegmentedButton,
      ]}
    >
      {icon(responseType === value)}
      <Text
        style={
          responseType === value
            ? styles.activeSegmentedButtonText
            : styles.segmentedButtonText
        }
      >
        {label}
      </Text>
    </Pressable>
  );

  useEffect(() => {
    if (!auth?.currentUser) return;
    // @ts-ignore
    setHasVerifiedEmail(auth?.currentUser?.emailVerified);
  }, [auth]);

  return (
    <View>
      {// @ts-ignore
        (!hasVerifiedEmail && (fullUserDoc.accessLevel !== 'admin' && fullUserDoc.accessLevel !== 'superuser' && fullUserDoc.email !== '1@1.nclay')) ?
          <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Pressable
              disabled={true}
              onPress={() => {
              }}
              style={{
                backgroundColor: "darkgrey",
                borderColor: "#fff",
                borderWidth: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                borderRadius: 5,
                marginBottom: 10,
              }}
            >
              <Text style={{color: "#fff"}}>Verify Email To Run Prompt</Text>
            </Pressable>
            {
              hasSentVerifyEmail ? (
                <Pressable
                  disabled
                  style={{
                    backgroundColor: "grey",
                    borderColor: "#fff",
                    borderWidth: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    borderRadius: 5,
                    maxWidth: '90%',
                  }}
                >
                  <Text style={{color: "#fff"}}>Resent Verify Email</Text>
                </Pressable>
              ) : (
                <Pressable
                  onPress={async () => {
                    if (user) {
                      await sendEmailVerification(user);
                      setHasSentVerifyEmail(true);
                    }
                  }}
                  style={{
                    backgroundColor: "#008080",
                    borderColor: "#fff",
                    borderWidth: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    borderRadius: 5,
                    maxWidth: '90%',
                  }}
                >
                  <Text style={{color: "#fff"}}>Resend Verification Email</Text>
                </Pressable>
              )
            }
          </View>
          :
          !fetchingGPT ?
            // <Button title="Run Prompt" onPress={() => getGPTData(promptContent)}/>
            <View>
              <View style={{
                backgroundColor: '#2a2a2a',
                borderRadius: 5,
                padding: 10,
                marginBottom: 15,
              }}>
                <View>
                  <View style={{
                    ...styles.titleContainer,
                    backgroundColor: 'transparent',
                    padding: 0,
                  }}>
                    <View style={{flexDirection: 'row'}}>
                      <MaterialCommunityIcons name="robot" size={24} color="#CCC"/>
                      <Text style={{
                        ...styles.title,
                        color: '#ccc',
                        marginLeft: 8,
                        fontSize: 20,
                        fontWeight: 'bold',
                      }}>
                        AI Response Type
                      </Text>
                    </View>
                    <Text style={{
                      color: '#ccc',
                      fontSize: 14,
                      fontStyle: 'italic',
                      marginTop: 5,
                      textAlign: 'center',
                    }}>
                      Choose between getting text or image responses from highPerplexity's AI!
                    </Text>
                    <View style={{
                      ...styles.segmentedButtonsContainer,
                      justifyContent: 'center',
                      marginTop: 16,
                    }}>
                      <ToggleButton
                        value="gpt"
                        label="GPT"
                        icon={() => <MaterialCommunityIcons name="brain" color={"#CCC"} size={24}/>}
                      />
                      <ToggleButton
                        value="image"
                        label="Image"
                        icon={() => <MaterialCommunityIcons name="image" color={"#CCC"} size={24}/>}
                      />
                    </View>
                  </View>
                  {(responseType === 'gpt') &&
                    <View style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginVertical: 4,
                    }}>
                      <Text style={{fontSize: 15, fontWeight: 'bold', color: '#CCC', marginRight: 8}}>
                        USE GPT-4?
                      </Text>
                      <Pressable
                        onPress={toggleSwitch}
                        style={{
                          width: 30,
                          height: 25,
                          borderRadius: 50,
                          borderWidth: 1,
                          borderColor: '#767577',
                          backgroundColor: isGPT4 ? '#3273dc' : '#767577',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Text style={{fontSize: 10, color: isGPT4 ? '#fff' : '#000'}}>
                          {isGPT4 ? 'YES' : 'NO'}
                        </Text>
                      </Pressable>
                    </View>
                  }
                </View>
              </View>
              <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Pressable
                  onPress={responseType === 'gpt' ? getGPTConvo : () => getGPTImageConvo(false)}
                  style={({pressed}) => [
                    {
                      backgroundColor: pressed ? "#6A9AE2" : "#3273DC",
                      borderColor: "#fff",
                      borderWidth: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingVertical: 16,
                      paddingHorizontal: 30,
                      borderRadius: 8,
                      shadowColor: "#000",
                      shadowOffset: {width: 0, height: 4},
                      shadowOpacity: 0.3,
                      shadowRadius: 4,
                      elevation: 4,
                      maxWidth: 300,
                    },
                  ]}
                >
                  <Text style={{color: "#fff", fontSize: 28, fontWeight: "bold", marginRight: 10}}>
                    Run Prompt
                  </Text>
                  <MaterialCommunityIcons name="play" size={35} color="#fff"/>
                </Pressable>
              </View>
            </View>
            :
            <Pressable
              disabled={true}
              onPress={() => {
              }}
              style={{
                backgroundColor: "grey",
                borderColor: "#fff",
                borderWidth: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                borderRadius: 5,
              }}
            >
              <Text style={{color: "#fff"}}>Running Prompt...</Text>
            </Pressable>
      }
    </View>
  );
};

const styles = StyleSheet.create({
  segmentedButtonsContainer: {
    flexDirection: 'row',
    marginVertical: 8,
    borderRadius: 8,
    overflow: 'hidden',
  },
  segmentedButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 8,
    borderColor: '#4B4F56',
    borderWidth: 1,
    backgroundColor: '#2C2F33',
    minWidth: 135,
  },
  activeSegmentedButton: {
    backgroundColor: '#4834d4',
  },
  pressedSegmentedButton: {
    backgroundColor: '#1E88E5',
  },
  segmentedButtonText: {
    color: '#FFF',
    marginLeft: 5,
  },
  activeSegmentedButtonText: {
    color: '#FFF',
    marginLeft: 5,
    fontWeight: 'bold',
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#FFF',
    fontSize: 16,
    marginLeft: 8,
  },
});

export default RunPromptButton;
