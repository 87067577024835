import React, {useState} from 'react';
import {Animated, Platform, Pressable, StyleSheet} from 'react-native';
import {Text, View} from 'dripsy';
import {useNavigation} from '@react-navigation/native';
import {getAuth, signOut} from 'firebase/auth';
import * as Linking from "expo-linking";
import {Ionicons as Icon} from '@expo/vector-icons';
import {ScrollView} from 'react-native-gesture-handler';
import useUnits from 'rxn-units';
import {useRoute} from '@react-navigation/native';
import {useCollapseStatus} from "../../utils/misc/useCollapseStatus";

const auth = getAuth();

const handleRedditLinkClick = () => {
  if (Platform.OS == 'web') {
    window.open('https://www.reddit.com/r/highPerplexity/', '_blank');
  } else {
    Linking.openURL('https://www.reddit.com/r/highPerplexity/');
  }
}

const DrawerMenu = (props: { navStatus: string, setNavStatus: any }) => {
  const {collapseStatus, setCollapseStatus, collapseAnim} = useCollapseStatus();
  const navigation = useNavigation();
  const {vh} = useUnits();
  const [drawerAnim] = useState(new Animated.Value(0));
  const [isHpLogoHovered, setIsHpLogoHovered] = useState(false);
  const [isSignOutHovered, setIsSignOutHovered] = useState(false);
  const [isCloseMenuHovered, setIsCloseMenuHovered] = useState(false);
  const [isRedditHovered, setIsRedditHovered] = useState(false);
  const [isCollapseExpandHovered, setIsCollapseExpandHovered] = useState(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const route = useRoute();

  const menuItems = [
    {name: 'Home', iconName: 'home', screen: 'highPerplexity'},
    {name: 'About hP', iconName: 'information-circle', screen: 'About'},
    {name: 'Browse Prompts', iconName: 'search', screen: 'Browse'},
    {name: 'Create Prompt', iconName: 'create', screen: 'Create New Prompt'},
    {name: 'My Prompts', iconName: 'list', screen: 'My Prompts'},
    {name: 'Saved Prompts', iconName: 'bookmark', screen: 'Saved Prompts'},
    {name: 'Saved Conversations', iconName: 'chatbubble-ellipses', screen: 'My Conversations'},
    {name: 'Store', iconName: 'cart', screen: 'Store'},
    {name: 'Token Manager', iconName: 'calculator', screen: 'Token Manager'},
    {name: 'Settings', iconName: 'settings', screen: 'Settings'},
  ];

  const renderItem = (item: any, index: any) => {
    const iconSize = 24;
    const [isHovered, setIsHovered] = useState(false);
    const isActive = route.name === item.screen;

    const handleHoverIn = () => setIsHovered(true);
    const handleHoverOut = () => setIsHovered(false);

    return (
      <Pressable
        key={item.name}
        onPress={() => {
          props.setNavStatus('closed');
          //@ts-ignore
          navigation.navigate(item.screen, item.params);
        }}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        style={[
          styles.drawerMenuItem,
          isHovered && {backgroundColor: '#3a3a3a'},
          isActive && {backgroundColor: '#1e1e1e'},
        ]}
      >
        <Icon
          name={item.iconName}
          size={iconSize}
          color={isActive ? '#50E3C2' : '#FFF'}
        />
        {!collapseStatus && (
          <Text
            style={[
              styles.drawerMenuItemText,
              isActive && {color: '#50E3C2'},
            ]}
          >
            {item.name}
          </Text>
        )}
      </Pressable>
    );
  };

  const animationDuration = Platform.OS === 'web' ||
  (route.name !== "highPerplexity" &&
    route.name !== "Welcome" &&
    route.name !== "Browse") ? 100 : 0;

  Animated.timing(drawerAnim, {
    toValue: props.navStatus === "closed" ? 0 : 1,
    duration: animationDuration,
    useNativeDriver: false,
  }).start();

  return (<Animated.View
    style={[
      styles.drawerMenu,
      {maxHeight: vh(100)},
      {
        width: collapseAnim.interpolate({
          inputRange: [0, 1],
          outputRange: [240, 70],
        }),
      },
      {
        transform: [
          {
            translateX: drawerAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [-240, 0],
            }),
          },
        ],
      },
    ]}
  >
    <ScrollView showsVerticalScrollIndicator={showScrollIndicator}>
      <View>
        <Pressable
          onHoverIn={() => setIsHpLogoHovered(true)}
          onHoverOut={() => setIsHpLogoHovered(false)}
          onPress={() => {
            props.setNavStatus("closed");
            //@ts-ignore
            navigation.navigate("highPerplexity");
          }}
          style={[
            styles.drawerLogoContainer,
            {overflow: "hidden"},
            isHpLogoHovered && {backgroundColor: "#3a3a3a"},
          ]}
        >
          <Text style={styles.drawerLogoText}>hP</Text>
        </Pressable>
        <Pressable
          onPress={() => {
            setShowScrollIndicator(false);
            Animated.timing(collapseAnim, {
              toValue: collapseStatus ? 0 : 1,
              duration: 200,
              useNativeDriver: false,
            }).start(() => {
              const newCollapseStatus = !collapseStatus;
              setCollapseStatus(newCollapseStatus);
              setShowScrollIndicator(true);
            });
          }}
          onHoverIn={() => setIsCollapseExpandHovered(true)}
          onHoverOut={() => setIsCollapseExpandHovered(false)}
          style={[
            styles.collapseButton,
            isCollapseExpandHovered && {backgroundColor: '#3a3a3a'},
          ]}
        >
          <View style={styles.collapseButtonContainer}>
            <Icon
              name={
                collapseStatus
                  ? 'chevron-forward-circle-outline'
                  : 'chevron-back-circle-outline'
              }
              size={24}
              color="#FFFFFF"
            />
            {!collapseStatus && (
              <Text style={styles.collapseButtonText}>Slim View</Text>
            )}
          </View>
          {collapseStatus && (
            <Text style={{color: '#FFFFFF', fontSize: 12}}>
              Expand
            </Text>
          )}
        </Pressable>
        {menuItems.map(renderItem)}
        <Pressable
          onPress={handleRedditLinkClick}
          onHoverIn={() => setIsRedditHovered(true)}
          onHoverOut={() => setIsRedditHovered(false)}
          style={[
            styles.drawerMenuItem,
            isRedditHovered && {backgroundColor: "#3a3a3a"},
          ]}
        >
          <Icon name="logo-reddit" size={24} color="#FFFFFF"/>
          {!collapseStatus && (
            <Text style={styles.drawerMenuItemText}>r/highPerplexity</Text>
          )}
        </Pressable>
        <Pressable
          onPress={() => {
            props.setNavStatus("closed");
            signOut(auth);
          }}
          onHoverIn={() => setIsSignOutHovered(true)}
          onHoverOut={() => setIsSignOutHovered(false)}
          style={[
            styles.drawerMenuItem,
            isSignOutHovered && {backgroundColor: "#3a3a3a"},
          ]}
        >
          <Icon name="log-out" size={24} color="#FFFFFF"/>
          {!collapseStatus && (
            <Text style={styles.drawerMenuItemText}>Sign Out</Text>
          )}
        </Pressable>
        <Pressable
          onPress={() => props.setNavStatus("closed")}
          onHoverIn={() => setIsCloseMenuHovered(true)}
          onHoverOut={() => setIsCloseMenuHovered(false)}
          style={[
            styles.drawerMenuItem,
            {flexDirection: collapseStatus ? 'column' : 'row'},
            isCloseMenuHovered && {backgroundColor: "#3a3a3a"},
          ]}
        >
          <Icon name="close" size={24} color="#FFFFFF"/>
          {!collapseStatus && (
            <Text style={[styles.drawerMenuItemText, {marginLeft: 2}]}>Close Menu</Text>
          )}
          {collapseStatus && (
            <Text style={{color: '#FFFFFF', fontSize: 12}}>
              Close
            </Text>
          )}
        </Pressable>
      </View>
    </ScrollView>
  </Animated.View>);
}

const styles = StyleSheet.create({
  drawerMenu: {
    position: Platform.OS === "web" ? ("fixed" as any) : "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    flex: 1,
    backgroundColor: '#2C2C2C',
    zIndex: 100,
    borderRightWidth: 1,
    borderRightColor: '#505050',
  },
  drawerMenuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderColor: '#505050',
    padding: 8,
    flex: 1,
    textAlign: 'center',
  },
  drawerMenuItemText: {
    fontSize: 18,
    color: '#FFFFFF',
    marginLeft: 6,
  },
  drawerLogoContainer: {
    borderWidth: 1,
    borderColor: '#FFF',
    minWidth: 60,
    minHeight: 60,
    borderRadius: 60 / 2,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 5
  },
  drawerLogoText: {
    color: '#FFFFFF',
    fontSize: 25,
    padding: 12,
  },
  collapseButton: {
    alignItems: 'center',
    padding: 8,
  },
  collapseButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  collapseButtonText: {
    color: '#FFFFFF',
    fontSize: 18,
    marginLeft: 4,
  },
});

export default DrawerMenu;
