import React from 'react';
import {StyleSheet} from 'react-native';
import {Pressable, View} from 'dripsy';
import {Card, Text} from 'react-native-paper';
import {defaultTagImageData} from "../utils/misc/defaultImageData";

const TagCard = (props: {
  navigation: any,
  tagTitle: string,
  promptAmount: number,
}) => {
  return (<Pressable
    sx={{marginRight: [0, 0, 20], marginBottom: 15, width: [300, 400, 300]}}
    onPress={() => props.navigation.navigate('View Tag', {tagName: props.tagTitle})}
  >
    <Card style={{backgroundColor: '#25232A'}}>
      <Card.Content style={{marginBottom: 10}}>
        <Text variant="titleSmall" style={{fontWeight: '700', color: '#FFF', textAlign: 'center'}}>
          {props.tagTitle}
        </Text>
      </Card.Content>
      <Card.Cover source={{uri: defaultTagImageData}}/>
      <Card.Content style={{marginTop: 10, flexDirection: 'row', justifyContent: 'center'}}>
        <View style={styles.cardTextContainer}>
          <Text variant="bodySmall" style={{color: '#FFF'}}>
            {props.promptAmount} {(props.promptAmount === 1) ? 'Prompt' : 'Prompts'}
          </Text>
        </View>
      </Card.Content>
    </Card>
  </Pressable>);
}

const styles = StyleSheet.create({
  cardTextContainer: {
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
  },
});

export default TagCard;
