import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {ActivityIndicator, Dimensions, RefreshControl, SafeAreaView, StyleSheet, TextInput} from "react-native";
import {ScrollView} from 'react-native-gesture-handler';
import {Pressable, Text, View} from "dripsy";
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {StackScreenProps} from "@react-navigation/stack";
import {collection, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import {useFocusEffect} from "@react-navigation/native";
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import {getAuth} from "firebase/auth";
import TypingIndicator from "../../components/TypingIndicator";
import useUnits from "rxn-units";
import * as Clipboard from "expo-clipboard";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import base64 from 'base64-js';

const windowHeight = Dimensions.get('window').height;

const GPTConversationScreen: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [userDoc, setUserDoc] = useState('');
  const [fullUserDoc, setFullUserDoc] = useState();
  const [convoDoc, setConvoDoc] = useState();
  const [modelType, setModelType] = useState('');
  const [tokenCost, setTokenCost] = useState();
  const {user} = useAuthentication();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [remainingTokens, setRemainingTokens] = useState(0);
  const [updateToggle, setUpdateToggle] = useState(false);
  const {vh} = useUnits();
  const [isAfterFirstLoad, setIsAfterFirstLoad] = useState(false);
  const scrollViewRef = useRef(null);
  const [copiedMessageStatus, setCopiedMessageStatus] = useState([]);
  const [convoId, setConvoId] = useState();
  const [gpt3UserAPI, setGpt3UserAPI] = useState(false);
  const [gpt4UserAPI, setGpt4UserAPI] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  useFocusEffect(
    useCallback(() => {
      setConvoId(route?.params?.convoId);
      fetchConversation();
      setUpdateToggle(!updateToggle);
    }, [route?.params?.convoId])
  );

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      // @ts-ignore
      scrollViewRef.current.scrollToEnd({animated: true});
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 25);
  }, [messages]);

  const handleCopyMessage = async (itemContent: any, index: any) => {
    if (itemContent) {
      await Clipboard.setStringAsync(itemContent);
      const newCopiedMessageStatus = [...copiedMessageStatus];
      // @ts-ignore
      newCopiedMessageStatus[index] = true;
      setCopiedMessageStatus(newCopiedMessageStatus);
      setTimeout(() => {
        const updatedCopiedMessageStatus = [...copiedMessageStatus];
        // @ts-ignore
        updatedCopiedMessageStatus[index] = false;
        setCopiedMessageStatus(updatedCopiedMessageStatus);
      }, 75);
    }
  };

  useEffect(() => {
    (async () => {
      if (!auth || !convoId) return;
      const querySnapshot = await getDocs(collection(db, "conversations"));
      const matchingDoc = querySnapshot.docs.find(doc => convoId === doc.data().id);
      if (matchingDoc) {
        // @ts-ignore
        setConvoDoc(matchingDoc.data());
        setModelType(matchingDoc?.data()?.model || 'gpt3');
      }
    })();
  }, [updateToggle, auth, convoId]);

  useEffect(() => {
    if (!modelType || !fullUserDoc) return;
    if (modelType === 'gpt4') {
      // @ts-ignore
      setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt4UserAPI)
        ? 0 : 2);
    } else {
      // @ts-ignore
      setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt3UserAPI)
        ? 0 : 1);
    }
  }, [modelType, fullUserDoc]);

  useEffect(() => {
    if (!user) return;
    (async () => {
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === user?.uid) {
          setRemainingTokens(doc?.data()?.tokenAdmin || 0);
        }
      });
    })();
  }, [updateToggle, user]);

  const fetchConversation = async () => {
    const convoId = route?.params?.convoId;
    if (!convoId) return;

    const conversationDoc = await getDoc(doc(db, 'conversations', convoId));
    if (conversationDoc.exists()) {
      // @ts-ignore
      setMessages(conversationDoc.data().conversationHistory);
    } else {
      console.error('No conversation found with ID:', convoId);
    }
    setLoading(false);
  };

  const updateConversation = async (convoId: any, newMessages: any) => {
    const conversationRef = doc(db, 'conversations', convoId);
    await updateDoc(conversationRef, {conversationHistory: newMessages});
  };

  useEffect(() => {
    if (!user) return;
    (async () => {
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === user?.uid) {
          setUserDoc(doc.id);
          // @ts-ignore
          setFullUserDoc(doc.data());
          if (doc.data().gpt3UserAPI) {
            setGpt3UserAPI(true)
          } else {
            setGpt3UserAPI(false)
          }
          if (doc.data().gpt4UserAPI) {
            setGpt4UserAPI(true)
          } else {
            setGpt4UserAPI(false)
          }
        }
      });
    })();
  }, [user, updateToggle]);

  const renderItem = (item: any, isMessageCopied: any, handleCopyMessage: any, key: any) => {
    const itemContent = item.content;
    const showCopyIcon = itemContent !== "One moment please...";

    return (
      <Pressable key={key} onPress={handleCopyMessage}>
        <View
          style={[
            styles.messageWrapper,
            item.role === "assistant" ? styles.assistantMessageWrapper : null,
          ]}
        >
          <View style={
            [
              styles.labelWrapper,
              item.role === "assistant" && styles.highPerplexityLabel,
              {marginRight: 10},
            ]
          }>
            <Text
              style={[
                styles.label,
                item.role === "assistant"
                  ? styles.assistantLabel
                  : styles.userLabel,
              ]}
            >
              {item.role === "assistant" ? "hP" : "You"}
            </Text>
          </View>
          <View
            style={[
              styles.messageBubble,
              item.role === "assistant" ? styles.assistantMessageBubble : null,
              {paddingRight: itemContent === "One moment please..." ? 10 : 30}
            ]}
          >
            <View style={{flex: 1}}>
              {itemContent === "One moment please..." ? (
                <View style={{flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                  <TypingIndicator/>
                </View>
              ) : (
                <Text
                  style={[
                    styles.messageText,
                    item.role === "assistant" ? styles.assistantMessageText : null,
                  ]}
                >
                  {itemContent}
                </Text>
              )}
            </View>
            {showCopyIcon && (
              <View style={{position: "absolute", bottom: 5, right: 5}}>
                <MaterialCommunityIcons
                  name="content-copy"
                  size={20}
                  color={
                    isMessageCopied
                      ? "#4A90E2"
                      : item.role === "assistant"
                        ? "#000"
                        : "#FFF"
                  }
                />
              </View>
            )}
          </View>
        </View>
      </Pressable>
    );
  };

  const sendMessage = async () => {
    if (!userInput) return;
    setLoading(true);

    let accountType = 'basic';

    // @ts-ignore
    if (fullUserDoc?.accessLevel) {
      // @ts-ignore
      accountType = fullUserDoc?.accessLevel;
    }

    const newMessages = [
      ...messages,
      {role: "user", content: userInput},
      {role: "assistant", content: "One moment please..."},
    ];
    // @ts-ignore
    setMessages(newMessages);

    // @ts-ignore
    const authTokenValue = await auth.currentUser.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    // @ts-ignore
    let APIStatus;

    if (modelType === 'gpt4') {
      APIStatus = gpt4UserAPI;
    }

    if (modelType === 'gpt3') {
      APIStatus = gpt3UserAPI;
    }

    let socket = new WebSocket("wss://ws.highperplexity.com");

    socket.addEventListener('open', (event) => {
      socket.send(JSON.stringify({
        modelToggle: modelType,
        conversation: newMessages.slice(0, -1),
        firebase_token_value: authTokenValue.token,
        accountType: accountType,
        // @ts-ignore
        APIStatus: APIStatus,
        convoId: convoId,
      }));
    });

    let completionContent = '';

    socket.addEventListener('message', (event) => {
      const result = JSON.parse(event.data);

      const tempConvoId = result?.convoId;
      if (tempConvoId) setConvoId(tempConvoId);

      if (result?.response === '[DONE]') {
        const GPTMessage = completionContent.trim();
        const updatedMessages = [
          ...newMessages.slice(0, -1),
          {role: "assistant", content: GPTMessage},
        ];
        // @ts-ignore
        setMessages(updatedMessages);

        if (tempConvoId) updateConversation(tempConvoId, updatedMessages);

        // @ts-ignore
        setRemainingTokens(remainingTokens - tokenCost);
        setUserInput("");
        setLoading(false);
      } else if (result?.message === "No remaining tokens") {
        const GPTMessage =
          "You have no remaining tokens, go to the Settings page to get more tokens for free!";
        const updatedMessages = [
          ...newMessages.slice(0, -1),
          {role: "assistant", content: GPTMessage},
        ];
        // @ts-ignore
        setMessages(updatedMessages);
        // @ts-ignore
        setRemainingTokens(remainingTokens - tokenCost);
        setLoading(false);
      } else {
        let data = result?.response?.data;
        let chunkData;
        if (Array.isArray(data)) {
          chunkData = String.fromCharCode.apply(null, data);
        } else if (typeof data === 'string') {
          if (data.length % 4 !== 0) {
            data += '='.repeat(4 - data.length % 4);
          }
          const byteArray = base64.toByteArray(data);
          chunkData = new TextDecoder().decode(byteArray);
        }

        //@ts-ignore
        const chunkDataArray = chunkData.split('\n');

        chunkDataArray.forEach((chunkData, index) => {
          if (index !== 0) {
            chunkData = 'data: ' + chunkData;
          }

          const jsonStart = chunkData.indexOf('{');
          const jsonEnd = chunkData.lastIndexOf('}');
          if (jsonStart >= 0 && jsonEnd >= 0) {
            const jsonStr = chunkData.substring(jsonStart, jsonEnd + 1).trim();

            const sanitizedJsonStr = jsonStr.replace(/[\n\r]+|[\s]{2,}/g, ' ');

            const chunkJson = JSON.parse(sanitizedJsonStr);

            const chunk = chunkJson?.choices[0]?.delta?.content;
            if (chunk) {
              completionContent += chunk;

              const updatedMessages = [
                ...newMessages.slice(0, -1),
                {role: "assistant", content: completionContent},
              ];
              // @ts-ignore
              setMessages(updatedMessages);
            }
          }
        });
      }
    });

    socket.addEventListener('close', (event) => {
      console.log('Server connection closed: ', event.code);
    });

    socket.addEventListener('error', (event) => {
      console.log('WebSocket error: ', event);
    });
  };

  const renderInput = () => {
    if (loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="small" color="#FFF"/>
          <Text style={styles.loadingIndicator}>Waiting for response...</Text>
        </View>
      );
    }

    if (!isAfterFirstLoad) {
      setIsAfterFirstLoad(true);
    }

    return (
      <>
        <TextInput
          style={[styles.input]}
          value={userInput}
          onChangeText={setUserInput}
          placeholder="Type your message..."
          placeholderTextColor="#999"
          multiline={true}
        />
        <Pressable style={styles.sendButton} onPress={sendMessage}>
          <Text style={styles.sendButtonText}>Send</Text>
        </Pressable>
      </>
    );
  };

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  // @ts-ignore
  if ((loading || !user?.uid || !convoDoc?.createdBy || !fullUserDoc) && !isAfterFirstLoad) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.chatContentContainer}>
              <View style={styles.container}>
                <View style={styles.chatLogoContainer}>
                  <View style={styles.chatLogoCircle}>
                    <Text style={styles.chatLogoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.chatLoadingText}>is loading...</Text>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  // @ts-ignore
  if (user.uid !== convoDoc?.createdBy) {
    return <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <ScrollView>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
          <View style={styles.chatContentContainer}>
            <Text style={{color: '#FFF', textAlign: 'center', fontSize: 25}}>
              Sorry, saved conversations can only be viewed by the user that saved them.
            </Text>
          </View>
        </Pressable>
      </ScrollView>
    </SafeAreaView>
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <View style={{flex: 1}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <View style={styles.contentContainer}>
              <View style={{marginBottom: 12}}>
                <Text style={{color: '#FFF', fontSize: 20, marginBottom: 8, textAlign: 'center'}}>
                  Saved Conversation:
                </Text>
                <Text style={{color: '#FFF', fontSize: 20, textAlign: 'center'}}>
                  {//@ts-ignore
                    convoDoc?.name}
                </Text>
              </View>
              <View sx={{width: ['85%', '90%', '100%']}}>
                <View style={styles.chatContentContainer}>
                  <View style={styles.chatWrapper}>
                    <ScrollView
                      ref={scrollViewRef}
                      contentContainerStyle={styles.chatContainer}
                      showsVerticalScrollIndicator={false}
                      onContentSizeChange={() => {
                        setTimeout(() => {
                          // @ts-ignore
                          scrollViewRef.current.scrollToEnd({animated: true});
                        }, 10);
                      }}
                    >
                      {messages.map((item, index) => (
                        renderItem(
                          item,
                          copiedMessageStatus[index],
                          // @ts-ignore
                          () => handleCopyMessage(item.content, index),
                          index
                        )
                      ))}
                    </ScrollView>
                  </View>
                  <View style={{marginTop: 10}}>
                    <Text style={styles.remainingTokensText}>Using {(modelType === 'gpt4') ? 'GPT-4' : 'GPT-3'}</Text>
                  </View>
                  <View style={{marginVertical: 10}}>
                    <Text style={[styles.remainingTokensText, {marginBottom: 8}]}>
                      Token Cost: {tokenCost}
                    </Text>
                    <Text style={styles.remainingTokensText}>
                      Remaining Tokens: {remainingTokens}
                    </Text>
                  </View>
                  <View style={styles.inputWrapper}>{renderInput()}</View>
                </View>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </View>
      <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#202124',
    cursor: 'default',
  },
  chatWrapper: {
    flex: 1,
    maxHeight: (windowHeight || 0) * 0.7,
  },
  chatContentContainer: {
    flex: 1,
  },
  chatContainer: {
    flexGrow: 1,
    alignItems: 'flex-start',
    maxWidth: 600,
    width: '100%',
    paddingHorizontal: 10,
  },
  messageWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 5,
    width: '100%',
  },
  assistantMessageWrapper: {
    justifyContent: 'flex-end',
  },
  icon: {
    marginHorizontal: 5,
    alignSelf: 'flex-start',
    opacity: 0.8,
  },
  messageBubble: {
    backgroundColor: '#4E88DE',
    borderRadius: 5,
    padding: 10,
    marginBottom: 5,
    flex: 1,
  },
  assistantMessageBubble: {
    backgroundColor: '#EFEFEF',
    borderRadius: 5,
    padding: 10,
    marginBottom: 5,
    flex: 1,
    flexDirection: 'row',
  },
  messageText: {
    color: '#FFF',
    fontWeight: 'bold',
  },
  assistantMessageText: {
    color: '#202124',
    fontStyle: 'italic',
  },
  labelWrapper: {
    width: 40,
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
  },
  userLabel: {
    color: '#4E88DE',
  },
  assistantLabel: {
    color: '#9FA8DA',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#2D2F31',
    borderRadius: 5,
    marginBottom: 20,
  },
  input: {
    flex: 1,
    backgroundColor: '#EFEFEF',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginRight: 10,
    color: '#202124',
  },
  sendButton: {
    backgroundColor: "#4E88DE",
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 10,
  },
  sendButtonText: {
    color: "#FFF",
  },
  loadingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  loadingIndicator: {
    color: "#FFF",
    fontSize: 16,
    marginLeft: 8,
  },
  remainingTokensText: {
    color: "#FFF",
    textAlign: 'center',
  },
  highPerplexityLabel: {
    borderWidth: 1,
    borderColor: "#9FA8DA",
    borderRadius: 50,
    paddingVertical: 9,
  },
  chatScrollContainer: {
    flexGrow: 1,
  },
  chatLogoContainer: {
    marginBottom: 10,
  },
  chatLogoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatLogoText: {
    color: '#FFF',
    fontSize: 24,
  },
  chatLoadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
});

export default GPTConversationScreen;
