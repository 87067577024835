import React from 'react';
import {Text} from 'react-native';
import {View} from "dripsy";
import CustomTextInput from "../CustomTextInput";
import {IconButton} from 'react-native-paper';

const CustomPromptInputSection = ({
                                    customPromptInput,
                                    setCustomPromptInput,
                                    setTooltipId,
                                    setTooltipVisible
                                  }: any) => {
  const showTooltip = () => {
    setTooltipId("customPrompt");
    setTooltipVisible(true);
  };
  return (
    <View style={{marginBottom: 16, alignItems: "center"}} sx={{width: [300, 400, 500]}}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{color: "#FFF", fontSize: 22}}>Custom Input</Text>
        <IconButton
          icon="information-outline"
          size={20}
          style={{margin: 0}}
          onPress={showTooltip}
          theme={{colors: {primary: "#fff"}}}
        />
      </View>
      <Text style={{color: "#ccc", fontSize: 14, textAlign: "center", marginBottom: 10, marginTop: 5}}>
        Write your own custom input and press Run Prompt to get your personalized response from highPerlexity.
        Tap the tooltip icon for more info.
      </Text>
      <View style={{width: '100%'}}>
        <CustomTextInput
          value={customPromptInput}
          onChangeText={setCustomPromptInput}
          placeholder={"Enter your custom input before running prompt..."}
          inputType="customPrompt"
        />
      </View>
    </View>
  );
};

export default CustomPromptInputSection;
