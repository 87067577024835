import React, {FC, useCallback, useState} from 'react';
import {RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import Icon from 'react-native-vector-icons/FontAwesome';
import {Button, Input} from 'react-native-elements';
import {StackScreenProps} from '@react-navigation/stack';
import {getAuth, sendPasswordResetEmail} from 'firebase/auth';
import {Link} from '@react-navigation/native';
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import useUnits from "rxn-units";

const ForgotPasswordScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const auth = getAuth();
  const [value, setValue] = useState({email: ''});
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  async function sendResetEmail(email: string) {
    await sendPasswordResetEmail(auth, email);
    setValue({...value, email: ''});
    setHasSentEmail(true);
  }

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <View style={{flex: 1}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <View style={styles.logoContainer}>
              <Text style={styles.logoText}>
                hP
              </Text>
            </View>
            {hasSentEmail && <View style={{marginTop: 20}}>
              <Text style={{color: '#FFF', fontSize: 16}}>
                Reset password email has been sent!
              </Text>
            </View>
            }
            <View style={styles.subheaderContainer}>
              <Text
                style={styles.subheaderText}
                sx={{fontSize: [15, 18, 22]}}
              >
                Forgot your password?
              </Text>
            </View>
            <View style={styles.controls}>
              <Input
                placeholder='Email'
                value={value.email}
                onChangeText={(text) => setValue({...value, email: text})}
                style={styles.control}
                leftIcon={<Icon
                  name='envelope'
                  size={16}
                  color={'#FFF'}
                />}
              />
              <Button title="Send Reset Email" onPress={() => sendResetEmail(value.email)}/>
              <Link to={{screen: 'Welcome'}} style={styles.goBackButton}>
                <Text style={{color: '#E5E5E8', fontSize: 18, fontWeight: '400'}}>
                  Back
                </Text>
              </Link>
            </View>
          </Pressable>
        </ScrollView>
      </View>
      <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#202124',
    alignItems: 'center',
    cursor: 'default',
  },
  logoContainer: {
    borderWidth: 1,
    borderColor: '#FFF',
    width: 90,
    height: 90,
    borderRadius: 90 / 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
  },
  logoText: {
    color: '#FFF',
    fontSize: 45
  },
  subheaderContainer: {
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  subheaderText: {
    color: '#FFF'
  },
  controls: {
    width: 250
  },
  control: {
    color: '#FFF'
  },
  error: {
    marginTop: 10,
    padding: 10,
    color: '#fff',
    backgroundColor: '#D54826FF',
  },
  goBackButton: {
    marginTop: 10,
    backgroundColor: '#737781',
    textAlign: 'center',
    padding: 8,
    borderRadius: 5
  },
});

export default ForgotPasswordScreen;
