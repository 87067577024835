import React, {useState} from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {Button} from 'react-native-elements';
import {Pressable} from "dripsy";
import {FontAwesome} from "@expo/vector-icons";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import {getAuth} from "firebase/auth";

const RatingSection = ({
                         promptId,
                         promptRating,
                         totalPromptRating,
                         totalPromptVotes,
                         setPromptRating,
                         userDoc,
                         setAllPromptRatings,
                         matchingPrompt,
                       }: any) => {
  const [isSavingRating, setIsSavingRating] = useState(false);
  const [isSaveRatingActive, setIsSaveRatingActive] = useState(false);
  const auth = getAuth();
  const playgroundPromptId = 'b148c38f-0cc3-435d-8ccc-93e59fe028e3';

  const handleRating = (rating: any) => {
    setPromptRating(rating);
    setIsSaveRatingActive(true);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Pressable key={i} onPress={() => handleRating(i)} style={{marginRight: i < 5 ? 10 : 0}}>
          <FontAwesome
            name={i <= promptRating ? 'star' : 'star-o'}
            size={30}
            color="#C0C0C0"
          />
        </Pressable>
      );
    }
    return stars;
  };

  const saveRating = () => {
    if (isSavingRating) return;
    (async () => {
      setIsSavingRating(true);
      const authTokenValue = await auth?.currentUser?.getIdTokenResult();
      // @ts-ignore
      const userRef = doc(db, "users", userDoc);
      // @ts-ignore
      await updateDoc(userRef, {currentJWT: authTokenValue.token});

      const response = await fetch("https://highperplexity-prompt-ratings-update-mjp27ovqaq-uc.a.run.app", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "firebase_token_value": authTokenValue?.token,
          "rating": promptRating,
          // @ts-ignore
          "promptId": matchingPrompt?.promptId,
        }),
      });

      await response.json();

      const querySnapshot = await getDocs(collection(db, "promptRatings"));
      const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);

      if (matchingDoc) {
        setAllPromptRatings(matchingDoc?.data()?.ratings || []);
      } else {
        // @ts-ignore
        setAllPromptRatings([]);
      }

      setIsSaveRatingActive(false);
      setIsSavingRating(false);
    })();
  };

  if (promptId === playgroundPromptId) {
    return null;
  }

  return (
    <>
      <View style={styles.sliderContainer}>
        <Text style={{color: '#FFF', textAlign: 'center', fontSize: 25}}>
          Rate This Prompt:
        </Text>
        <View style={styles.starContainer}>{renderStars()}</View>
        <View>
          <Text style={{color: '#FFF', textAlign: 'center', fontSize: 22}}>
            Your Rating: {promptRating ? promptRating.toFixed(0) : 'Not Rated'}
          </Text>
        </View>
        {isSaveRatingActive && (
          <View style={{marginTop: 10}}>
            <Button title="Save Rating" onPress={saveRating}/>
          </View>
        )}
      </View>
      <View style={{marginBottom: 5, flexDirection: 'row'}}>
        <View
          style={{
            borderWidth: 1,
            borderColor: '#FFF',
            borderRadius: 15,
            paddingVertical: 5,
            paddingHorizontal: 10,
            marginRight: 15,
          }}
        >
          <Text style={{color: '#FFF'}}>Total Points: {totalPromptRating || 'None'}</Text>
        </View>
        <View
          style={{
            borderWidth: 1,
            borderColor: '#FFF',
            borderRadius: 15,
            paddingVertical: 5,
            paddingHorizontal: 10,
          }}
        >
          <Text style={{color: '#FFF'}}>
            Average
            Rating: {(totalPromptRating && totalPromptVotes)
            ? (totalPromptRating / totalPromptVotes).toFixed(2)
            : 'None'}
          </Text>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  sliderContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  starContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 10,
  },
});

export default RatingSection;
