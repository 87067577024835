import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Dimensions, Platform, RefreshControl, SafeAreaView, ScrollView} from 'react-native';
import {Pressable, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import {Configure, Index, InstantSearch,} from 'react-instantsearch-native';
import algoliasearch from 'algoliasearch';
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import CustomSortBy from "../../components/CustomSortBy";
import CustomSearchFilter from "../../components/CustomSearchFilter";
import CustomInfiniteHits from "../../components/CustomInfiniteHits";
import ScrollToTop from "../../components/ScrollToTop";

const algoliaClient = algoliasearch('DSWB3NXLUY', '959f1b50ecd093c1de513294bd97b4bf');

const screenWidth = Dimensions.get('window').width;

const HighPerplexityHome: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const scrollRef = useRef(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const CustomConfigure = ({searchQuery}: any) => <Configure query={searchQuery}/>;

  const scrollToTop = () => {
    if (Platform.OS === 'web') {
      window.scrollTo({top: 0, behavior: 'smooth'});
    } else {
      //@ts-ignore
      scrollRef.current.scrollTo({x: 0, y: 0, animated: true});
    }
  };

  const handleScroll = (event: any) => {
    const yOffset = Platform.OS === 'web' ? event.nativeEvent.contentOffset.y : event.nativeEvent.contentOffset.y;
    setShowScrollToTop(yOffset > 100);
  };

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  useEffect(() => {
    const handleWebScroll = () => {
      if (Platform.OS === 'web') {
        const yOffset = window.scrollY;
        setShowScrollToTop(yOffset > 100);
      }
    };

    if (Platform.OS === 'web') {
      window.addEventListener('scroll', handleWebScroll);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('scroll', handleWebScroll);
      }
    };
  }, []);

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <InstantSearch
        indexName="highPerplexity"
        searchClient={algoliaClient}
      >
        <Index indexName="highPerplexity">
          <View style={{flex: 1}}>
            <ScrollView
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>}
              ref={scrollRef}
              onScroll={handleScroll}
              scrollEventThrottle={16}
            >
              <Pressable
                style={{flex: 1, alignItems: 'center'}}
                sx={{minHeight: [vh(100)], cursor: 'default'}}
                onPress={closeDrawerMenu}
              >
                <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
                <View style={{flexDirection: 'row', marginBottom: 10}}>
                  <View style={{marginRight: screenWidth < 768 ? 10 : 20}}>
                    <CustomSortBy
                      defaultRefinement="highPerplexity_popular"
                      items={[
                        {value: 'highPerplexity_popular', label: 'Most Popular'},
                        {value: 'highPerplexity', label: 'Most Relevant (To Filter)'},
                        {value: 'highPerplexity_timestamp_desc', label: 'Newest'},
                        {value: 'highPerplexity_timestamp_asc', label: 'Oldest'},
                      ]}
                    />
                  </View>
                  <View>
                    <CustomSearchFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
                  </View>
                </View>
                <CustomConfigure searchQuery={searchQuery}/>
                {//@ts-ignore
                  <CustomInfiniteHits searchQuery={searchQuery} navigation={navigation}/>}
              </Pressable>
            </ScrollView>
          </View>
        </Index>
      </InstantSearch>
      <ScrollToTop showScrollToTop={showScrollToTop} scrollToTop={scrollToTop}/>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

export default HighPerplexityHome;
