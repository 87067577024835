import React from 'react';
import {SafeAreaView, StyleSheet, Text, View} from 'react-native';
import {Pressable} from "dripsy";
import Header from "./Header";
import DrawerMenu from "./DrawerMenu";
import useUnits from "rxn-units";

const DeletedScreen = ({navStatus, setNavStatus, navigation, closeDrawerMenu}: any) => {
  const {vh} = useUnits();
  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
        <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
        <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
        <View style={styles.contentContainer}>
          <View style={styles.container}>
            <View style={styles.logoContainer}>
              <View style={styles.logoCircle}>
                <Text style={styles.logoText}>hP</Text>
              </View>
            </View>
            <Text style={styles.loadingText}>Sorry, this prompt is no longer available...</Text>
          </View>
        </View>
      </Pressable>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  logoText: {
    color: '#FFF',
    fontSize: 35
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  logoContainer: {
    marginBottom: 10,
  },
});

export default DeletedScreen;
