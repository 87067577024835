import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {
  ActivityIndicator,
  Button,
  Dimensions,
  Image,
  Modal,
  Platform,
  RefreshControl,
  SafeAreaView,
  StyleSheet,
  Switch,
  TextInput,
  useWindowDimensions
} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {Card, HelperText, IconButton, Paragraph, Portal, Title} from 'react-native-paper';
import {Pressable, Text, useSx, View} from 'dripsy';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {db} from "../../config/firebase";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from "firebase/firestore";
import {StackScreenProps} from "@react-navigation/stack";
import * as Clipboard from 'expo-clipboard';
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import uuid from "react-native-uuid";
import {getAuth} from 'firebase/auth';
import useUnits from "rxn-units";
import {Link, useFocusEffect} from '@react-navigation/native';
import {AntDesign, Feather, FontAwesome, Ionicons, MaterialCommunityIcons, MaterialIcons} from '@expo/vector-icons';
import {defaultImageArray} from "../../utils/misc/defaultImageData";
import {imageLoadingMessages, loadingPromptMessages} from "../../utils/misc/promptLoadingMessages";
import CustomTextInput from "../../components/CustomTextInput";
import TypingIndicator from "../../components/TypingIndicator";
import {LinearGradient} from "expo-linear-gradient";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import Onboarding from "react-native-onboarding-swiper";
import {CommentList} from "../../components/CommentList";
import CommentInput from "../../components/CommentInput";

const ViewPromptScreen: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const {vh} = useUnits();
  const [promptId, setPromptId] = useState();
  const [navStatus, setNavStatus] = useState('closed');
  const [matchingPrompt, setMatchingPrompt] = useState();
  const [mySavedPrompts, setMySavedPrompts] = useState();
  const [userDoc, setUserDoc] = useState('');
  const [fullUserDoc, setFullUserDoc] = useState();
  const [userUpvotedComments, setUserUpvotedComments] = useState();
  const [promptVariables, setPromptVariables] = useState([]);
  const [promptComments, setPromptComments] = useState([]);
  const [promptLeaveComment, setPromptLeaveComment] = useState('');
  const [originalPromptContent, setOriginalPromptContent] = useState();
  const [promptContent, setPromptContent] = useState();
  const customSX = useSx();
  const {user} = useAuthentication();
  const [isAlreadySaved, setIsAlreadySaved] = useState(false);
  const [isPromptCreator, setIsPromptCreator] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [promptDocId, setPromptDocId] = useState();
  const [promptImage, setPromptImage] = useState();
  const [GPTResponse, setGPTResponse] = useState([]);
  const [GPTStream, setGPTStream] = useState('');
  const [commentReplyValues, setCommentReplyValues] = useState([]);
  const [parentComments, setParentComments] = useState([]);
  const [childComments, setChildComments] = useState([]);
  const [fetchingGPT, setFetchingGPT] = useState(false);
  const auth = getAuth();
  const [hasVerifiedEmail, setHasVerifiedEmail] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [isSaveResponseActive, setIsSaveResponseActive] = useState(false);
  const [isResponseSaved, setIsResponseSaved] = useState(false);
  const [GPTMessage, setGPTMessage] = useState();
  const [cancelActive, setCancelActive] = useState(false);
  const [remainingTokens, setRemainingTokens] = useState(0);
  const [totalPromptRating, setTotalPromptRating] = useState(0);
  const [totalPromptVotes, setTotalPromptVotes] = useState(0);
  const [allPromptRatings, setAllPromptRatings] = useState();
  const [isSavingRating, setIsSavingRating] = useState(false);
  const [isLeavingComment, setIsLeavingComment] = useState(false);
  const [isLeavingCommentReply, setIsLeavingCommentReply] = useState(false);
  const [isUpvotingComment, setIsUpvotingComment] = useState(false);
  const [promptRating, setPromptRating] = useState(0);
  const [isSaveRatingActive, setIsSaveRatingActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputHeight, setInputHeight] = useState();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);
  const [showPromptDescription, setShowPromptDescription] = useState(true);
  const [showPromptContent, setShowPromptContent] = useState(true);
  const [showTrueDelete, setShowTrueDelete] = useState(false);
  const [updateToggle, setUpdateToggle] = useState(false);
  const [isStartConvoActive, setIsStartConvoActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isBeforePromptRun, setIsBeforePromptRun] = useState(true);
  const [isBeforeImagePromptRun, setIsBeforeImagePromptRun] = useState(true);
  const [userInput, setUserInput] = useState('');
  const scrollViewRef = useRef(null);
  const [isAfterFirstLoad, setIsAfterFirstLoad] = useState(false);
  const [copiedMessageStatus, setCopiedMessageStatus] = useState([]);
  const [newCommentActive, setNewCommentActive] = useState(false);
  const [windowHeight, setWindowHeight] = useState();
  const [realSaveActive, setRealSaveActive] = useState(false);
  const [saveConvoName, setSaveConvoName] = useState("");
  const [hasConvoBeenSaved, setHasConvoBeenSaved] = useState(false);
  const [randomImage, setRandomImage] = useState();
  const [showTrueReport, setShowTrueReport] = useState(false);
  const [isGPT4, setIsGPT4] = useState(false);
  const [responseType, setResponseType] = useState('gpt');
  const windowDimensions = useWindowDimensions();
  const [image, setImage] = useState('');
  const [imageHistory, setImageHistory] = useState([]);
  const [canEditPrompt, setCanEditPrompt] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [hideOriginalImagePrompt, setHideOriginalImagePrompt] = useState(false);
  const [imageloadingMessage, setImageLoadingMessage] = useState('');
  const [tokenCost, setTokenCost] = useState();
  const [showRealTrophyReward, setShowRealTrophyReward] = useState();
  const [tropyCount, setTrophyCount] = useState();
  const [rewardingTrophy, setRewardingTrophy] = useState(false);
  const [hasOnboarded, setHasOnboarded] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);
  const [logConvoId, setLogConvoId] = useState();
  const [logImageConvoId, setLogImageConvoId] = useState();
  const [gpt3UserAPI, setGpt3UserAPI] = useState(false);
  const [gpt4UserAPI, setGpt4UserAPI] = useState(false);
  const [imagesUserAPI, setImagesUserAPI] = useState(false);
  const [isMobileTruncated, setIsMobileTruncated] = useState(true);
  const [isMobileContentTruncated, setIsMobileContentTruncated] = useState(true);

  const playgroundPromptId = 'b148c38f-0cc3-435d-8ccc-93e59fe028e3';

  const desktopOnboardingScreens = [
    (
      <>
        <View style={styles.iconContainer}>
          <Ionicons name="md-rocket" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: "#FFFFFF",
              fontSize: 24,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Your First highPerplexity Prompt
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left", marginBottom: 8}}>
            We're thrilled to have you here! Get ready to unleash the power of our advanced AI prompts.
          </Text>
          <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left"}}>
            Click next to learn how to use and customize prompts for a tailored experience.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <MaterialCommunityIcons name="book-open-page-variant" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 1: Reading Prompt Descriptions
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Each prompt comes with a concise, natural language description that clarifies its purpose and functionality.
            This handy guide should help simplify complex prompts, making them more accessible to you.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            For instance, a description like "Craft a story on any topic of your choice" conveys the essence of the
            prompt. Just swap the topic variable with your desired subject, and you're all set for a creative journey.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <MaterialCommunityIcons name="lightbulb-on-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 2: Understand Prompt Content
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            The key to an immersive AI-driven experience lies in understanding the content of each prompt. Familiarize
            yourself with the available variables to fully harness the potential of highPerplexity.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            For example, a prompt might have options that allow you to customize a story or message. You can modify
            these values to create a personalized experience tailored to your preferences.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="md-settings-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 3: Customizing Prompts
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            highPerplexity encourages you to modify and experiment with the prompts to bring your ideas to life. Adjust
            variables, add new elements, or combine multiple prompts to create a unique outcome.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Don't hesitate to get creative! Our platform is designed to support your vision and provide a seamless user
            experience.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="md-flask-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 4: Test and Experiment
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            highPerplexity offers a playground for you to test, tweak, and experiment with prompts before fully
            committing to them. Make use of this feature to optimize your ideas and uncover new possibilities.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Embrace the trial-and-error process and be open to refining your prompts for the best results.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="md-share-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 5: Share Your Creations
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Showcase your creativity and share your personalized prompts with the highPerplexity community. Engage with
            others, exchange ideas, and discover new approaches to using AI-driven prompts.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Together, we can inspire each other and unlock the full potential of AI!
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View
          style={{
            borderWidth: 2,
            borderColor: '#FFFFFF',
            borderRadius: 50,
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 15,
          }}
        >
          <Text style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 35}}>
            hP
          </Text>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            You're All Set!
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Congratulations! You're now ready to dive into the world of custom prompts with highPerplexity. Use
            your newfound knowledge to explore endless possibilities.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Remember, practice makes perfect. Keep experimenting and have fun!
          </Text>
        </View>
      </>
    ),
  ];

  const handleBackButtonClick = () => {
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (currentScreen < desktopOnboardingScreens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      toggleOnboardingStatus();
      setModalVisible(false);
    }
  };

  useEffect(() => {
    (async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const matchingDoc = querySnapshot.docs.find(doc => auth?.currentUser?.uid === doc.data().uid);
      if (matchingDoc) {
        if (matchingDoc?.data()?.promptViewOnboardedStatus === true) {
          setHasOnboarded(true);
        } else {
          setHasOnboarded(false);
        }
      }
    })();
  }, [updateToggle]);

  useEffect(() => {

    if (Platform.OS !== 'web') {
      setModalVisible(false);
    } else {
      if (hasOnboarded) {
        setModalVisible(false);
      } else {
        setModalVisible(true);
      }
    }
  }, [hasOnboarded]);

  const toggleOnboardingStatus = async () => {
    try {
      const userRef = doc(db, "users", userDoc);
      await updateDoc(userRef, {promptViewOnboardedStatus: true});
    } catch (error) {
      console.error("Error updating onboarded status: ", error);
    }
    setModalVisible(false);
    setHasOnboarded(true);
  }

  const changeLoadingMessage = () => {
    const randomIndex = Math.floor(Math.random() * imageLoadingMessages.length);
    setImageLoadingMessage(imageLoadingMessages[randomIndex]);
  };

  useEffect(() => {
    if (!responseType || !fullUserDoc) return;
    if (responseType.toLowerCase() === 'image') {
      //@ts-ignore
      setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.imagesUserAPI === true)
        ? 0 : 2);
    }
    if (responseType.toLowerCase() === 'gpt') {
      if (isGPT4) {
        //@ts-ignore
        setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt4UserAPI === true)
          ? 0 : 2);
      } else {
        //@ts-ignore
        setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt3UserAPI === true)
          ? 0 : 1);
      }
    }
  }, [responseType, fullUserDoc]);

  useEffect(() => {
    if (isGPT4) {
      //@ts-ignore
      setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt4UserAPI === true)
        ? 0 : 2);
    } else {
      //@ts-ignore
      setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt3UserAPI === true)
        ? 0 : 1);
    }
  }, [isGPT4]);

  useEffect(() => {
    if (!imageLoading) return;

    changeLoadingMessage();
    const interval = setInterval(changeLoadingMessage, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [imageLoading]);

  const addToImageHistory = (newImage: any) => {
    setImageHistory((prevState: any) => {
      // Check if the new image is already in the history
      if (prevState.includes(newImage)) {
        return prevState;
      }
      return [...prevState, newImage];
    });
  };

  const removeImageFromHistory = (imageToRemove: any) => {
    setImageHistory((prevImageHistory) => {
      return prevImageHistory.filter((image) => image !== imageToRemove);
    });
  };

  const ImageLimitMessage = () => (
    <View style={styles.imageLimitMessageContainer}>
      <Card style={styles.imageLimitMessageCard}>
        <Card.Content style={styles.imageLimitMessageContent}>
          <Ionicons name="alert-circle" size={24} color="#FF6347"/>
          <View style={styles.imageLimitMessageTextContainer}>
            <Title style={styles.imageLimitMessageTitle}>Limit Reached</Title>
            <Paragraph style={styles.imageLimitMessageText}>
              Image conversations may only contain up to 6 images at this time. To create another variation, remove an
              existing one first.
            </Paragraph>
          </View>
        </Card.Content>
      </Card>
    </View>
  );

  const handleArrowClick = (direction: "left" | "right") => {
    if (imageHistory.length === 0) return;
    //@ts-ignore
    const currentIndex = imageHistory.indexOf(image);
    const newIndex =
      direction === "left"
        ? (currentIndex - 1 + imageHistory.length) % imageHistory.length
        : (currentIndex + 1) % imageHistory.length;
    setImage(imageHistory[newIndex]);
  };

  const resetConversation = () => {
    setPromptContent(originalPromptContent);
    setImage('');
    setImageHistory([]);
    setCanEditPrompt(true);
    setGenerating(false);
    setIsSaving(false);
    setIsSaved(false);
  };

  const ImageHistoryItem = ({image, onClick, canRemove, index, isActive}: any) => {
    return (
      <View style={styles.imageHistoryItem}>
        <Pressable onPress={() => onClick(image)}>
          <Image
            source={{uri: image}}
            style={[isActive ? styles.activeImage : styles.image,
              customSX({width: [250, 330, 420], height: [250, 330, 420]})]}
            resizeMode="contain"
          />
          {!isActive && (
            <View style={styles.inactiveOverlay}/>
          )}
        </Pressable>
        {canRemove && !isActive && (
          <Pressable
            onPress={() => removeImageFromHistory(image)}
            style={styles.deleteImage}
          >
            <Ionicons
              name="close"
              size={12}
              color="#FFF"
            />
          </Pressable>
        )}
      </View>
    );
  };

  const CreateVariationButton = ({onPress}: any) => (
    <Pressable onPress={onPress} disabled={isSaving}>
      <LinearGradient
        colors={['#2a87ff', '#1a1f42']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={styles.gradientButton}
      >
        <Ionicons name="play" size={24} color="#ffffff"/>
        <Text style={styles.gradientButtonText}>Create New Variation</Text>
      </LinearGradient>
    </Pressable>
  );

  const SaveConversationButton = ({onPress}: any) => (
    <Pressable onPress={onPress} style={styles.saveGradientButton} disabled={(isSaving || isSaved)}>
      <LinearGradient
        colors={['#8a3ab9', '#4a0072']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={styles.gradient}
      >
        <Ionicons name="save" size={20} color="#ffffff"/>
        <Text style={styles.saveGradientButtonText}>
          {isSaving ? "Saving..." : isSaved ? "Saved" : "Save Image Conversation"}
        </Text>
      </LinearGradient>
    </Pressable>
  );

  const handleSaveConversation = async () => {
    if (!auth.currentUser || !userDoc) return;

    setIsSaving(true);

    const storage = getStorage();
    const imageFileDirectory = `${auth.currentUser.uid}/`;
    const imagePrefix = 'image_conversation_';
    const imageExtension = '.png';

    const saveImageToStorage = async (base64Image: any, index: any) => {
      const timestamp = new Date().getTime();
      const imageFileName = `${imagePrefix}${timestamp}_${index}${imageExtension}`;
      const imageFullName = `${imageFileDirectory}${imageFileName}`;
      const storageRef = ref(storage, imageFullName);

      const response = await fetch(base64Image);
      const imageBlob = await response.blob();
      await uploadBytes(storageRef, imageBlob);

      return await getDownloadURL(storageRef);
    };

    const imageUrls = await Promise.all(
      imageHistory.map((base64Image, index) => saveImageToStorage(base64Image, index))
    );

    const conversationData = {
      userId: auth.currentUser.uid,
      prompt: promptContent,
      imageHistory: imageUrls,
      mainImage: imageUrls[imageUrls.length - 1],
      createdAt: new Date(),
      deleted: false,
      convoId: uuid.v4(),
    };

    const conversationRef = collection(db, "imageConversations");
    await addDoc(conversationRef, conversationData);

    setIsSaving(false);
    setIsSaved(true);
  };

  const ResetConversationButton = ({onPress}: any) => (
    <Pressable onPress={onPress} style={styles.resetGradientButton} disabled={isSaving}>
      <LinearGradient
        colors={['#4a4a4a', '#2a2a2a']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={styles.gradient}
      >
        <Ionicons name="refresh-outline" size={20} color="#ffffff"/>
        <Text style={styles.resetGradientButtonText}>Reset Conversation</Text>
      </LinearGradient>
    </Pressable>
  );

  const handleGenerateImages = async (imageData?: any) => {
    if (!auth.currentUser) return;
    setImageLoadingMessage('');
    setGenerating(true);
    setImageLoading(true);
    setIsBeforePromptRun(true);
    setIsBeforeImagePromptRun(false);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      prompt: promptContent,
    };

    if (imageData) {
      // @ts-ignore
      requestBody.input_image_url = imageData;
    }

    try {
      const response = await fetch('https://highperplexity-dalle-middleware-mjp27ovqaq-uc.a.run.app', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      //@ts-ignore
      if (data && data.response) {
        if (image) {
          addToImageHistory(image);
        }
        //@ts-ignore
        const newImage = `data:image/png;base64,${data.response.b64_json}`;
        setImage(newImage);
        addToImageHistory(newImage);
      } else {
        console.log('Unexpected response format');
      }
    } catch (error) {
      console.error('Error generating images:', error);
    }
    setImageLoading(false);
    setCanEditPrompt(false);
  };

  const handleCreateVariation = async () => {
    if (image) {
      addToImageHistory(image);
      await handleGenerateImages(image);
      setIsSaved(false);
    }
  };

  const ToggleButton = ({value, label, icon}: any) => (
    <Pressable
      onPress={() => setResponseType(value)}
      style={({pressed}) => [
        styles.segmentedButton,
        responseType === value ? styles.activeSegmentedButton : {},
        pressed && styles.pressedSegmentedButton,
      ]}
    >
      {icon(responseType === value)}
      <Text
        style={
          responseType === value
            ? styles.activeSegmentedButtonText
            : styles.segmentedButtonText
        }
      >
        {label}
      </Text>
    </Pressable>
  );

  useFocusEffect(
    useCallback(() => {
      setPromptId(route?.params?.promptId);
      setShowTrueDelete(false);
      setUpdateToggle(!updateToggle);
      setShowPromptDescription(true);
      setShowPromptContent(true);
      setIsStartConvoActive(false);
      setIsBeforePromptRun(true);
      setIsBeforeImagePromptRun(true);
      setGPTResponse([]);
      setGPTStream('');
      // @ts-ignore
      setGPTMessage('');
      setMessages([]);
      // @ts-ignore
      setWindowHeight(Dimensions.get('window').height);
      setRealSaveActive(false);
      setSaveConvoName("");
      setHasConvoBeenSaved(false);
      const randomIndex = Math.floor(Math.random() * defaultImageArray.length);
      // @ts-ignore
      setRandomImage(defaultImageArray[randomIndex]);
      setIsGPT4(false);
      setHideOriginalImagePrompt(false);
      // @ts-ignore
      setLogConvoId(null);
      // @ts-ignore
      setLogImageConvoId(null);
    }, [route?.params?.promptId])
  );

  const showTooltip = (id: any) => {
    setTooltipId(id);
    setTooltipVisible(true);
  };

  const toggleSwitch = () => {
    setIsGPT4(previousState => !previousState);
  };

  const onRefresh = useCallback(() => {
    if (!user) return;
    setRefreshing(true);
    (async () => {
      const querySnapshot = await getDocs(collection(db, "prompts"));
      const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
      if (matchingDoc) {
        const {content, comments, variables} = matchingDoc.data();
        // @ts-ignore
        setPromptDocId(matchingDoc.id);
        // @ts-ignore
        setMatchingPrompt(matchingDoc.data());
        setTrophyCount(matchingDoc.data().trophyCount ? matchingDoc.data().trophyCount : 0);
        setOriginalPromptContent(content);
        setPromptContent(content);
        setUserInput(content);
        setPromptComments(comments.length ? comments : []);
        const tempVariableArray = variables.map((variable: any) => ({id: Date.now(), name: variable, value: ''}));
        setPromptVariables(tempVariableArray);
        setPromptImage(matchingDoc?.data()?.promptImage?.imageURL);
      }
    })();
    (async () => {
      const querySnapshot = await getDocs(collection(db, "comments"));
      const tempAllCommentsArray = querySnapshot.docs.map(doc => ({...doc.data(), docId: doc.id}));

      tempAllCommentsArray.forEach(commentElem => {
        // @ts-ignore
        commentElem['replyActive'] = false;
        // @ts-ignore
        commentElem['allRepliesActive'] = false;
      });
      // @ts-ignore
      setAllComments(tempAllCommentsArray?.length ? tempAllCommentsArray : []);
    })();
    (async () => {
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === user?.uid) {
          const {savedPrompts, commentUpvotes} = doc.data();
          setUserDoc(doc.id);
          // @ts-ignore
          setFullUserDoc(doc.data());
          if (doc.data().gpt3UserAPI) {
            setGpt3UserAPI(true);
          } else {
            setGpt3UserAPI(false);
          }
          if (doc.data().gpt4UserAPI) {
            setGpt4UserAPI(true);
          } else {
            setGpt4UserAPI(false);
          }
          if (doc.data().imagesUserAPI) {
            setImagesUserAPI(true);
          } else {
            setImagesUserAPI(false);
          }
          setRemainingTokens(doc?.data()?.tokenAdmin || 0);
          setMySavedPrompts(savedPrompts);
          setUserUpvotedComments(commentUpvotes);
        }
      });
    })();
    (async () => {
      const querySnapshot = await getDocs(collection(db, "promptRatings"));
      const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
      if (matchingDoc) {
        setAllPromptRatings(matchingDoc?.data()?.ratings || []);
      } else {
        // @ts-ignore
        setAllPromptRatings([]);
      }
    })();
    // @ts-ignore
    setHasVerifiedEmail(auth?.currentUser?.emailVerified);

    const tempPromptComments = [...promptComments];
    // @ts-ignore
    const tempParentCommentsArray = [];
    // @ts-ignore
    const tempChildCommentsArray = [];
    tempPromptComments.forEach(comment => {
      // @ts-ignore
      if (comment?.parentCommentId) {
        tempChildCommentsArray.push(comment);
      } else {
        tempParentCommentsArray.push(comment);
      }
    });
    // @ts-ignore
    setParentComments(tempParentCommentsArray?.length ? tempParentCommentsArray : []);
    // @ts-ignore
    setChildComments(tempChildCommentsArray?.length ? tempChildCommentsArray : []);

    setRefreshing(false);
  }, []);

  async function reportComment(commentID: any) {
    try {
      const reportedCommentsCollection = collection(db, 'reportedComments');
      const commentDocRef = doc(reportedCommentsCollection, commentID);
      const commentSnapshot = await getDoc(commentDocRef);

      if (commentSnapshot.exists()) {
        const commentData = commentSnapshot.data();
        const timesReported = commentData.timesReported;
        const reportedBy = commentData.reportedBy || [];

        // @ts-ignore
        if (reportedBy.includes(user?.uid)) {
          // @ts-ignore
          console.log(`User has already reported comment.`);
          return;
        }

        reportedBy.push(user?.uid);
        await updateDoc(commentDocRef, {
          timesReported: timesReported + 1,
          reportedBy: reportedBy,
        });
      } else {
        await setDoc(commentDocRef, {
          timesReported: 1,
          reportedBy: [user?.uid],
        });
      }

      console.log(`Comment reported by user successfully.`);
    } catch (error) {
      console.error(`Error reporting comment ${commentID} by user ${user?.uid}:`, error);
    }
  }

  const handleCopyMessage = async (itemContent: any, index: any) => {
    if (itemContent) {
      await Clipboard.setStringAsync(itemContent);
      const newCopiedMessageStatus = [...copiedMessageStatus];
      // @ts-ignore
      newCopiedMessageStatus[index] = true;
      setCopiedMessageStatus(newCopiedMessageStatus);
      setTimeout(() => {
        const updatedCopiedMessageStatus = [...copiedMessageStatus];
        // @ts-ignore
        updatedCopiedMessageStatus[index] = false;
        setCopiedMessageStatus(updatedCopiedMessageStatus);
      }, 75);
    }
  };

  const renderItem = (item: any, isMessageCopied: any, handleCopyMessage: any, key: any) => {
    const itemContent = item.content;
    const showCopyIcon = itemContent !== "One moment please...";

    return (
      <Pressable key={key}
                 onPress={handleCopyMessage}>
        <View
          style={[
            styles.messageWrapper,
            item.role === "assistant" ? styles.assistantMessageWrapper : null,
          ]}
        >
          <View style={
            [
              styles.labelWrapper,
              item.role === "assistant" && styles.highPerplexityLabel,
              {marginRight: 8},
            ]
          }>
            <Text
              style={[
                styles.label,
                item.role === "assistant"
                  ? styles.assistantLabel
                  : styles.userLabel,
              ]}
            >
              {item.role === "assistant" ? "hP" : "You"}
            </Text>
          </View>
          <View
            style={[
              styles.messageBubble,
              item.role === "assistant" ? styles.assistantMessageBubble : null,
              {paddingRight: itemContent === "One moment please..." ? 10 : 30}
            ]}
          >
            <View style={{flex: 1}}>
              {itemContent === "One moment please..." ? (
                <View style={{flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                  <TypingIndicator/>
                </View>
              ) : (
                <Text
                  style={[
                    styles.messageText,
                    item.role === "assistant" ? styles.assistantMessageText : null,
                  ]}
                >
                  {itemContent}
                </Text>
              )}
            </View>
            {showCopyIcon && (
              <View style={{position: 'absolute', bottom: 5, right: 5}}>
                <MaterialCommunityIcons
                  name="content-copy"
                  size={20}
                  color={isMessageCopied ?
                    "#4A90E2" : item.role === "assistant" ?
                      "#000" : "#FFF"
                  }
                />
              </View>
            )}
          </View>
        </View>
      </Pressable>
    );
  };

  const renderInput = () => {
    if (fetchingGPT) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="small" color="#FFF"/>
          <Text style={styles.loadingIndicator}>Waiting for response...</Text>
        </View>
      );
    }

    if (!isAfterFirstLoad) {
      setIsAfterFirstLoad(true);
    }

    return (
      <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%'}}>
        <TextInput
          style={[styles.input, {flex: 1, width: '100%'}]}
          value={userInput}
          onChangeText={setUserInput}
          placeholder="Type your message..."
          placeholderTextColor="#999"
          multiline={true}
        />
        <Pressable style={styles.sendButton} onPress={getGPTConvo}>
          <Text style={styles.sendButtonText}>Send</Text>
          <Ionicons name="send" size={20} color="#FFF"/>
        </Pressable>
      </View>
    );
  };

  const createConversation = async (responseData: any) => {
    if (true) return;
    const savedResponseRef = await addDoc(collection(db, "conversations"), {
      conversationHistory: [
        {role: 'user', content: promptContent},
        {role: 'assistant', content: responseData},
      ],
      createdBy: user?.uid,
    });

    return savedResponseRef.id;
  };

  const saveConversation = async () => {
    if (!saveConvoName) return;

    const timestamp = new Date().getTime();

    let modelType;

    if (isGPT4) {
      modelType = 'gpt4';
    } else {
      modelType = 'gpt3';
    }

    const docRef = await addDoc(collection(db, "conversations"), {
      conversationHistory: messages,
      name: saveConvoName,
      createdBy: user?.uid,
      deleted: false,
      timestamp: timestamp,
      model: modelType,
    });

    const docId = docRef.id;

    await updateDoc(docRef, {
      id: docId,
    });

    setSaveConvoName("");
    setRealSaveActive(false);
    setHasConvoBeenSaved(true);
  };

  const renderTooltipContent = () => {
    switch (tooltipId) {
      // @ts-ignore
      case 'promptContent':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              This is the prompt you will be running. It may contain variables, which are words or phrases that are
              surrounded by %| and |% symbols. To customize the prompt, you can either tap in the box to make edits
              manually, or you can update the variables in the section below the prompt.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              For example, if the prompt says "Tell me a story about %|TOPIC|%," you can fill in the value for %|TOPIC|%
              in the section below the prompt. If you fill it in with "space travel," the AI will tell you a story about
              space travel.
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptVariables':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              Variables are what make highPerplexity prompts so fun and powerful. They allow you to create prompts that
              can be customized with different values, making them versatile and adaptable to different scenarios.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              To use a variable in a prompt, simply fill out the text input for that variable in the "Prompt Variables"
              section. For instance, if the prompt is: "tell me a story about %|TOPIC|%" and you fill in "space travel"
              as the value for %|TOPIC|%, the AI will tell you a story about space travel.
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptTokens':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              highPerplexity uses a token system to run prompts. Tokens are required to generate prompts, but they are
              free and can be obtained by watching ads. There is no limit to the number of ads you can watch to get more
              tokens. highPerplexity Pro users can run all prompt types at no token cost!
            </Text>
          </View>
        );
      // @ts-ignore
      case 'tokenCost':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              The token cost for running prompts depends on the type of prompt and your user level. Here's a breakdown
              of the token costs:
            </Text>
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>
              <MaterialCommunityIcons name="robot" size={16} color="#f0f0f3" style={{marginRight: 8}}/>
              <Text style={{color: '#f0f0f3', fontSize: 16, lineHeight: 24}}>
                GPT-3 prompts: 1 token
              </Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>
              <MaterialCommunityIcons name="image" size={16} color="#f0f0f3" style={{marginRight: 8}}/>
              <Text style={{color: '#f0f0f3', fontSize: 16, lineHeight: 24}}>
                Image prompts: 2 tokens
              </Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>
              <MaterialCommunityIcons name="brain" size={16} color="#f0f0f3" style={{marginRight: 8}}/>
              <Text style={{color: '#f0f0f3', fontSize: 16, lineHeight: 24}}>
                GPT-4 prompts: 2 tokens
              </Text>
            </View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              highPerplexity Pro accounts can run all prompt types at no cost!
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptDescription':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              The prompt description provides an overview of the prompt you are about to run. While it is not the actual
              prompt text, it should serve as an informative guide to help you understand the purpose and functionality
              of the prompt. A well-crafted prompt description should include details about the prompt and examples of
              input values for the variables.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              By offering a thorough description, users will be able to make the most of the customization features and
              tailor the prompt to suit their specific needs.
            </Text>
          </View>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      const screenWidth = windowDimensions.width;

      if (screenWidth < 768) {
        // @ts-ignore
        setInputHeight(200);
      } else {
        // @ts-ignore
        setInputHeight(300);
      }
    };

    updateHeight();
  }, [windowDimensions]);

  const inputStyle = {
    height: inputHeight,
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ccc',
    fontSize: 16,
    lineHeight: 24,
    backgroundColor: '#333',
    color: '#fff',
  };

  const handleRating = (rating: any) => {
    setPromptRating(rating);
    setIsSaveRatingActive(true);
  };

  const copyToClipboard = async () => {
    if (promptContent) await Clipboard.setStringAsync(promptContent);
  };

  const savePrompt = async () => {
    if (isAlreadySaved) return;
    await updateDoc(doc(db, "users", userDoc), {
      savedPrompts: arrayUnion(promptId)
    });
    setIsAlreadySaved(true);
  };

  const removeSavedPrompt = async () => {
    if (!isAlreadySaved) return;
    await updateDoc(doc(db, "users", userDoc), {
      savedPrompts: arrayRemove(promptId)
    });
    setIsAlreadySaved(false);
  };

  const saveResponse = async () => {
    if (isResponseSaved) return;
    setIsResponseSaved(true);
    const timestamp = new Date().getTime();

    const responseData = {
      // @ts-ignore
      userEmail: fullUserDoc?.email,
      savedById: auth?.currentUser?.uid,
      promptDocId: promptDocId,
      promptId: promptId,
      promptContent: promptContent,
      timestamp: timestamp,
      responseContent: GPTMessage,
      // @ts-ignore
      promptName: matchingPrompt?.title,
      promptImage: promptImage,
      deleted: false,
    };
    const savedResponseRef = await addDoc(collection(db, "savedResponses"), responseData);

    await updateDoc(doc(db, "users", userDoc), {
      savedResponses: arrayUnion(savedResponseRef.id)
    });
  };

  const updateVariableInput = (text: string, index: number) => {
    setPromptVariables(prevState => {
      const newPromptVariables = [...prevState];
      // @ts-ignore
      newPromptVariables[index].value = text;
      return newPromptVariables;
    });
  };

  const leaveComment = async (comment: any) => {
    if (!comment) return;

    if (isLeavingComment) return;
    setIsLeavingComment(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    // @ts-ignore
    const userRef = doc(db, "users", userDoc);
    // @ts-ignore
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    const response = await fetch("https://highperplexity-comment-and-reply-mjp27ovqaq-uc.a.run.app", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "firebase_token_value": authTokenValue?.token,
        "comment_type": "parent",
        "content": comment,
        "user_uid": auth?.currentUser?.uid,
        "user_email": auth?.currentUser?.email,
        // @ts-ignore
        "user_name": fullUserDoc?.userName,
        "comment_id": uuid.v4(),
        "prompt_id": promptId,
        "prompt_doc_id": promptDocId,
        "user_doc": userDoc,
        "timestamp": new Date().getTime(),
      }),
    });

    await response.json();

    const promptsSnapshot = await getDocs(collection(db, "prompts"));
    const matchingDoc = promptsSnapshot.docs.find((doc) => promptId === doc.data().promptId);
    if (matchingDoc) {
      setPromptComments(matchingDoc?.data()?.comments?.length ? matchingDoc?.data()?.comments : []);
    }

    const querySnapshot = await getDocs(collection(db, "comments"));
    const tempAllCommentsArray = querySnapshot.docs.map(doc => ({...doc.data(), docId: doc.id}));

    tempAllCommentsArray.forEach(commentElem => {
      // @ts-ignore
      commentElem['replyActive'] = false;
      // @ts-ignore
      commentElem['allRepliesActive'] = false;
    });
    // @ts-ignore
    setAllComments(tempAllCommentsArray?.length ? tempAllCommentsArray : []);

    setPromptLeaveComment('');
    setIsLeavingComment(false);
  };

  const leaveCommentReply = async (parentCommentId: string, commentText: string, index: number) => {
    if (!commentText) return;

    if (isLeavingCommentReply) return;
    setIsLeavingCommentReply(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    // @ts-ignore
    const userRef = doc(db, "users", userDoc);
    // @ts-ignore
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    const response = await fetch("https://highperplexity-comment-and-reply-mjp27ovqaq-uc.a.run.app", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "firebase_token_value": authTokenValue?.token,
        "comment_type": "child",
        "content": commentText,
        "user_uid": auth?.currentUser?.uid,
        "user_email": auth?.currentUser?.email,
        // @ts-ignore
        "user_name": fullUserDoc?.userName,
        "comment_id": uuid.v4(),
        "prompt_id": promptId,
        "prompt_doc_id": promptDocId,
        "parent_comment_id": parentCommentId,
        "user_doc": userDoc,
        "timestamp": new Date().getTime(),
      }),
    });

    await response.json();

    const promptsSnapshot = await getDocs(collection(db, "prompts"));
    const matchingDoc = promptsSnapshot.docs.find((doc) => promptId === doc.data().promptId);
    if (matchingDoc) {
      setPromptComments(matchingDoc?.data()?.comments?.length ? matchingDoc?.data()?.comments : []);
    }

    const querySnapshot = await getDocs(collection(db, "comments"));
    const tempAllCommentsArray = querySnapshot.docs.map(doc => ({...doc.data(), docId: doc.id}));

    tempAllCommentsArray.forEach(commentElem => {
      // @ts-ignore
      commentElem['replyActive'] = false;
      // @ts-ignore
      commentElem['allRepliesActive'] = false;
    });
    // @ts-ignore
    setAllComments(tempAllCommentsArray?.length ? tempAllCommentsArray : []);

    const tempCommentReplies = [...commentReplyValues];
    // @ts-ignore
    tempCommentReplies[index] = '';
    setCommentReplyValues(tempCommentReplies);
    setIsLeavingCommentReply(false);
  }

  const getUpvoteCount = (commentId: string) => {
    // @ts-ignore
    const comment = allComments.find(c => c.docId === commentId);
    // @ts-ignore
    return (comment?.commentUpvotes?.length) || 0;
  };

  // @ts-ignore
  const hasBeenUpvoted = comment => userUpvotedComments?.some(upvotedComment => upvotedComment.commentId === comment.commentId);

  useFocusEffect(
    useCallback(() => {
      const fetchData = async () => {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, 'prompts'));
        const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
        if (matchingDoc) {
          const {content, comments, variables} = matchingDoc.data();
          //@ts-ignore
          setPromptDocId(matchingDoc.id);
          //@ts-ignore
          setMatchingPrompt(matchingDoc.data());
          setTrophyCount(matchingDoc.data().trophyCount ? matchingDoc.data().trophyCount : 0);
          setOriginalPromptContent(content);
          setPromptContent(content);
          setUserInput(content);
          setPromptComments(comments?.length ? comments : []);
          const tempVariableArray = variables.map((variable: any) => ({
            id: Date.now(),
            name: variable,
            value: '',
          }));
          setPromptVariables(tempVariableArray);
          setPromptImage(matchingDoc?.data()?.promptImage?.imageURL);
          setLoading(false);
        }
      };

      fetchData();

      return () => {
      };
    }, [promptId, updateToggle]),
  );

  useFocusEffect(
    useCallback(() => {
      const fetchComments = async () => {
        const querySnapshot = await getDocs(collection(db, 'comments'));
        const tempAllCommentsArray = querySnapshot.docs.map((doc) => ({...doc.data(), docId: doc.id}));

        tempAllCommentsArray.forEach((commentElem) => {
          //@ts-ignore
          commentElem['replyActive'] = false;
          //@ts-ignore
          commentElem['allRepliesActive'] = false;
        });
        //@ts-ignore
        setAllComments(tempAllCommentsArray?.length ? tempAllCommentsArray : []);
      };

      fetchComments();

      return () => {
      };
    }, [promptId, updateToggle]),
  );

  useFocusEffect(
    useCallback(() => {
      if (!user) return;

      const fetchUserData = async () => {
        const userSnapshot = await getDocs(collection(db, 'users'));
        userSnapshot.forEach((doc) => {
          if (doc.data().uid === user?.uid) {
            const {savedPrompts, commentUpvotes} = doc.data();
            setUserDoc(doc.id);
            //@ts-ignore
            setFullUserDoc(doc.data());
            if (doc.data().gpt3UserAPI) {
              setGpt3UserAPI(true);
            } else {
              setGpt3UserAPI(false);
            }
            if (doc.data().gpt4UserAPI) {
              setGpt4UserAPI(true);
            } else {
              setGpt4UserAPI(false);
            }
            if (doc.data().imagesUserAPI) {
              setImagesUserAPI(true);
            } else {
              setImagesUserAPI(false);
            }
            setRemainingTokens(doc?.data()?.tokenAdmin || 0);
            setMySavedPrompts(savedPrompts);
            setUserUpvotedComments(commentUpvotes);
          }
        });
      };

      fetchUserData();

      return () => {
      };
    }, [user, updateToggle, messages]),
  );

  useEffect(() => {
    // @ts-ignore
    setIsAlreadySaved(mySavedPrompts?.includes(promptId));
  }, [mySavedPrompts, promptId]);

  function customReplace(string: string, search: any, replace: any) {
    return string.split(search).join(replace);
  }

  useEffect(() => {
    let tempOriginalContent = originalPromptContent;
    promptVariables.forEach(variable => {
      // @ts-ignore
      if (tempOriginalContent?.includes(variable.name)) {
        // @ts-ignore
        tempOriginalContent = customReplace(tempOriginalContent, variable.name, (variable.value || variable.name));
      }
    });
    setPromptContent(tempOriginalContent);
    // @ts-ignore
    setUserInput(tempOriginalContent);
  }, [promptVariables]);

  useEffect(() => {
    if (!auth?.currentUser) return;
    // @ts-ignore
    setHasVerifiedEmail(auth?.currentUser?.emailVerified);
  }, [auth]);

  useEffect(() => {
    // @ts-ignore
    const isCreator = fullUserDoc?.uid === matchingPrompt?.postedById;
    setIsPromptCreator(isCreator);
  }, [fullUserDoc, matchingPrompt]);

  const GPTStreamAnimationFrame = useRef();

  useEffect(() => {
    if (!GPTResponse?.length) {
      // @ts-ignore
      cancelAnimationFrame(GPTStreamAnimationFrame.current);
      setCancelActive(false);
      return;
    }

    setCancelActive(true);
    let wordCounter = 0;
    let tempGPTResponseString = '';
    let prevTimestamp = 0;

    const updateGPTStream = (timestamp: any) => {
      if (wordCounter === GPTResponse.length) {
        // @ts-ignore
        cancelAnimationFrame(GPTStreamAnimationFrame.current);
        setCancelActive(false);
        return;
      }

      const timeSinceLastUpdate = timestamp - prevTimestamp;

      if (timeSinceLastUpdate >= 50) {
        tempGPTResponseString = `${tempGPTResponseString} ${GPTResponse[wordCounter]}`.trimStart();
        setGPTStream(tempGPTResponseString);
        wordCounter++;
        prevTimestamp = timestamp;
      }

      // @ts-ignore
      GPTStreamAnimationFrame.current = requestAnimationFrame(updateGPTStream);
    };

    // @ts-ignore
    GPTStreamAnimationFrame.current = requestAnimationFrame(updateGPTStream);

    // @ts-ignore
    return () => cancelAnimationFrame(GPTStreamAnimationFrame.current);
  }, [GPTResponse]);

  useEffect(() => {
    // @ts-ignore
    if (!promptComments?.length) {
      setParentComments([]);
      setChildComments([]);
      return;
    }
    const tempPromptComments = [...promptComments];
    // @ts-ignore
    const tempParentCommentsArray = [];
    // @ts-ignore
    const tempChildCommentsArray = [];
    tempPromptComments?.forEach(comment => {
      // @ts-ignore
      if (comment?.parentCommentId) {
        tempChildCommentsArray.push(comment);
      } else {
        tempParentCommentsArray.push(comment);
      }
    });
    // @ts-ignore
    setParentComments(tempParentCommentsArray?.length ? tempParentCommentsArray : []);
    // @ts-ignore
    setChildComments(tempChildCommentsArray?.length ? tempChildCommentsArray : []);
  }, [allComments, promptComments, promptId, updateToggle]);

  useFocusEffect(
    useCallback(() => {
      if (!promptId) return;

      const fetchPromptRatings = async () => {
        const querySnapshot = await getDocs(collection(db, 'promptRatings'));
        const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
        if (matchingDoc) {
          setAllPromptRatings(matchingDoc?.data()?.ratings || []);
        } else {
          //@ts-ignore
          setAllPromptRatings([]);
        }
      };

      fetchPromptRatings();

      return () => {
        // Clean up logic, if necessary
      };
    }, [promptId, updateToggle]),
  );

  useEffect(() => {
    if (!allPromptRatings) return;

    // Find the rating object that matches the user's UID
    // @ts-ignore
    const userRatingObj = allPromptRatings.find((ratingObj: any) => ratingObj.userID === user?.uid);

    // If a matching rating object is found, update the promptRating state
    if (userRatingObj) {
      setPromptRating(userRatingObj?.rating || 0);
    } else {
      setPromptRating(0);
    }
    let totalRating = 0;
    // @ts-ignore
    allPromptRatings.forEach((promptRating: any) => {
      totalRating = totalRating += promptRating.rating;
    });
    setTotalPromptRating(totalRating);
    // @ts-ignore
    setTotalPromptVotes(allPromptRatings?.length);
  }, [allPromptRatings, updateToggle]);

  const getGPTData = async (prompt: any) => {
    if (fetchingGPT) return;
    setFetchingGPT(true);
    setIsBeforePromptRun(false);
    setGPTResponse([]);
    setIsSaveResponseActive(false);
    setIsStartConvoActive(false);
    setIsResponseSaved(false);

    const updateLoadingMessage = () => {
      const randomIndex = Math.floor(Math.random() * loadingPromptMessages.length);
      setGPTStream(loadingPromptMessages[randomIndex]);
    };

    updateLoadingMessage();
    const loadingMessageInterval = setInterval(updateLoadingMessage, 10000);

    // @ts-ignore
    const authTokenValue = await auth.currentUser.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    const timestamp = new Date().getTime();
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    const response = await fetch("https://highperplexity-gpt-middleware-mjp27ovqaq-uc.a.run.app", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "ai_prompt": prompt,
        "firebase_token_value": authTokenValue.token,
      }),
    });

    clearInterval(loadingMessageInterval);

    const result = await response.json();
    let GPTMessage;

    //@ts-ignore
    if (result?.message === "No remaining tokens") {
      GPTMessage = "You have no remaining tokens, go to the Settings page to get more tokens for free!"
    } else {
      //@ts-ignore
      GPTMessage = result?.response?.choices[0]?.message?.content?.trim();
    }

    setGPTMessage(GPTMessage);

    const ranPromptData = {
      promptValue: prompt,
      timestamp: timestamp,
      // @ts-ignore
      user: fullUserDoc.uid,
      // @ts-ignore
      userEmail: fullUserDoc?.email,
    };
    const ranPromptRef = await addDoc(collection(db, "ranPrompts"), ranPromptData);

    const promptResponseData = {
      responseContent: GPTMessage,
      timestamp: timestamp,
      // @ts-ignore
      user: fullUserDoc.uid,
      // @ts-ignore
      userEmail: fullUserDoc?.email,
    };
    const promptResponseRef = await addDoc(collection(db, "promptResponses"), promptResponseData);

    await updateDoc(userRef, {ranPrompts: arrayUnion(ranPromptRef.id)});
    await updateDoc(userRef, {promptResponses: arrayUnion(promptResponseRef.id)});
    const GPTMessageArray = GPTMessage.split(' ');
    setGPTResponse(GPTMessageArray);
    setIsSaveResponseActive(true);
    setIsStartConvoActive(true);
    setFetchingGPT(false);
    setRemainingTokens(remainingTokens - 1);
  }

  const getGPTConvo = async () => {
    if (fetchingGPT) return;

    let tempUserInput = userInput;
    let modelToggle = 'gpt3';
    let currentlyRemainingTokens = remainingTokens;
    let accountType = 'basic';

    // @ts-ignore
    if (fullUserDoc?.accessLevel) {
      // @ts-ignore
      accountType = fullUserDoc?.accessLevel;
    }

    if (isGPT4) {
      modelToggle = 'gpt4';
    }

    if (isBeforePromptRun) {
      // @ts-ignore
      tempUserInput = promptContent;
    }

    if (!tempUserInput) return;
    setUserInput("");

    setFetchingGPT(true);
    setIsBeforePromptRun(false);

    const newMessages = [
      ...messages,
      {role: "user", content: tempUserInput},
      {role: "assistant", content: "One moment please..."},
    ];
    // @ts-ignore
    setMessages(newMessages);

    // @ts-ignore
    const authTokenValue = await auth.currentUser.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    let convoId;

    if (logConvoId) {
      convoId = logConvoId;
    } else {
      convoId = uuid.v4();
    }

    let APIStatus;

    if (modelToggle === 'gpt4') {
      APIStatus = gpt4UserAPI;
    }

    if (modelToggle === 'gpt3') {
      APIStatus = gpt3UserAPI;
    }

    const response = await fetch(
      "https://highperplexity-gpt-conversation-system-middleware-mjp27ovqaq-uc.a.run.app",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          modelToggle: modelToggle,
          conversation: newMessages.slice(0, -1),
          firebase_token_value: authTokenValue.token,
          accountType: accountType,
          convoId: convoId,
          APIStatus: APIStatus,
        }),
      }
    );

    const result = await response.json();
    //@ts-ignore
    setLogConvoId(result?.convoId);
    let GPTMessage = '';

    //@ts-ignore
    if (result?.message === "No remaining tokens") {
      GPTMessage =
        "You have no remaining tokens, go to the Settings page to get more tokens for free!";
    } else {
      //@ts-ignore
      GPTMessage = result?.response?.choices[0]?.message?.content?.trim();
    }

    const updatedMessages = [
      ...newMessages.slice(0, -1),
      {role: "assistant", content: GPTMessage},
    ];
    // @ts-ignore
    setMessages(updatedMessages);
    setHasConvoBeenSaved(false);
    // @ts-ignore
    setRemainingTokens(currentlyRemainingTokens - tokenCost);
    setFetchingGPT(false);
  }

  const getGPTImageConvo = async (imageData: any) => {
    if (fetchingGPT) return;

    let tempUserInput = userInput;
    let currentlyRemainingTokens = remainingTokens;
    let accountType = 'basic';

    // @ts-ignore
    if (fullUserDoc?.accessLevel) {
      // @ts-ignore
      accountType = fullUserDoc?.accessLevel;
    }

    if (isBeforeImagePromptRun) {
      // @ts-ignore
      tempUserInput = promptContent;
    }

    if (!tempUserInput) return;
    setUserInput("");

    setFetchingGPT(true);
    setIsBeforeImagePromptRun(false);
    setImageLoading(true);
    setGenerating(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    let convoId;

    if (logConvoId) {
      convoId = logConvoId;
    } else {
      convoId = uuid.v4();
    }

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      prompt: promptContent,
      accountType: accountType,
      convoId: convoId,
      APIStatus: imagesUserAPI,
    };

    if (imageData) {
      // @ts-ignore
      requestBody.input_image_url = imageData;
    }

    try {
      const response = await fetch('https://highperplexity-dalle-middleware-mjp27ovqaq-uc.a.run.app', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      //@ts-ignore
      setLogImageConvoId(data?.convoId);

      //@ts-ignore
      if (data && data.response) {
        if (image) {
          addToImageHistory(image);
        }
        //@ts-ignore
        const newImage = `data:image/png;base64,${data.response.b64_json}`;
        setImage(newImage);
        addToImageHistory(newImage);
      } else {
        console.log('Unexpected response format');
      }
    } catch (error) {
      console.error('Error generating images:', error);
    }
    setImageLoading(false);
    setCanEditPrompt(false);
    // @ts-ignore
    setRemainingTokens(currentlyRemainingTokens - tokenCost);
    setFetchingGPT(false);
  }

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  const upvoteComment = async (comment: any, upvoteType: string) => {
    if (isUpvotingComment) return;

    setIsUpvotingComment(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      timestamp: new Date().getTime(),
      comment_id: comment.commentId,
      user_uid: auth?.currentUser?.uid,
      user_email: auth?.currentUser?.email,
      // @ts-ignore
      user_name: fullUserDoc?.userName,
      user_doc: userDoc,
      upvote_type: upvoteType,
      prompt_id: promptDocId,
    };

    const response = await fetch("https://highperplexity-comment-upvotes-mjp27ovqaq-uc.a.run.app", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    await response.json();

    const [promptsSnapshot, querySnapshot] = await Promise.all([
      getDocs(collection(db, "prompts")),
      getDocs(collection(db, "comments")),
    ]);

    const matchingDoc = promptsSnapshot.docs.find(doc => promptId === doc.data().promptId);
    if (matchingDoc) setPromptComments(matchingDoc?.data()?.comments?.length ? matchingDoc.data().comments : []);

    const tempAllCommentsArray = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      docId: doc.id,
      replyActive: false,
      allRepliesActive: false,
    }));
    // @ts-ignore
    setAllComments(tempAllCommentsArray?.length ? tempAllCommentsArray : []);

    const usersQuery = query(collection(db, "users"), where("uid", "==", user?.uid));
    const userSnapshot = await getDocs(usersQuery);
    const matchedUserDoc = userSnapshot.docs[0];

    if (matchedUserDoc) setUserUpvotedComments(matchedUserDoc.data().commentUpvotes);

    setIsUpvotingComment(false);
  };

  const saveRating = () => {
    if (isSavingRating) return;
    (async () => {
      setIsSavingRating(true);
      const authTokenValue = await auth?.currentUser?.getIdTokenResult();
      // @ts-ignore
      const userRef = doc(db, "users", userDoc);
      // @ts-ignore
      await updateDoc(userRef, {currentJWT: authTokenValue.token});

      const response = await fetch("https://highperplexity-prompt-ratings-update-mjp27ovqaq-uc.a.run.app", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "firebase_token_value": authTokenValue?.token,
          "rating": promptRating,
          // @ts-ignore
          "promptId": matchingPrompt?.promptId,
        }),
      });

      await response.json();

      const querySnapshot = await getDocs(collection(db, "promptRatings"));
      const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);

      if (matchingDoc) {
        setAllPromptRatings(matchingDoc?.data()?.ratings || []);
      } else {
        // @ts-ignore
        setAllPromptRatings([]);
      }

      setIsSaveRatingActive(false);
      setIsSavingRating(false);
    })();
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <Pressable key={i} onPress={() => handleRating(i)} style={{marginRight: i < 5 ? 10 : 0}}>
          <FontAwesome
            name={i <= promptRating ? 'star' : 'star-o'}
            size={30}
            color="#C0C0C0"
          />
        </Pressable>
      );
    }
    return stars;
  };

  const deletePrompt = async () => {
    // @ts-ignore
    const promptRef = doc(db, "prompts", promptDocId);
    // @ts-ignore
    await updateDoc(promptRef, {deleted: true});
    navigation.navigate("highPerplexity")
  }

  const reportPrompt = async () => {
    try {
      // Update the user's reportedPrompts field
      const userRef = doc(db, "users", userDoc);
      await updateDoc(userRef, {
        reportedPrompts: arrayUnion(promptId)
      });

      // Update or create a document in the reportedPrompts collection
      const reportedPromptsCollection = collection(db, 'reportedPrompts');
      const promptDocRef = doc(reportedPromptsCollection, promptId);
      const promptSnapshot = await getDoc(promptDocRef);

      if (promptSnapshot.exists()) {
        const promptData = promptSnapshot.data();
        const timesReported = promptData.timesReported;
        const reportedBy = promptData.reportedBy || [];

        if (reportedBy.includes(userDoc)) {
          console.log(`User has already reported prompt.`);
          return;
        }

        reportedBy.push(userDoc);
        await updateDoc(promptDocRef, {
          timesReported: timesReported + 1,
          reportedBy: reportedBy,
        });
      } else {
        await setDoc(promptDocRef, {
          timesReported: 1,
          reportedBy: [userDoc],
        });
      }

      console.log(`Prompt reported by user successfully.`);
      navigation.navigate("highPerplexity");
    } catch (error) {
      console.error(`Error reporting prompt ${promptId} by user ${userDoc}:`, error);
    }
  };

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      // @ts-ignore
      scrollViewRef.current.scrollToEnd({animated: true});
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 25);
  }, [messages]);

  if (!fullUserDoc) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>is loading...</Text>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  // @ts-ignore
  if (matchingPrompt?.deleted) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>Sorry, this prompt is no longer available...</Text>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  if (!hasOnboarded) {
    if (Platform.OS !== 'web') {
      return (
        <Onboarding
          onSkip={() => toggleOnboardingStatus()}
          onDone={() => toggleOnboardingStatus()}
          pages={[
            {
              backgroundColor: '#202124',
              image: (
                <View style={styles.iconContainer}>
                  <Ionicons name="md-rocket" size={96} color="#FFFFFF"/>
                </View>
              ),
              title: (
                <View style={{paddingHorizontal: 15, marginBottom: 15}}>
                  <Text
                    style={{
                      color: "#FFFFFF",
                      fontSize: 24,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Your First highPerplexity Prompt
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left", marginBottom: 8}}>
                    We're thrilled to have you here! Get ready to unleash the power of our advanced AI prompts.
                  </Text>
                  <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left"}}>
                    Tap next to learn how to use and customize prompts for a tailored experience.
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <MaterialCommunityIcons name="book-open-page-variant" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center'
                    }}>
                    Step 1: Reading Prompt Descriptions
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    Each prompt comes with a concise, natural language description that clarifies its purpose and
                    functionality. This handy guide should help simplify complex prompts, making them more accessible to
                    you.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                    For instance, a description like "Craft a story on any topic of your choice" conveys the essence of
                    the prompt. Just swap the topic variable with your desired subject, and you're all set for a
                    creative journey.
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <MaterialCommunityIcons name="lightbulb-on-outline" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center'
                    }}>
                    Step 2: Understand Prompt Content
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    The key to an immersive AI-driven experience lies in understanding the content of each prompt.
                    Familiarize yourself with the available variables to fully harness the potential of highPerplexity.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    For example, a prompt might have options that allow you to customize a story or message. You can
                    modify these values to create a personalized experience tailored to your preferences.
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <Ionicons name="md-settings-outline" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center',
                    }}
                  >
                    Step 3: Customizing Prompts
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    highPerplexity encourages you to modify and experiment with the prompts to bring your ideas to life.
                    Adjust variables, add new elements, or combine multiple prompts to create a unique outcome.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    Don't hesitate to get creative! Our platform is designed to support your vision and provide a
                    seamless user experience.
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <Ionicons name="md-flask-outline" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center',
                    }}
                  >
                    Step 4: Test and Experiment
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    highPerplexity offers a playground for you to test, tweak, and experiment with prompts before fully
                    committing to them. Make use of this feature to optimize your ideas and uncover new possibilities.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                    Embrace the trial-and-error process and be open to refining your prompts for the best results.
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <Ionicons name="md-share-outline" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center',
                    }}
                  >
                    Step 5: Share Your Creations
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    Showcase your creativity and share your personalized prompts with the highPerplexity community.
                    Engage with others, exchange ideas, and discover new approaches to using AI-driven prompts.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                    Together, we can inspire each other and unlock the full potential of AI!
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <View
                  style={{
                    borderWidth: 2,
                    borderColor: '#FFFFFF',
                    borderRadius: 50,
                    paddingHorizontal: 20,
                    paddingVertical: 20,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 35}}>hP</Text>
                </View>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center',
                    }}
                  >
                    You're All Set!
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 15}}>
                    Congratulations! You're now ready to dive into the world of custom prompts with highPerplexity. Use
                    your newfound knowledge to explore endless possibilities.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                    Remember, practice makes perfect. Keep experimenting and have fun!
                  </Text>
                </View>
              ),
            },
          ]}
        />
      );
    }
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(false);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                {desktopOnboardingScreens[currentScreen]}
                <View
                  style={[
                    styles.buttonContainer,
                    currentScreen === desktopOnboardingScreens.length - 1
                      ? styles.buttonContainerLastScreen
                      : styles.buttonContainerStandard,
                  ]}
                >
                  {currentScreen === 0 && (
                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        toggleOnboardingStatus();
                        setModalVisible(false);
                      }}
                    >
                      <Ionicons name="close-outline" size={24} color="#FFFFFF"/>
                      <Text style={styles.buttonText}>Skip</Text>
                    </Pressable>
                  )}

                  {currentScreen > 0 && (
                    <Pressable style={styles.button} onPress={handleBackButtonClick}>
                      <Ionicons name="arrow-back" size={24} color="#FFFFFF"/>
                      <Text style={styles.buttonText}>Back</Text>
                    </Pressable>
                  )}

                  {currentScreen < desktopOnboardingScreens.length - 1 && (
                    <Pressable style={styles.button} onPress={handleNextButtonClick}>
                      <Text style={[styles.buttonText, {marginRight: 4}]}>Next</Text>
                      <Ionicons name="arrow-forward" size={24} color="#FFFFFF"/>
                    </Pressable>
                  )}

                  {currentScreen === desktopOnboardingScreens.length - 1 && (
                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        toggleOnboardingStatus();
                        setModalVisible(false);
                      }}
                    >
                      <Ionicons name="checkmark-outline" size={24} color="#FFFFFF"/>
                      <Text style={styles.buttonText}>Finished!</Text>
                    </Pressable>
                  )}
                </View>
              </View>
            </View>
          </Modal>
          <View style={styles.contentContainer}>
            <View style={{maxWidth: 750, marginBottom: 10}}>
              <Text style={{color: '#FFF', fontSize: 25, textAlign: 'center'}}>
                {// @ts-ignore
                  matchingPrompt?.title}
              </Text>
            </View>
            <View style={{marginBottom: 15}}>
              <Pressable
                onPress={() => {
                  setCurrentScreen(0);
                  setHasOnboarded(false);
                }}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1,
                  borderColor: '#FFF',
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                }}
              >
                <MaterialIcons name="slideshow" size={16} color="#FFF" style={{marginRight: 5}}/>
                <Text style={{color: '#FFF', textAlign: 'center', fontSize: 14}}>
                  View Customizable Prompt Information
                </Text>
              </Pressable>
            </View>
            {
              //@ts-ignore
              (fullUserDoc?.trophyCount && fullUserDoc?.trophyCount > 0 && !rewardingTrophy && promptId !== playgroundPromptId) && (
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                  <Pressable
                    style={({pressed}) => [
                      {
                        backgroundColor: pressed ? '#4CAF50' : 'transparent',
                        borderColor: '#FFF',
                        borderWidth: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 12,
                        paddingHorizontal: 8,
                        paddingVertical: 4,
                        borderRadius: 8,
                      },
                    ]}
                    onPress={//@ts-ignore
                      () => setShowRealTrophyReward(true)}
                  >
                    <MaterialCommunityIcons name="trophy" size={16} color="#FFF" style={{marginRight: 8}}/>
                    <Text style={{color: '#FFF', fontSize: 16}}>Give Trophy Reward</Text>
                  </Pressable>
                </View>
              )
            }
            {
              //@ts-ignore
              (showRealTrophyReward && !rewardingTrophy) && (
                <View style={{justifyContent: 'center', alignItems: 'center'}}>
                  <Pressable
                    style={({pressed}) => [
                      {
                        backgroundColor: pressed ? '#4CAF50' : '#4CAF50',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 12,
                        paddingHorizontal: 8,
                        paddingVertical: 4,
                        borderRadius: 8,
                      },
                    ]}
                    onPress={//@ts-ignore
                      async () => {
                        setRewardingTrophy(true);
                        const authTokenValue = await auth?.currentUser?.getIdTokenResult();
                        // @ts-ignore
                        const userRef = doc(db, "users", userDoc);
                        // @ts-ignore
                        await updateDoc(userRef, {currentJWT: authTokenValue.token});

                        const response = await fetch("https://highperplexity-trophy-manager-middleware-mjp27ovqaq-uc.a.run.app", {
                          method: 'POST',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            "firebase_token_value": authTokenValue?.token,
                            "action": "reset",
                            //@ts-ignore
                            "postedById": matchingPrompt?.postedById,
                            //@ts-ignore
                            "promptId": matchingPrompt?.promptId,
                          }),
                        });

                        await response.json();

                        const querySnapshot = await getDocs(collection(db, "prompts"));
                        const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
                        if (matchingDoc) {
                          setTrophyCount(matchingDoc.data().trophyCount ? matchingDoc.data().trophyCount : 0);
                        }
                        //@ts-ignore
                        setShowRealTrophyReward(false);
                        setRewardingTrophy(false);
                      }}
                  >
                    <MaterialCommunityIcons
                      name="check-circle"
                      size={16}
                      color="#FFF"
                      style={{marginRight: 8}}
                    />
                    <Text style={{color: '#FFF', fontSize: 16}}>Confirm Trophy Reward</Text>
                  </Pressable>
                </View>
              )
            }
            {(!!tropyCount && tropyCount > 0) &&
              <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 12}}>
                <MaterialCommunityIcons name="trophy-award" size={20} color="#FFF" style={{marginRight: 6}}/>
                <Text style={{color: '#FFF', fontSize: 16}}>Trophies Received: {tropyCount}</Text>
              </View>
            }
            <View style={{marginBottom: 15, alignItems: 'center'}}>
              <Image source={{uri: promptImage || randomImage}}
                     style={[{width: 400, borderRadius: 10},
                       customSX({maxWidth: [315, '90%', '100%'], height: [240, 300, 300]})]}
              />
            </View>
            <View style={{marginBottom: 12, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <Link to={{
                screen: 'User Prompts', params: {// @ts-ignore
                  userName: matchingPrompt?.postedById
                }
              }}
                    style={{
                      flexDirection: 'row',
                      borderColor: '#FFF',
                      borderWidth: 1,
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 10
                    }}>
                <View>
                  <Text style={{color: '#FFF', marginRight: 6}}>
                    Posted by:
                  </Text>
                </View>
                <View>
                  <Text style={{color: '#FFF'}}>
                    {// @ts-ignore
                      matchingPrompt?.userName}
                  </Text>
                </View>
              </Link>
              {// @ts-ignore
                (matchingPrompt?.postedById === user?.uid || fullUserDoc.accessLevel === 'superuser') &&
                <View style={{marginLeft: 8}}>
                  <Pressable style={{
                    borderWidth: 1,
                    borderColor: 'red',
                    paddingHorizontal: 8,
                    paddingVertical: 4,
                    borderRadius: 8,
                  }}
                             onPress={() => setShowTrueDelete(true)}>
                    <Text style={{color: 'red', fontSize: 16}}>
                      Delete Prompt
                    </Text>
                  </Pressable>
                </View>
              }
              {// @ts-ignore
                (matchingPrompt?.postedById !== user?.uid || fullUserDoc.accessLevel === 'superuser') &&
                <View style={{marginLeft: 8}}>
                  <Pressable style={{
                    borderWidth: 1,
                    borderColor: 'red',
                    paddingHorizontal: 8,
                    paddingVertical: 4,
                    borderRadius: 8,
                  }}
                             onPress={() => setShowTrueReport(true)}>
                    <Text style={{color: 'red', fontSize: 16}}>
                      Report
                    </Text>
                  </Pressable>
                </View>
              }
            </View>
            {showTrueDelete &&
              <View>
                <Pressable style={{
                  backgroundColor: 'red',
                  paddingHorizontal: 10,
                  paddingVertical: 6,
                  borderRadius: 8,
                }}
                           onPress={deletePrompt}>
                  <Text style={{color: '#FFF', fontSize: 20}}>
                    Tap To Confirm Delete
                  </Text>
                </Pressable>
              </View>
            }
            {showTrueReport &&
              <View>
                <Pressable style={{
                  backgroundColor: 'red',
                  paddingHorizontal: 10,
                  paddingVertical: 6,
                  borderRadius: 8,
                }}
                           onPress={reportPrompt}>
                  <Text style={{color: '#FFF', fontSize: 20}}>
                    Tap To Confirm Report
                  </Text>
                </Pressable>
              </View>
            }
            {(promptId !== playgroundPromptId) &&
              <>
                <View style={styles.sliderContainer}>
                  <Text style={{color: '#FFF', textAlign: 'center', fontSize: 25}}>
                    Rate This Prompt:
                  </Text>
                  <View style={styles.starContainer}>{renderStars()}</View>
                  <View>
                    <Text style={{color: '#FFF', textAlign: 'center', fontSize: 22}}>
                      Your Rating:{' '}
                      {!!promptRating ? promptRating.toFixed(0) : 'Not Rated'}
                    </Text>
                  </View>
                  {isSaveRatingActive && (
                    <View style={{marginTop: 10}}>
                      <Button title="Save Rating" onPress={saveRating}/>
                    </View>
                  )}
                </View>
                <View style={{marginBottom: 5, flexDirection: 'row'}}>
                  <View style={{
                    borderWidth: 1,
                    borderColor: '#FFF',
                    borderRadius: 15,
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    marginRight: 15,
                  }}>
                    <Text style={{color: '#FFF'}}>
                      Total Points: {totalPromptRating || 'None'}
                    </Text>
                  </View>
                  <View style={{
                    borderWidth: 1,
                    borderColor: '#FFF',
                    borderRadius: 15,
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                  }}>
                    <Text style={{color: '#FFF'}}>
                      Average
                      Rating: {(totalPromptRating && totalPromptVotes) ? (totalPromptRating / totalPromptVotes).toFixed(2) : 'None'}
                    </Text>
                  </View>
                </View>
              </>
            }
            {// @ts-ignore
              (showPromptDescription && matchingPrompt?.description) &&
              <>
                {Platform.OS === 'web' ?
                  <View style={{
                    backgroundColor: '#2a2a2a',
                    borderRadius: 5,
                    padding: 15,
                    marginTop: 8,
                  }}
                        sx={{width: [300, 400, 600]}}
                  >
                    <View style={{alignItems: 'center'}}>
                      <Ionicons name="md-book-sharp" size={60} color="#ccc"/>
                    </View>
                    <Text style={{
                      color: '#ccc',
                      fontSize: 14,
                      fontStyle: 'italic',
                      marginBottom: 8,
                    }}>
                      This section provides a description of the prompt you are about to run, not the prompt itself.
                      Click the tooltip icon for more info.
                    </Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
                      <HelperText type="info" visible={true}
                                  style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
                        Prompt Description
                      </HelperText>
                      <IconButton
                        icon="information-outline"
                        size={20}
                        style={{margin: 0}}
                        onPress={() => showTooltip('promptDescription')}
                        theme={{colors: {primary: "#ccc"}}}
                      />
                    </View>
                    <TextInput
                      value={
                        //@ts-ignore
                        matchingPrompt?.description?.replace(/\\n/g, "\n") ||
                        "The prompt author did not provide a description for this prompt. Please tap the Show Full Prompt Content button to get a better idea of how to use it!"
                      }
                      //@ts-ignore
                      style={{
                        ...inputStyle,
                        borderColor: "#ccc",
                        fontSize: 16,
                        lineHeight: 24,
                      }}
                      editable={false}
                      multiline={true}
                    />
                  </View>
                  :
                  <View style={{
                    backgroundColor: '#2a2a2a',
                    borderRadius: 5,
                    padding: 10,
                    marginTop: 8,
                  }}
                        sx={{width: [300, 400, 500]}}
                  >
                    <View style={{alignItems: 'center'}}>
                      <Ionicons name="md-book-sharp" size={60} color="#ccc"/>
                    </View>
                    <Text style={{
                      color: '#ccc',
                      fontSize: 14,
                      fontStyle: 'italic',
                      marginBottom: 8,
                    }}>
                      This section provides a description of the prompt you are about to run, not the prompt itself.
                      Tap the tooltip icon for more info.
                    </Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
                      <HelperText type="info" visible={true}
                                  style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
                        Prompt Description
                      </HelperText>
                      <IconButton
                        icon="information-outline"
                        size={20}
                        style={{margin: 0}}
                        onPress={() => showTooltip('promptDescription')}
                        theme={{colors: {primary: "#ccc"}}}
                      />
                    </View>
                    <TextInput
                      value={
                        isMobileTruncated ?
                          // @ts-ignore
                          (matchingPrompt?.description?.replace(/\\n/g, "\n").substring(0, 300) + '...') :
                          // @ts-ignore
                          (matchingPrompt?.description?.replace(/\\n/g, "\n") ||
                            "The prompt author did not provide a description for this prompt. Please tap the Show Full Prompt Content button to get a better idea of how to use it!")
                      }
                      //@ts-ignore
                      style={{
                        ...inputStyle,
                        borderColor: "#ccc",
                        fontSize: 16,
                        lineHeight: 24,
                        height: 'auto',
                      }}
                      editable={false}
                      multiline={true}
                    />
                  </View>
                }
              </>
            }
            {// @ts-ignore
              matchingPrompt?.description &&
              <View style={{marginTop: 10, marginBottom: 5}}>
                {Platform.OS === 'web' ?
                  <Pressable
                    style={({pressed}) => [
                      {
                        backgroundColor: pressed ? "#4a4a4a" : "transparent",
                        borderColor: "#ccc",
                        borderWidth: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        borderRadius: 5,
                        shadowColor: "#000",
                        shadowOffset: {width: 0, height: 1},
                        shadowOpacity: 0.2,
                        shadowRadius: 1,
                        elevation: 2,
                      },
                    ]}
                    onPress={() => setShowPromptDescription(!showPromptDescription)}
                  >
                    <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
                      {showPromptDescription ? "Hide Prompt Description" : "Show Prompt Description"}
                    </Text>
                    <Ionicons name={showPromptDescription ? "md-book-outline" : "md-book-sharp"} size={20}
                              color="#FFF"/>
                  </Pressable>
                  :
                  <Pressable
                    style={({pressed}) => [
                      {
                        backgroundColor: pressed ? "#4a4a4a" : "transparent",
                        borderColor: "#ccc",
                        borderWidth: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        borderRadius: 5,
                        shadowColor: "#000",
                        shadowOffset: {width: 0, height: 1},
                        shadowOpacity: 0.2,
                        shadowRadius: 1,
                        elevation: 2,
                      },
                    ]}
                    onPress={() => setIsMobileTruncated(!isMobileTruncated)}
                  >
                    <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
                      {isMobileTruncated ? "Show Full Prompt Description" : "Collapse Prompt Description"}
                    </Text>
                    <Ionicons
                      name={isMobileTruncated ? "md-expand" : "md-contract"}
                      size={20}
                      color="#FFF"
                    />
                  </Pressable>
                }
              </View>
            }
            {(isBeforePromptRun && isBeforeImagePromptRun) &&
              <>
                <View style={{marginBottom: 12}}>
                  {(showPromptContent && promptId !== playgroundPromptId) &&
                    <>
                      {Platform.OS === 'web' ?
                        <View style={{
                          backgroundColor: '#2a2a2a',
                          borderRadius: 5,
                          padding: 15,
                          marginTop: 8,
                        }}
                              sx={{width: [300, 400, 600]}}>
                          <View style={{alignItems: 'center'}}>
                            <Ionicons name="md-create" size={60} color="#ccc"/>
                          </View>
                          <Text style={{
                            color: '#ccc',
                            fontSize: 14,
                            fontStyle: 'italic',
                            marginBottom: 8,
                          }}>
                            This section contains the prompt that you are about to run. Configure the variables before
                            running the prompt or click the tooltip icon for more info.
                          </Text>
                          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
                            <HelperText type="info" visible={true}
                                        style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
                              Prompt Content <Text style={{fontSize: 14, fontWeight: 'normal'}}>(Editable)</Text>
                            </HelperText>
                            <IconButton
                              icon="information-outline"
                              size={20}
                              style={{margin: 0}}
                              onPress={() => showTooltip('promptContent')}
                              theme={{colors: {primary: "#ccc"}}}
                            />
                          </View>
                          <TextInput
                            value={promptContent}
                            //@ts-ignore
                            style={{
                              ...inputStyle,
                              borderColor: "#ccc",
                              fontSize: 16,
                              lineHeight: 24,
                            }}
                            editable={true}
                            multiline={true}
                            onChangeText={(text: any) => {
                              // @ts-ignore
                              setPromptContent(text);
                              setUserInput(text);
                            }}
                          />
                        </View>
                        :
                        <View style={{
                          backgroundColor: '#2a2a2a',
                          borderRadius: 5,
                          padding: 10,
                          marginTop: 8,
                        }}
                              sx={{width: [300, 400, 500]}}>
                          <View style={{alignItems: 'center'}}>
                            <Ionicons name="md-create" size={60} color="#ccc"/>
                          </View>
                          <Text style={{
                            color: '#ccc',
                            fontSize: 14,
                            fontStyle: 'italic',
                            marginBottom: 8,
                          }}>
                            This section contains the prompt that you are about to run. Configure the variables before
                            running the prompt or tap the tooltip icon for more info.
                          </Text>
                          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
                            <HelperText type="info" visible={true}
                                        style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
                              Prompt Content <Text style={{fontSize: 14, fontWeight: 'normal'}}>(Editable)</Text>
                            </HelperText>
                            <IconButton
                              icon="information-outline"
                              size={20}
                              style={{margin: 0}}
                              onPress={() => showTooltip('promptContent')}
                              theme={{colors: {primary: "#ccc"}}}
                            />
                          </View>
                          <TextInput
                            value={
                              isMobileContentTruncated ?
                                // @ts-ignore
                                (promptContent.replace(/\\n/g, "\n").substring(0, 300) + '...') :
                                // @ts-ignore
                                (promptContent.replace(/\\n/g, "\n"))
                            }
                            //@ts-ignore
                            style={{
                              ...inputStyle,
                              borderColor: "#ccc",
                              fontSize: 16,
                              lineHeight: 24,
                              height: 'auto',
                            }}
                            editable={true}
                            multiline={true}
                            onChangeText={(text: any) => {
                              // @ts-ignore
                              setPromptContent(text);
                              setUserInput(text);
                            }}
                          />
                        </View>
                      }
                      {/*<View*/}
                      {/*  style={{marginTop: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>*/}
                      {/*  <Pressable*/}
                      {/*    style={({pressed}) => [*/}
                      {/*      {*/}
                      {/*        backgroundColor: pressed ? "#3D6DCC" : "transparent",*/}
                      {/*        borderColor: "#fff",*/}
                      {/*        borderWidth: 1,*/}
                      {/*        flexDirection: "row",*/}
                      {/*        justifyContent: "center",*/}
                      {/*        alignItems: "center",*/}
                      {/*        padding: 10,*/}
                      {/*        borderRadius: 5,*/}
                      {/*      },*/}
                      {/*    ]}*/}
                      {/*    onPress={copyToClipboard}*/}
                      {/*  >*/}
                      {/*    <Text style={{color: "#fff", marginRight: 5}}>Copy Prompt Content</Text>*/}
                      {/*    <MaterialCommunityIcons name="content-copy" size={20} color="#fff"/>*/}
                      {/*  </Pressable>*/}
                      {/*</View>*/}
                    </>
                  }
                  <Portal>
                    <Modal
                      visible={tooltipVisible}
                      onRequestClose={() => setTooltipVisible(false)}
                      transparent={true}
                      animationType="slide"
                    >
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          padding: 20,
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: '#2c2c2e',
                            borderRadius: 10,
                            padding: 20,
                            width: '100%',
                            maxWidth: 400,
                          }}
                        >
                          {renderTooltipContent()}
                          <Button
                            onPress={() => setTooltipVisible(false)}
                            title="Got it!"
                          />
                        </View>
                      </View>
                    </Modal>
                  </Portal>
                </View>
                {(promptId !== playgroundPromptId) &&
                  <View style={{marginTop: 5, marginBottom: 10}}>
                    {Platform.OS === 'web' ?
                      <Pressable
                        onPress={() => setShowPromptContent(!showPromptContent)}
                        style={({pressed}) => [
                          {
                            backgroundColor: pressed ? "#4a4a4a" : "transparent",
                            borderColor: "#ccc",
                            borderWidth: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            borderRadius: 5,
                            shadowColor: "#000",
                            shadowOffset: {width: 0, height: 1},
                            shadowOpacity: 0.2,
                            shadowRadius: 1,
                            elevation: 2,
                          },
                        ]}
                      >
                        <Text style={{color: "#fff", fontSize: 16, marginRight: 5}}>
                          {showPromptContent ? "Hide Prompt Content" : "Show Prompt Content"}
                        </Text>
                        <Feather name={showPromptContent ? "file-minus" : "file-plus"} size={20} color="#FFF"
                                 style={{marginLeft: 5}}/>
                      </Pressable>
                      :
                      <Pressable
                        style={({pressed}) => [
                          {
                            backgroundColor: pressed ? "#4a4a4a" : "transparent",
                            borderColor: "#ccc",
                            borderWidth: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            borderRadius: 5,
                            shadowColor: "#000",
                            shadowOffset: {width: 0, height: 1},
                            shadowOpacity: 0.2,
                            shadowRadius: 1,
                            elevation: 2,
                          },
                        ]}
                        onPress={() => setIsMobileContentTruncated(!isMobileContentTruncated)}
                      >
                        <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
                          {isMobileContentTruncated ? "Show Full Prompt Content" : "Collapse Prompt Content"}
                        </Text>
                        <Ionicons
                          name={isMobileContentTruncated ? "md-expand" : "md-contract"}
                          size={20}
                          color="#FFF"
                        />
                      </Pressable>
                    }
                  </View>
                }
                {!!promptVariables.length ? (
                  <View style={{marginBottom: 16}} sx={{width: [300, 400, 500]}}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{color: "#FFF", fontSize: 22}}>Prompt Variables</Text>
                      <IconButton
                        icon="information-outline"
                        size={20}
                        style={{margin: 0}}
                        onPress={() => showTooltip("promptVariables")}
                        theme={{colors: {primary: "#fff"}}}
                      />
                    </View>
                    <Text style={{color: "#ccc", fontSize: 14, textAlign: "center", marginBottom: 10, marginTop: 5}}>
                      Fill in the variables and press Run Prompt to get your personalized response from highPerlexity.
                      Tap the tooltip icon for more info.
                    </Text>
                    {promptVariables?.map((variable, index) => (
                      <View
                        key={index}
                        style={{
                          marginBottom: 12,
                          borderRadius: 8,
                          borderWidth: 1,
                          borderColor: "#ccc",
                          backgroundColor: "#333",
                          padding: 12,
                        }}
                      >
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                          <Ionicons name="md-code-outline" size={20} color="#ccc" style={{marginRight: 8}}/>
                          <Text style={{color: "#FFF", fontSize: 16}}>
                            {//@ts-ignore
                              variable.name.replace(/%|\|/g, "")}
                          </Text>
                        </View>
                        {//@ts-ignore
                          variable.description && (
                            <Text style={{color: "#ccc", fontSize: 12, marginBottom: 4}}>
                              {//@ts-ignore
                                variable.description}
                            </Text>
                          )}
                        <CustomTextInput
                          // @ts-ignore
                          value={variable.value}
                          onChangeText={(text: any) => updateVariableInput(text, index)}
                          //@ts-ignore
                          placeholder={`Enter value for "${variable.name.replace(/%|\|/g, '')}" variable...`}
                          inputType="variable"
                        />
                      </View>
                    ))}
                  </View>
                ) : (
                  <View style={{marginBottom: 16, alignItems: "center"}} sx={{width: [300, 400, 500]}}>
                    <Text style={{color: "#ccc", fontSize: 22, marginBottom: 8}}>
                      This prompt has no variables!
                    </Text>
                    <AntDesign name="exclamationcircleo" size={50} color="#CCC" style={{marginBottom: 8}}/>
                    <Text
                      style={{color: "#ccc", fontSize: 16, textAlign: "center", paddingHorizontal: 5, lineHeight: 24}}>
                      Since there are no variables to fill in, you can proceed to the response options. Choose the
                      desired response type and tap the "Run Prompt" button to get a response from highPerplexity.
                    </Text>
                    <Text
                      style={{
                        color: "#ccc",
                        fontSize: 16,
                        textAlign: "center",
                        paddingHorizontal: 5,
                        lineHeight: 24,
                        marginTop: 10,
                        paddingTop: 8,
                        paddingBottom: 8
                      }}
                    >
                      Tap the "Show Full Prompt Content" button above to edit this prompt's content.
                    </Text>
                  </View>
                )}
                <>
                  <View>
                    {// @ts-ignore
                      (!hasVerifiedEmail && (fullUserDoc.accessLevel !== 'admin' && fullUserDoc.accessLevel !== 'superuser' && fullUserDoc.email !== '1@1.nclay')) ?
                        <Pressable
                          disabled={true}
                          onPress={() => {
                          }}
                          style={{
                            backgroundColor: "grey",
                            borderColor: "#fff",
                            borderWidth: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            borderRadius: 5,
                          }}
                        >
                          <Text style={{color: "#fff"}}>Verify Email To Run Prompt</Text>
                        </Pressable>
                        :
                        !fetchingGPT ?
                          // <Button title="Run Prompt" onPress={() => getGPTData(promptContent)}/>
                          <View>
                            <View style={{
                              backgroundColor: '#2a2a2a',
                              borderRadius: 5,
                              padding: 10,
                              marginBottom: 15,
                            }}>
                              <View>
                                <View style={{
                                  ...styles.titleContainer,
                                  backgroundColor: 'transparent',
                                  padding: 0,
                                }}>
                                  <View style={{flexDirection: 'row'}}>
                                    <MaterialCommunityIcons name="robot" size={24} color="#CCC"/>
                                    <Text style={{
                                      ...styles.title,
                                      color: '#ccc',
                                      marginLeft: 8,
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                    }}>
                                      AI Response Type
                                    </Text>
                                  </View>
                                  <Text style={{
                                    color: '#ccc',
                                    fontSize: 14,
                                    fontStyle: 'italic',
                                    marginTop: 5,
                                    textAlign: 'center',
                                  }}>
                                    Choose between getting text or image responses from highPerplexity's AI!
                                  </Text>
                                  <View style={{
                                    ...styles.segmentedButtonsContainer,
                                    justifyContent: 'center',
                                    marginTop: 16,
                                  }}>
                                    <ToggleButton
                                      value="gpt"
                                      label="GPT"
                                      icon={() => <MaterialCommunityIcons name="brain" color={"#CCC"} size={24}/>}
                                    />
                                    <ToggleButton
                                      value="image"
                                      label="Image"
                                      icon={() => <MaterialCommunityIcons name="image" color={"#CCC"} size={24}/>}
                                    />
                                  </View>
                                </View>
                                {(responseType === 'gpt') &&
                                  <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginVertical: 4,
                                  }}>
                                    <Text style={{fontSize: 15, fontWeight: 'bold', color: '#CCC', marginRight: 8}}>
                                      USE GPT-4?
                                    </Text>
                                    <Switch
                                      trackColor={{false: '#767577', true: '#81b0ff'}}
                                      thumbColor={isGPT4 ? '#3273dc' : '#f4f3f4'}
                                      ios_backgroundColor="#3e3e3e"
                                      onValueChange={toggleSwitch}
                                      value={isGPT4}
                                    />
                                  </View>
                                }
                              </View>
                            </View>
                            <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                              <Pressable
                                onPress={responseType === 'gpt' ? getGPTConvo : () => getGPTImageConvo(false)}
                                style={({pressed}) => [
                                  {
                                    backgroundColor: pressed ? "#6A9AE2" : "#3273DC",
                                    borderColor: "#fff",
                                    borderWidth: 1,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingVertical: 16,
                                    paddingHorizontal: 30,
                                    borderRadius: 8,
                                    shadowColor: "#000",
                                    shadowOffset: {width: 0, height: 4},
                                    shadowOpacity: 0.3,
                                    shadowRadius: 4,
                                    elevation: 4,
                                    maxWidth: 300,
                                  },
                                ]}
                              >
                                <Text style={{color: "#fff", fontSize: 28, fontWeight: "bold", marginRight: 10}}>
                                  Run Prompt
                                </Text>
                                <MaterialCommunityIcons name="play" size={35} color="#fff"/>
                              </Pressable>
                            </View>
                          </View>
                          :
                          <Pressable
                            disabled={true}
                            onPress={() => {
                            }}
                            style={{
                              backgroundColor: "grey",
                              borderColor: "#fff",
                              borderWidth: 1,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 10,
                              borderRadius: 5,
                            }}
                          >
                            <Text style={{color: "#fff"}}>Running Prompt...</Text>
                          </Pressable>
                    }
                  </View>
                  <View style={{
                    backgroundColor: '#2a2a2a',
                    borderRadius: 5,
                    padding: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginVertical: 15,
                  }}
                        sx={{width: [300, 400, 400]}}
                  >
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <Text style={{
                        color: '#CCC',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 'bold',
                        marginRight: 4,
                      }}>
                        Token Cost: {tokenCost}
                      </Text>
                      <IconButton
                        icon="information-outline"
                        size={20}
                        style={{margin: 0}}
                        onPress={() => showTooltip('tokenCost')}
                        theme={{colors: {primary: "#ccc"}}}
                      />
                    </View>
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <Text style={{
                        color: '#CCC',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 'bold',
                        marginRight: 4,
                      }}>
                        Remaining Tokens: {// @ts-ignore
                        remainingTokens}
                      </Text>
                      <IconButton
                        icon="information-outline"
                        size={20}
                        style={{margin: 0}}
                        onPress={() => showTooltip('promptTokens')}
                        theme={{colors: {primary: "#ccc"}}}
                      />
                    </View>
                    <Text style={{
                      color: '#CCC',
                      textAlign: 'center',
                      fontStyle: 'italic',
                      fontSize: 14,
                      marginTop: 8,
                    }}>
                      Different AI response types cost different amounts of tokens. Tap the tooltip for more info.
                    </Text>
                  </View>
                  {!isPromptCreator &&
                    <View style={{marginBottom: 10}}>
                      <Pressable
                        style={({pressed}) => [
                          {
                            backgroundColor: pressed ? "#3D6DCC" : "transparent",
                            borderColor: "#fff",
                            borderWidth: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            borderRadius: 5,
                          },
                        ]}
                        onPress={isAlreadySaved ? removeSavedPrompt : savePrompt}
                      >
                        <Text style={{color: "#fff", marginRight: 5}}>
                          {isAlreadySaved ? "Prompt Already Saved" : "Save Prompt"}
                        </Text>
                        <MaterialCommunityIcons name={isAlreadySaved ? "bookmark-check" : "bookmark-plus"} size={20}
                                                color="#fff"/>
                      </Pressable>
                    </View>
                  }
                </>
                {!!GPTStream &&
                  <View style={[{
                    marginBottom: 12,
                    borderWidth: 1,
                    borderColor: '#FFF',
                    borderRadius: 10,
                    padding: 10,
                  }, customSX({width: [300, 450, 600]})]}>
                    <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 8}}>
                      {!fetchingGPT ? "AI Response" : "Waiting on AI Response"}
                    </Text>
                    <Text style={{color: '#FFF'}}>
                      {GPTStream}
                    </Text>
                  </View>
                }
                {cancelActive &&
                  <View style={{marginBottom: 10}}>
                    <Button title="Cancel This Response" onPress={() => setGPTResponse([])}/>
                  </View>
                }
                {(!cancelActive && isStartConvoActive) &&
                  <View style={{marginBottom: 15}}>
                    <Button title="Create Conversation From Response" onPress={async () => {
                      const convoId = await createConversation(GPTMessage);
                      navigation.navigate('GPTConversation', {convoId});
                    }}/>
                  </View>
                }
                {(!cancelActive && isSaveResponseActive) &&
                  <View style={{marginBottom: 5}}>
                    {isResponseSaved ?
                      <Button disabled={true}
                              title="Response Already Saved" onPress={() => {
                      }}/>
                      :
                      <Button title="Save This Response" onPress={saveResponse}/>
                    }
                  </View>
                }
              </>
            }
            {!isBeforePromptRun &&
              <View sx={{width: ['85%', '90%', '100%']}}>
                <View style={[styles.chatContentContainer]}>
                  <View
                    style={{marginBottom: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <View>
                      <Text style={{color: '#9FA8DA', textAlign: 'center', fontSize: 22}}>
                        Your conversation with
                      </Text>
                    </View>
                    <View style={{
                      borderWidth: 1,
                      borderColor: "#9FA8DA",
                      borderRadius: 50,
                      paddingHorizontal: 7,
                      paddingVertical: 7,
                      marginLeft: 6,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Text
                        style={[
                          styles.label,
                          {color: '#9FA8DA'}
                        ]}
                      >
                        hP
                      </Text>
                    </View>
                  </View>
                  <View sx={{// @ts-ignore
                    maxHeight: [(windowHeight || 0) * 0.5, (windowHeight || 0) * 0.6, (windowHeight || 0) * 0.7],
                  }}>
                    <ScrollView
                      ref={scrollViewRef}
                      contentContainerStyle={styles.chatContainer}
                      showsVerticalScrollIndicator={false}
                    >
                      {messages.map((item, index) => (
                        // @ts-ignore
                        renderItem(item, copiedMessageStatus[index], () => handleCopyMessage(item.content, index), index)
                      ))}
                    </ScrollView>
                  </View>
                  <View style={{marginVertical: 10}}>
                    <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 10}}>
                      Token Cost: {tokenCost}
                    </Text>
                    <Text style={{color: '#FFF', textAlign: 'center'}}>
                      Remaining Tokens: {remainingTokens}
                    </Text>
                  </View>
                  <View style={[styles.inputWrapper]}
                        sx={{width: ['100%', '100%', '70%']}}
                  >
                    {renderInput()}
                  </View>
                </View>
                {hasConvoBeenSaved ?
                  <View style={{alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
                    <Pressable
                      style={({pressed}) => [
                        {
                          backgroundColor: pressed ? "#3D6DCC" : "transparent",
                          borderColor: "#fff",
                          borderWidth: 1,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 10,
                          borderRadius: 5,
                        },
                      ]}
                      onPress={() => {
                      }}
                    >
                      <Text style={{color: "#fff", marginRight: 5}}>
                        Conversation Saved
                      </Text>
                      <MaterialCommunityIcons name="bookmark-check" size={20}
                                              color="#fff"/>
                    </Pressable>
                  </View>
                  :
                  realSaveActive ?
                    <View style={{alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
                      <TextInput
                        style={styles.convoNameInput}
                        value={saveConvoName}
                        onChangeText={setSaveConvoName}
                        placeholder="Conversation Name"
                        placeholderTextColor="#999"
                      />
                      <Pressable
                        disabled={fetchingGPT}
                        style={({pressed}) => [
                          {
                            backgroundColor: pressed ? "#3D6DCC" : "transparent",
                            borderColor: "#fff",
                            borderWidth: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            borderRadius: 5,
                          },
                          fetchingGPT ? styles.disabledButton : null,
                        ]}
                        onPress={fetchingGPT ? () => {
                        } : saveConversation}
                      >
                        <Text style={{color: "#fff", marginRight: 5}}>Save Conversation</Text>
                        <Ionicons name="md-save" size={20} color="#FFF"/>
                      </Pressable>
                    </View>
                    :
                    <View style={{alignItems: 'center', justifyContent: 'center', marginBottom: 10}}>
                      <Pressable
                        disabled={fetchingGPT}
                        style={({pressed}) => [
                          {
                            backgroundColor: pressed ? "#3D6DCC" : "transparent",
                            borderColor: "#fff",
                            borderWidth: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 10,
                            borderRadius: 5,
                          },
                          fetchingGPT ? styles.disabledButton : null,
                        ]}
                        onPress={fetchingGPT ? () => {
                        } : () => setRealSaveActive(true)}
                      >
                        <Text style={{color: "#fff", marginRight: 5}}>Save Conversation</Text>
                        <Ionicons name="md-save" size={20} color="#FFF"/>
                      </Pressable>
                    </View>
                }
              </View>
            }
            {!isBeforeImagePromptRun &&
              <View sx={{width: ['85%', '90%', '100%'], alignItems: 'center', marginTop: 20}}>
                <View sx={{alignItems: 'center', marginBottom: 20}}>
                  <View
                    style={{marginBottom: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <View>
                      <Text sx={{color: '#9FA8DA', textAlign: 'center', fontSize: [18, 20, 22]}}>
                        Your image conversation with
                      </Text>
                    </View>
                    <View style={{
                      borderWidth: 1,
                      borderColor: "#9FA8DA",
                      borderRadius: 50,
                      paddingHorizontal: 7,
                      paddingVertical: 7,
                      marginLeft: 6,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Text
                        style={[
                          styles.label,
                          {color: '#9FA8DA'}
                        ]}
                      >
                        hP
                      </Text>
                    </View>
                  </View>
                  {!hideOriginalImagePrompt &&
                    <View style={styles.inputContainer} sx={{width: [300, 400, 500]}}>
                      <Ionicons name="pencil" size={20} color="#9E9E9E" style={styles.inputIcon}/>
                      {(!generating && canEditPrompt) &&
                        <TextInput
                          style={styles.textInput}
                          //@ts-ignore
                          onChangeText={(text) => setPromptContent(text)}
                          value={promptContent}
                          placeholder="Enter your prompt"
                          placeholderTextColor="#9E9E9E"
                          editable={canEditPrompt}
                        />
                      }
                      {(generating || !canEditPrompt) &&
                        <Text style={{color: '#FFF'}}>
                          {promptContent}
                        </Text>
                      }
                    </View>
                  }
                  <View style={{marginBottom: 10}}>
                    <Pressable
                      style={({pressed}) => [
                        {
                          backgroundColor: pressed ? "#3D6DCC" : "transparent",
                          borderColor: "#fff",
                          borderWidth: 1,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 10,
                          borderRadius: 5,
                        },
                      ]}
                      onPress={() => setHideOriginalImagePrompt(!hideOriginalImagePrompt)}
                    >
                      <Text style={{color: "#fff", marginRight: 5}}>
                        {hideOriginalImagePrompt ? "Show Image Prompt Content" : "Hide Image Prompt Content"}
                      </Text>
                      <Ionicons name={hideOriginalImagePrompt ? "md-eye-outline" : "md-eye-off-outline"} size={20}
                                color="#FFF"/>
                    </Pressable>
                  </View>
                  {!generating && canEditPrompt && (
                    <Button
                      title="Create Image"
                      onPress={() => handleGenerateImages()}
                      //@ts-ignore
                      buttonStyle={styles.generateButton}
                    />
                  )}
                  {imageLoading ? (
                    <View style={styles.imageLoadingContainer} sx={{height: [300, 400, 500], width: [300, 400, 500]}}>
                      <Text style={styles.loadingText}>{imageloadingMessage}</Text>
                      <ActivityIndicator size="large" color="#4E8FFF"/>
                    </View>
                  ) : (
                    !!image && (
                      <View sx={{height: [300, 400, 500], width: [300, 400, 500]}}>
                        <Image
                          source={{uri: image}}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: 5,
                          }}
                          resizeMode="contain"
                        />
                      </View>
                    )
                  )}
                  {imageHistory.length > 0 && (
                    <>
                      {!imageLoading &&
                        <View style={styles.buttonsContainer}>
                          {(imageHistory.length >= 6) ?
                            <ImageLimitMessage/>
                            :
                            <View style={{marginTop: 10}}>
                              <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 10}}>
                                Token Cost: {tokenCost}
                              </Text>
                              <Text style={{color: '#FFF', textAlign: 'center'}}>
                                Remaining Tokens: {remainingTokens}
                              </Text>
                              <CreateVariationButton onPress={handleCreateVariation}/>
                            </View>
                          }
                          <SaveConversationButton onPress={handleSaveConversation}/>
                          {/*<ResetConversationButton onPress={resetConversation}/>*/}
                        </View>
                      }
                      <View style={styles.imageHistoryContainer} sx={{width: [270, 360, 450], alignItems: 'center'}}>
                        <Text style={styles.imageHistoryTitle}>Image History</Text>
                        <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                          <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 5
                          }}>
                            <Pressable onPress={() => handleArrowClick("right")} style={styles.arrowButton}>
                              <Ionicons
                                name="chevron-back"
                                size={35}
                                color="#FFFFFF"
                              />
                            </Pressable>
                            <Pressable onPress={() => handleArrowClick("left")} style={styles.arrowButton}>
                              <Ionicons
                                name="chevron-forward"
                                size={35}
                                color="#FFFFFF"
                              />
                            </Pressable>
                          </View>
                          <View style={styles.imageHistoryScrollView}>
                            {[...imageHistory].reverse().map((historyImage, index) => (
                              <ImageHistoryItem
                                key={index}
                                image={historyImage}
                                onClick={(clickedImage: any) => setImage(clickedImage)}
                                canRemove={imageHistory.length > 1}
                                index={index}
                                isActive={historyImage === image}
                              />
                            ))}
                          </View>
                        </View>
                      </View>
                    </>
                  )}
                </View>
              </View>
            }
            {promptId !== playgroundPromptId &&
              <View style={{marginTop: 20}}>
                <Text style={{fontSize: 24, fontWeight: 'bold', color: '#fff', marginBottom: 10, textAlign: 'center'}}>
                  Comments
                </Text>
                {promptComments.length === 0 && (
                  <Text style={{fontSize: 16, color: '#888', marginBottom: 10}}>
                    No comments yet. Be the first to comment!
                  </Text>
                )}
                <CommentList
                  promptComments={promptComments}
                  //@ts-ignore
                  leaveComment={leaveComment}
                  //@ts-ignore
                  leaveCommentReply={leaveCommentReply}
                  hasBeenUpvoted={hasBeenUpvoted}
                  upvoteComment={upvoteComment}
                  reportComment={reportComment}
                  preAuth={false}
                />
                <CommentInput onLeaveComment={leaveComment}/>
              </View>
            }
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#202124',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  sliderContainer: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  starContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 10,
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    color: '#FFF',
    fontSize: 24,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  chatContainer: {
    flexGrow: 1,
    alignItems: 'flex-start',
    maxWidth: 600,
    width: '100%',
  },
  remainingTokensText: {
    color: "#FFF",
    textAlign: 'center',
    marginLeft: 15,
  },
  inputWrapper: {
    flexDirection: 'row',
    padding: 10,
    alignSelf: 'center',
  },
  messageWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 5,
    width: '100%',
  },
  assistantMessageWrapper: {
    justifyContent: 'flex-end',
  },
  icon: {
    marginHorizontal: 5,
    alignSelf: 'flex-start',
    opacity: 0.8,
  },
  messageBubble: {
    backgroundColor: '#4E88DE',
    borderRadius: 5,
    padding: 10,
    marginBottom: 5,
    flex: 1,
  },
  assistantMessageBubble: {
    backgroundColor: '#EFEFEF',
    borderRadius: 5,
    padding: 10,
    marginBottom: 5,
    flex: 1,
    flexDirection: 'row',
  },
  messageText: {
    color: '#FFF',
    fontWeight: 'bold',
  },
  assistantMessageText: {
    color: '#202124',
    fontStyle: 'italic',
  },
  labelWrapper: {
    width: 40,
    alignItems: 'center',
  },
  label: {
    fontWeight: 'bold',
  },
  userLabel: {
    color: '#4E88DE',
  },
  assistantLabel: {
    color: '#9FA8DA',
  },
  input: {
    backgroundColor: '#EFEFEF',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
    color: '#202124',
  },
  sendButton: {
    backgroundColor: "#4E88DE",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButtonText: {
    color: "#FFF",
    marginRight: 8,
    fontSize: 20,
    fontWeight: 'bold',
  },
  loadingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: '100%'
  },
  imageLoadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#fff',
    borderStyle: 'dashed',
    padding: 10,
  },
  loadingIndicator: {
    color: "#FFF",
    fontSize: 16,
    marginLeft: 8,
  },
  highPerplexityLabel: {
    borderWidth: 1,
    borderColor: "#9FA8DA",
    borderRadius: 50,
    paddingVertical: 9,
  },
  chatScrollContainer: {
    flexGrow: 1,
  },
  chatLogoContainer: {
    marginBottom: 10,
  },
  chatLogoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatLogoText: {
    color: '#FFF',
    fontSize: 24,
  },
  chatLoadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
  chatContentContainer: {
    flex: 1,
    marginVertical: 15,
    borderColor: '#FFF',
    borderWidth: 1,
    borderRadius: 15,
    borderStyle: 'dashed',
    paddingVertical: 15,
    paddingRight: 15,
    paddingLeft: 10,
  },
  convoNameInput: {
    backgroundColor: '#EFEFEF',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: '#202124',
    width: 220,
    marginBottom: 10
  },
  disabledButton: {
    opacity: 0.35,
  },
  responseTypeLabel: {
    color: '#FFF',
    marginRight: 10,
  },
  title: {
    color: '#FFF',
    fontSize: 16,
    marginLeft: 8,
  },
  segmentedButtonsContainer: {
    flexDirection: 'row',
    marginVertical: 8,
    borderRadius: 8,
    overflow: 'hidden',
  },
  segmentedButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 8,
    borderColor: '#4B4F56',
    borderWidth: 1,
    backgroundColor: '#2C2F33',
    minWidth: 135,
  },
  activeSegmentedButton: {
    backgroundColor: '#4834d4',
  },
  pressedSegmentedButton: {
    backgroundColor: '#1E88E5',
  },
  segmentedButtonText: {
    color: '#FFF',
    marginLeft: 5,
  },
  activeSegmentedButtonText: {
    color: '#FFF',
    marginLeft: 5,
    fontWeight: 'bold',
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageHistoryItem: {
    position: "relative",
    marginVertical: 10,
  },
  inactiveOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: 5,
  },
  image: {
    borderRadius: 5,
  },
  activeImage: {
    borderRadius: 5,
    borderWidth: 3,
    borderColor: '#007AFF',
    shadowColor: '#007AFF',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  deleteImage: {
    position: "absolute",
    top: -5,
    right: -5,
    backgroundColor: "#FF6B6B",
    borderRadius: 50,
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  deleteImageText: {
    color: "#FFF",
    fontSize: 12,
  },
  gradientButton: {
    borderRadius: 30,
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#2a87ff',
    marginTop: 15,
    marginBottom: 10,
  },
  gradientButtonText: {
    fontSize: 18,
    color: '#ffffff',
    marginLeft: 8,
    fontWeight: 'bold',
  },
  saveGradientButton: {
    borderRadius: 30,
    overflow: 'hidden',
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#8a3ab9',
  },
  gradient: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 30,
  },
  saveGradientButtonText: {
    color: '#ffffff',
    marginLeft: 8,
    fontSize: 16,
    fontWeight: '600',
  }, resetGradientButton: {
    borderRadius: 30,
    overflow: 'hidden',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#4a4a4a',
  },
  resetGradientButtonText: {
    color: '#ffffff',
    marginLeft: 8,
    fontSize: 16,
    fontWeight: '600',
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  inputIcon: {
    marginRight: 10,
  },
  imageContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  buttonsContainer: {
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  textInput: {
    width: "100%",
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: "#FFFFFF",
    marginBottom: 10,
  },
  imageHistoryContainer: {
    marginTop: 15,
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingVertical: 15,
    paddingHorizontal: 35,
  },
  imageHistoryTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
    marginBottom: 15,
    textAlign: 'center',
  },
  imageHistoryScrollView: {
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
  },
  generateButton: {
    backgroundColor: "#4E8FFF",
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginBottom: 20,
  },
  imageLimitMessageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  },
  imageLimitMessageCard: {
    backgroundColor: '#1a1f42',
    borderColor: '#2a87ff',
    borderWidth: 1,
  },
  imageLimitMessageContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageLimitMessageTextContainer: {
    marginLeft: 10,
    flex: 1,
  },
  imageLimitMessageTitle: {
    color: '#FF6347',
  },
  imageLimitMessageText: {
    color: '#ffffff',
  },
  arrowButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5,
    borderWidth: 1,
    borderColor: '#FFFFFF',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  arrowText: {
    color: '#FFFFFF',
    fontSize: 16,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    maxWidth: 800,
    margin: 20,
    backgroundColor: '#202124',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  buttonContainerStandard: {
    justifyContent: 'space-between',
  },
  buttonContainerLastScreen: {
    justifyContent: 'center',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#3a3a3a',
    padding: 10,
    borderRadius: 5,
    marginLeft: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 16,
    marginLeft: 5,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
});

export default ViewPromptScreen;
