import React, {FC, useCallback, useState} from 'react';
import {RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import PluginIntroduction from "../../components/Common/PluginIntroduction";
import PluginHowToUse from "../../components/Common/PluginHowToUse";
import PluginExamples from "../../components/Common/PluginExamples";
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import UserHeader from "../../components/User/Header";
import UserDrawerMenu from "../../components/User/DrawerMenu";

const AuthHeader = Header;
const AuthDrawerMenu = DrawerMenu;

const GPTPluginScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);
  const {user} = useAuthentication();

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          {user ?
            <UserHeader navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            :
            <AuthHeader navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          }
          <View sx={{
            width: '100%',
            maxWidth: 1500,
            paddingTop: 20,
            alignItems: 'center',
            paddingLeft: [20, 20, 40],
            paddingRight: [20, 20, 40]
          }}>
            <PluginIntroduction/>
            <PluginHowToUse/>
            <PluginExamples/>
          </View>
        </Pressable>
      </ScrollView>
    </View>
    {user ?
      <UserDrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
      :
      <AuthDrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    }
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  header: {
    color: '#FFF',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  text: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 20,
  },
});

export default GPTPluginScreen;
