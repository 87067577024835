import React from 'react';
import {Text} from 'react-native';
import {View} from "dripsy";
import {IconButton} from 'react-native-paper';
import {AntDesign, Ionicons} from '@expo/vector-icons';
import CustomTextInput from "../CustomTextInput";

const PromptVariablesSection = ({
                                  promptVariables,
                                  setPromptVariables,
                                  setTooltipId,
                                  setTooltipVisible,
                                }: any) => {
  const showTooltip = (id: any) => {
    setTooltipId(id);
    setTooltipVisible(true);
  };

  const updateVariableInput = (text: string, index: number) => {
    setPromptVariables((prevState: any) => {
      const newPromptVariables = [...prevState];
      // @ts-ignore
      newPromptVariables[index].value = text;
      return newPromptVariables;
    });
  };

  return !!promptVariables.length ? (
    <View style={{marginBottom: 16}} sx={{width: [300, 400, 500]}}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={{color: "#FFF", fontSize: 22}}>Prompt Variables</Text>
        <IconButton
          icon="information-outline"
          size={20}
          style={{margin: 0}}
          onPress={() => showTooltip("promptVariables")}
          theme={{colors: {primary: "#fff"}}}
        />
      </View>
      <Text style={{color: "#ccc", fontSize: 14, textAlign: "center", marginBottom: 10, marginTop: 5}}>
        Fill in the variables and press Run Prompt to get your personalized response from highPerlexity.
        Tap the tooltip icon for more info.
      </Text>
      {promptVariables?.map((variable: any, index: any) => (
        <View
          key={index}
          style={{
            marginBottom: 12,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: "#ccc",
            backgroundColor: "#333",
            padding: 12,
          }}
        >
          <View style={{flexDirection: "row", alignItems: "center"}}>
            <Ionicons name="md-code-outline" size={20} color="#ccc" style={{marginRight: 8}}/>
            <Text style={{color: "#FFF", fontSize: 16}}>
              {//@ts-ignore
                variable.name.replace(/%|\|/g, "")}
            </Text>
          </View>
          {//@ts-ignore
            variable.description && (
              <Text style={{color: "#ccc", fontSize: 12, marginBottom: 4}}>
                {//@ts-ignore
                  variable.description}
              </Text>
            )}
          <CustomTextInput
            // @ts-ignore
            value={variable.value}
            onChangeText={(text: any) => updateVariableInput(text, index)}
            //@ts-ignore
            placeholder={`Enter value for "${variable.name.replace(/%|\|/g, '')}" variable...`}
            inputType="variable"
          />
        </View>
      ))}
    </View>
  ) : (
    <View style={{marginBottom: 16, alignItems: "center"}} sx={{width: [300, 400, 500]}}>
      <Text style={{color: "#ccc", fontSize: 22, marginBottom: 8}}>
        This prompt has no variables!
      </Text>
      <AntDesign name="exclamationcircleo" size={50} color="#CCC" style={{marginBottom: 8}}/>
      <Text
        style={{color: "#ccc", fontSize: 16, textAlign: "center", paddingHorizontal: 5, lineHeight: 24}}>
        Since there are no variables to fill in, you can proceed to the response options. Choose the
        desired response type and tap the "Run Prompt" button to get a response from highPerplexity.
      </Text>
      <Text
        style={{
          color: "#ccc",
          fontSize: 16,
          textAlign: "center",
          paddingHorizontal: 5,
          lineHeight: 24,
          marginTop: 10,
          paddingTop: 8,
          paddingBottom: 8
        }}
      >
        Tap the "Show Full Prompt Content" button above to edit this prompt's content.
      </Text>
    </View>
  );
};

export default PromptVariablesSection;
