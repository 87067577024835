import React, {FC, useCallback, useEffect, useState} from 'react';
import {ActivityIndicator, Image, RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Box, Pressable, Text, useSx, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import ConversationCard from "../../components/ConversationCard";
import {collection, getDocs, query, where} from "firebase/firestore";
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import {db} from "../../config/firebase";
import {FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons';

const SavedConversationsScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);
  const [mySavedConversations, setMySavedConversations] = useState([]);
  const [mySavedImageConversations, setMySavedImageConversations] = useState([]);
  const {user} = useAuthentication();
  const customSX = useSx();
  const [loading, setLoading] = useState(true);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!user) return;
    (async () => {
      setLoading(true);
      // @ts-ignore
      let tempSavedResponses = [];
      const convoQuery = query(
        collection(db, 'conversations'),
        where('createdBy', '==', user?.uid),
        where('deleted', '==', false)
      );
      const querySnapshot = await getDocs(convoQuery);
      if (!querySnapshot.empty) {
        querySnapshot.docs.forEach((responseDoc) =>
          tempSavedResponses.push(responseDoc.data())
        );
        // @ts-ignore
        setMySavedConversations(tempSavedResponses);
      }

      // @ts-ignore
      let tempSavedImageResponses = [];
      const imageQuery = query(
        collection(db, 'imageConversations'),
        where('userId', '==', user?.uid),
        where('deleted', '==', false)
      );
      const imageSnapshot = await getDocs(imageQuery);
      if (!imageSnapshot.empty) {
        imageSnapshot.docs.forEach((responseDoc) =>
          tempSavedImageResponses.push(responseDoc.data())
        );
        // @ts-ignore
        setMySavedImageConversations(tempSavedImageResponses);
      }
      setLoading(false);
    })();
  }, [user]);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  if (loading) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>is loading...</Text>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <View style={{flex: 1}}>
        <ScrollView
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
          }
        >
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header
              navStatus={navStatus}
              setNavStatus={setNavStatus}
              navigation={navigation}
            />
            <View style={{alignItems: 'center', marginBottom: 15}}>
              <Text style={{color: '#FFF', textAlign: 'center', fontSize: 24}}>
                Saved Conversations
              </Text>
            </View>
            {!!mySavedConversations.length &&
              <Box sx={{width: ['100%', '90%', '60%'], alignSelf: 'center', paddingHorizontal: 15}}>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 8}}>
                  <MaterialCommunityIcons name="comment-text" size={25} color="white"/>
                  <Text style={[styles.listTitle, {marginLeft: 8}]}>Text Conversations</Text>
                </View>
                <View>
                  {mySavedConversations.map((conversation, index) => (
                    <ConversationCard
                      key={index}
                      conversation={conversation}
                      onView={() =>
                        navigation.navigate('GPTConversation', {//@ts-ignore
                          convoId: conversation?.id
                        })
                      }
                    />
                  ))}
                </View>
              </Box>
            }
            {!!mySavedImageConversations.length &&
              <Box sx={{width: ['100%', '90%', '60%'], alignSelf: 'center', paddingHorizontal: 15}}>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 8}}>
                  <FontAwesome name="image" size={25} color="white"/>
                  <Text style={[styles.listTitle, {marginLeft: 8}]}>Image Conversations</Text>
                </View>
                <View style={{alignItems: 'center'}}>
                  {mySavedImageConversations.map((conversation, index) => (
                    <Pressable
                      key={index}
                      onPress={() =>
                        navigation.navigate('ImageConversation', {
                          //@ts-ignore
                          convoId: conversation?.convoId,
                        })
                      }
                    >
                      <View style={styles.imageContainer}
                            sx={{paddingHorizontal: [20, 20, 30], paddingVertical: [15, 15, 20]}}>
                        <Image
                          //@ts-ignore
                          source={{uri: conversation?.mainImage}}
                          style={[styles.savedImage, customSX({width: [250, 330, 420], height: [250, 330, 420]})]}
                          resizeMode="contain"
                        />
                      </View>
                    </Pressable>
                  ))}
                </View>
              </Box>
            }
          </Pressable>
        </ScrollView>
      </View>
      <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  innerContainer: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  listTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#fff',
    textAlign: 'center',
  },
  imageContainer: {
    marginBottom: 20,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ffffff',
    borderRadius: 10,
    backgroundColor: '#303235',
    maxWidth: 500,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  savedImage: {
    borderRadius: 5,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    color: '#FFF',
    fontSize: 24,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default SavedConversationsScreen;
