import React, {FC, useCallback, useEffect, useState} from 'react';
import {Text} from 'react-native-paper';
import {ActivityIndicator, RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../config/firebase";
import PromptCard from "../../components/PromptCard";
import {useFocusEffect} from '@react-navigation/native';

const ViewPromptsByTagScreen: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [searchResultPrompts, setSearchResultPrompts] = useState([]);
  const [tagName, setTagName] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      // @ts-ignore
      setTagName(decodeURI(route?.params?.tagName));
    }, [route?.params?.tagName])
  );

  const onRefresh = useCallback(() => {
    if (!tagName) return;
    setRefreshing(true);
    (async () => {
      const querySnapshot = await getDocs(
        query(
          collection(db, "prompts"),
          where("tags", "array-contains", tagName),
          where("deleted", "==", false)
        )
      );
      // @ts-ignore
      setSearchResultPrompts(querySnapshot.docs.map(doc => doc.data()));
      setRefreshing(false);
    })();
  }, []);

  useEffect(() => {
    if (!tagName) return;
    (async () => {
      const querySnapshot = await getDocs(
        query(
          collection(db, "prompts"),
          where("tags", "array-contains", tagName),
          where("deleted", "==", false)
        )
      );
      // @ts-ignore
      setSearchResultPrompts(querySnapshot.docs.map(doc => doc.data()));
      setLoading(false);
    })();
  }, [tagName]);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <View sx={{
            marginBottom: [20, 20, 12],
            flexDirection: ['column', 'column', 'row'],
            justifyContent: ['center', 'center', 'flex-start'],
            alignItems: ['center', 'center', 'flex-start'],
          }}>
            <Text style={{color: '#FFF', fontSize: 25}}>
              Prompts Tagged: {tagName}
            </Text>
          </View>
          <View>
            {loading ? (
              <View style={{marginVertical: 15}}>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            ) : (
              <View sx={{
                flexDirection: ['column', 'column', 'row'],
                justifyContent: 'center',
                alignItems: ['center', 'center', 'flex-start'],
                flexWrap: 'wrap',
                maxWidth: 1450,
              }}>
                {searchResultPrompts?.map((prompt: any) =>
                  <PromptCard key={prompt.promptId}
                              promptId={prompt.promptId}
                              navigation={navigation}
                              promptImage={prompt?.promptImage?.imageURL}
                              promptTitle={prompt.title}
                              promptUserName={prompt.userName}
                              promptRatings={prompt.ratings}
                              promptComments={prompt.comments?.length}/>
                )}
              </View>
            )}
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  cardTitleContainer: {
    backgroundColor: '#222236',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  cardTitleText: {
    color: '#FFF',
  },
  cardBackgroundImage: {
    flex: 1,
    resizeMode: "cover",
    width: "100%",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
});

export default ViewPromptsByTagScreen;
