import React, {useState} from 'react';
import {View, Text, Pressable} from 'react-native';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {arrayRemove, arrayUnion, doc, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";

const SavePromptButton = ({isPromptCreator, userDoc, promptId, isAlreadySaved, setIsAlreadySaved}: any) => {

  const savePrompt = async () => {
    if (isAlreadySaved) return;
    await updateDoc(doc(db, "users", userDoc), {
      savedPrompts: arrayUnion(promptId)
    });
    setIsAlreadySaved(true);
  };

  const removeSavedPrompt = async () => {
    if (!isAlreadySaved) return;
    await updateDoc(doc(db, "users", userDoc), {
      savedPrompts: arrayRemove(promptId)
    });
    setIsAlreadySaved(false);
  };

  if (isPromptCreator) {
    return null;
  }

  return (
    <View style={{marginBottom: 10}}>
      <Pressable
        style={({pressed}) => [
          {
            backgroundColor: pressed ? '#3D6DCC' : 'transparent',
            borderColor: '#fff',
            borderWidth: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
            borderRadius: 5,
          },
        ]}
        onPress={isAlreadySaved ? removeSavedPrompt : savePrompt}
      >
        <Text style={{color: '#fff', marginRight: 5}}>
          {isAlreadySaved ? 'Prompt Already Saved' : 'Save Prompt'}
        </Text>
        <MaterialCommunityIcons
          name={isAlreadySaved ? 'bookmark-check' : 'bookmark-plus'}
          size={20}
          color="#fff"
        />
      </Pressable>
    </View>
  );
};

export default SavePromptButton;
