import React, {FC, useCallback, useState} from 'react';
import {RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import Icon from 'react-native-vector-icons/FontAwesome';
import {Button, Input} from 'react-native-elements';
import {StackScreenProps} from '@react-navigation/stack';
import {createUserWithEmailAndPassword, getAuth, sendEmailVerification} from 'firebase/auth';
import {addDoc, collection} from "firebase/firestore";
import {db} from '../../config/firebase';
import Checkbox from "expo-checkbox";
import {Link} from '@react-navigation/native';
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import useUnits from "rxn-units";
import {useSentEmailVerify} from "../../utils/hooks/useSentEmailVerify";

const SignUpScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const auth = getAuth();
  const [value, setValue] = useState({
    email: '',
    userName: '',
    password: '',
    error: ''
  });
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [isChecked, setChecked] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const {hasSentVerifyEmail, setHasSentVerifyEmail} = useSentEmailVerify();

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  async function signUp() {
    const timestamp = new Date().getTime();
    if (value.email === '' || value.userName === '' || value.password === '' || !isChecked) {
      setValue({
        ...value,
        error: 'Email, Username, Password, and Terms are mandatory.'
      })
      return;
    }

    auth.onAuthStateChanged(async user => {
      if (!user || hasSentVerifyEmail) return;
      setHasSentVerifyEmail(true);
      // @ts-ignore
      await sendEmailVerification(user);
    });

    try {
      const newUserData: any = await createUserWithEmailAndPassword(auth, value.email.trim(), value.password);
      await addDoc(collection(db, "users"), {
        email: newUserData['_tokenResponse'].email,
        userName: value.userName,
        uid: newUserData['_tokenResponse'].uid || newUserData['_tokenResponse'].localId,
        commentUpvotes: [],
        comments: [],
        promptUpvotes: [],
        prompts: [],
        savedPrompts: [],
        ranPrompts: [],
        responsePrompts: [],
        timestamp: timestamp
      });
    } catch (error: any) {
      setValue({
        ...value,
        error: error.message,
      })
    }
  }

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <View style={{flex: 1}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <View style={styles.logoContainer}>
              <Text style={styles.logoText}>
                hP
              </Text>
            </View>
            <View style={styles.subheaderContainer}>
              <Text style={styles.subheaderText}>Sign up for highPerplexity</Text>
            </View>
            {!!value.error && <View style={styles.error}><Text>{value.error}</Text></View>}
            <View style={styles.controls}>
              <Input
                placeholder='Email'
                value={value.email}
                onChangeText={(text) => setValue({...value, email: text.trim()})}
                style={styles.control}
                leftIcon={<Icon
                  name='envelope'
                  size={16}
                  color={'#FFF'}
                />}
              />
              <Input
                placeholder='Username'
                value={value.userName}
                onChangeText={(text) => setValue({...value, userName: text.trim()})}
                style={styles.control}
                leftIcon={<Icon
                  name='user'
                  size={16}
                  color={'#FFF'}
                />}
              />
              <Input
                placeholder='Password'
                value={value.password}
                onChangeText={(text) => setValue({...value, password: text})}
                style={styles.control}
                secureTextEntry={true}
                leftIcon={<Icon
                  name='key'
                  size={16}
                  color={'#FFF'}
                />}
              />
              <View style={styles.checkboxContainer}>
                <Checkbox
                  value={isChecked}
                  onValueChange={setChecked}
                  color={isChecked ? '#4630EB' : undefined}
                  style={styles.checkbox}
                />
                <Text style={styles.checkboxText}>
                  I agree to the{" "}
                  <Link to={{screen: "Terms"}}>
                    <Text style={styles.termsLink}>highPerplexity Terms</Text>
                  </Link>
                </Text>
              </View>
              <Pressable style={styles.signUpButton} onPress={signUp}>
                <Text style={styles.signUpButtonText}>Sign Up</Text>
              </Pressable>
              <Link to={{screen: "Welcome"}} style={styles.goBackButton}>
                <Text style={styles.goBackButtonText}>Back</Text>
              </Link>
            </View>
          </Pressable>
        </ScrollView>
      </View>
      <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#202124',
    alignItems: 'center',
    cursor: 'default',
  },
  logoContainer: {
    borderWidth: 1,
    borderColor: '#FFF',
    width: 90,
    height: 90,
    borderRadius: 90 / 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
  },
  logoText: {
    color: '#FFF',
    fontSize: 45
  },
  subheaderContainer: {
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  subheaderText: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FFF",
  },
  controls: {
    width: 250
  },
  control: {
    color: '#FFF'
  },
  error: {
    marginTop: 10,
    padding: 10,
    color: '#fff',
    backgroundColor: '#D54826FF',
  },
  inputContainerStyle: {
    borderBottomWidth: 1,
    borderBottomColor: "#666",
  },
  inputStyle: {
    color: "#FFF",
  },
  checkboxContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 12,
  },
  checkbox: {
    marginRight: 8,
  },
  checkboxText: {
    color: "#FFF",
  },
  termsLink: {
    textDecorationLine: "underline",
  },
  signUpButton: {
    backgroundColor: "#4630EB",
    paddingVertical: 12,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 16,
    marginBottom: 12,
  },
  signUpButtonText: {
    color: "#FFF",
    fontSize: 18,
    fontWeight: "600",
  },
  goBackButton: {
    alignSelf: "center",
    marginTop: 10,
    backgroundColor: "#737781",
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 10,
  },
  goBackButtonText: {
    color: "#E5E5E8",
    fontSize: 18,
    fontWeight: "400",
  },
});

export default SignUpScreen;
