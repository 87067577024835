import React, {useEffect, useState} from 'react';
import {Platform, Text, TextInput, useWindowDimensions} from 'react-native';
import {Pressable, View} from "dripsy";
import {HelperText, IconButton} from 'react-native-paper';
import {Feather, Ionicons} from '@expo/vector-icons';

const PromptContentSection = ({
                                promptContent,
                                setPromptContent,
                                setUserInput,
                                setTooltipId,
                                setTooltipVisible,
                                matchingPrompt,
                                promptId,
                              }: any) => {
  const [inputHeight, setInputHeight] = useState();
  const [showPromptContent, setShowPromptContent] = useState(true);
  const [isMobileContentTruncated, setIsMobileContentTruncated] = useState(true);
  const windowDimensions = useWindowDimensions();

  const playgroundPromptId = 'b148c38f-0cc3-435d-8ccc-93e59fe028e3';

  const inputStyle = {
    height: inputHeight,
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ccc',
    fontSize: 16,
    lineHeight: 24,
    backgroundColor: '#333',
    color: '#fff',
  };

  const showTooltip = (id: any) => {
    setTooltipId(id);
    setTooltipVisible(true);
  };

  const ShowTruncToggle = () => {
    return <View style={{marginTop: 5, marginBottom: 10}}>
      {Platform.OS === 'web' ?
        <Pressable
          onPress={() => setShowPromptContent(!showPromptContent)}
          style={({pressed}) => [
            {
              backgroundColor: pressed ? "#4a4a4a" : "transparent",
              borderColor: "#ccc",
              borderWidth: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              borderRadius: 5,
              shadowColor: "#000",
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.2,
              shadowRadius: 1,
              elevation: 2,
              display: 'none',
            },
          ]}
        >
          <Text style={{color: "#fff", fontSize: 16, marginRight: 5}}>
            {showPromptContent ? "Hide Prompt Content" : "Show Prompt Content"}
          </Text>
          <Feather name={showPromptContent ? "file-minus" : "file-plus"} size={20} color="#FFF"
                   style={{marginLeft: 5}}/>
        </Pressable>
        :
        <Pressable
          style={({pressed}) => [
            {
              backgroundColor: pressed ? "#4a4a4a" : "transparent",
              borderColor: "#ccc",
              borderWidth: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              borderRadius: 5,
              shadowColor: "#000",
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.2,
              shadowRadius: 1,
              elevation: 2,
            },
          ]}
          onPress={() => setIsMobileContentTruncated(!isMobileContentTruncated)}
        >
          <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
            {isMobileContentTruncated ? "Show Full Prompt Content" : "Collapse Prompt Content"}
          </Text>
          <Ionicons
            name={isMobileContentTruncated ? "md-expand" : "md-contract"}
            size={20}
            color="#FFF"
          />
        </Pressable>
      }
    </View>
  }

  useEffect(() => {
    const updateHeight = () => {
      const screenWidth = windowDimensions.width;

      if (screenWidth < 768) {
        // @ts-ignore
        setInputHeight(200);
      } else {
        // @ts-ignore
        setInputHeight(300);
      }
    };

    updateHeight();
  }, [windowDimensions]);

  if (!matchingPrompt?.content || (promptId === playgroundPromptId)) {
    return null;
  }

  if (!showPromptContent) {
    return <ShowTruncToggle/>;
  }

  return <>
    {Platform.OS === 'web' ? (
      <View style={{
        backgroundColor: '#2a2a2a',
        borderRadius: 5,
        padding: 15,
      }}
            sx={{width: [300, 400, 600]}}>
        <View style={{alignItems: 'center'}}>
          <Ionicons name="md-create" size={60} color="#ccc"/>
        </View>
        <Text style={{
          color: '#ccc',
          fontSize: 14,
          fontStyle: 'italic',
          marginBottom: 8,
        }}>
          This section contains the prompt that you are about to run. Configure the variables before
          running the prompt or click the tooltip icon for more info.
        </Text>
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
          <HelperText type="info" visible={true}
                      style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
            Prompt Content <Text style={{fontSize: 14, fontWeight: 'normal'}}>(Editable)</Text>
          </HelperText>
          <IconButton
            icon="information-outline"
            size={20}
            style={{margin: 0}}
            onPress={() => showTooltip('promptContent')}
            theme={{colors: {primary: "#ccc"}}}
          />
        </View>
        <TextInput
          value={promptContent}
          style={{
            ...inputStyle,
            borderColor: "#ccc",
            fontSize: 16,
            lineHeight: 24,
          }}
          editable={true}
          multiline={true}
          onChangeText={(text: any) => {
            // @ts-ignore
            setPromptContent(text);
            setUserInput(text);
          }}
        />
      </View>
    ) : (
      <View style={{
        backgroundColor: '#2a2a2a',
        borderRadius: 5,
        padding: 10,
      }}
            sx={{width: [300, 400, 500]}}>
        <View style={{alignItems: 'center'}}>
          <Ionicons name="md-create" size={60} color="#ccc"/>
        </View>
        <Text style={{
          color: '#ccc',
          fontSize: 14,
          fontStyle: 'italic',
          marginBottom: 8,
        }}>
          This section contains the prompt that you are about to run. Configure the variables before
          running the prompt or tap the tooltip icon for more info.
        </Text>
        <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
          <HelperText type="info" visible={true}
                      style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
            Prompt Content <Text style={{fontSize: 14, fontWeight: 'normal'}}>(Editable)</Text>
          </HelperText>
          <IconButton
            icon="information-outline"
            size={20}
            style={{margin: 0}}
            onPress={() => showTooltip('promptContent')}
            theme={{colors: {primary: "#ccc"}}}
          />
        </View>
        <TextInput
          value={
            isMobileContentTruncated ?
              // @ts-ignore
              (promptContent.replace(/\\n/g, "\n").substring(0, 300) + '...') :
              // @ts-ignore
              (promptContent.replace(/\\n/g, "\n"))
          }
          style={{
            ...inputStyle,
            borderColor: "#ccc",
            fontSize: 16,
            lineHeight: 24,
            height: 'auto',
          }}
          editable={true}
          multiline={true}
          onChangeText={(text: any) => {
            // @ts-ignore
            setPromptContent(text);
            setUserInput(text);
          }}
        />
      </View>
    )}
    {// @ts-ignore
      matchingPrompt?.content &&
      <ShowTruncToggle/>
    }
  </>
};

export default PromptContentSection;
