import React, {FC, useCallback, useEffect, useState} from 'react';
import {ActivityIndicator, Image, RefreshControl, SafeAreaView, StyleSheet, TextInput} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {Pressable, Text, useSx, View} from 'dripsy';
import {Button} from 'react-native-elements';
import {Card, Paragraph, Title} from "react-native-paper";
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {StackScreenProps} from "@react-navigation/stack";
import {getAuth} from 'firebase/auth';
import {addDoc, collection, doc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {db} from "../../config/firebase";
import {Ionicons} from '@expo/vector-icons';
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import {LinearGradient} from 'expo-linear-gradient';
import {imageLoadingMessages} from "../../utils/misc/promptLoadingMessages";
import useUnits from "rxn-units";

const ImageConversationScreen: FC<StackScreenProps<any>> = ({navigation, route}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const auth = getAuth();
  const [refreshing, setRefreshing] = useState(false);
  const [userDoc, setUserDoc] = useState('');
  const [fullUserDoc, setFullUserDoc] = useState();
  const [remainingTokens, setRemainingTokens] = useState();
  const [tokenCost, setTokenCost] = useState();
  const [prompt, setPrompt] = useState('');
  const [image, setImage] = useState('');
  const [imageHistory, setImageHistory] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const {user} = useAuthentication();
  const [canEditPrompt, setCanEditPrompt] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [conversationData, setConversationData] = useState(null);
  const [hideOriginalImagePrompt, setHideOriginalImagePrompt] = useState(false);
  const customSX = useSx();
  const [loading, setLoading] = useState(true);
  const [imageloadingMessage, setImageLoadingMessage] = useState('');
  const [imagesUserAPI, setImagesUserAPI] = useState(false);

  const fetchConversationData = async (convoId: any) => {
    setLoading(true);
    const convoQuery = query(
      collection(db, "imageConversations"),
      where("convoId", "==", convoId)
    );

    const querySnapshot = await getDocs(convoQuery);

    if (!querySnapshot.empty) {
      const conversationData = querySnapshot.docs[0].data();
      //@ts-ignore
      setConversationData(conversationData);
      setImage(conversationData.mainImage);
      setImageHistory(conversationData.imageHistory);
    } else {
      console.log("No such conversation!");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!user) return;
    (async () => {
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === user?.uid) {
          setUserDoc(doc.id);
          // @ts-ignore
          setFullUserDoc(doc.data());
          if (doc.data().imagesUserAPI) {
            setImagesUserAPI(true);
          } else {
            setImagesUserAPI(false);
          }
          setRemainingTokens(doc?.data()?.tokenAdmin || 0);
          // @ts-ignore
          setTokenCost((doc.data()?.accessLevel === 'pro' || doc.data()?.accessLevel === 'admin' || doc.data()?.accessLevel === 'superuser' || doc.data()?.accessLevel === 'friend' || doc.data()?.imagesUserAPI === true)
            ? 0 : 2);
        }
      });
    })();
  }, [user]);

  useEffect(() => {
    if (route.params?.convoId) {
      fetchConversationData(route.params.convoId);
    }
  }, [route.params?.convoId]);

  const changeLoadingMessage = () => {
    const randomIndex = Math.floor(Math.random() * imageLoadingMessages.length);
    setImageLoadingMessage(imageLoadingMessages[randomIndex]);
  };

  useEffect(() => {
    if (!imageLoading) return;

    changeLoadingMessage();
    const interval = setInterval(changeLoadingMessage, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [imageLoading]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  useEffect(() => {
    (async () => {
      if (!auth) return;
      const querySnapshot = await getDocs(collection(db, "users"));
      const matchingDoc = querySnapshot.docs.find(doc => auth?.currentUser?.uid === doc.data().uid);
      if (matchingDoc) {
        setUserDoc(matchingDoc.id);
      }
    })();
  }, [auth]);

  const addToImageHistory = (newImage: any) => {
    setImageHistory((prevState: any) => {
      // Check if the new image is already in the history
      if (prevState.includes(newImage)) {
        return prevState;
      }
      return [...prevState, newImage];
    });
  };

  const removeImageFromHistory = (indexToRemove: any) => {
    setImageHistory((prevImageHistory) => {
      return prevImageHistory.filter((_, index) => index !== indexToRemove);
    });
  };

  const handleArrowClick = (direction: "left" | "right") => {
    //@ts-ignore
    if (imageHistory?.length === 0) return;
    //@ts-ignore
    const currentIndex = imageHistory?.indexOf(image);
    const newIndex =
      direction === "left"
        //@ts-ignore
        ? (currentIndex - 1 + imageHistory?.length) % imageHistory?.length
        //@ts-ignore
        : (currentIndex + 1) % imageHistory?.length;
    //@ts-ignore
    setImage(imageHistory[newIndex]);
  };

  const resetConversation = () => {
    setPrompt('');
    setImage('');
    setImageHistory([]);
    setCanEditPrompt(false);
    setGenerating(false);
    setIsSaving(false);
    setIsSaved(true);
  };

  const ImageHistoryItem = ({image, onClick, canRemove, index, isActive}: any) => {
    return (
      <View style={styles.imageHistoryItem}>
        <Pressable onPress={() => onClick(image)}>
          <Image
            source={{uri: image}}
            style={[isActive ? styles.activeImage : styles.image,
              customSX({width: [250, 330, 420], height: [250, 330, 420]})]}
            resizeMode="contain"
          />
          {!isActive && (
            <View style={styles.inactiveOverlay}/>
          )}
        </Pressable>
        {canRemove && !isActive && (
          <Pressable
            onPress={() => removeImageFromHistory(image)}
            style={styles.deleteImage}
          >
            <Ionicons
              name="close"
              size={12}
              color="#FFF"
            />
          </Pressable>
        )}
      </View>
    );
  };

  const CreateVariationButton = ({onPress}: any) => (
    <Pressable onPress={onPress} disabled={isSaving}>
      <LinearGradient
        colors={['#2a87ff', '#1a1f42']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={styles.gradientButton}
      >
        <Ionicons name="play" size={24} color="#ffffff"/>
        <Text style={styles.gradientButtonText}>Create New Variation</Text>
      </LinearGradient>
    </Pressable>
  );

  const SaveConversationButton = ({onPress}: any) => (
    <Pressable onPress={onPress} style={styles.saveGradientButton} disabled={isSaving}>
      <LinearGradient
        colors={['#8a3ab9', '#4a0072']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={styles.gradient}
      >
        <Ionicons name="save" size={20} color="#ffffff"/>
        <Text style={styles.saveGradientButtonText}>
          {isSaving ? "Saving..." : isSaved ? "Saved" : "Save Image Conversation"}
        </Text>
      </LinearGradient>
    </Pressable>
  );

  const handleSaveConversation = async () => {
    if (!auth.currentUser || !userDoc) return;

    setIsSaving(true);

    const storage = getStorage();
    const imageFileDirectory = `${auth.currentUser.uid}/`;
    const imagePrefix = 'image_conversation_';
    const imageExtension = '.png';

    const saveImageToStorage = async (base64Image: any, index: any) => {
      const timestamp = new Date().getTime();
      const imageFileName = `${imagePrefix}${timestamp}_${index}${imageExtension}`;
      const imageFullName = `${imageFileDirectory}${imageFileName}`;
      const storageRef = ref(storage, imageFullName);

      const response = await fetch(base64Image);
      const imageBlob = await response.blob();
      await uploadBytes(storageRef, imageBlob);

      return await getDownloadURL(storageRef);
    };

    const imageUrls = await Promise.all(
      imageHistory.map((base64Image, index) => saveImageToStorage(base64Image, index))
    );

    const conversationData = {
      userId: auth.currentUser.uid,
      prompt: prompt,
      imageHistory: imageUrls,
      mainImage: imageUrls[imageUrls.length - 1],
      createdAt: new Date(),
    };

    const conversationRef = collection(db, "imageConversations");
    await addDoc(conversationRef, conversationData);

    setIsSaving(false);
    setIsSaved(true);
  };

  const ResetConversationButton = ({onPress}: any) => (
    <Pressable onPress={onPress} style={styles.resetGradientButton} disabled={isSaving}>
      <LinearGradient
        colors={['#4a4a4a', '#2a2a2a']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={styles.gradient}
      >
        <Ionicons name="refresh-outline" size={20} color="#ffffff"/>
        <Text style={styles.resetGradientButtonText}>Reset Conversation</Text>
      </LinearGradient>
    </Pressable>
  );

  const handleGenerateImages = async (imageData?: any) => {
    if (!auth.currentUser) return;
    setImageLoadingMessage('');
    setGenerating(true);
    setImageLoading(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      //@ts-ignore
      prompt: conversationData?.prompt,
      APIStatus: imagesUserAPI,
    };

    if (imageData) {
      // @ts-ignore
      requestBody.input_image_url = imageData;
    }

    try {
      const response = await fetch('https://highperplexity-dalle-middleware-mjp27ovqaq-uc.a.run.app', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data && data.response) {
        if (image) {
          addToImageHistory(image);
        }
        const newImage = `data:image/png;base64,${data.response.b64_json}`;
        setImage(newImage);
        addToImageHistory(newImage);
      } else {
        console.log('Unexpected response format');
      }
    } catch (error) {
      console.error('Error generating images:', error);
    }
    setImageLoading(false);
    setCanEditPrompt(false);
  };

  const handleCreateVariation = async () => {
    if (image) {
      addToImageHistory(image);
      await handleGenerateImages(image);
      setIsSaved(false);
    }
  };

  const ImageLimitMessage = () => (
    <View style={styles.imageLimitMessageContainer}>
      <Card style={styles.imageLimitMessageCard}>
        <Card.Content style={styles.imageLimitMessageContent}>
          <Ionicons name="alert-circle" size={24} color="#FF6347"/>
          <View style={styles.imageLimitMessageTextContainer}>
            <Title style={styles.imageLimitMessageTitle}>Limit Reached</Title>
            <Paragraph style={styles.imageLimitMessageText}>
              Image conversations may only contain up to 6 images at this time. To create another variation, remove an
              existing one first.
            </Paragraph>
          </View>
        </Card.Content>
      </Card>
    </View>
  );

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  if (loading) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>is loading...</Text>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  return (<SafeAreaView style={styles.safeAreaView}>
    <View style={{flex: 1}}>
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }
      >
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header
            navStatus={navStatus}
            setNavStatus={setNavStatus}
            navigation={navigation}
          />
          <View style={{width: '100%', alignItems: 'center', marginTop: 20}}>
            <View sx={{width: ['85%', '90%', '100%'], alignItems: 'center', marginBottom: 20}}>
              <View style={{marginBottom: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <View>
                  <Text sx={{color: '#9FA8DA', textAlign: 'center', fontSize: [18, 20, 22]}}>
                    Your image conversation with
                  </Text>
                </View>
                <View style={{
                  borderWidth: 1,
                  borderColor: "#9FA8DA",
                  borderRadius: 50,
                  paddingHorizontal: 7,
                  paddingVertical: 7,
                  marginLeft: 6,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <Text
                    style={[
                      styles.label,
                      {color: '#9FA8DA'}
                    ]}
                  >
                    hP
                  </Text>
                </View>
              </View>
              {!hideOriginalImagePrompt &&
                <View style={styles.inputContainer} sx={{width: [300, 400, 500]}}>
                  <Ionicons name="pencil" size={20} color="#9E9E9E" style={styles.inputIcon}/>
                  {(!generating && canEditPrompt) &&
                    <TextInput
                      style={styles.textInput}
                      //@ts-ignore
                      onChangeText={(text) => setPromptContent(text)}
                      //@ts-ignore
                      value={conversationData?.prompt}
                      placeholder="Enter your prompt"
                      placeholderTextColor="#9E9E9E"
                      editable={canEditPrompt}
                    />
                  }
                  {(generating || !canEditPrompt) &&
                    <Text style={{color: '#FFF'}}>
                      {//@ts-ignore
                        conversationData?.prompt}
                    </Text>
                  }
                </View>
              }
              <View style={{marginBottom: 10}}>
                <Pressable
                  style={({pressed}) => [
                    {
                      backgroundColor: pressed ? "#3D6DCC" : "transparent",
                      borderColor: "#fff",
                      borderWidth: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                      borderRadius: 5,
                    },
                  ]}
                  onPress={() => setHideOriginalImagePrompt(!hideOriginalImagePrompt)}
                >
                  <Text style={{color: "#fff", marginRight: 5}}>
                    {hideOriginalImagePrompt ? "Show Image Prompt Content" : "Hide Image Prompt Content"}
                  </Text>
                  <Ionicons name={hideOriginalImagePrompt ? "md-eye-outline" : "md-eye-off-outline"} size={20}
                            color="#FFF"/>
                </Pressable>
              </View>
              {!generating && canEditPrompt && (
                <Button
                  title="Create Image"
                  onPress={() => handleGenerateImages()}
                  //@ts-ignore
                  buttonStyle={styles.generateButton}
                />
              )}
              {imageLoading ? (
                <View style={styles.imageLoadingContainer} sx={{height: [300, 400, 500], width: [300, 400, 500]}}>
                  <Text style={styles.loadingText}>{imageloadingMessage}</Text>
                  <ActivityIndicator size="large" color="#4E8FFF"/>
                </View>
              ) : (
                //@ts-ignore
                !!image && (
                  <View sx={{height: [300, 400, 500], width: [300, 400, 500]}}>
                    <Image
                      //@ts-ignore
                      source={{uri: image}}
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 5,
                      }}
                      resizeMode="contain"
                    />
                  </View>
                )
              )}
              {imageHistory?.length > 0 && (
                <>
                  {!imageLoading &&
                    <View style={styles.buttonsContainer}>
                      {//@ts-ignore
                        (imageHistory?.length >= 6) ?
                          <ImageLimitMessage/>
                          :
                          <View style={{marginTop: 10}}>
                            <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 10}}>
                              Token Cost: {tokenCost}
                            </Text>
                            <Text style={{color: '#FFF', textAlign: 'center'}}>
                              Remaining Tokens: {remainingTokens}
                            </Text>
                            <CreateVariationButton onPress={handleCreateVariation}/>
                          </View>
                      }
                      <SaveConversationButton onPress={handleSaveConversation}/>
                      {/*<ResetConversationButton onPress={resetConversation}/>*/}
                    </View>
                  }
                  <View style={styles.imageHistoryContainer} sx={{width: [270, 360, 450], alignItems: 'center'}}>
                    <Text style={styles.imageHistoryTitle}>Image History</Text>
                    <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 5
                      }}>
                        <Pressable onPress={() => handleArrowClick("right")} style={styles.arrowButton}>
                          <Ionicons
                            name="chevron-back"
                            size={35}
                            color="#FFFFFF"
                          />
                        </Pressable>
                        <Pressable onPress={() => handleArrowClick("left")} style={styles.arrowButton}>
                          <Ionicons
                            name="chevron-forward"
                            size={35}
                            color="#FFFFFF"
                          />
                        </Pressable>
                      </View>
                      <View style={styles.imageHistoryScrollView}>
                        {//@ts-ignore
                          [...imageHistory]?.reverse().map((historyImage, index) => (
                            <ImageHistoryItem
                              key={index}
                              image={historyImage}
                              onClick={(clickedImage: any) => setImage(clickedImage)}
                              //@ts-ignore
                              canRemove={imageHistory?.length > 1}
                              index={index}
                              isActive={historyImage === image}
                            />
                          ))}
                      </View>
                    </View>
                  </View>
                </>
              )}
            </View>
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    backgroundColor: "#202124",
  },
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#202124",
    cursor: "default",
  },
  heading: {
    fontSize: 28,
    fontWeight: "600",
    color: "#FFFFFF",
    marginBottom: 20,
  },
  buttonsContainer: {
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  createVariationButton: {
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginVertical: 10,
    minWidth: 166,
    minHeight: 46,
    backgroundColor: '#1c7af2',
  },
  resetButton: {
    backgroundColor: '#FF6B6B',
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
    minWidth: 166,
    minHeight: 46,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    width: "100%",
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: "#FFFFFF",
    marginBottom: 10,
  },
  arrowIcon: {
    padding: 8,
  },
  generateButton: {
    backgroundColor: "#4E8FFF",
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginBottom: 20,
  },
  imageRow: {
    justifyContent: "space-between",
    marginBottom: 10,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  inputIcon: {
    marginRight: 10,
  },
  imageContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  goBackButton: {
    backgroundColor: "#4E8FFF",
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginTop: 20,
  },
  imageHistoryContainer: {
    marginTop: 15,
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingVertical: 15,
    paddingHorizontal: 35,
  },
  imageHistoryTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
    marginBottom: 15,
    textAlign: 'center',
  },
  imageHistoryScrollView: {
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    color: '#FFF',
    fontSize: 45
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  imageHistoryItem: {
    position: "relative",
    marginVertical: 10,
  },
  image: {
    width: 250,
    height: 250,
    borderRadius: 5,
  },
  activeImage: {
    width: 250,
    height: 250,
    borderRadius: 5,
    borderWidth: 3,
    borderColor: '#007AFF',
    shadowColor: '#007AFF',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  deleteImage: {
    position: "absolute",
    top: -5,
    right: -5,
    backgroundColor: "#FF6B6B",
    borderRadius: 50,
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  deleteImageText: {
    color: "#FFF",
    fontSize: 12,
  },
  imageLoadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#fff',
    borderStyle: 'dashed',
  },
  button: {
    borderRadius: 5,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
  },
  resetConversationButton: {
    backgroundColor: '#2c2c2e',
  },
  buttonText: {
    color: '#ffffff',
    marginLeft: 5,
  },
  createVariationButtonText: {
    fontSize: 18,
  },
  resetConversationButtonText: {
    fontSize: 16,
  },
  gradientButton: {
    borderRadius: 30,
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#2a87ff',
    marginTop: 15,
    marginBottom: 10,
  },
  gradientButtonText: {
    fontSize: 18,
    color: '#ffffff',
    marginLeft: 8,
    fontWeight: 'bold',
  },
  saveGradientButton: {
    borderRadius: 30,
    overflow: 'hidden',
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#8a3ab9',
  },
  gradient: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 30,
  },
  saveGradientButtonText: {
    color: '#ffffff',
    marginLeft: 8,
    fontSize: 16,
    fontWeight: '600',
  },
  resetGradientButton: {
    borderRadius: 30,
    overflow: 'hidden',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#4a4a4a',
  },
  resetGradientButtonText: {
    color: '#ffffff',
    marginLeft: 8,
    fontSize: 16,
    fontWeight: '600',
  },
  label: {
    fontWeight: 'bold',
  },
  imageLimitMessageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  },
  imageLimitMessageCard: {
    backgroundColor: '#1a1f42',
    borderColor: '#2a87ff',
    borderWidth: 1,
  },
  imageLimitMessageContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageLimitMessageTextContainer: {
    marginLeft: 10,
    flex: 1,
  },
  imageLimitMessageTitle: {
    color: '#FF6347',
  },
  imageLimitMessageText: {
    color: '#ffffff',
  },
  arrowButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5,
    borderWidth: 1,
    borderColor: '#FFFFFF',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  arrowText: {
    color: '#FFFFFF',
    fontSize: 16,
  },
  inactiveOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: 5,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ImageConversationScreen;
