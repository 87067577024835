import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Dimensions} from 'react-native';
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import './CheckoutForm.css';
import {getAuth} from "firebase/auth";
import {collection, doc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../config/firebase";
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import {Pressable, Text, View} from "dripsy";
import Icon from "react-native-vector-icons/FontAwesome";

const screenWidth = Dimensions.get('window').width;

const CheckoutForm = ({priceId, setSelectedProduct}: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const auth = getAuth();
  const {user} = useAuthentication();
  const [userDoc, setUserDoc] = useState('');
  const [fullUserDoc, setFullUserDoc] = useState('');
  const [paymentStatus, setPaymentStatus] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');
  const [nameValid, setNameValid] = useState(false);
  const [zipValid, setZipValid] = useState(false);
  const [cardValid, setCardValid] = useState(false);

  const handleCardNumberChange = (event: any) => {
    setCardValid(event.complete);
  };

  const handleCardExpiryChange = (event: any) => {
    setCardValid(event.complete);
  };

  const handleCardCvcChange = (event: any) => {
    setCardValid(event.complete);
  };

  const handleNameChange = (e: any) => {
    setNameValid(e.target.value.trim().length > 0);
  };

  const handleZipChange = (e: any) => {
    setZipValid(/^\d{5}$/.test(e.target.value.trim()));
  };
  const stripeElementOptions = {
    style: {
      base: {
        color: '#ffffff',
        fontSize: '16px',
        '::placeholder': {
          color: '#757575',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  useEffect(() => {
    if (!user) return;
    (async () => {
      const userQuery = query(collection(db, "users"), where("uid", "==", user.uid));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        setUserDoc(doc.id);
        //@ts-ignore
        setFullUserDoc(doc.data());
      }
    })();
  }, [user]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPaymentStatus('loading');

    const cardElement = elements.getElement(CardNumberElement);
    //@ts-ignore
    const cardholderName = document.getElementById('name').value;
    //@ts-ignore
    const cardholderZip = document.getElementById('zip').value;

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!,
      billing_details: {
        name: cardholderName,
        address: {
          postal_code: cardholderZip,
        },
        //@ts-ignore
        email: fullUserDoc ? fullUserDoc.email : '',
      },
    });

    if (error) {
      console.error('[error]', error);
      setPaymentStatus('error');
    } else {
      // Call your server to process the payment with the paymentMethod.id
      console.log('[PaymentMethod]', paymentMethod);

      // Send paymentMethod.id, product ID, and authTokenValue to your server
      const authTokenValue = await auth?.currentUser?.getIdTokenResult();
      // @ts-ignore
      const userRef = doc(db, "users", userDoc);
      // @ts-ignore
      await updateDoc(userRef, {currentJWT: authTokenValue.token});

      const response = await fetch("https://highperplexity-stripe-manager-mjp27ovqaq-uc.a.run.app", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "firebase_token_value": authTokenValue?.token,
          "price_id": priceId,
          "payment_method_id": paymentMethod.id,
        }),
      });

      const data = await response.json();
      console.log('data');
      console.log(data);

      if (response.ok) {
        setPaymentStatus('success');
      } else {
        setPaymentStatus('error');
      }
    }
  };

  const renderContent = () => {
    return (
      <>
        <div className="stripe-info">
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon name="lock" size={20} color="#FFF"/>
            {screenWidth < 768 ?
              <Text style={{color: '#FFF', fontSize: 16, marginLeft: 10}}>
                Powered by{' '}
                <Text style={{fontWeight: 'bold'}}>Stripe</Text>
              </Text>
              :
              <Text style={{color: '#FFF', fontSize: 16, marginLeft: 10}}>
                Secure checkout experience powered by{' '}
                <Text style={{fontWeight: 'bold'}}>Stripe</Text>
              </Text>
            }
          </View>
        </div>
        <div style={{display: paymentStatus === 'idle' ? 'flex' : 'none', flexDirection: 'column'}}>
          <div className="card-element-container">
            <div className="input-field" style={{marginBottom: screenWidth < 768 ? 8 : 12}}>
              <label htmlFor="name">
                <Text style={{color: '#FFF'}}>
                  Name on card
                </Text>
              </label>
              <input
                id="name"
                type="text"
                placeholder="Full Name"
                onChange={handleNameChange}
              />
            </div>
            <div className="input-field" style={{marginBottom: screenWidth < 768 ? 8 : 12}}>
              <label htmlFor="cardNumber">
                <Text style={{color: '#FFF'}}>
                  Card number
                </Text>
              </label>
              <CardNumberElement
                id="cardNumber"
                className="stripe-input"
                options={stripeElementOptions}
                onChange={handleCardNumberChange}
              />
            </div>
            <div className="input-row">
              <div className="input-field" style={{marginBottom: screenWidth < 768 ? 8 : 12}}>
                <label htmlFor="expiry">
                  <Text style={{color: '#FFF'}}>
                    Expiry date
                  </Text>
                </label>
                <CardExpiryElement
                  id="expiry"
                  className="stripe-input"
                  options={stripeElementOptions}
                  onChange={handleCardExpiryChange}
                />
              </div>
              <div className="input-field" style={{marginBottom: screenWidth < 768 ? 8 : 12}}>
                <label htmlFor="cvc">
                  <Text style={{color: '#FFF'}}>
                    CVC
                  </Text>
                </label>
                <CardCvcElement
                  id="cvc"
                  className="stripe-input"
                  options={stripeElementOptions}
                  onChange={handleCardCvcChange}
                />
              </div>
            </div>
            <div className="input-field">
              <label htmlFor="zip">
                <Text style={{color: '#FFF'}}>
                  Billing ZIP code
                </Text>
              </label>
              <input
                id="zip"
                type="text"
                placeholder="ZIP Code"
                onChange={handleZipChange}
              />
            </div>
          </div>
          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '75%',
            justifyContent: 'center',
            marginHorizontal: 'auto',
          }}>
            <Text style={{color: '#FFF', fontSize: 14, fontStyle: 'italic', textAlign: 'center'}}>
              All payments are processed securely by Stripe, Inc.
              <Text sx={{display: screenWidth < 768 ? 'none' : 'block'}}>
                highPerplexity does not save or store any user payment information.
              </Text>
            </Text>
          </View>
          <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={!stripe || !nameValid || !zipValid || !cardValid}
              className="pay-button"
              style={{
                marginRight: 10,
                backgroundColor: (!stripe || !nameValid || !zipValid || !cardValid) ? '#CCC' : '#424242',
                color: (!stripe || !nameValid || !zipValid || !cardValid) ? '#7E7E7E' : '#FFF',
                padding: '12px 24px',
                borderRadius: '5px',
                border: 'none',
                cursor: (!stripe || !nameValid || !zipValid || !cardValid) ? 'not-allowed' : 'pointer',
              }}
            >
              {(!stripe || !nameValid || !zipValid || !cardValid) ? 'Enter Valid Payment Details' : 'Pay'}
            </button>
            <button type="button"
                    onClick={() => setSelectedProduct(null)}
                    className="pay-button">
              Close
            </button>
          </View>
        </div>
        <View
          sx={{display: paymentStatus === 'loading' ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center'}}>
          <ActivityIndicator size="large" color="#ffffff"/>
          <Text sx={{color: 'white', textAlign: 'center', marginTop: 2}}>Processing payment...</Text>
        </View>
        <View
          sx={{display: paymentStatus === 'error' ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center'}}>
          <Text sx={{color: 'red', textAlign: 'center', marginBottom: 2}}>
            Error processing payment. Please try again.
          </Text>
          <Pressable
            onPress={() => setPaymentStatus('idle')}
            style={({pressed}: any) => [
              {
                backgroundColor: pressed ? '#ccc' : '#000',
                padding: 10,
                borderRadius: 5,
              },
            ]}
          >
            <Text sx={{color: '#FFF', fontWeight: 'bold'}}>Try again</Text>
          </Pressable>
        </View>
        <View
          sx={{display: paymentStatus === 'success' ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center'}}>
          <Text style={{color: '#FFF', textAlign: 'center', fontWeight: 'bold', fontSize: 18, marginBottom: 8}}>
            Thank you for your payment!
          </Text>
          <Text style={{color: '#FFF', textAlign: 'center', fontStyle: 'italic', fontSize: 14}}>
            Refresh the store page to see your purchase in the Account Information section.
          </Text>
        </View>
      </>
    );
  };

  return (
    <div className="checkout-form" onSubmit={handleSubmit}>
      {renderContent()}
    </div>
  );
};

export default CheckoutForm;
