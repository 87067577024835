import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {Comment} from './Comment';

interface CommentListProps {
  promptComments: any[];
  leaveComment: () => Promise<void>;
  leaveCommentReply: (parentCommentId: string, commentText: string) => Promise<void>;
  hasBeenUpvoted: (comment: any) => boolean;
  upvoteComment: (comment: any, action: 'add' | 'remove') => void;
  reportComment: (comment: any) => Promise<void>;
  preAuth: boolean;
}


export const CommentList: React.FC<CommentListProps> = ({
                                                          promptComments,
                                                          leaveComment,
                                                          leaveCommentReply,
                                                          hasBeenUpvoted,
                                                          upvoteComment,
                                                          reportComment,
                                                          preAuth
                                                        }) => {
  const [parentComments, setParentComments] = useState<any[]>([]);
  const [childComments, setChildComments] = useState<any[]>([]);

  useEffect(() => {
    const fetchComments = () => {
      const parents = promptComments.filter(
        (comment) => comment.parentCommentId === false
      );
      const children = promptComments.filter(
        (comment) => comment.parentCommentId !== false
      );

      setParentComments(parents);
      setChildComments(children);
    };

    fetchComments();
  }, [promptComments]);

  return (
    <View>
      {parentComments.map((comment, index) => (
        <Comment
          key={index}
          index={index}
          comment={comment}
          //@ts-ignore
          leaveComment={leaveComment}
          leaveCommentReply={leaveCommentReply}
          hasBeenUpvoted={hasBeenUpvoted}
          upvoteComment={upvoteComment}
          childComments={childComments.filter(
            (childComment) => childComment.parentCommentId === comment.commentId
          )}
          reportComment={reportComment}
          preAuth={preAuth}
        />
      ))}
    </View>
  );
};
