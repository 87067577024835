import React from 'react';
import {View, Text, ScrollView, SafeAreaView, ActivityIndicator, StyleSheet} from 'react-native';
import {Pressable} from "dripsy";
import Header from "./Header";
import DrawerMenu from "./DrawerMenu";
import useUnits from "rxn-units";

const LoadingScreen = ({navStatus, setNavStatus, navigation, closeDrawerMenu}: any) => {
  const {vh} = useUnits();
  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <ScrollView>
        <Pressable
          style={styles.container}
          sx={{minHeight: [vh(100)]}}
          onPress={closeDrawerMenu}
        >
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
          <View style={styles.contentContainer}>
            <View style={styles.container}>
              <View style={styles.logoCircle}>
                <Text style={styles.logoText}>hP</Text>
              </View>
              <Text style={styles.loadingText}>is loading...</Text>
              <ActivityIndicator size="large" color="#C8BDD9"/>
            </View>
          </View>
        </Pressable>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  logoText: {
    color: '#FFF',
    fontSize: 35
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default LoadingScreen;
