import React, {FC, useCallback, useEffect, useState} from 'react';
import {RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import {Button} from 'react-native-elements';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {StackScreenProps} from "@react-navigation/stack";
import {getAuth} from 'firebase/auth';
import useUnits from "rxn-units";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";

const TokenManagerScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const auth = getAuth();
  const [refreshing, setRefreshing] = useState(false);
  const [userDoc, setUserDoc] = useState();
  const [hasReset, setHasReset] = useState(false);

  useEffect(() => {
    if (!auth) return;
    (async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const matchingDoc = querySnapshot.docs.find(doc => auth?.currentUser?.uid === doc.data().uid);
      if (matchingDoc) {
        // @ts-ignore
        setUserDoc(matchingDoc?.id);
      }
    })();
  }, [auth]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <ScrollView refreshControl={
      <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
    }>
      <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
        <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
        <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
        <View style={styles.contentContainer}>
          <View style={{marginBottom: 35}}>
            <Text style={{color: '#FFF', fontSize: 30}}>
              Token Manager
            </Text>
          </View>
        </View>
      </Pressable>
    </ScrollView>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#202124',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
});

export default TokenManagerScreen;
