import React, {FC, useCallback, useEffect, useState} from 'react';
import {ActivityIndicator, Modal, Platform, RefreshControl, SafeAreaView, StyleSheet} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {HelperText, IconButton, Portal} from 'react-native-paper';
import {Image, Pressable, Text, View} from 'dripsy';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {Button} from "react-native-elements";
import uuid from 'react-native-uuid';
import {db} from "../../config/firebase";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {getAuth} from 'firebase/auth';
import {StackScreenProps} from "@react-navigation/stack";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import useUnits from "rxn-units";
import {SimpleAccordion} from 'react-native-simple-accordion';
import * as Linking from "expo-linking";
import CustomTextInput from "../../components/CustomTextInput";
import {useFocusEffect} from "@react-navigation/native";
import Onboarding from 'react-native-onboarding-swiper';
import {Feather, FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons} from '@expo/vector-icons';
import {LinearGradient} from 'expo-linear-gradient';

const accentColor = '#DDD';

const loadingMessages = [
  'highPerplexity is generating your prompt details...',
  'Analyzing content for better suggestions...',
  'Exploring creative ideas for your prompt...',
  'Crafting engaging titles for your prompt...',
  'Searching for relevant tags to improve discoverability...',
  'Brainstorming unique descriptions for your prompt...',
  'Fine-tuning the content for optimal results...',
  'Working on making your prompt stand out...',
  'Diving deep into your content for better context...',
  'Generating insightful details for your prompt...',
  'Gathering relevant information to enhance your prompt...',
  'Optimizing your prompt for maximum impact...',
  'Connecting the dots to create a cohesive prompt...',
  'Studying your content to ensure the best suggestions...',
  'Extracting valuable insights from your input...',
  'Tailoring your prompt to match your content...',
  'Focusing on making your prompt more engaging...',
  'Polishing your prompt to perfection...',
  'Searching our knowledge base for the best ideas...',
  'Innovating to make your prompt more compelling...',
  'Analyzing trends to make your prompt up-to-date...',
  'Striving for the perfect balance in your prompt...',
  'Envisioning the best outcome for your prompt...',
  'Harnessing the power of AI to enhance your prompt...',
  'Delving into the details to improve your prompt...',
  'Pushing the boundaries of creativity for your prompt...',
  'Synthesizing information to craft the perfect prompt...',
  'Working tirelessly to generate the best prompt details...',
  'Blending art and science to create your prompt...',
  'Transforming your content into a masterpiece...',
];

const CreateNewPromptScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const auth = getAuth();
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [userDoc, setUserDoc] = useState('');
  const [fullUserDoc, setFullUserDoc] = useState();
  const [promptTitle, setPromptTitle] = useState('');
  const [promptContent, setPromptContent] = useState('');
  const [promptTags, setPromptTags] = useState('');
  const [promptDescription, setPromptDescription] = useState('');
  const [promptVariables, setPromptVariables] = useState([]);
  const [image, setImage] = useState(null);
  const [imageBytes, setImageBytes] = useState(null);
  const [showFAQ, setShowFAQ] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [isSavingPrompt, setIsSavingPrompt] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);
  const [submitError, setSubmitError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasOnboarded, setHasOnboarded] = useState(true);
  const [updateToggle, setUpdateToggle] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [generatingDetails, setGeneratingDetails] = useState(false);
  const [hasGeneratedDetails, setHasGeneratedDetails] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);
  const [generateTokenCost, setGenerateTokenCost] = useState();
  const [hasOwnGPT4, setHasOwnGPT4] = useState();
  const [generateError, setGenerateError] = useState(false);

  const desktopOnboardingScreens = [
    (
      <>
        <View style={styles.iconContainer}>
          <Ionicons name="md-create" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: "#FFFFFF",
              fontSize: 24,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Create highPerplexity Prompts
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left"}}>
            New here, or just need a refresher? No problem! Click next to learn about creating customizable
            highPerplexity prompts.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="create-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 1: Give Your Prompt a Title and Image
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            A meaningful title and an appropriate image help users understand your prompt better.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            For example, if you're creating a story generator with customizable settings, you might name it "AI Story
            Generator" and use an image that reflects a fantasy or medieval setting.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <MaterialCommunityIcons name="application-variable-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 2: Craft Your Prompt Content and Use Variables
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            The content of your prompt is essential, and using variables makes it dynamic!
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            To create a variable, simply surround any word or phrase with %| and |% symbols, like this: %|EXAMPLE|%.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            When you run the prompt, you can easily replace these variables with custom values. This allows you to
            create a single prompt template that can be used in multiple scenarios with different values.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Feather name="file-text" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 3: Provide a Clear Description
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            A well-written description helps users understand the main objective of your prompt and how to use the
            variables.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            For example, if your prompt is "Tell me a story about %|TOPIC|%," you could write the following:
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            "This prompt generates a story based on a specific topic. To use this prompt, simply replace the TOPIC
            variable with your desired subject, and the output will generate a story related to that theme. For example,
            you can replace TOPIC with 'space travel' to get a story about adventures in outer space."
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="pricetags-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 4: Tag Your Prompt
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Tags make it easy for other users to find your highPerplexity prompts. Add as many tags as you want,
            separated by commas.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            To tag a prompt, add a comma-separated list of tags to the "Prompt Tags" field. For example, if you were
            creating a prompt about fantasy writing, you might add the tags "fantasy", "writing", "english", and
            "authors".
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View
          style={{
            borderWidth: 2,
            borderColor: '#FFFFFF',
            borderRadius: 50,
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 15,
          }}
        >
          <Text style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 35}}>
            hP
          </Text>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            That's it!
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            For more information, check out the About page:
          </Text>
        </View>
        <View style={{marginTop: 15}}>
          <Pressable onPress={() => {
            toggleOnboardingStatus().then(res => {
              setModalVisible(false);
              navigation.navigate('About');
            });
          }}>
            <LinearGradient
              colors={['#4a4a4a', '#3a3a3a']}
              start={{x: 0, y: 0}}
              end={{x: 1, y: 0}}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingHorizontal: 15,
                paddingVertical: 10,
                borderRadius: 5,
                marginRight: 10
              }}
            >
              <Ionicons name="information-circle-outline" size={24} color="#ffffff"
                        style={{marginRight: 5}}/>
              <Text style={{color: '#ffffff', fontSize: 16}}>
                About
              </Text>
            </LinearGradient>
          </Pressable>
        </View>
      </>
    ),
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMessage(
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)],
      );
    }, 6500);

    return () => clearInterval(timer);
  }, [loadingMessages]);

  const updateIndex = (selectedIndex: any) => {
    setSelectedIndex(selectedIndex);
  };

  useFocusEffect(
    useCallback(() => {
      setSubmitError(false);
      setUpdateToggle(!updateToggle);
      setPromptTitle('');
      setPromptContent('');
      setPromptTags('');
      setPromptDescription('');
      setPromptVariables([]);
      setImage(null);
      setImageBytes(null);
      setShowFAQ(false);
      setGeneratingDetails(false);
      setHasGeneratedDetails(false);
      setSelectedIndex(0);
    }, [])
  );

  const showTooltip = (id: any) => {
    setTooltipId(id);
    setTooltipVisible(true);
  };

  const handleBackButtonClick = () => {
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (currentScreen < desktopOnboardingScreens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      toggleOnboardingStatus();
      setModalVisible(false);
    }
  };

  const renderTooltipContent = () => {
    switch (tooltipId) {
      // @ts-ignore
      case 'promptContent':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 10,
              }}
            >
              With highPerplexity's unique variable system, you can create dynamic and customizable prompts in an
              instant! To define a variable, simply surround any word or phrase with %| and |% symbols, like this:
              %|EXAMPLE|%.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              When you run the prompt, you can easily replace these variables with custom values. This allows you to
              create a single prompt template that can be used in multiple scenarios with different values.
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptTags':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              Tags are the easiest way for other users to find your highPerplexity prompts. You can add as many tags as
              you want, separated by commas. Users can then search for individual tags to find prompts.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              To tag a prompt, simply add a comma-separated list of tags to the "Prompt Tags" field. For example, if you
              were creating a prompt about fantasy writing, you might add the tags "fantasy", "writing",
              "english", and "authors".
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptVariables':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              Variables are what make highPerplexity prompts so fun and powerful. You can use them to create dynamic and
              customizable prompts that can be used in multiple scenarios with different values.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              To create a variable, simply surround any word or phrase with %| and |% symbols. For example, if you
              wanted to create a prompt that asks for a story about a specific topic, you could use the variable
              %|TOPIC|% and write the prompt as "Tell me a story about %|TOPIC|%". When someone runs the prompt, they
              can replace the %|TOPIC|% variable with any topic they like, such as "space travel", and get a story about
              space travel.
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptDescription':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              Explain the main objective of the prompt and provide guidance on how the
              variables should be used. For instance, if your prompt is "Tell me a story about %|TOPIC|%," you could
              write the following:
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              "This prompt generates a story based on a specific topic. To use this prompt, simply replace the TOPIC
              variable with your desired subject, and the output will generate a story related to that theme. For
              example, you can replace TOPIC with 'space travel' to get a story about adventures in outer space."
            </Text>
          </View>
        );
      default:
        return null;
    }
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      const img = await fetch(result.assets[0].uri);
      const bytes = await img.blob();
      // @ts-ignore
      setImage(result.assets[0].uri);
      // @ts-ignore
      setImageBytes(bytes);
    }
  };

  const promptVariableChange = (text: string, index: number) => {
    const newPromptVariables = [...promptVariables];
    // @ts-ignore
    newPromptVariables[index].value = text;
    setPromptVariables(newPromptVariables);
  };

  const createPrompt = async () => {
    setSubmitError(false);

    if (!promptTitle.length || !promptContent.length) {
      setSubmitError(true);
      return;
    }

    if (isSavingPrompt) return;
    setIsSavingPrompt(true);

    const storage = getStorage();
    // @ts-ignore
    const imageFileDirectory = `${fullUserDoc?.uid}/`;
    const imagePrefix = 'image_';
    const imageExtension = '.png';
    const timestamp = new Date().getTime();
    const imageFileName = imagePrefix + timestamp + imageExtension;
    const imageFullName = imageFileDirectory + imageFileName;
    const storageRef = ref(storage, imageFullName);
    const promptId = uuid.v4();

    const promptData = {
      title: promptTitle,
      description: promptDescription,
      searchableTitle: [],
      content: promptContent,
      searchableContent: [],
      email: auth?.currentUser?.email,
      // @ts-ignore
      userName: fullUserDoc?.userName,
      postedById: auth?.currentUser?.uid,
      promptId: promptId,
      comments: [],
      tags: [],
      variables: [],
      promptUpvotes: [],
      timestamp: timestamp,
      deleted: false,
    };
    // @ts-ignore
    promptVariables.forEach(variable => promptData.variables.push(variable?.value));
    // @ts-ignore
    if (promptTags) promptTags.split(",").forEach(tag => promptData.tags.push(tag?.toLowerCase().trim()));

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      promptData: promptData,
      user_doc: userDoc,
    };

    const response = await fetch("https://highperplexity-create-prompt-mjp27ovqaq-uc.a.run.app", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const newPromptRefId = await response.json();

    if (image) {
      // @ts-ignore
      await uploadBytes(storageRef, imageBytes);

      const firebaseStorageFileName = `gs://promptness-rnw.appspot.com/${imageFullName}`;
      const gsReference = ref(storage, firebaseStorageFileName);
      getDownloadURL(gsReference).then(async url => {
        const promptRequestBody = {
          firebase_token_value: authTokenValue?.token,
          prompt_ref_id: newPromptRefId,
          image_name: imageFullName,
          image_url: url,
          user_doc: userDoc,
        };

        const response = await fetch("https://highperplexity-create-prompt-mjp27ovqaq-uc.a.run.app", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(promptRequestBody),
        });

        await response.json();
      })
    }

    fetch("https://highperplexity-algolia-indexing-middleware-mjp27ovqaq-uc.a.run.app/?update=true&trigger=prompt-create");

    setPromptTitle('');
    setPromptContent('');
    setPromptVariables([]);
    setPromptTags('');
    setPromptDescription('');
    setImage(null);
    setImageBytes(null);

    navigation.navigate('New View Prompt', {promptId: promptId});
  };

  useFocusEffect(
    useCallback(() => {
      const fetchUserData = async () => {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const matchingDoc = querySnapshot.docs.find((doc) => auth?.currentUser?.uid === doc.data().uid);
        if (matchingDoc) {
          setUserDoc(matchingDoc.id);
          //@ts-ignore
          setFullUserDoc(matchingDoc.data());
          if (matchingDoc?.data()?.promptCreationOnboardedStatus === true) {
            setHasOnboarded(true);
          } else {
            setHasOnboarded(false);
          }
          if (matchingDoc?.data()?.gpt4UserAPI === true) {
            //@ts-ignore
            setHasOwnGPT4(true);
          } else {
            //@ts-ignore
            setHasOwnGPT4(false);
          }
        }
        setLoading(false);
      };

      fetchUserData();

      return () => {
      };
    }, [updateToggle]),
  );

  useEffect(() => {
    if (hasOwnGPT4) {
      //@ts-ignore
      setGenerateTokenCost(0);
    } else {
      // @ts-ignore
      setGenerateTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend')
        ? 0 : 5);
    }
  }, [hasOwnGPT4]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      setModalVisible(false);
    } else {
      if (hasOnboarded) {
        setModalVisible(false);
      } else {
        setModalVisible(true);
      }
    }
  }, [hasOnboarded]);

  useEffect(() => {
    const regex = /%\|(.*?)\|\%/g;
    const matches = promptContent?.match(regex) || [];
    const uniqueMatches = Array.from(new Set(matches));
    const variables = uniqueMatches.map((value, id) => ({id, value}));
    // @ts-ignore
    setPromptVariables(variables);
  }, [promptContent, updateToggle]);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  function customReplace(string: string, search: any, replace: any) {
    return string.split(search).join(replace);
  }

  const handleSubredditClick = () => {
    if (Platform.OS == 'web') {
      window.open('https://www.reddit.com/r/highPerplexity/', '_blank');
    } else {
      Linking.openURL('https://www.reddit.com/r/highPerplexity/');
    }
  }

  const toggleOnboardingStatus = async () => {
    try {
      const userRef = doc(db, "users", userDoc);
      await updateDoc(userRef, {promptCreationOnboardedStatus: true});
    } catch (error) {
      console.error("Error updating onboarded status: ", error);
    }
    setModalVisible(false);
    setHasOnboarded(true);
  }

  if (loading || !fullUserDoc) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>is loading...</Text>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  if (!hasOnboarded) {
    if (Platform.OS !== 'web') {
      return (
        <Onboarding
          onSkip={() => toggleOnboardingStatus()}
          onDone={() => toggleOnboardingStatus()}
          pages={[
            {
              backgroundColor: '#202124',
              image: (
                <View style={styles.iconContainer}>
                  <Ionicons name="md-create" size={96} color="#FFFFFF"/>
                </View>
              ),
              title: (
                <View style={{paddingHorizontal: 15, marginBottom: 15}}>
                  <Text
                    style={{
                      color: "#FFFFFF",
                      fontSize: 24,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Create highPerplexity Prompts
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left"}}>
                    New here, or just need a refresher? No problem! Tap next to learn about creating customizable
                    highPerplexity prompts.
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <Ionicons name="create-outline" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center'
                    }}>
                    Step 1: Give Your Prompt a Title and Image
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    A meaningful title and an appropriate image help users understand your prompt better.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                    For example, if you're creating a story generator with customizable settings, you might name it
                    "AI
                    Story Generator" and use an image that reflects a fantasy or medieval setting.
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <MaterialCommunityIcons name="application-variable-outline" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center'
                    }}>
                    Step 2: Craft Your Prompt Content and Use Variables
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    The content of your prompt is essential, and using variables makes it dynamic!
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    To create a variable, simply surround any word or phrase with %| and |% symbols, like this:
                    %|EXAMPLE|%.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                    When you run the prompt, you can easily replace these variables with custom values. This allows
                    you
                    to
                    create a single prompt template that can be used in multiple scenarios with different values.
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <Feather name="file-text" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center',
                    }}
                  >
                    Step 3: Provide a Clear Description
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    A well-written description helps users understand the main objective of your prompt and how to use
                    the
                    variables.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    For example, if your prompt is "Tell me a story about %|TOPIC|%," you could write the following:
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                    "This prompt generates a story based on a specific topic. To use this prompt, simply replace the
                    TOPIC
                    variable with your desired subject, and the output will generate a story related to that theme.
                    For
                    example, you can replace TOPIC with 'space travel' to get a story about adventures in outer
                    space."
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <Ionicons name="pricetags-outline" size={96} color="#FFFFFF"/>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center',
                    }}
                  >
                    Step 4: Tag Your Prompt
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
                    Tags make it easy for other users to find your highPerplexity prompts. Add as many tags as you
                    want,
                    separated by commas.
                  </Text>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
                    To tag a prompt, add a comma-separated list of tags to the "Prompt Tags" field. For example, if
                    you
                    were creating a prompt about fantasy writing, you might add the tags "fantasy", "writing",
                    "english",
                    and "authors".
                  </Text>
                </View>
              ),
            },
            {
              backgroundColor: '#202124',
              image: (
                <View
                  style={{
                    borderWidth: 2,
                    borderColor: '#FFFFFF',
                    borderRadius: 50,
                    paddingHorizontal: 20,
                    paddingVertical: 20,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 35}}>hP</Text>
                </View>
              ),
              title: (
                <View style={{paddingHorizontal: 15}}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 25,
                      textAlign: 'center',
                    }}
                  >
                    That's it!
                  </Text>
                </View>
              ),
              subtitle: (
                <View style={{paddingHorizontal: 15}}>
                  <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'center', marginBottom: 15}}>
                    For more information, check out the About page:
                  </Text>
                  <View style={{marginTop: 15, display: 'flex', alignItems: 'center'}}>
                    <Pressable onPress={() => {
                      toggleOnboardingStatus().then(res => {
                        setModalVisible(false);
                        navigation.navigate('About');
                      });
                    }}>
                      <LinearGradient
                        colors={['#4a4a4a', '#3a3a3a']}
                        start={{x: 0, y: 0}}
                        end={{x: 1, y: 0}}
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          paddingHorizontal: 15,
                          paddingVertical: 10,
                          borderRadius: 5,
                          marginRight: 10
                        }}
                      >
                        <Ionicons name="information-circle-outline" size={24} color="#ffffff"
                                  style={{marginRight: 5}}/>
                        <Text style={{color: '#ffffff', fontSize: 16}}>
                          About
                        </Text>
                      </LinearGradient>
                    </Pressable>
                  </View>
                </View>
              ),
            },
          ]}
        />
      );
    }
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(false);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                {desktopOnboardingScreens[currentScreen]}
                <View
                  style={[
                    styles.buttonContainer,
                    currentScreen === desktopOnboardingScreens.length - 1
                      ? styles.buttonContainerLastScreen
                      : styles.buttonContainerStandard,
                  ]}
                >
                  {currentScreen === 0 && (
                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        toggleOnboardingStatus();
                        setModalVisible(false);
                      }}
                    >
                      <Ionicons name="close-outline" size={24} color="#FFFFFF"/>
                      <Text style={styles.buttonText}>Skip</Text>
                    </Pressable>
                  )}

                  {currentScreen > 0 && (
                    <Pressable style={styles.button} onPress={handleBackButtonClick}>
                      <Ionicons name="arrow-back" size={24} color="#FFFFFF"/>
                      <Text style={styles.buttonText}>Back</Text>
                    </Pressable>
                  )}

                  {currentScreen < desktopOnboardingScreens.length - 1 && (
                    <Pressable style={styles.button} onPress={handleNextButtonClick}>
                      <Text style={[styles.buttonText, {marginRight: 4}]}>Next</Text>
                      <Ionicons name="arrow-forward" size={24} color="#FFFFFF"/>
                    </Pressable>
                  )}

                  {currentScreen === desktopOnboardingScreens.length - 1 && (
                    <Pressable
                      style={styles.button}
                      onPress={() => {
                        toggleOnboardingStatus();
                        setModalVisible(false);
                      }}
                    >
                      <Ionicons name="checkmark-outline" size={24} color="#FFFFFF"/>
                      <Text style={styles.buttonText}>Finished!</Text>
                    </Pressable>
                  )}
                </View>
              </View>
            </View>
          </Modal>
          <View style={[styles.contentContainer, {backgroundColor: '#2a2a2a', padding: 20, borderRadius: 10}]}
                sx={{width: [300, 400, 600]}}>
            <View style={{alignItems: 'center', marginBottom: 15}}>
              <FontAwesome5 name="pencil-alt" size={48} color="#FFF"/>
              <Text style={{color: '#FFF', fontSize: 30, marginTop: 10, textAlign: 'center'}}>
                Create New Prompt
              </Text>
              {!!selectedIndex &&
                <Text style={{color: '#FFF', fontSize: 20, marginBottom: 10}}>
                  (AI Assisted)
                </Text>
              }
              <Text style={{color: '#ccc', fontStyle: 'italic', textAlign: 'center', marginTop: 5}}>
                This form is used to create (not run) new highPerplexity prompts.{' '}
                {!selectedIndex ?
                  <Text>Tap the button below for more info about creating customizable prompts.</Text>
                  :
                  <Text>
                    On this version of the form you only need to enter your prompt content and our AI can help generate
                    the rest of the prompt details.
                  </Text>
                }
              </Text>
            </View>
            {!selectedIndex &&
              <View style={{marginBottom: 25}}>
                <Pressable
                  onPress={() => {
                    setCurrentScreen(0);
                    setHasOnboarded(false);
                  }}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: '#FFF',
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }}
                >
                  <MaterialIcons name="slideshow" size={16} color="#FFF" style={{marginRight: 5}}/>
                  <Text style={{color: '#FFF', textAlign: 'center', fontSize: 14}}>
                    View Prompt Creation Information
                  </Text>
                </Pressable>
              </View>
            }
            <Text style={styles.title}>Choose Prompt Creation Method</Text>
            <View style={styles.promptButtonContainer}>
              <Pressable
                style={[
                  styles.promptButton,
                  selectedIndex === 0 && styles.promptSelectedButton,
                ]}
                onPress={() => updateIndex(0)}
              >
                <MaterialCommunityIcons name="pencil" size={26} color="white"/>
                <Text
                  style={[
                    styles.promptButtonText,
                    selectedIndex === 0 && styles.promptSelectedButtonText,
                  ]}
                >
                  Manual
                </Text>
              </Pressable>
              <Pressable
                style={[
                  styles.promptButton,
                  selectedIndex === 1 && styles.promptSelectedButton,
                ]}
                onPress={() => updateIndex(1)}
              >
                <MaterialCommunityIcons name="robot" size={26} color="white"/>
                <Text
                  style={[
                    styles.promptButtonText,
                    selectedIndex === 1 && styles.promptSelectedButtonText,
                  ]}
                >
                  AI Assisted
                </Text>
              </Pressable>
            </View>
            {!!selectedIndex &&
              <Text style={{color: '#ccc', fontStyle: 'italic', textAlign: 'center', marginTop: 5}}>
                Utilize our AI-assisted prompt detail generator by providing your prompt content and letting our AI
                create the title, description, and tags for you!
              </Text>
            }
            {!selectedIndex &&
              <Text style={styles.subtitle}>
                Fill out all of your prompt details manually or just write the prompt content and let highPerplexity's
                AI create the rest of the information!
              </Text>
            }
            {!selectedIndex &&
              <>
                <View style={{marginBottom: 5, width: '100%'}}>
                  <CustomTextInput
                    label="Prompt Title"
                    value={promptTitle}
                    onChangeText={(text: any) => setPromptTitle(text)}
                    placeholder="Enter prompt title here..."
                    inputType="title"
                  />
                </View>
                <View style={{alignItems: 'center'}}>
                  <Pressable onPress={pickImage}>
                    <LinearGradient
                      colors={["#4a76a8", "#5c8db8"]}
                      start={{x: 0, y: 0}}
                      end={{x: 1, y: 0}}
                      style={[styles.gradientButton, {paddingHorizontal: 15, paddingVertical: 8}]}
                    >
                      <Ionicons name="cloud-upload" size={22} color="#ffffff" style={{marginRight: 10}}/>
                      <Text style={[styles.gradientButtonText, {fontSize: 18}]}>Upload Image</Text>
                    </LinearGradient>
                  </Pressable>
                  <Text style={styles.helperText}>
                    Upload an image for your prompt. This image represents your prompt in search results and helps
                    others
                    understand its purpose - but won't affect the output.
                  </Text>
                  {image && (
                    <Image
                      source={{uri: image}}
                      style={{borderRadius: 10, marginBottom: 12}}
                      sx={{width: [240, 290, 350], height: [180, 220, 260]}}
                    />
                  )}
                </View>
                <View style={{width: '100%'}}>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <HelperText type="info" visible={true} style={{color: '#ccc'}}>
                      Prompt Description
                    </HelperText>
                    <IconButton
                      icon="information-outline"
                      size={20}
                      style={{margin: 0}}
                      onPress={() => showTooltip('promptDescription')}
                      theme={{colors: {primary: "#ccc"}}}
                    />
                  </View>
                  <CustomTextInput
                    value={promptDescription}
                    onChangeText={(text: any) => setPromptDescription(text)}
                    placeholder="Enter prompt description here..."
                    inputType="description"
                  />
                </View>
                <View style={{width: '100%'}}>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <HelperText type="info" visible={true} style={{color: '#ccc'}}>
                      Prompt Content
                    </HelperText>
                    <IconButton
                      icon="information-outline"
                      size={20}
                      style={{margin: 0}}
                      onPress={() => showTooltip('promptContent')}
                      theme={{colors: {primary: "#ccc"}}}
                    />
                  </View>
                  <CustomTextInput
                    value={promptContent}
                    onChangeText={(text: any) => setPromptContent(text)}
                    placeholder="Enter prompt content here..."
                    inputType="content"
                  />
                  <Text style={styles.helperText}>
                    To define a variable in your prompt content, simply surround any word or phrase with %| and |%
                    symbols,
                    like this: %|EXAMPLE|%. Tap the tooltip for more info.
                  </Text>
                  <Portal>
                    <Modal
                      visible={tooltipVisible}
                      onRequestClose={() => setTooltipVisible(false)}
                      transparent={true}
                      animationType="slide"
                    >
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          padding: 20,
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: '#2c2c2e',
                            borderRadius: 10,
                            padding: 20,
                            width: '100%',
                            maxWidth: 400,
                          }}
                        >
                          {renderTooltipContent()}
                          <Button
                            onPress={() => setTooltipVisible(false)}
                            title="Got it!"
                          />
                        </View>
                      </View>
                    </Modal>
                  </Portal>
                </View>
                <View style={{width: '100%'}}>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <HelperText type="info" visible={true} style={{color: '#ccc'}}>
                      Prompt Tags (comma separated)
                    </HelperText>
                    <IconButton
                      icon="information-outline"
                      size={20}
                      style={{margin: 0}}
                      onPress={() => showTooltip('promptTags')}
                      theme={{colors: {primary: "#ccc"}}}
                    />
                  </View>
                  <CustomTextInput
                    value={promptTags}
                    onChangeText={(text: any) => setPromptTags(text)}
                    placeholder="Enter prompt tags here..."
                    inputType="tags"
                  />
                </View>
                <View style={{width: '100%'}}>
                  {!!promptVariables.length &&
                    <View
                      style={{marginBottom: 15, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                      <Text style={{color: '#FFF', fontSize: 25, textAlign: 'center'}}>
                        Prompt Variables
                      </Text>
                      <IconButton
                        icon="information-outline"
                        size={20}
                        style={{margin: 0}}
                        onPress={() => showTooltip('promptVariables')}
                        theme={{colors: {primary: "#ccc"}}}
                      />
                    </View>
                  }
                  {promptVariables.map((inputField, index) => (
                    // @ts-ignore
                    <View key={inputField?.id}>
                      <CustomTextInput
                        label={`Variable ${index + 1} Name:`}
                        value={customReplace(
                          customReplace(
                            // @ts-ignore
                            inputField.value,
                            '%|',
                            ''), '|%', '')}
                        onChangeText={(text: any) => promptVariableChange(text, index)}
                        editable={false}
                      />
                    </View>
                  ))}
                </View>
              </>
            }
            {!!selectedIndex &&
              <>
                {generatingDetails ?
                  <View style={styles.generateLoadContainer}>
                    <View style={styles.contentWrapper}>
                      <ActivityIndicator size="large" color="#1e88e5"/>
                      <Text style={styles.generateLoadTitle}>Generating Prompt Details</Text>
                      <Text style={styles.generateLoadText}>{currentMessage}</Text>
                    </View>
                  </View>
                  :
                  <>
                    {hasGeneratedDetails &&
                      <View style={{marginBottom: 5, width: '100%'}}>
                        <CustomTextInput
                          label="Prompt Title"
                          value={promptTitle}
                          onChangeText={(text: any) => setPromptTitle(text)}
                          placeholder="Enter prompt title here..."
                          inputType="title"
                        />
                      </View>
                    }
                    {hasGeneratedDetails &&
                      <View style={{alignItems: 'center'}}>
                        <Pressable onPress={pickImage}>
                          <LinearGradient
                            colors={["#4a76a8", "#5c8db8"]}
                            start={{x: 0, y: 0}}
                            end={{x: 1, y: 0}}
                            style={[styles.gradientButton, {paddingHorizontal: 15, paddingVertical: 8}]}
                          >
                            <Ionicons name="cloud-upload" size={22} color="#ffffff" style={{marginRight: 10}}/>
                            <Text style={[styles.gradientButtonText, {fontSize: 18}]}>Upload Image</Text>
                          </LinearGradient>
                        </Pressable>
                        <Text style={styles.helperText}>
                          Upload an image for your prompt. This image represents your prompt in search results and helps
                          others
                          understand its purpose - but won't affect the output.
                        </Text>
                        {image && (
                          <Image
                            source={{uri: image}}
                            style={{borderRadius: 10, marginBottom: 12}}
                            sx={{width: [240, 290, 350], height: [180, 220, 260]}}
                          />
                        )}
                      </View>
                    }
                    {hasGeneratedDetails &&
                      <View style={{width: '100%'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                          <HelperText type="info" visible={true} style={{color: '#ccc'}}>
                            Prompt Description
                          </HelperText>
                          <IconButton
                            icon="information-outline"
                            size={20}
                            style={{margin: 0}}
                            onPress={() => showTooltip('promptDescription')}
                            theme={{colors: {primary: "#ccc"}}}
                          />
                        </View>
                        <CustomTextInput
                          value={promptDescription}
                          onChangeText={(text: any) => setPromptDescription(text)}
                          placeholder="Enter prompt description here..."
                          inputType="description"
                        />
                      </View>
                    }
                    <View style={{width: '100%'}}>
                      <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <HelperText type="info" visible={true} style={{color: '#ccc'}}>
                          Prompt Content
                        </HelperText>
                        <IconButton
                          icon="information-outline"
                          size={20}
                          style={{margin: 0}}
                          onPress={() => showTooltip('promptContent')}
                          theme={{colors: {primary: "#ccc"}}}
                        />
                      </View>
                      <CustomTextInput
                        value={promptContent}
                        onChangeText={(text: any) => setPromptContent(text)}
                        placeholder="Enter prompt content here..."
                        inputType="content"
                      />
                      <Text style={styles.helperText}>
                        To define a variable in your prompt content, simply surround any word or phrase with %| and |%
                        symbols,
                        like this: %|EXAMPLE|%. Tap the tooltip for more info.
                      </Text>
                      <Portal>
                        <Modal
                          visible={tooltipVisible}
                          onRequestClose={() => setTooltipVisible(false)}
                          transparent={true}
                          animationType="slide"
                        >
                          <View
                            style={{
                              flex: 1,
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                              padding: 20,
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: '#2c2c2e',
                                borderRadius: 10,
                                padding: 20,
                                width: '100%',
                                maxWidth: 400,
                              }}
                            >
                              {renderTooltipContent()}
                              <Button
                                onPress={() => setTooltipVisible(false)}
                                title="Got it!"
                              />
                            </View>
                          </View>
                        </Modal>
                      </Portal>
                    </View>
                    {hasGeneratedDetails &&
                      <View style={{width: '100%'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                          <HelperText type="info" visible={true} style={{color: '#ccc'}}>
                            Prompt Tags (comma separated)
                          </HelperText>
                          <IconButton
                            icon="information-outline"
                            size={20}
                            style={{margin: 0}}
                            onPress={() => showTooltip('promptTags')}
                            theme={{colors: {primary: "#ccc"}}}
                          />
                        </View>
                        <CustomTextInput
                          value={promptTags}
                          onChangeText={(text: any) => setPromptTags(text)}
                          placeholder="Enter prompt tags here..."
                          inputType="tags"
                        />
                      </View>
                    }
                    <View style={{width: '100%'}}>
                      {!!promptVariables.length &&
                        <View
                          style={{
                            marginBottom: 15,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <Text style={{color: '#FFF', fontSize: 25, textAlign: 'center'}}>
                            Prompt Variables
                          </Text>
                          <IconButton
                            icon="information-outline"
                            size={20}
                            style={{margin: 0}}
                            onPress={() => showTooltip('promptVariables')}
                            theme={{colors: {primary: "#ccc"}}}
                          />
                        </View>
                      }
                      {promptVariables.map((inputField, index) => (
                        // @ts-ignore
                        <View key={inputField?.id}>
                          <CustomTextInput
                            label={`Variable ${index + 1} Name:`}
                            value={customReplace(
                              customReplace(
                                // @ts-ignore
                                inputField.value,
                                '%|',
                                ''), '|%', '')}
                            onChangeText={(text: any) => promptVariableChange(text, index)}
                            editable={false}
                          />
                        </View>
                      ))}
                    </View>
                  </>
                }
                {generateError &&
                  <View>
                    <Text style={{
                      color: '#ffd0d7',
                      marginBottom: 10,
                      borderWidth: 1,
                      borderColor: '#ffd0d7',
                      paddingHorizontal: 15,
                      paddingVertical: 5,
                      borderRadius: 10,
                      fontSize: 20,
                      textAlign: 'center',
                    }}>
                      Prompt Content is required for this feature
                    </Text>
                  </View>
                }
                {!generatingDetails &&
                  <>
                    <Pressable onPress={async () => {
                      if (!promptContent) {
                        setGenerateError(true);
                        return;
                      }

                      setGenerateError(false);

                      setGeneratingDetails(true);
                      // @ts-ignore
                      const authTokenValue = await auth.currentUser.getIdTokenResult();
                      const userRef = doc(db, "users", userDoc);
                      const timestamp = new Date().getTime();
                      await updateDoc(userRef, {currentJWT: authTokenValue.token});

                      const response = await fetch("https://highperplexity-prompt-detail-middleware-mjp27ovqaq-uc.a.run.app", {
                        method: 'POST',
                        headers: {
                          Accept: 'application/json',
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          "promptContent": promptContent,
                          "firebase_token_value": authTokenValue.token,
                          "APIStatus": hasOwnGPT4,
                          //@ts-ignore
                          "accountType": fullUserDoc?.accessLevel,
                        }),
                      });

                      const result = await response.json();

                      //@ts-ignore
                      setPromptTitle(result?.titleCompletion?.choices[0]?.message?.content?.trim());
                      //@ts-ignore
                      setPromptDescription(result?.descriptionCompletion?.choices[0]?.message?.content?.trim());
                      //@ts-ignore
                      setPromptTags(result?.tagCompletion?.choices[0]?.message?.content?.trim());

                      setGeneratingDetails(false);
                      setHasGeneratedDetails(true);
                    }}
                               style={styles.generateDetailsButton}
                    >
                      <MaterialIcons name="autorenew" size={24} color="white"/>
                      <Text style={styles.generateDetailsButtonText}>Generate Prompt Details</Text>
                    </Pressable>
                    <Text style={styles.generateCostText}>Token Cost: {generateTokenCost}</Text>
                    <Text style={styles.generateCostInfo}>
                      This feature requires GPT-4 and incurs a cost of 5 tokens. You can reduce this cost to 0 tokens with{' '}
                      <Pressable onPress={() => navigation.navigate("Store")}>
                        <Text style={{textDecorationLine: 'underline', fontWeight: 'bold', color: '#FFF'}}>
                          highPerplexity Pro
                        </Text>
                      </Pressable>
                      {' '}or by providing your own OpenAI Key (with GPT-4 access) on the{' '}
                      <Pressable onPress={() => navigation.navigate("Settings")}>
                        <Text style={{textDecorationLine: 'underline', fontWeight: 'bold', color: '#FFF'}}>
                          Settings
                        </Text>
                      </Pressable>
                      {' '}page.
                    </Text>
                  </>
                }
              </>
            }
          </View>
          <View style={{marginVertical: 12, width: '100%'}} sx={{width: [300, 400, 500]}}>
            <Pressable
              onPress={() => setShowFAQ((prevShowFAQ) => !prevShowFAQ)}
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: '#FFF',
                paddingHorizontal: 16,
                paddingVertical: 8,
                borderRadius: 10,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }}
            >
              <Feather name={!showFAQ ? "maximize-2" : "minimize-2"}
                       size={18} color="#FFF" style={{marginRight: 8}}/>
              <Text style={{color: '#FFF', textAlign: 'center', fontSize: 18}}>
                {!showFAQ ? 'Show Prompt FAQ' : 'Hide Prompt FAQ'}
              </Text>
            </Pressable>
          </View>
          {showFAQ && <View sx={{paddingLeft: [20, 20, 40], paddingRight: [20, 20, 40]}}>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      Making custom prompts on highPerplexity is super easy! Here's an example that shows what a
                      customizable highPerplexity prompt for ChatGPT that generates 10 ideas for a new book with a
                      customizable genre that is set by a highPerplexity variable:
                    </Text>
                    <Text style={[styles.accordionContentText, {fontStyle: 'italic'}]}>
                      Pretend you're an author that is brainstorming about writing a new %|GENRE|% book. What are 10
                      good
                      ideas for that book? Provide a numbered list with very detailed and specific answers.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      In this example prompt, %|GENRE|% is a highPerplexity variable. Other users will be able to
                      easily
                      replace the value of %|GENRE|% in this prompt with their own custom values when they view this
                      prompt on the highPerplexity platform.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      On highPerplexity, prompts can have any number of custom variables - meaning that you can create
                      and
                      share a customizable prompt that is as simple or complex as you desire for any task that you can
                      imagine!
                    </Text>
                  </View>
                }
                title={"How do I make a customizable prompt?"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      Custom highPerplexity variables are the words or phrases inside of a prompt that are meant to be
                      swapped out with different values when the prompt is ran. Here's an example:
                    </Text>
                    <Text style={[styles.accordionContentText, {fontStyle: 'italic'}]}>
                      Pretend you're an author that is brainstorming about writing a new %|GENRE|% book. What are 10
                      good
                      ideas for that book? Provide a numbered list with very detailed and specific answers.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      In this example prompt, %|GENRE|% is a highPerplexity variable. Other users will be able to
                      easily
                      replace the value of %|GENRE|% in this prompt with their own custom values when they view this
                      prompt on the highPerplexity platform.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      On highPerplexity, prompts can have any number of custom variables - meaning that you can create
                      and
                      share a customizable prompt that is as simple or complex as you desire for any task that you can
                      imagine!
                    </Text>
                  </View>
                }
                title={"What are custom highPerplexity variables?"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      If you have any specific questions or just want to help build the highPerplexity community,
                      check out out the <Pressable onPress={handleSubredditClick}>
                      <Text style={{
                        color: '#FFF',
                        fontSize: 14,
                        textDecorationLine: 'underline',
                      }}>
                        highPerplexity subreddit
                      </Text>
                    </Pressable>!
                    </Text>
                  </View>
                }
                title={"The highPerplexity subreddit"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
          </View>
          }
          {submitError &&
            <View>
              <Text style={{
                color: '#ffd0d7',
                marginBottom: 15,
                borderWidth: 1,
                borderColor: '#ffd0d7',
                paddingHorizontal: 15,
                paddingVertical: 5,
                borderRadius: 10,
                fontSize: 20,
                textAlign: 'center',
              }}>
                Prompt Title and Prompt Content are required
              </Text>
            </View>
          }
          {!selectedIndex && !isSavingPrompt &&
            <View style={{marginVertical: 20, flexDirection: 'row', justifyContent: 'center'}}>
              <Pressable onPress={createPrompt}>
                <LinearGradient
                  colors={['#1a1f42', '#003d80']}
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 0}}
                  style={[styles.gradientButton, {paddingHorizontal: 20, paddingVertical: 10}]}
                >
                  <Ionicons name="save" size={26} color="#ffffff" style={{marginRight: 10}}/>
                  <Text style={[styles.gradientButtonText, {fontSize: 22}]}>Save Prompt</Text>
                </LinearGradient>
              </Pressable>
            </View>
          }
          {!!selectedIndex && hasGeneratedDetails && !isSavingPrompt &&
            <View style={{marginVertical: 20, flexDirection: 'row', justifyContent: 'center'}}>
              <Pressable onPress={createPrompt}>
                <LinearGradient
                  colors={['#1a1f42', '#003d80']}
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 0}}
                  style={[styles.gradientButton, {paddingHorizontal: 20, paddingVertical: 10}]}
                >
                  <Ionicons name="save" size={26} color="#ffffff" style={{marginRight: 10}}/>
                  <Text style={[styles.gradientButtonText, {fontSize: 22}]}>Save Prompt</Text>
                </LinearGradient>
              </Pressable>
            </View>
          }
          {isSavingPrompt &&
            <View style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 20}}>
              <ActivityIndicator size="large" color="#ADD8E6"/>
              <Text style={{marginLeft: 10, fontSize: 20, color: '#ADD8E6'}}>Saving Prompt...</Text>
            </View>
          }
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#202124',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  newPromptInput: {
    borderWidth: 1,
    borderColor: '#FFF',
    padding: 15,
    borderRadius: 10,
    width: 300,
    color: '#FFF',
    marginBottom: 10,
  },
  accordionHeader: {
    height: 'auto',
    flexDirection: "row",
    backgroundColor: "#333",
    padding: 16,
    alignItems: "center",
  },
  accordionHeaderText: {
    color: accentColor,
  },
  accordionContentContainer: {
    paddingTop: 10,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#444',
  },
  accordionContentText: {
    color: accentColor,
    marginBottom: 15,
    fontSize: 14,
    textAlign: 'justify',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    maxWidth: 800,
    margin: 20,
    backgroundColor: '#202124',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  buttonContainerStandard: {
    justifyContent: 'space-between',
  },
  buttonContainerLastScreen: {
    justifyContent: 'center',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#3a3a3a',
    padding: 10,
    borderRadius: 5,
    marginLeft: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 16,
    marginLeft: 5,
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    color: '#FFF',
    fontSize: 24,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  gradientButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    marginBottom: 12,
    borderWidth: 1,
    borderColor: '#FFF',
  },
  gradientButtonText: {
    color: '#FFF',
    textAlign: 'center',
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
  helperText: {
    fontStyle: "italic",
    color: "#ccc",
    fontSize: 14,
    textAlign: "center",
    marginBottom: 12,
  },
  promptButtonContainer: {
    flexDirection: 'row',
    borderRadius: 15,
    overflow: 'hidden',
    marginBottom: 10,
  },
  promptButton: {
    flex: 1,
    paddingVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#424242',
    width: 145,
  },
  promptSelectedButton: {
    backgroundColor: '#1e88e5',
  },
  promptButtonText: {
    color: 'white',
    fontSize: 16,
    marginLeft: 5,
  },
  promptSelectedButtonText: {
    fontWeight: 'bold',
  },
  title: {
    color: '#ccc',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    color: '#ccc',
    fontSize: 14,
    fontStyle: 'italic',
    marginBottom: 20,
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  generateLoadContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  generateLoadTitle: {
    marginVertical: 10,
    color: 'white',
    fontSize: 24,
    fontWeight: '700',
    textAlign: 'center',
  },
  generateLoadText: {
    color: '#BDBDBD',
    fontSize: 16,
    fontWeight: '400',
    textAlign: 'center',
    paddingHorizontal: 20,
    lineHeight: 22,
  },
  generateDetailsButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#FFF',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: '#424242',
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginTop: 10,
  },
  generateDetailsButtonText: {
    color: '#FFF',
    fontSize: 16,
    marginLeft: 10,
  },
  generateCostText: {
    color: '#CCC',
    fontSize: 16,
    marginTop: 5,
    marginBottom: 5,
    textAlign: 'center',
  },
  generateCostInfo: {
    color: '#CCC',
    fontStyle: 'italic',
    fontSize: 14,
    textAlign: 'center',
    paddingHorizontal: 10,
    marginBottom: 10,
  },
});

export default CreateNewPromptScreen;
