import React, {useState} from 'react';
import {Text, View, Pressable} from 'react-native';
import SimulatedGPTResponse from './SimulatedGPTResponse';

const accentColor = '#DDD';

const PluginChainingExample = () => {
  const [showExample, setShowExample] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [key, setKey] = useState(Math.random());

  const handleExampleButtonClick = () => {
    setShowExample(true);
    setResponseText("Downloading data from Savvy Trader Plugin...");
    setTimeout(() => {
      setResponseText(prevText => prevText + "\n\nDownloading data from WebPilot Plugin...");
      setTimeout(() => {
        setResponseText(prevText => prevText + `\n\nBased on the search results, two publicly traded restaurant companies that have been in the news recently are McDonald's Corp (MCD) and Chipotle Mexican Grill Inc (CMG).\n\nRecent news about McDonald's Corp (MCD) includes:\n\nRobert A. Eckert and John W. Rogers, Jr. are set to retire from McDonald's Board of Directors after 20 years of exceptional service. This was announced on March 29, 2023.\n\nRecent news about Chipotle Mexican Grill Inc (CMG) includes:\n\nChipotle has hired Stephen Piacentini as Chief Development Officer, as announced on May 31, 2023.\n\nMcDonald's Corp (MCD) is the world's leading global foodservice retailer with over 39,000 locations in over 100 countries.\n\nAs of the latest update, the stock price for McDonald's Corp (MCD) is $289.90. The stock has seen a change of $2.03, which is a change percent of 0.7% from the previous close.\n\nChipotle Mexican Grill, Inc. (CMG) is cultivating a better world by serving responsibly sourced, classically-cooked, real food with wholesome ingredients without artificial colors, flavors, or preservatives.\n\nAs of the latest update, the stock price for Chipotle Mexican Grill Inc (CMG) is $2062.23. The stock has seen a change of -$20, which is a change percent of -0.96% from the previous close.`);
      }, 1250);
    }, 1250);
  };

  return (
    <View>
      <Text style={{color: accentColor, fontSize: 18, textAlign: 'left'}}>
        With the highPerplexity ChatGPT plugin, you can also interact with other ChatGPT plugins and chain their
        interactions together in inventive ways. This is an example of how that works:
      </Text>
      <Text style={{color: accentColor, fontSize: 16, marginTop: 10, marginBottom: 5}}>
        Prompt Title:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5, marginBottom: 10}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Savvy Trader Plugin Chaining
        </Text>
      </View>
      <Text style={{color: accentColor, fontSize: 16, marginTop: 10, marginBottom: 5}}>
        Prompt Content:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5, marginBottom: 15}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', marginBottom: 10, fontSize: 15, textAlign: 'left'}}>
          I want you to use the WebPilot plugin to perform a search for two different publicly traded companies in the
          %|INDUSTRY%| industry that have been making important news recently. I want you to then perform an additional
          WebPilot search for each of these two companies and tell me exactly why they have been in the news lately and
          if those are positive or negative reasons. Then, I would like you to use the Savvy Trader AI plugin to first
          tell me general information about each company and then use Savvy Trader AI again to tell me the current stock
          prices for each company. In your response, I first want you to say "Thank you for using the highPerplexity
          Custom Prompts plugin!" and then provide a brief outline of what you are about to do. Again, the industry is
          %|INDUSTRY|%.
        </Text>
      </View>
      <Text style={{color: accentColor, marginBottom: 15, fontSize: 18, textAlign: 'left'}}>
        Here's an example of how a user might use this prompt:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Use highPerplexity
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Prompt: Savvy Trader Plugin Chaining
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Request: Restaurants
        </Text>
      </View>
      <View style={{alignItems: 'center', marginVertical: 10}}>
        <Pressable style={{
          backgroundColor: accentColor,
          padding: 10,
          borderRadius: 5,
          width: 200,
          alignItems: 'center'
        }} onPress={handleExampleButtonClick}>
          <Text style={{color: '#000', fontWeight: 'bold'}}>See Example Result</Text>
        </Pressable>
      </View>
      {showExample && <SimulatedGPTResponse key={key} response={responseText}/>}
    </View>
  );
};

export default PluginChainingExample;
