import React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import CreateNewPromptScreen from "../screens/User/CreateNewPromptScreen";
import ViewPromptScreen from "../screens/User/ViewPromptScreen";
import ViewPromptsByTagScreen from "../screens/User/ViewPromptsByTag";
import ViewPromptsByPatternScreen from "../screens/User/ViewPromptsByPattern";
import ViewAllTagsScreen from "../screens/User/ViewAllTags";
import MyPromptsScreen from "../screens/User/MyPromptsScreen";
import SavedPromptsScreen from "../screens/User/SavedPromptsScreen";
import SearchResultsScreen from "../screens/User/SearchResultsScreen";
import ViewUserPromptsScreen from "../screens/User/ViewUserPromptsScreen";
import AboutScreen from "../screens/User/About";
import SettingsScreen from "../screens/User/Settings";
import SavedResponsesScreen from "../screens/User/SavedResponsesScreen";
import ViewSavedResponse from "../screens/User/ViewSavedResponse";
import HowItWorksScreen from "../screens/User/HowItWorks";
import GPTConversationScreen from "../screens/User/GPTConversation";
import SavedConversationsScreen from "../screens/User/SavedConversationsScreen";
import ImageConversationScreen from "../screens/User/ImageConversation";
import StoreScreen from "../screens/User/StoreScreen";
import TermsOfServiceScreen from "../screens/User/TermsOfService";
import PrivacyPolicyScreen from "../screens/User/PrivacyPolicy";
import ContactScreen from "../screens/User/ContactScreen";
import HighPerplexityHome from "../screens/User/highPerplexity";
import GPTPluginScreen from "../screens/Common/GPTPluginScreen";
import TokenManagerScreen from "../screens/User/TokenManager";
import NewViewPromptScreen from "../screens/Common/ViewPromptScreen";

const Stack = createStackNavigator();

const linking = {
  prefixes: ['https://highperplexity.com'],
  config: {
    screens: {
      "highPerplexity": "",
      "Create New Prompt": "/create",
      "View Prompt": "/view2/:promptId",
      "New View Prompt": "/view/:promptId",
      "View Response": "/view-response/:responseId",
      "View Tag": "/tags/:tagName",
      "View Trend": "/trending/:trendName",
      "Browse": "/browse",
      "View All Tags": "/tags/all",
      "My Prompts": "/my-prompts",
      "Saved Prompts": "/saved-prompts",
      "Saved Responses": "/saved-responses",
      "Search Results": "/search/:queryText",
      "User Prompts": "/user/prompts/:userName",
      "About": "/about",
      "Settings": "/settings",
      "How It Works": "/how-it-works",
      "My Conversations": "/my-conversations",
      "GPTConversation": "/conversation/:convoId",
      "ImageConversation": "/image-conversation/:convoId",
      "Store": "/store",
      "Terms": "/terms",
      "Privacy": "/privacy",
      "Contact": "/contact",
      "GPT Plugin": "/gpt-plugin",
      "Token Manager": "/token-manager",
    },
  },
}

export default function UserStack() {
  return (<NavigationContainer linking={linking}>
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen name="highPerplexity" component={HighPerplexityHome}/>
      <Stack.Screen name="Create New Prompt" component={CreateNewPromptScreen}/>
      <Stack.Screen name="View Prompt" component={ViewPromptScreen}/>
      <Stack.Screen name="New View Prompt" component={NewViewPromptScreen}/>
      <Stack.Screen name="View Tag" component={ViewPromptsByTagScreen}/>
      <Stack.Screen name="View Trend" component={ViewPromptsByPatternScreen}/>
      <Stack.Screen name="Browse" component={HighPerplexityHome}/>
      <Stack.Screen name="View All Tags" component={ViewAllTagsScreen}/>
      <Stack.Screen name="My Prompts" component={MyPromptsScreen}/>
      <Stack.Screen name="Saved Prompts" component={SavedPromptsScreen}/>
      <Stack.Screen name="Saved Responses" component={SavedResponsesScreen}/>
      <Stack.Screen name="Search Results" component={SearchResultsScreen}/>
      <Stack.Screen name="User Prompts" component={ViewUserPromptsScreen}/>
      <Stack.Screen name="View Response" component={ViewSavedResponse}/>
      <Stack.Screen name="About" component={AboutScreen}/>
      <Stack.Screen name="Settings" component={SettingsScreen}/>
      <Stack.Screen name="How It Works" component={HowItWorksScreen}/>
      <Stack.Screen name="My Conversations" component={SavedConversationsScreen}/>
      <Stack.Screen name="GPTConversation" component={GPTConversationScreen}/>
      <Stack.Screen name="ImageConversation" component={ImageConversationScreen}/>
      <Stack.Screen name="Store" component={StoreScreen}/>
      <Stack.Screen name="Terms" component={TermsOfServiceScreen}/>
      <Stack.Screen name="Privacy" component={PrivacyPolicyScreen}/>
      <Stack.Screen name="Contact" component={ContactScreen}/>
      <Stack.Screen name="GPT Plugin" component={GPTPluginScreen}/>
      <Stack.Screen name="Token Manager" component={TokenManagerScreen}/>
    </Stack.Navigator>
  </NavigationContainer>);
}
