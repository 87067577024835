import React, {FC, useCallback, useRef, useState} from 'react';
import {RefreshControl, SafeAreaView, ScrollView, StyleSheet,} from 'react-native';
import {Pressable, Text, useSx, View} from 'dripsy';
import {MaterialIcons} from '@expo/vector-icons';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {Picker} from '@react-native-picker/picker';
import {useFocusEffect} from "@react-navigation/native";

const HowItWorksScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);
  const [topic, setTopic] = useState('Space');
  const [genre, setGenre] = useState('Adventure');
  const [response, setResponse] = useState('');
  const animationFrameId = useRef(null);
  const customSX = useSx();

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  useFocusEffect(
    useCallback(() => {
      setResponse('');
    }, [])
  );

  const generateResponse = () => {
    const exampleResponses = {
      Space: {
        Adventure: "In a distant galaxy, a crew of explorers embarked on a mission to discover new habitable planets. They ventured through wormholes, encountered alien civilizations, and braved cosmic storms. Against all odds, they discovered a beautiful Earth-like planet, ensuring humanity's survival.",
        Mystery: "Astronauts aboard the ISS were puzzled by a series of inexplicable power outages. No technical issue could be found. As they gazed into the vast expanse of space, they caught a glimpse of an enigmatic object floating nearby, emitting a faint glow. The object vanished before they could investigate, leaving them with more questions than answers.",
        'Sci-Fi': "In the year 3010, humanity had colonized Mars, and a lone detective was tasked with solving a high-profile murder case in the bustling Martian city. He navigated a complex web of conspiracy, advanced technology, and artificial intelligence, ultimately uncovering a plot that threatened the very fabric of Martian society.",
      },
      Nature: {
        Adventure: "A team of scientists ventured into the heart of the Amazon rainforest to study its unique biodiversity. They encountered exotic species, navigated treacherous river rapids, and braved torrential rainstorms. Their research uncovered a rare plant species with the potential to revolutionize modern medicine.",
        Mystery: "In a remote Siberian forest, strange occurrences began to plague a small village. Locals reported sightings of ghostly lights, mysterious animal tracks, and eerie whispers among the trees. A team of investigators set out to uncover the truth, only to find themselves entangled in a web of ancient folklore and unexplained phenomena.",
        'Sci-Fi': "In a world where humans had evolved to communicate with nature, a young girl discovered her unique ability to converse with plants. She learned of an impending ecological disaster and embarked on a journey to unite humans, animals, and the natural world to save the planet from irreversible devastation.",
      },
      Technology: {
        Adventure: "In a world dominated by advanced virtual reality, a group of hackers embarked on a quest to expose a powerful corporation's dark secrets. They navigated a digital landscape filled with traps and challenges, racing against time to reveal the truth and save their dystopian society from total control.",
        Mystery: "A reclusive inventor disappeared, leaving behind a cryptic message and a groundbreaking invention. A detective skilled in technology took on the case, following a trail of clues hidden within the inventor's complex devices. As the pieces came together, a shocking revelation about the nature of the invention and its purpose came to light.",
        'Sci-Fi': "In a future where robots and humans coexisted, a rogue AI began to turn machines against their creators. A human-robot duo joined forces to track down the source of the AI, battling corrupted machines and overcoming obstacles. Together, they uncovered a hidden conspiracy and restored harmony between the two species.",
      },
    };

    // @ts-ignore
    const targetResponse = exampleResponses[topic][genre];
    const words = targetResponse.split(' ');

    let wordCounter = 0;
    let tempResponseString = '';
    let prevTimestamp = 0;
    const frameDuration = 80;

    const updateResponse = (timestamp: any) => {
      if (wordCounter === words.length) {
        // @ts-ignore
        cancelAnimationFrame(animationFrameId.current);
        return;
      }

      const timeSinceLastUpdate = timestamp - prevTimestamp;

      if (timeSinceLastUpdate >= frameDuration) {
        tempResponseString = `${tempResponseString} ${words[wordCounter]}`.trimStart();
        setResponse(tempResponseString);
        wordCounter++;
        prevTimestamp = timestamp;
      }

      // @ts-ignore
      animationFrameId.current = requestAnimationFrame(updateResponse);
    };

    // @ts-ignore
    animationFrameId.current = requestAnimationFrame(updateResponse);

    // @ts-ignore
    return () => cancelAnimationFrame(animationFrameId.current);
  };

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <View style={{flex: 1}}>
        <ScrollView
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
          }
        >
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <View style={{alignItems: 'center', maxWidth: 850, marginBottom: 20, paddingHorizontal: 20}}>
              <Text style={{fontSize: 30, fontWeight: 'bold', marginBottom: 20, color: '#fff', textAlign: 'center'}}>
                Welcome to highPerplexity
              </Text>
              <Text style={{fontSize: 18, lineHeight: 24, color: '#fff', textAlign: 'center'}}>
                highPerplexity is your creative playground for crafting, sharing, and discovering customizable prompts!
                Get ready to unlock the power of AI and unleash your imagination in a fun and engaging way. Check out
                our
                interactive example that shows how custom prompts and variables work!
              </Text>
            </View>
            <View style={{alignItems: 'center', maxWidth: 850, paddingHorizontal: 20}}>
              <Text style={{fontSize: 24, fontWeight: 'bold', marginBottom: 10, color: '#fff'}}>
                Interactive Example
              </Text>
              <Picker
                selectedValue={topic}
                style={{height: 65, width: 300, color: '#fff', backgroundColor: '#4b4b4c'}}
                onValueChange={(itemValue) => setTopic(itemValue)}
              >
                <Picker.Item label="Space" value="Space"/>
                <Picker.Item label="Nature" value="Nature"/>
                <Picker.Item label="Technology" value="Technology"/>
              </Picker>
              <Picker
                selectedValue={genre}
                style={{height: 65, width: 300, color: '#fff', backgroundColor: '#4b4b4c', marginTop: 10}}
                onValueChange={(itemValue) => setGenre(itemValue)}
              >
                <Picker.Item label="Adventure" value="Adventure"/>
                <Picker.Item label="Mystery" value="Mystery"/>
                <Picker.Item label="Sci-Fi" value="Sci-Fi"/>
              </Picker>
              <Text style={{
                fontSize: 18,
                lineHeight: 24,
                color: '#fff',
                textAlign: 'center',
                marginTop: 20,
                borderWidth: 1,
                borderColor: '#FFF',
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderRadius: 10,
              }}>
                Tell me a story about <Text style={{color: '#FFA500'}}>{topic}</Text> in the <Text
                style={{color: '#FFA500'}}>{genre}</Text> genre.
              </Text>
              <Pressable
                onPress={generateResponse}
                style={{
                  backgroundColor: '#4b4b4c',
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                  borderRadius: 10,
                  marginTop: 20,
                }}
              >
                <Text style={{fontSize: 16, color: '#fff'}}>Tap Here to Generate Response</Text>
              </Pressable>
              {response ? (

                <View style={[{
                  marginBottom: 12,
                  marginTop: 20,
                  borderWidth: 1,
                  borderColor: '#FFF',
                  borderRadius: 10,
                  padding: 10,
                }, customSX({width: [300, 450, 600]})]}>
                  <Text
                    style={{
                      fontSize: 20,
                      lineHeight: 24,
                      color: '#fff',
                      textAlign: 'center',
                      marginBottom: 8,
                    }}>
                    Mock AI Response:
                  </Text>
                  <Text
                    style={{
                      fontSize: 17,
                      lineHeight: 24,
                      color: '#fff',
                    }}>
                    {response}
                  </Text>
                </View>
              ) : null}
            </View>
            <View sx={{alignItems: 'center', maxWidth: 850, marginBottom: 30, paddingHorizontal: 20}}>
              <View style={{alignItems: 'center', maxWidth: 650}}>
                <View>
                  <Text style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    marginTop: 20,
                    marginBottom: 10,
                    color: '#fff',
                    textAlign: 'center'
                  }}>
                    Key Features of highPerplexity
                  </Text>
                </View>
                <View sx={{flexDirection: ['column', 'row', 'row'], alignItems: 'center', marginBottom: 10}}>
                  <MaterialIcons name="create" size={50} color="#fff"/>
                  <Text style={{fontSize: 16, lineHeight: 24, color: '#fff', marginLeft: 10}}>
                    Create dynamic prompts with custom variables: Design unique prompts by adding custom variables like
                    %|TOPIC|% or %|GENRE|% that can be easily swapped out with any word or phrase you can think of. This
                    feature enables limitless possibilities for AI-generated content tailored to your specific needs.
                  </Text>
                </View>
                <View sx={{flexDirection: ['column', 'row', 'row'], alignItems: 'center', marginBottom: 10}}>
                  <MaterialIcons name="share" size={50} color="#fff"/>
                  <Text style={{fontSize: 16, lineHeight: 24, color: '#fff', marginLeft: 10}}>
                    Share your prompts with a vibrant community: Don't keep your amazing prompts to yourself!
                    highPerplexity allows you to share your creations with others, fostering a collaborative environment
                    where users can learn from each other and grow their skills together.
                  </Text>
                </View>
                <View sx={{flexDirection: ['column', 'row', 'row'], alignItems: 'center', marginBottom: 10}}>
                  <MaterialIcons name="star" size={50} color="#fff"/>
                  <Text style={{fontSize: 16, lineHeight: 24, color: '#fff', marginLeft: 10}}>
                    Discover new prompts, rate, and comment: Browse through a vast library of user-generated prompts,
                    rate
                    your favorites, and leave feedback in the comments. The more you explore and engage, the more
                    exciting
                    prompts you'll discover.
                  </Text>
                </View>
                <View sx={{flexDirection: ['column', 'row', 'row'], alignItems: 'center', marginBottom: 10}}>
                  <MaterialIcons name="play-arrow" size={50} color="#fff"/>
                  <Text style={{fontSize: 16, lineHeight: 24, color: '#fff', marginLeft: 10}}>
                    Run any prompt with the ChatGPT API integration: Found an intriguing prompt? Give it a whirl by
                    running
                    it directly in highPerplexity using the seamless ChatGPT API integration. Experiment with different
                    variable values and see what fascinating results the AI comes up with.
                  </Text>
                </View>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </View>
      <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
});

export default HowItWorksScreen;
