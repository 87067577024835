import React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';

import WelcomeScreen from '../screens/Auth/Welcome';
import SignUpScreen from "../screens/Auth/SignUpScreen";
import ViewPromptPreAuthScreen from "../screens/Auth/ViewPromptPreAuthScreen";
import ViewPromptsByTagScreen from "../screens/Auth/ViewPromptsByTag";
import ViewPromptsByPatternScreen from "../screens/Auth/ViewPromptsByPattern";
import ViewAllTagsScreen from "../screens/Auth/ViewAllTags";
import SearchResultsScreen from "../screens/Auth/SearchResultsScreen";
import AboutScreen from "../screens/Auth/About";
import ViewUserPromptsScreen from "../screens/Auth/ViewUserPromptsScreen";
import TermsOfServiceScreen from "../screens/Auth/TermsOfService";
import PrivacyPolicyScreen from "../screens/Auth/PrivacyPolicy";
import ForgotPasswordScreen from "../screens/Auth/ForgotPasswordScreen";
import ViewSavedResponse from "../screens/Auth/ViewSavedResponse";
import HowItWorksScreen from "../screens/Auth/HowItWorks";
import ContactScreen from "../screens/Auth/ContactScreen";
import HighPerplexityHome from "../screens/Auth/highPerplexity";
import GPTPluginScreen from "../screens/Common/GPTPluginScreen";

import NotLoggedInScreen from "../screens/Auth/NotLoggedIn";
import SettingsScreen from "../screens/User/Settings";

const Stack = createStackNavigator();

const linking = {
  prefixes: ['https://promptness.io', 'https://highperplexity.com'],
  config: {
    screens: {
      "Welcome": "",
      "Sign Up": "/signup",
      "Forgot Password": "/forgot-password",
      "View Prompt": "/view2/:promptId",
      "New View Prompt": "/view/:promptId",
      "View Tag": "/tags/:tagName",
      "View Trend": "/trending/:trendName",
      "Browse": "/browse",
      "View All Tags": "/tags/all",
      "Search Results": "/search/:queryText",
      "About": "/about",
      "Terms": "/terms",
      "Privacy": "/privacy",
      "User Prompts": "/user/prompts/:userName",
      "View Response": "/view-response/:responseId",
      "Saved Responses": "/saved-responses",
      "GPT Plugin": "/gpt-plugin",
      // created to match up with userStack
      "Create New Prompt": "/create",
      "My Prompts": "/my-prompts",
      "Saved Prompts": "/saved-prompts",
      "Settings": "/settings",
      "How It Works": "/how-it-works",
      "My Conversations": "/my-conversations",
      "ImageConversation": "/image-conversation/:convoId",
      "GPTConversation": "/conversation/:convoId",
      "Store": "/store",
      "Contact": "/contact",
      "Token Manager": "/token-manager",
    },
  },
}

export default function AuthStack() {
  return (<NavigationContainer linking={linking}>
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen name="Welcome" component={WelcomeScreen}/>
      <Stack.Screen name="Sign Up" component={SignUpScreen}/>
      <Stack.Screen name="Forgot Password" component={ForgotPasswordScreen}/>
      <Stack.Screen name="View Prompt" component={ViewPromptPreAuthScreen}/>
      <Stack.Screen name="New View Prompt" component={ViewPromptPreAuthScreen}/>
      <Stack.Screen name="View Tag" component={ViewPromptsByTagScreen}/>
      <Stack.Screen name="View Trend" component={ViewPromptsByPatternScreen}/>
      <Stack.Screen name="Browse" component={HighPerplexityHome}/>
      <Stack.Screen name="View All Tags" component={ViewAllTagsScreen}/>
      <Stack.Screen name="Search Results" component={SearchResultsScreen}/>
      <Stack.Screen name="About" component={AboutScreen}/>
      <Stack.Screen name="User Prompts" component={ViewUserPromptsScreen}/>
      <Stack.Screen name="Terms" component={TermsOfServiceScreen}/>
      <Stack.Screen name="Privacy" component={PrivacyPolicyScreen}/>
      <Stack.Screen name="View Response" component={ViewSavedResponse}/>
      <Stack.Screen name="How It Works" component={HowItWorksScreen}/>
      <Stack.Screen name="Create New Prompt" component={NotLoggedInScreen}/>
      <Stack.Screen name="My Prompts" component={NotLoggedInScreen}/>
      <Stack.Screen name="Saved Prompts" component={NotLoggedInScreen}/>
      <Stack.Screen name="Settings" component={NotLoggedInScreen}/>
      <Stack.Screen name="Saved Responses" component={NotLoggedInScreen}/>
      <Stack.Screen name="My Conversations" component={NotLoggedInScreen}/>
      <Stack.Screen name="GPTConversation" component={NotLoggedInScreen}/>
      <Stack.Screen name="ImageConversation" component={NotLoggedInScreen}/>
      <Stack.Screen name="Store" component={NotLoggedInScreen}/>
      <Stack.Screen name="Contact" component={ContactScreen}/>
      <Stack.Screen name="GPT Plugin" component={GPTPluginScreen}/>
      <Stack.Screen name="Token Manager" component={NotLoggedInScreen}/>
    </Stack.Navigator>
  </NavigationContainer>);
}
