import React, {useState} from 'react';
import {ActivityIndicator, Pressable, StyleSheet, Text, View} from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';

interface ReplyProps {
  reply: any;
  upvoteComment: (comment: any, action: 'add' | 'remove') => void;
  hasBeenUpvoted: (comment: any) => boolean;
  reportComment: (comment: any) => Promise<void>;
}

export const Reply: React.FC<ReplyProps> = ({
                                              reply,
                                              upvoteComment,
                                              hasBeenUpvoted,
                                              reportComment,
                                            }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpvote = async () => {
    setIsLoading(true);
    await upvoteComment(reply, hasBeenUpvoted(reply) ? 'remove' : 'add');
    setIsLoading(false);
  };

  const handleReport = async () => {
    await reportComment(reply.commentId);
  };

  return (
    <View style={styles.replyContainer}>
      <View style={styles.replyHeader}>
        <Text style={styles.replyAuthor}>By: {reply.userName}</Text>
      </View>
      <Text style={styles.replyContent}>{reply.content}</Text>
      <View style={styles.upvoteContainer}>
        {isLoading ? (
          <View style={styles.voteButton}>
            <ActivityIndicator size="small" color="#FFF"/>
          </View>
        ) : (
          <View style={{flexDirection:'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Pressable onPress={handleUpvote} style={styles.voteButton}>
              <MaterialIcons
                name="thumb-up"
                size={24}
                color={hasBeenUpvoted(reply) ? '#449e48' : '#FFF'}
              />
              <Text style={styles.voteCount}>{reply?.commentUpvotes?.length || 0}</Text>
            </Pressable>
          </View>
        )}
        <Pressable style={styles.reportButton} onPress={handleReport}>
          <Text style={styles.reportButtonText}>Report</Text>
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  replyContainer: {
    marginLeft: 5,
    borderWidth: 1,
    borderColor: 'transparent',
    padding: 10,
    borderRadius: 10,
    marginBottom: 15,
    backgroundColor: '#3c3c3e',
  },
  replyHeader: {
    marginBottom: 5,
  },
  replyAuthor: {
    color: '#FFF',
    fontWeight: 'bold',
  },
  replyContent: {
    backgroundColor: '#39393b',
    borderRadius: 10,
    color: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  upvoteContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  voteButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
    paddingVertical: 2,
    marginRight: 5,
  },
  voteCount: {
    color: '#FFF',
    marginLeft: 5,
  },
  reportButton: {
    backgroundColor: '#4b4b4c',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  },
  reportButtonText: {
    color: '#FFF',
  },
});
