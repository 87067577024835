import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {Reply} from './Reply';

interface ReplyListProps {
  replies: any[];
  upvoteComment: (comment: any, action: 'add' | 'remove') => void;
  hasBeenUpvoted: (comment: any) => boolean;
  reportComment: (comment: any) => Promise<void>;
}

export const ReplyList: React.FC<ReplyListProps> = ({
                                                      replies,
                                                      upvoteComment,
                                                      hasBeenUpvoted,
                                                      reportComment,
                                                    }) => {
  return (
    <View style={styles.replyListContainer}>
      {replies.length > 0 && (
        <>
          <Text style={styles.replyListTitle}>Replies:</Text>
          {replies.map((reply, index) => (
            <Reply
              key={index}
              reply={reply}
              upvoteComment={upvoteComment}
              hasBeenUpvoted={hasBeenUpvoted}
              reportComment={reportComment}
            />
          ))}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  replyListContainer: {
    marginTop: 10,
  },
  replyListTitle: {
    color: '#FFF',
    fontWeight: 'bold',
    marginBottom: 10,
  },
});
