import React from 'react';
import {Linking, Pressable, StyleSheet, Text} from 'react-native';
import {View} from "dripsy";
import {FontAwesome} from '@expo/vector-icons';

const PluginIntroduction: React.FC = () => {
  const handleLink = () => {
    Linking.openURL('https://openai.com/blog/chatgpt-plugins');
  };

  return (
    <View style={styles.sectionContainer} sx={{width: ['95%', '95%', '75%']}}>
      <View style={{flexDirection: 'column', alignItems: 'center'}}>
        <FontAwesome name="info-circle" size={40} color="#FFF"/>
        <Text style={styles.sectionTitle}>Introduction</Text>
      </View>
      <Text style={styles.sectionText}>
        The highPerplexity ChatGPT plugin lets the hP platform act as a custom backend prompting engine for ChatGPT. To
        install it, you need to:
      </Text>
      <View style={styles.listContainer}>
        <Text style={styles.listItem}>1. Open ChatGPT. In the bottom left corner, select Settings {'>'} Beta Features,
          then
          toggle the Plugins setting.</Text>
        <Text style={styles.listItem}>2. In ChatGPT, select New Chat.</Text>
        <Text style={styles.listItem}>3. Switch the ChatGPT model to GPT-4.</Text>
        <Text style={styles.listItem}>4. From the dropdown menu, select Plugins {'>'} Plugin Store.</Text>
        <Text style={styles.listItem}>5. Search for and install the highPerplexity plugin.</Text>
      </View>
      <Pressable onPress={handleLink} style={styles.linkContainer}>
        <FontAwesome name="external-link" size={25} color="#FFF"/>
        <Text style={styles.linkText}>Learn more about ChatGPT plugins from OpenAI</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionContainer: {
    backgroundColor: '#303030',
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
    alignSelf: 'center',
  },
  sectionTitle: {
    color: '#FFF',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
  listContainer: {
    paddingLeft: 12,
  },
  listItem: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
  linkText: {
    color: '#FFF',
    fontSize: 18,
    textDecorationLine: 'underline',
    marginLeft: 8,
  },
  linkContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
});

export default PluginIntroduction;
