import React, {createContext, useContext, useState, useRef} from 'react';
import {Animated} from 'react-native';

interface CollapseStatusContextType {
  collapseStatus: boolean;
  setCollapseStatus: (status: boolean) => void;
  collapseAnim: Animated.Value;
}

const CollapseStatusContext = createContext<CollapseStatusContextType>({
  collapseStatus: false,
  setCollapseStatus: () => {
  },
  collapseAnim: new Animated.Value(0),
});

export const useCollapseStatus = () => {
  return useContext(CollapseStatusContext);
};

export const CollapseStatusProvider = ({children}: any) => {
  const [collapseStatus, setCollapseStatus] = useState(false);
  const collapseAnim = useRef(new Animated.Value(0)).current;

  return (
    <CollapseStatusContext.Provider value={{collapseStatus, setCollapseStatus, collapseAnim}}>
      {children}
    </CollapseStatusContext.Provider>
  );
};
