import React, {useState} from 'react';
import {View, Text, Pressable, StyleSheet, Modal} from 'react-native';
import {Ionicons, MaterialCommunityIcons} from '@expo/vector-icons';
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../config/firebase";

const ViewPromptDesktopOnboarding = ({modalVisible, setModalVisible, setHasOnboarded, userDoc, currentScreen, setCurrentScreen}: any) => {

  const desktopOnboardingScreens = [
    (
      <>
        <View style={styles.iconContainer}>
          <Ionicons name="md-rocket" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: "#FFFFFF",
              fontSize: 24,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Your First highPerplexity Prompt
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left", marginBottom: 8}}>
            We're thrilled to have you here! Get ready to unleash the power of our advanced AI prompts.
          </Text>
          <Text style={{color: "#FFFFFF", fontSize: 16, textAlign: "left"}}>
            Click next to learn how to use and customize prompts for a tailored experience.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <MaterialCommunityIcons name="book-open-page-variant" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 1: Reading Prompt Descriptions
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Each prompt comes with a concise, natural language description that clarifies its purpose and functionality.
            This handy guide should help simplify complex prompts, making them more accessible to you.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            For instance, a description like "Craft a story on any topic of your choice" conveys the essence of the
            prompt. Just swap the topic variable with your desired subject, and you're all set for a creative journey.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <MaterialCommunityIcons name="lightbulb-on-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 2: Understand Prompt Content
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            The key to an immersive AI-driven experience lies in understanding the content of each prompt. Familiarize
            yourself with the available variables to fully harness the potential of highPerplexity.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            For example, a prompt might have options that allow you to customize a story or message. You can modify
            these values to create a personalized experience tailored to your preferences.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="md-settings-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 3: Customizing Prompts
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            highPerplexity encourages you to modify and experiment with the prompts to bring your ideas to life. Adjust
            variables, add new elements, or combine multiple prompts to create a unique outcome.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Don't hesitate to get creative! Our platform is designed to support your vision and provide a seamless user
            experience.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="md-flask-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 4: Test and Experiment
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            highPerplexity offers a playground for you to test, tweak, and experiment with prompts before fully
            committing to them. Make use of this feature to optimize your ideas and uncover new possibilities.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Embrace the trial-and-error process and be open to refining your prompts for the best results.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="md-share-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Step 5: Share Your Creations
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Showcase your creativity and share your personalized prompts with the highPerplexity community. Engage with
            others, exchange ideas, and discover new approaches to using AI-driven prompts.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Together, we can inspire each other and unlock the full potential of AI!
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View
          style={{
            borderWidth: 2,
            borderColor: '#FFFFFF',
            borderRadius: 50,
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 15,
          }}
        >
          <Text style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 35}}>
            hP
          </Text>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            You're All Set!
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Congratulations! You're now ready to dive into the world of custom prompts with highPerplexity. Use
            your newfound knowledge to explore endless possibilities.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Remember, practice makes perfect. Keep experimenting and have fun!
          </Text>
        </View>
      </>
    ),
  ];

  const toggleOnboardingStatus = async () => {
    try {
      //@ts-ignore
      const userRef = doc(db, "users", userDoc);
      await updateDoc(userRef, {promptViewOnboardedStatus: true});
    } catch (error) {
      console.error("Error updating onboarded status: ", error);
    }
    setModalVisible(false);
    setHasOnboarded(true);
  }

  const handleBackButtonClick = () => {
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (currentScreen < desktopOnboardingScreens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      toggleOnboardingStatus();
      setModalVisible(false);
    }
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          {desktopOnboardingScreens[currentScreen]}
          <View
            style={[
              styles.buttonContainer,
              currentScreen === desktopOnboardingScreens.length - 1
                ? styles.buttonContainerLastScreen
                : styles.buttonContainerStandard,
            ]}
          >
            {currentScreen === 0 && (
              <Pressable
                style={styles.button}
                onPress={() => {
                  toggleOnboardingStatus();
                  setModalVisible(false);
                }}
              >
                <Ionicons name="close-outline" size={24} color="#FFFFFF"/>
                <Text style={styles.buttonText}>Skip</Text>
              </Pressable>
            )}

            {currentScreen > 0 && (
              <Pressable style={styles.button} onPress={handleBackButtonClick}>
                <Ionicons name="arrow-back" size={24} color="#FFFFFF"/>
                <Text style={styles.buttonText}>Back</Text>
              </Pressable>
            )}

            {currentScreen < desktopOnboardingScreens.length - 1 && (
              <Pressable style={styles.button} onPress={handleNextButtonClick}>
                <Text style={[styles.buttonText, {marginRight: 4}]}>Next</Text>
                <Ionicons name="arrow-forward" size={24} color="#FFFFFF"/>
              </Pressable>
            )}

            {currentScreen === desktopOnboardingScreens.length - 1 && (
              <Pressable
                style={styles.button}
                onPress={() => {
                  toggleOnboardingStatus();
                  setModalVisible(false);
                }}
              >
                <Ionicons name="checkmark-outline" size={24} color="#FFFFFF"/>
                <Text style={styles.buttonText}>Finished!</Text>
              </Pressable>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    maxWidth: 800,
    margin: 20,
    backgroundColor: '#202124',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  buttonContainerStandard: {
    justifyContent: 'space-between',
  },
  buttonContainerLastScreen: {
    justifyContent: 'center',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#3a3a3a',
    padding: 10,
    borderRadius: 5,
    marginLeft: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 16,
    marginLeft: 5,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
});

export default ViewPromptDesktopOnboarding;
