import React, {useCallback, useEffect, useState} from 'react';
import {Platform, StyleSheet} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import {Link} from '@react-navigation/native';
import {Feather} from '@expo/vector-icons';
import {Configure, InstantSearch} from 'react-instantsearch-native';
import algoliasearch from 'algoliasearch/lite';
import SearchBox from "../SearchBox";
import Hits from "../Hits";
import useAdSenseScript from "../../utils/hooks/useAdSenseScript";
import useAdCashScript from "../../utils/hooks/useAdCashScript";

const searchClient = algoliasearch('DSWB3NXLUY', '9b525590ee55105d6f863cf735e78d0c');

const Header = (props: { navStatus: string, setNavStatus: any, navigation?: any }) => {
  const [searchState, setSearchState] = useState({});
  const [searchInput, setSearchInput] = useState('');

  useAdSenseScript(props.navigation);
  useAdCashScript(props.navigation);

  const clearInput = useCallback(() => {
    setSearchInput('');
  }, []);

  return (<View style={{width: '100%'}}>
    <View style={styles.headerContainer}>
      <Pressable style={styles.headerMenuContainer}
                 sx={{width: [50, 50, 220], alignItems: ['center', 'center', 'flex-start']}}
                 onPress={() => props.setNavStatus(props.navStatus === 'closed' ? 'open' : 'closed')}>
        <Feather name="menu" size={30} color="#FFF"/>
      </Pressable>
      <View sx={{display: ['none', 'none', 'flex']}}>
        <InstantSearch
          searchClient={searchClient}
          indexName="highPerplexity"
          searchState={searchState}
          onSearchStateChange={setSearchState}
        >
          <SearchBox
            //@ts-ignore
            setSearchInput={setSearchInput}
            clearInput={clearInput}
            searchInput={searchInput}
          />
          <Hits
            //@ts-ignore
            searchInput={searchInput}
            clearInput={clearInput}
          />
          <Configure hitsPerPage={6}/>
        </InstantSearch>
      </View>
      <View style={{flexDirection: 'row'}}
            sx={{width: ['auto', 'auto', 220], justifyContent: ['center', 'center', 'flex-end']}}
      >
        <View style={[styles.headerLogoContainer, {width: 60, height: 60, overflow: 'hidden'}]}>
          <Link to={{screen: 'Welcome'}} style={{padding: 15}}
                onPress={() => props.setNavStatus('closed')}>
            <Text style={styles.headerLogoText}>
              hP
            </Text>
          </Link>
        </View>
      </View>
    </View>
    <View style={{width: '100%', alignItems: 'center', marginTop: 5, marginBottom: 15}}
          sx={{display: ['flex', 'flex', 'none']}}>
      <View style={{width: '95%'}}>
        <InstantSearch
          searchClient={searchClient}
          indexName="highPerplexity"
          searchState={searchState}
          onSearchStateChange={setSearchState}
        >
          <SearchBox
            //@ts-ignore
            setSearchInput={setSearchInput}
            clearInput={clearInput}
            searchInput={searchInput}
          />
          <Hits
            //@ts-ignore
            searchInput={searchInput}
            clearInput={clearInput}
          />
          <Configure hitsPerPage={6}/>
        </InstantSearch>
      </View>
    </View>
  </View>);
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  headerMenuContainer: {
    justifyContent: 'center',
    height: 50,
  },
  headerLogoContainer: {
    borderWidth: 1,
    borderColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 60 / 2,
  },
  headerLogoText: {
    color: '#FFF',
    fontSize: 20,
  },
  remainingTokensContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 8,
    height: 60,
    width: 100,
    overflow: 'hidden',
  },
  remainingTokensText: {
    color: '#FFF',
    fontSize: 20,
    fontWeight: '600',
  },
});

export default Header;
