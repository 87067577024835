import React, {useEffect, useState} from 'react';
import {Platform, Text, TextInput, useWindowDimensions} from 'react-native';
import {Pressable, View} from "dripsy";
import {HelperText, IconButton} from 'react-native-paper';
import {Ionicons} from '@expo/vector-icons';

const PromptDescriptionSection = ({
                                    matchingPrompt,
                                    setTooltipId,
                                    setTooltipVisible,
                                  }: any) => {
  const [inputHeight, setInputHeight] = useState();
  const [showPromptDescription, setShowPromptDescription] = useState(true);
  const [isMobileTruncated, setIsMobileTruncated] = useState(true);
  const windowDimensions = useWindowDimensions();

  const inputStyle = {
    height: inputHeight,
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ccc',
    fontSize: 16,
    lineHeight: 24,
    backgroundColor: '#333',
    color: '#fff',
  };

  const showTooltip = (id: any) => {
    setTooltipId(id);
    setTooltipVisible(true);
  };

  const ShowTruncToggle = () => {
    return <View style={{marginTop: 10, marginBottom: 5}}>
      {Platform.OS === 'web' ?
        <Pressable
          style={({pressed}) => [
            {
              backgroundColor: pressed ? "#4a4a4a" : "transparent",
              borderColor: "#ccc",
              borderWidth: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              borderRadius: 5,
              shadowColor: "#000",
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.2,
              shadowRadius: 1,
              elevation: 2,
              display: 'none',
            },
          ]}
          onPress={() => setShowPromptDescription(!showPromptDescription)}
        >
          <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
            {showPromptDescription ? "Hide Prompt Description" : "Show Prompt Description"}
          </Text>
          <Ionicons name={showPromptDescription ? "md-book-outline" : "md-book-sharp"} size={20}
                    color="#FFF"/>
        </Pressable>
        :
        <Pressable
          style={({pressed}) => [
            {
              backgroundColor: pressed ? "#4a4a4a" : "transparent",
              borderColor: "#ccc",
              borderWidth: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              borderRadius: 5,
              shadowColor: "#000",
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.2,
              shadowRadius: 1,
              elevation: 2,
            },
          ]}
          onPress={() => setIsMobileTruncated(!isMobileTruncated)}
        >
          <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
            {isMobileTruncated ? "Show Full Prompt Description" : "Collapse Prompt Description"}
          </Text>
          <Ionicons
            name={isMobileTruncated ? "md-expand" : "md-contract"}
            size={20}
            color="#FFF"
          />
        </Pressable>
      }
    </View>
  }

  useEffect(() => {
    const updateHeight = () => {
      const screenWidth = windowDimensions.width;

      if (screenWidth < 768) {
        // @ts-ignore
        setInputHeight(200);
      } else {
        // @ts-ignore
        setInputHeight(300);
      }
    };

    updateHeight();
  }, [windowDimensions]);

  if (!matchingPrompt?.description) {
    return null;
  }

  if (!showPromptDescription) {
    return <ShowTruncToggle/>;
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <View style={{
          backgroundColor: '#2a2a2a',
          borderRadius: 5,
          padding: 15,
        }}
              sx={{width: [300, 400, 600]}}
        >
          <View style={{alignItems: 'center'}}>
            <Ionicons name="md-book-sharp" size={60} color="#ccc"/>
          </View>
          <Text style={{
            color: '#ccc',
            fontSize: 14,
            fontStyle: 'italic',
            marginBottom: 8,
          }}>
            This section provides a description of the prompt you are about to run, not the prompt itself.
            Click the tooltip icon for more info.
          </Text>
          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
            <HelperText type="info" visible={true}
                        style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
              Prompt Description
            </HelperText>
            <IconButton
              icon="information-outline"
              size={20}
              style={{margin: 0}}
              onPress={() => showTooltip('promptDescription')}
              theme={{colors: {primary: "#ccc"}}}
            />
          </View>
          <TextInput
            value={
              //@ts-ignore
              matchingPrompt?.description?.replace(/\\n/g, "\n") ||
              "The prompt author did not provide a description for this prompt. Please tap the Show Full Prompt Content button to get a better idea of how to use it!"
            }
            style={{
              ...inputStyle,
              borderColor: "#ccc",
              fontSize: 16,
              lineHeight: 24,
            }}
            editable={false}
            multiline={true}
          />
        </View>
      ) : (
        <View style={{
          backgroundColor: '#2a2a2a',
          borderRadius: 5,
          padding: 10,
        }}
              sx={{width: [300, 400, 500]}}
        >
          <View style={{alignItems: 'center'}}>
            <Ionicons name="md-book-sharp" size={60} color="#ccc"/>
          </View>
          <Text style={{
            color: '#ccc',
            fontSize: 14,
            fontStyle: 'italic',
            marginBottom: 8,
          }}>
            This section provides a description of the prompt you are about to run, not the prompt itself.
            Tap the tooltip icon for more info.
          </Text>
          <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
            <HelperText type="info" visible={true}
                        style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
              Prompt Description
            </HelperText>
            <IconButton
              icon="information-outline"
              size={20}
              style={{margin: 0}}
              onPress={() => showTooltip('promptDescription')}
              theme={{colors: {primary: "#ccc"}}}
            />
          </View>
          <TextInput
            value={
              isMobileTruncated ?
                // @ts-ignore
                (matchingPrompt?.description?.replace(/\\n/g, "\n").substring(0, 300) + '...') :
                // @ts-ignore
                (matchingPrompt?.description?.replace(/\\n/g, "\n") ||
                  "The prompt author did not provide a description for this prompt. Please tap the Show Full Prompt Content button to get a better idea of how to use it!")
            }
            style={{
              ...inputStyle,
              borderColor: "#ccc",
              fontSize: 16,
              lineHeight: 24,
              height: 'auto',
            }}
            editable={false}
            multiline={true}
          />
        </View>
      )}
      {// @ts-ignore
        matchingPrompt?.description &&
        <ShowTruncToggle/>
      }
    </>
  );
};

export default PromptDescriptionSection;
