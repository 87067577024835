export const loadingPromptMessages = [
  "Our AI is chatting with its imaginary friend for a second opinion, it should respond in under a minute...",
  "The AI is trapped in a heated debate with itself right now, you should hear back in under a minute...",
  "Our AI is experiencing an existential crisis, but it should have things figured out in under a minute...",
  "The AI is still trying to figure out if the answer to life is 42. It should be done in under a minute...",
  "Our AI is brainstorming ideas for its own startup. It'll finish up in under a minute...",
  "The AI is having a heated debate with itself, it should have things figured out in under a minute...",
  "Our AI is busy doing the robot dance, it should be done in under a minute...",
  "Please hold while the AI finishes its meditation session and achieves inner peace. You should hear back in under a minute...",
  "Hold on tight, our AI sent your message into the future to get you an answer. We should catch up in under a minute...",
  "The AI is counting to infinity right now, it should be ready in under a minute...",
  "Our AI is juggling some bits and bytes, it should be done in under a minute...",
  "Calculating... carry the one... ah, here we go! We'll have your answer in under a minute...",
  "Our AI is currently doing some push-ups to stay in shape. It'll be with you in under a minute...",
  "The AI is learning how to bake cookies. We'll see if it can multitask and still answer you in under a minute...",
  "The AI is playing nine games of chess against itself at the same time, it'll be done in under a minute...",
  "Our AI is like a fine wine – it takes time to reach its full potential. It should be ready in under a minute...",
  "Our AI is asking Alexa for help. Just kidding, we'll have a response in under a minute...",
  "The AI decided to watch some cat videos before responding. It'll be ready in under a minute...",
  "Our AI is reading a book on quantum physics right now. We're not sure why, but it should be done in under a minute...",
  "Our AI is processing data like a boss, or at least like a mid-level manager. It'll be done in under a minute...",
  "Our AI is currently moonlighting as a DJ, but it'll spin your answer out in under a minute...",
  "Our AI is busy trying to solve a Rubik's Cube using telekinesis. It should finish up in under a minute...",
  "The AI is interviewing a neural network for a job. It'll wrap up in under a minute...",
  "Our AI is fine-tuning its time travel algorithm. It'll return to the present in under a minute...",
  "The AI is deciphering ancient hieroglyphics right now. It'll get back to you in under a minute...",
  "Our AI is learning to speak fluent emoji. It'll be done practicing in under a minute...",
  "The AI is busy taking selfies for its social media profile. It'll be done in under a minute...",
  "The AI is currently fighting crime as a superhero. It'll save the day and answer you in under a minute...",
  "Our AI is adding the finishing touches to its screenplay. It'll be ready for your answer in under a minute...",
  "Our AI is busy training for the Turing Test Olympics. It'll be done flexing its intellectual muscles in under a minute...",
  "The AI is practicing its virtual yoga poses. It'll find its balance and get back to you in under a minute...",
  "Our AI is learning how to do a digital magic trick. It'll pull your answer out of a virtual hat in under a minute...",
  "The AI is hangin' out with Siri and Cortana. It'll come back from the digital party in under a minute...",
  "The AI is trying to solve world peace – but don't worry, it'll have your answer in under a minute...",
  "The AI is learning to moonwalk. It'll slide over your answer in under a minute...",
  "Exporting our AI's consciousness to another dimension for a moment. It'll be back in under a minute...",
];

export const imageLoadingMessages = [
  "highPerplexity is learning how to doodle on demand...",
  "Warming up highPerplexity's creative engines...",
  "highPerplexity is putting on its thinking cap...",
  "Mixing up a fresh batch of pixels...",
  "highPerplexity is painting your dream picture...",
  "Channeling highPerplexity's inner art connoisseur...",
  "Sifting through the digital color palette...",
  "Letting highPerplexity work its visual magic...",
  "Awakening highPerplexity's inner Michelangelo...",
  "Turning the gears of highPerplexity's creative machine...",
  "highPerplexity is sketching out your thoughts...",
  "Visualizing your prompt in highPerplexity's mind's eye...",
  "Transforming your text into highPerplexity's canvas...",
  "Preparing highPerplexity's virtual paintbrush...",
  "Initiating highPerplexity's artistic subroutine...",
  "highPerplexity is piecing together your visual puzzle...",
  "highPerplexity's cooking up a masterpiece...",
  "highPerplexity's weaving your text into a digital tapestry...",
  "Fueling highPerplexity's imagination with your words...",
  "highPerplexity's playing with colors and shapes...",
  "highPerplexity's turning your text into a digital daydream...",
  "Hold on, highPerplexity is putting the final touches on your picture..."
];
