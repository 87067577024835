import React, {useState} from 'react';
import {StyleSheet} from "react-native";
import {ActivityIndicator, Pressable, Text, View} from 'dripsy';
import {ScrollView} from 'react-native-gesture-handler';
import {connectInfiniteHits} from 'react-instantsearch-native';
import PromptCard from './PromptCard';
import {Ionicons} from '@expo/vector-icons';

const CustomInfiniteHitsComponent = ({
                                       hits,
                                       hasMore,
                                       refineNext,
                                       navigation,
                                     }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const onLoadMore = async () => {
    if (hasMore) {
      setIsLoading(true);
      await refineNext();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  };

  return (
    <>
      <ScrollView>
        <View
          sx={{
            flexDirection: ['column', 'column', 'row'],
            justifyContent: 'center',
            alignItems: ['center', 'center', 'flex-start'],
            flexWrap: 'wrap',
            maxWidth: 1450,
          }}
        >
          {hits.map((prompt: any) => {
            return (
              <PromptCard
                key={prompt.promptId}
                promptId={prompt.promptId}
                navigation={navigation}
                promptImage={prompt.promptImage?.imageURL}
                promptTitle={prompt.title}
                promptUserName={prompt.userName}
                promptRatings={prompt.ratings}
                promptComments={prompt.comments?.length}
              />
            );
          })}
        </View>
      </ScrollView>
      {hasMore && (
        <Pressable onPress={onLoadMore} style={styles.loadMoreButtonStyle} disabled={isLoading}>
          {isLoading ? (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="small" color="#fff"/>
            </View>
          ) : (
            <>
              <Text style={styles.loadMoreButtonTextStyle}>Load More Prompts</Text>
              <Ionicons name="md-add-circle" size={22} color="#fff"/>
            </>
          )}
        </Pressable>
      )}
    </>
  );
};

const CustomInfiniteHits = connectInfiniteHits(CustomInfiniteHitsComponent);

const styles = StyleSheet.create({
  loadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadMoreButtonStyle: {
    backgroundColor: '#4c4c4c',
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  loadMoreButtonTextStyle: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    marginRight: 8,
  },
});

export default CustomInfiniteHits;
