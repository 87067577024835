import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  Pressable,
  Modal,
  TextInput,
  StyleSheet, Platform, Dimensions,
} from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';

const truncateText = (text: any, maxLength: any) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

const screenWidth = Dimensions.get('window').width;

const items = [
  {label: "All", value: ""},
  {label: "Writing", value: "Writing"},
  {label: "Math", value: "Math"},
  {label: "Education", value: "Education"},
  {label: "Business", value: "Business"},
];

const CustomSearchFilter = ({searchQuery, setSearchQuery}: any) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [customFilterValue, setCustomFilterValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(items[0]);

  useEffect(() => {
    const updatedItem = items.find((item) => item.value === searchQuery);
    if (updatedItem) {
      setSelectedItem(updatedItem);
    } else {
      setSelectedItem({label: searchQuery, value: searchQuery});
    }
  }, [searchQuery]);

  const openMenu = () => setMenuVisible(true);
  const closeMenu = (event?: any) => {
    if (event) {
      event.stopPropagation();
    }
    setMenuVisible(false);
  };

  const onItemPress = (itemValue: any) => {
    setSearchQuery(`${itemValue}`);
    closeMenu();
  };

  const onCustomFilterChange = (text: string) => {
    setCustomFilterValue(text);
  };

  const onCustomFilterSubmit = () => {
    setSearchQuery(customFilterValue);
    setSelectedItem({label: customFilterValue, value: customFilterValue});
    closeMenu();
  };

  return (
    <View style={styles.sortByContainer}>
      <View>
        <Text style={styles.sortByLabelText}>Filter Prompts:</Text>
        <Pressable style={styles.sortByButton} onPress={openMenu}>
          <Text style={styles.sortBySelectedText}>
            {truncateText(selectedItem.label, 12)}
          </Text>
          <MaterialIcons name="arrow-drop-down" size={24} style={styles.sortByArrow}/>
        </Pressable>
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={menuVisible}
        onRequestClose={closeMenu}
      >
        <Pressable style={styles.modalOverlay} onPress={closeMenu}>
          <Pressable onPress={(event) => event.stopPropagation()}>
            <View style={styles.modalContainer}>
              <Pressable style={styles.closeButton} onPress={closeMenu}>
                <MaterialIcons name="close" size={24} color="white"/>
              </Pressable>
              <View style={styles.modalHeader}>
                <MaterialIcons name="search" size={24} color="white" style={styles.modalIcon}/>
                <Text style={styles.modalTitle}>Filter Prompts:</Text>
              </View>
              {items.map((item) => {
                const isSelected = item.value === selectedItem.value;
                return (
                  <Pressable
                    key={item.label}
                    onPress={() => {
                      onItemPress(item.value);
                      setSelectedItem(item);
                    }}
                    style={({hovered, pressed}) => [
                      styles.modalItem,
                      isSelected && styles.modalItemSelected,
                      pressed && styles.modalItemPressed,
                      Platform.OS === 'web' && hovered && styles.modalItemHovered,
                    ]}
                  >
                    <Text style={styles.modalItemText}>{item.label}</Text>
                    {isSelected && (
                      <MaterialIcons name="check" size={24} color="white" style={styles.modalItemIcon}/>
                    )}
                  </Pressable>
                );
              })}
              <View style={styles.customFilterOption}>
                <Text style={styles.modalItemText}>Custom</Text>
                <Pressable onPressIn={(event: any) => event.stopPropagation()} style={{height: 35, marginLeft: 6}}>
                  <TextInput
                    style={styles.customFilterInput}
                    onChangeText={onCustomFilterChange}
                    onSubmitEditing={onCustomFilterSubmit}
                    placeholder="Enter any value"
                    placeholderTextColor="#CCC"
                  />
                </Pressable>
                <Pressable
                  style={({pressed}) => [
                    styles.submitButton,
                    pressed && styles.submitButtonPressed,
                  ]}
                  onPress={onCustomFilterSubmit}
                >
                  <MaterialIcons name="send" size={20} color="#CCC"/>
                </Pressable>
              </View>
            </View>
          </Pressable>
        </Pressable>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  sortByContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sortByButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#3c3c3c',
    paddingLeft: 10,
    paddingRight: 2,
    paddingVertical: screenWidth < 768 ? 4 : 8,
    borderRadius: 4,
    width: screenWidth < 768 ? 140 : 200,
  },
  sortByLabelText: {
    marginRight: 8,
    fontSize: screenWidth < 768 ? 14 : 16,
    color: '#fff',
    fontWeight: 'bold',
  },
  sortBySelectedText: {
    fontSize: screenWidth < 768 ? 15 : 18,
    color: '#fff',
  },
  sortByArrow: {
    color: '#fff',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    backgroundColor: '#202124',
    borderRadius: 10,
    padding: 20,
    width: screenWidth < 768 ? 300 : 400,
  },
  modalTitle: {
    fontSize: 18,
    color: 'white',
    fontWeight: 'bold',
  },
  modalItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  modalItemText: {
    fontSize: 16,
    color: 'white',
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  modalIcon: {
    marginRight: 10,
  },
  modalItemSelected: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  modalItemPressed: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  modalItemIcon: {
    marginLeft: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 2,
  },
  modalItemHovered: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  customFilterOption: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255, 255, 255, 0.2)',
  },
  customFilterInput: {
    flex: 1,
    paddingHorizontal: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 4,
    color: 'white',
    fontSize: 16,
  },
  submitButton: {
    marginLeft: 8,
    padding: 8,
    backgroundColor: '#4c4c4c',
    borderRadius: 4,
  },
  submitButtonPressed: {
    opacity: 0.5,
  },
});

export default CustomSearchFilter;
