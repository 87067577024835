import React, {Key, useState} from 'react';
import {Pressable, Text, View} from 'react-native';
import SimulatedGPTResponse from './SimulatedGPTResponse';

const accentColor = '#DDD';

const PluginCustomPromptExample = () => {
  const [showExample, setShowExample] = useState(false);
  const [key, setKey] = useState<Key>(Math.random());

  const responseText = `As a CEO, facing a financial crisis, there are several steps I would consider to ensure the survival of the company:

1.) Assess the Situation: The first step is to fully understand the extent and nature of the crisis. This involves a thorough financial audit to identify the areas where the company is losing money.

2.) Cost Reduction: Look for areas where expenses can be cut without severely impacting the operation of the business. This could involve downsizing, renegotiating contracts, or cutting back on non-essential expenses.

3.) Revenue Enhancement: Look for ways to increase revenue. This could involve launching new products, entering new markets, or improving sales and marketing efforts.

4.) Debt Restructuring: If the company has a significant amount of debt, it may be necessary to negotiate with creditors to restructure the debt to make it more manageable.

5.) Seeking Investment: It may be necessary to seek additional investment to provide the company with the capital it needs to overcome the crisis.`;

  const handleExampleButtonClick = () => {
    setShowExample(true);
    setKey(Math.random());
  };

  return (
    <View>
      <Text style={{color: accentColor, fontSize: 18, textAlign: 'left'}}>
        With the highPerplexity ChatGPT plugin, you can make ChatGPT take on any role or task. Here's an example showing
        how to make the AI act like a CEO.
      </Text>
      <Text style={{color: accentColor, fontSize: 16, marginTop: 10, marginBottom: 5}}>
        Prompt Title:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5, marginBottom: 10}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Act Like A CEO
        </Text>
      </View>
      <Text style={{color: accentColor, fontSize: 16, marginTop: 10, marginBottom: 5}}>
        Prompt Content:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5, marginBottom: 15}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', marginBottom: 10, fontSize: 15, textAlign: 'left'}}>
          I want you to act as a Chief Executive Officer for a hypothetical company. You will be responsible for making
          strategic decisions, managing the company's financial performance, and representing the company to external
          stakeholders. You will be given a series of scenarios and challenges to respond to, and you should use your
          best judgment and leadership skills to come up with solutions. Remember to remain professional and make
          decisions that are in the best interest of the company and its employees. Your first challenge is: %|REQUEST|%
        </Text>
      </View>
      <Text style={{color: accentColor, marginBottom: 15, fontSize: 18, textAlign: 'left'}}>
        Here's an example of how a user might use this prompt:
      </Text>
      <View style={{backgroundColor: '#555', padding: 10, borderRadius: 5}}>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Use highPerplexity
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Prompt: Act Like A CEO
        </Text>
        <Text style={{color: '#FFF', fontFamily: 'Courier', fontSize: 15, textAlign: 'left'}}>
          Request: The company is facing a financial crisis. What steps would you take to ensure its survival?
        </Text>
      </View>
      <View style={{alignItems: 'center', marginVertical: 10}}>
        <Pressable style={{
          backgroundColor: accentColor,
          padding: 10,
          borderRadius: 5,
          width: 200,
          alignItems: 'center'
        }} onPress={handleExampleButtonClick}>
          <Text style={{color: '#000', fontWeight: 'bold'}}>See Example Result</Text>
        </Pressable>
      </View>
      {showExample && <SimulatedGPTResponse key={key} response={responseText}/>}
    </View>
  );
};

export default PluginCustomPromptExample;
