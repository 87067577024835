import React from 'react';
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native';
import {useAuthentication} from '../utils/hooks/useAuthentication';
import UserStack from './userStack';
import AuthStack from './authStack';
import {CollapseStatusProvider} from "../utils/misc/useCollapseStatus";
import {CollapseStatusPreAuthProvider} from "../utils/misc/useCollapseStatusPreAuth";

export default function RootNavigation() {
  const {user, loading} = useAuthentication();

  if (loading) {
    return (
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          <View style={styles.logoCircle}>
            <Text style={styles.logoText}>hP</Text>
          </View>
        </View>
        <Text style={styles.loadingText}>is loading...</Text>
        <ActivityIndicator size="large" color="#C8BDD9"/>
      </View>
    );
  }

  return user ?
    <CollapseStatusProvider>
      <UserStack/>
    </CollapseStatusProvider>
    :
    <CollapseStatusPreAuthProvider>
      <AuthStack/>
    </CollapseStatusPreAuthProvider>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#202124',
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    color: '#FFF',
    fontSize: 24,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
});
