import React from 'react';
import {View, Text, Pressable, StyleSheet} from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

const CustomPromptInfoButton = ({setCurrentScreen, setHasOnboarded}: any) => {
  return (
    <View style={styles.container}>
      <Pressable
        onPress={() => {
          setCurrentScreen(0);
          setHasOnboarded(false);
        }}
        style={styles.button}
      >
        <MaterialIcons name="slideshow" size={16} color="#FFF" style={styles.icon}/>
        <Text style={styles.text}>
          View Customizable Prompt Information
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  icon: {
    marginRight: 5,
  },
  text: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: 14,
  },
});

export default CustomPromptInfoButton;
