import React, {useState} from 'react';
import {Dimensions, Modal, Platform, Pressable, StyleSheet, Text, View,} from 'react-native';
import {connectSortBy} from 'react-instantsearch-dom';
import {MaterialIcons} from '@expo/vector-icons';

const truncateText = (text: any, maxLength: any) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

const screenWidth = Dimensions.get('window').width;

const CustomSortBy = connectSortBy(({refine, items, currentRefinement}: any) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  const onItemPress = (itemValue: any) => {
    refine(itemValue);
    closeMenu();
  };

  const selectedItem = items.find((item: any) => item.value === currentRefinement);

  return (
    <View style={styles.sortByContainer}>
      <View>
        <Text style={styles.sortByLabelText}>Sort Prompts:</Text>
        <Pressable style={styles.sortByButton} onPress={openMenu}>
          <Text style={styles.sortBySelectedText}>
            {truncateText(selectedItem.label, 12)}
          </Text>
          <MaterialIcons name="arrow-drop-down" size={24} style={styles.sortByArrow}/>
        </Pressable>
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={menuVisible}
        onRequestClose={closeMenu}
      >
        <Pressable style={styles.modalOverlay} onPress={closeMenu}>
          <View style={styles.modalContainer}>
            <Pressable style={styles.closeButton} onPress={closeMenu}>
              <MaterialIcons name="close" size={24} color="white"/>
            </Pressable>
            <View style={styles.modalHeader}>
              <MaterialIcons name="sort" size={24} color="white" style={styles.modalIcon}/>
              <Text style={styles.modalTitle}>Sort Prompts:</Text>
            </View>
            {items.map((item: any) => {
              const isSelected = item.value === currentRefinement;
              return (
                <Pressable
                  key={item.value}
                  onPress={() => onItemPress(item.value)}
                  style={({hovered, pressed}) => [
                    styles.modalItem,
                    isSelected && styles.modalItemSelected,
                    pressed && styles.modalItemPressed,
                    Platform.OS === 'web' && hovered && styles.modalItemHovered,
                  ]}
                >
                  <Text style={styles.modalItemText}>{item.label}</Text>
                  {isSelected && (
                    <MaterialIcons name="check" size={24} color="white" style={styles.modalItemIcon}/>
                  )}
                </Pressable>
              );
            })}
          </View>
        </Pressable>
      </Modal>
    </View>
  );
});

const styles = StyleSheet.create({
  sortByContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sortByButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#3c3c3c',
    paddingLeft: 10,
    paddingRight: 2,
    paddingVertical: screenWidth < 768 ? 4 : 8,
    borderRadius: 4,
    width: screenWidth < 768 ? 140 : 200,
  },
  sortByLabelText: {
    marginRight: 8,
    fontSize: screenWidth < 768 ? 14 : 16,
    color: '#fff',
    fontWeight: 'bold',
  },
  sortBySelectedText: {
    fontSize: screenWidth < 768 ? 15 : 18,
    color: '#fff',
  },
  sortByArrow: {
    color: '#fff',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    backgroundColor: '#202124',
    borderRadius: 10,
    padding: 20,
    width: screenWidth < 768 ? 300 : 400,
  },
  modalTitle: {
    fontSize: 18,
    color: 'white',
    fontWeight: 'bold',
  },
  modalItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  modalItemText: {
    fontSize: 16,
    color: 'white',
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  modalIcon: {
    marginRight: 10,
  },
  modalItemSelected: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  modalItemPressed: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  modalItemIcon: {
    marginLeft: 10,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 2,
  },
  modalItemHovered: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
});

export default CustomSortBy;
