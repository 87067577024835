import React, {useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import CustomPromptInputSection from "./CustomPromptInputSection";
import PromptVariablesSection from "./PromptVariablesSection";

const PromptInputMethodSelector = ({
                                     promptVariables,
                                     setPromptVariables,
                                     setTooltipId,
                                     setTooltipVisible,
                                     customPromptInput,
                                     setCustomPromptInput,
                                     inputMethod,
                                     setInputMethod
                                   }: any) => {

  return (
    <View style={styles.container}>
      <View style={styles.titleRow}>
        <MaterialCommunityIcons name="text" size={30} color="#9575CD"/>
        <Text style={styles.title}>Choose Input Method</Text>
      </View>
      <Text style={styles.explanation}>You can either write your own custom input or use the variables provided by the
        prompt author.</Text>
      <View style={styles.optionsContainer}>
        <Pressable
          style={[styles.option, inputMethod === 'custom' && styles.selectedOption]}
          onPress={() => setInputMethod('custom')}
        >
          <Text style={styles.optionText}>
            Write Custom Input
          </Text>
        </Pressable>
        <Pressable
          style={[styles.option, inputMethod === 'variables' && styles.selectedOption]}
          onPress={() => setInputMethod('variables')}
        >
          <Text style={styles.optionText}>
            Use Variables
          </Text>
        </Pressable>
      </View>
      {inputMethod === 'custom' ? (
        <CustomPromptInputSection
          customPromptInput={customPromptInput}
          setCustomPromptInput={setCustomPromptInput}
          setTooltipId={setTooltipId}
          setTooltipVisible={setTooltipVisible}
        />
      ) : (
        <PromptVariablesSection
          promptVariables={promptVariables}
          setPromptVariables={setPromptVariables}
          setTooltipId={setTooltipId}
          setTooltipVisible={setTooltipVisible}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  title: {
    marginLeft: 8,
    fontSize: 24,
    color: '#9575CD',
    fontWeight: 'bold',
  },
  explanation: {
    fontStyle: 'italic',
    color: '#ddd',
    textAlign: 'center',
    marginBottom: 10,
  },
  optionsContainer: {
    flexDirection: 'row',
    marginBottom: 15,
    borderRadius: 20,
    overflow: 'hidden',
    elevation: 5,
    shadowOffset: {width: 1, height: 1},
    shadowColor: '#333',
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },
  option: {
    flex: 1,
    padding: 10,
    backgroundColor: '#333',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 150,
  },
  selectedOption: {
    backgroundColor: '#9575CD',
  },
  optionText: {
    color: '#fff',
    fontWeight: '500',
  },
});

export default PromptInputMethodSelector;
