import React from 'react';
import {StyleSheet, Text} from 'react-native';
import {View} from "dripsy";
import {FontAwesome} from '@expo/vector-icons';

const PluginHowToUse: React.FC = () => {
  return (
    <View style={styles.sectionContainer} sx={{width: ['95%', '95%', '75%']}}>
      <View style={{flexDirection: 'column', alignItems: 'center'}}>
        <FontAwesome name="cogs" size={40} color="#FFF"/>
        <Text style={styles.sectionTitle}>How to Use</Text>
      </View>
      <Text style={styles.sectionText}>
        The highPerplexity plugin is a tool that allows you to search for and run custom prompts directly inside of
        ChatGPT. It's designed to make it extremely easy to utilize intricate and nuanced prompts that require detail
        and creativity. After installing and enabling the plugin, you can use it like this:
      </Text>
      <View style={styles.listContainer}>
        <Text style={styles.listItem}>1. In your ChatGPT prompt, you will need to provide a highPerplexity prompt name
          and a request to send to that prompt. The prompt name should be the exact name of the prompt on highPerplexity
          and the request should be a specific task or question to send to that prompt.</Text>
        <Text style={styles.listItem}>For example, you could run this directly in ChatGPT:</Text>
        <Text style={styles.codeSnippet}>
          Use highPerplexity{'\n\n'}
          Prompt: Leonardo da Vinci{'\n\n'}
          Request: Tell me about your favorite inventions!
        </Text>
        <Text style={styles.listItem}>2. The highPerplexity plugin will search for a suitable prompt based on your
          input. In this case, it will find the "Leonardo da Vinci" prompt that causes ChatGPT to act and respond like
          Leonardo da Vinci.</Text>
        <Text style={styles.listItem}>3. The plugin then combines the prompt and your request to generate a detailed,
          creative response. In this case, ChatGPT would have the personality of Leonardo da Vinci and would discuss
          information about various inventions.</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionContainer: {
    backgroundColor: '#303030',
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
    alignSelf: 'center',
  },
  sectionTitle: {
    color: '#FFF',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
  listContainer: {
    paddingLeft: 12,
  },
  listItem: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
  codeSnippet: {
    color: '#FFF',
    fontSize: 16,
    backgroundColor: '#404040',
    borderRadius: 5,
    padding: 10,
    fontFamily: 'monospace',
    marginBottom: 10,
  },
});

export default PluginHowToUse;
