import React from 'react';
import {Dimensions, Modal, Pressable, StyleSheet, View} from 'react-native';
import {Text} from "dripsy";
import CheckoutForm from "../User/CheckoutForm";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {Ionicons} from '@expo/vector-icons';

const screenWidth = Dimensions.get('window').width;
const stripePromise = loadStripe('pk_live_51MuUraIkpFzYaQtSvwatqyvO2UEMBFWgo48rlRVowadTy8iZePpBxuz3HhD7FjkRk8eJlgkMyAZJ9mf1lQDPWPAZ003GuAe1mm');

interface PaymentModalProps {
  selectedProduct: any;
  setSelectedProduct: (product: any) => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({selectedProduct, setSelectedProduct}) => {
  return (
    <Elements stripe={stripePromise}>
      {selectedProduct && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={selectedProduct !== null}
        >
          <View style={styles.modalView}>
            <View style={styles.modalContent}>
              <Pressable
                style={styles.closeModalButton}
                onPress={() => setSelectedProduct(null)}
              >
                <Ionicons name="close" size={24} color="#CCC"/>
              </Pressable>
              <Text sx={{
                color: '#FFF',
                fontSize: 20,
                fontWeight: 'bold',
                textAlign: 'center',
                display: screenWidth < 768 ? 'none' : 'block'
              }}>
                Selected Product:
              </Text>
              <Text style={{
                color: '#FFF',
                fontSize: 22,
                fontWeight: 'bold',
                textAlign: 'center',
                marginVertical: 8,
              }}>
                {//@ts-ignore
                  selectedProduct.name}
              </Text>
              <Text style={{color: '#FFF', fontSize: 18, marginBottom: screenWidth < 768 ? 8 : 12}}>
                Price: ${//@ts-ignore
                (selectedProduct.prices[0].unit_amount / 100).toFixed(2)}
              </Text>
              <View style={{width: '100%'}}>
                <CheckoutForm priceId={selectedProduct.prices[0].id} setSelectedProduct={setSelectedProduct}/>
              </View>
            </View>
          </View>
        </Modal>
      )}
    </Elements>
  );
};

const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalContent: {
    backgroundColor: 'rgb(32, 33, 36)',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    maxWidth: '90%',
    minWidth: screenWidth < 768 ? 300 : 600,
    position: 'relative',
  },
  closeModalButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#424242',
    borderRadius: 50,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeModalText: {
    color: '#FFF',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default PaymentModal;
