import React, {useEffect, useState} from 'react';
import {Pressable, StyleSheet, Text, TextInput} from 'react-native';
import {View} from 'dripsy';
import {connectSearchBox} from 'react-instantsearch-native';

const SearchBox = ({currentRefinement, refine, searchInput, setSearchInput}: any) => {
  const [inputValue, setInputValue] = useState(currentRefinement);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      refine(inputValue);
      setSearchInput(inputValue);
    }, 750);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [inputValue, refine, setSearchInput]);

  useEffect(() => {
    setInputValue(searchInput);
  }, [searchInput]);

  const clearSearch = () => {
    setInputValue('');
    setSearchInput('');
    refine('');
  };

  return (
    <View style={styles.searchContainer}
          sx={{width: ['auto', 'auto', 300]}}
    >
      <TextInput
        style={styles.input}
        onChangeText={setInputValue}
        value={inputValue}
        placeholder="🔍 Search"
        placeholderTextColor="#888"
      />
      {inputValue.length > 0 && (
        <Pressable style={styles.clearButton} onPress={clearSearch}>
          <Text style={styles.clearButtonText}>X</Text>
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    height: 40,
    borderColor: '#888',
    borderWidth: 1,
    borderRadius: 8,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#333',
    color: '#FFF',
    flex: 1,
  },
  clearButton: {
    marginLeft: -40,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  clearButtonText: {
    color: '#FFF',
    fontSize: 16,
  },
});

export default connectSearchBox(SearchBox);
