import React, {useState} from 'react';
import {ActivityIndicator, Pressable, StyleSheet, Text, TextInput} from 'react-native';
import {View} from "dripsy";
import {ReplyList} from './ReplyList';
import {MaterialIcons} from '@expo/vector-icons';

interface CommentProps {
  index: number;
  comment: any;
  childComments: any[];
  hasBeenUpvoted: (comment: any) => boolean;
  upvoteComment: (comment: any, action: 'add' | 'remove') => void;
  leaveCommentReply: (parentCommentId: string, commentText: string) => Promise<void>;
  reportComment: (comment: any) => Promise<void>;
  preAuth: boolean;
}

export const Comment: React.FC<CommentProps> = ({
                                                  index,
                                                  comment,
                                                  childComments,
                                                  hasBeenUpvoted,
                                                  upvoteComment,
                                                  leaveCommentReply,
                                                  reportComment,
                                                  preAuth
                                                }) => {
  const [showReplies, setShowReplies] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toggleReplies = () => setShowReplies(!showReplies);
  const toggleReplyInput = () => setShowReplyInput(!showReplyInput);

  const handleUpvote = async () => {
    setIsLoading(true);
    await upvoteComment(comment, hasBeenUpvoted(comment) ? 'remove' : 'add');
    setIsLoading(false);
  };

  const handleReport = async () => {
    await reportComment(comment.commentId);
  };

  const handleReplySubmit = async () => {
    await leaveCommentReply(comment.commentId, replyText);
    setReplyText('');
    setShowReplyInput(false);
  };

  return (
    <View style={styles.commentContainer} sx={{width: [300, 400, 500]}}>
      <View style={styles.commentHeader}>
        <Text style={styles.commentNumber}>Comment {index + 1}:</Text>
        <Text style={styles.commentAuthor}>By: {comment.userName}</Text>
      </View>
      <Text style={styles.commentContent}>{comment.content}</Text>
      <View>
        <View style={styles.commentFooter}>
          {isLoading ? (
            <ActivityIndicator size="small" color="#FFF"/>
          ) : (
            <Pressable
              onPress={handleUpvote}
              style={[
                styles.voteButton,
                {
                  borderColor: hasBeenUpvoted(comment) ? '#449e48' : '#FFF',
                },
              ]}
            >
              <MaterialIcons
                name="thumb-up"
                size={20}
                color={hasBeenUpvoted(comment) ? '#449e48' : '#FFF'}
              />
              <Text style={styles.voteCount}>{comment?.commentUpvotes?.length || 0}</Text>
            </Pressable>
          )}
          {!preAuth &&
            <Pressable style={styles.replyButton} onPress={toggleReplyInput}>
              <Text style={styles.replyButtonText}>Reply</Text>
            </Pressable>
          }
          <Pressable style={styles.showRepliesButton} onPress={() => {
            if (childComments.length > 0) {
              toggleReplies();
            }
          }}>
            <Text style={styles.showRepliesButtonText}>
              {childComments.length} Replies
            </Text>
          </Pressable>
          {!preAuth &&
            <Pressable style={styles.reportButton} onPress={handleReport}>
              <Text style={styles.reportButtonText}>Report</Text>
            </Pressable>
          }
        </View>
        {showReplyInput && (
          <View style={styles.replyInputContainer}>
            <TextInput
              style={styles.replyInput}
              value={replyText}
              onChangeText={setReplyText}
              placeholder="Write your reply..."
              placeholderTextColor="#999"
              multiline={true}
            />
            <Pressable style={styles.submitReplyButton} onPress={handleReplySubmit}>
              <Text style={styles.submitReplyButtonText}>Submit</Text>
            </Pressable>
          </View>
        )}
        {showReplies && (
          <ReplyList
            replies={childComments}
            upvoteComment={upvoteComment}
            hasBeenUpvoted={hasBeenUpvoted}
            reportComment={reportComment}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  commentContainer: {
    borderWidth: 1,
    borderColor: 'transparent',
    padding: 16,
    borderRadius: 10,
    marginBottom: 20,
    backgroundColor: '#2c2c2e',
  },
  commentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  commentNumber: {
    color: '#FFF',
    fontWeight: 'bold',
  },
  commentAuthor: {
    color: '#FFF',
  },
  commentContent: {
    backgroundColor: '#39393b',
    borderRadius: 10,
    color: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  voteCount: {
    color: '#FFF',
    marginLeft: 5,
  },
  reportButton: {
    backgroundColor: '#4b4b4c',
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderRadius: 5,
  },
  reportButtonText: {
    color: '#FFF',
  },
  commentFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 300,
  },
  voteButton: {
    marginRight: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  replyButton: {
    backgroundColor: '#4b4b4c',
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderRadius: 5,
    marginRight: 4,
  },
  replyButtonText: {
    color: '#FFF',
  },
  showRepliesButton: {
    backgroundColor: '#4b4b4c',
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderRadius: 5,
    marginRight: 4,
  },
  showRepliesButtonText: {
    color: '#FFF',
  },
  replyInputContainer: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  replyInput: {
    flex: 1,
    backgroundColor: '#4b4b4c',
    color: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    marginRight: 10,
  },
  submitReplyButton: {
    backgroundColor: '#4b4b4c',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  },
  submitReplyButtonText: {
    color: '#FFF',
  },
});
