import React, {useState} from 'react';
import {View, Text, Button} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {useSx} from "dripsy";
import {addDoc, arrayUnion, collection, doc, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import {getAuth} from "firebase/auth";

const PromptAIResponse = ({
                      GPTStream,
                      fetchingGPT,
                      isStartConvoActive,
                      isResponseSaved,
                      setIsResponseSaved,
                      promptContent,
                      fullUserDoc,
                      promptDocId,
                      promptId,
                      GPTMessage,
                      matchingPrompt,
                      promptImage,
                      userDoc,
                    }: any) => {
  const [GPTResponse, setGPTResponse] = useState([]);
  const navigation = useNavigation();
  const customSX = useSx();
  const {user} = useAuthentication();
  const auth = getAuth();

  const cancelActive = false;
  const isSaveResponseActive = false;

  const createConversation = async (responseData: any) => {
    if (true) return;
    const savedResponseRef = await addDoc(collection(db, "conversations"), {
      conversationHistory: [
        {role: 'user', content: promptContent},
        {role: 'assistant', content: responseData},
      ],
      createdBy: user?.uid,
    });

    return savedResponseRef.id;
  };

  const saveResponse = async () => {
    if (isResponseSaved) return;
    setIsResponseSaved(true);
    const timestamp = new Date().getTime();

    const responseData = {
      // @ts-ignore
      userEmail: fullUserDoc?.email,
      savedById: auth?.currentUser?.uid,
      promptDocId: promptDocId,
      promptId: promptId,
      promptContent: promptContent,
      timestamp: timestamp,
      responseContent: GPTMessage,
      // @ts-ignore
      promptName: matchingPrompt?.title,
      promptImage: promptImage,
      deleted: false,
    };
    const savedResponseRef = await addDoc(collection(db, "savedResponses"), responseData);

    await updateDoc(doc(db, "users", userDoc), {
      savedResponses: arrayUnion(savedResponseRef.id)
    });
  };

  return (
    <>
      {GPTStream && (
        <View
          style={[
            {
              marginBottom: 12,
              borderWidth: 1,
              borderColor: '#FFF',
              borderRadius: 10,
              padding: 10,
            },
            customSX({width: [300, 450, 600]}),
          ]}
        >
          <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 8}}>
            {!fetchingGPT ? 'AI Response' : 'Waiting on AI Response'}
          </Text>
          <Text style={{color: '#FFF'}}>{GPTStream}</Text>
        </View>
      )}
      {cancelActive && (
        <View style={{marginBottom: 10}}>
          <Button title="Cancel This Response" onPress={() => setGPTResponse([])}/>
        </View>
      )}
      {(!cancelActive && isStartConvoActive) && (
        <View style={{marginBottom: 15}}>
          <Button
            title="Create Conversation From Response"
            onPress={async () => {
              const convoId = await createConversation(GPTMessage);
              //@ts-ignore
              navigation.navigate('GPTConversation', {convoId});
            }}
          />
        </View>
      )}
      {(!cancelActive && isSaveResponseActive) && (
        <View style={{marginBottom: 5}}>
          {isResponseSaved ? (
            <Button disabled={true} title="Response Already Saved" onPress={() => {
            }}/>
          ) : (
            <Button title="Save This Response" onPress={saveResponse}/>
          )}
        </View>
      )}
    </>
  );
};

export default PromptAIResponse;
