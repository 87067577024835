import React from 'react';
import {StyleSheet, Text} from 'react-native';
import {View} from "dripsy";
import {FontAwesome} from '@expo/vector-icons';
import PluginAccordion from "./PluginAccordion";
import CharacterCreationExample from "./CharacterCreationExample";
import PluginChainingExample from "./PluginChainingExample";
import PluginCustomPromptExample from "./PluginCustomPromptExample";

const accentColor = '#DDD';

const PluginExamples: React.FC = () => {
  return (
    <View style={styles.sectionContainer} sx={{width: ['95%', '95%', '75%']}}>
      <View style={{flexDirection: 'column', alignItems: 'center'}}>
        <FontAwesome name="lightbulb-o" size={40} color="#FFF"/>
        <Text style={styles.sectionTitle}>Examples and Concepts</Text>
      </View>
      <PluginAccordion
        title="Creating Custom Characters"
        icon="user"
        //@ts-ignore
        content={<CharacterCreationExample/>}
      />
      <PluginAccordion
        title="Plugin Chaining Prompts"
        icon="chain"
        //@ts-ignore
        content={<PluginChainingExample/>}
      />
      <PluginAccordion
        title="Custom Roles and Tasks"
        icon="magic"
        //@ts-ignore
        content={<PluginCustomPromptExample/>}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  sectionContainer: {
    backgroundColor: '#303030',
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
    alignSelf: 'center',
  },
  sectionTitle: {
    color: '#FFF',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
});

export default PluginExamples;
