import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Dimensions, Platform, RefreshControl, SafeAreaView, ScrollView} from 'react-native';
import {Pressable, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {getAuth} from 'firebase/auth';
import {useFocusEffect} from '@react-navigation/native';
import {Configure, Index, InstantSearch,} from 'react-instantsearch-native';
import algoliasearch from 'algoliasearch';
import {db} from "../../config/firebase";
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import HowItWorksSection from "../../components/HowItWorksSection";
import CustomSortBy from "../../components/CustomSortBy";
import CustomSearchFilter from "../../components/CustomSearchFilter";
import CustomInfiniteHits from "../../components/CustomInfiniteHits";
import LoadingScreen from "../../components/User/LoadingScreen";
import HomeMobileOnboarding from "../../components/HomeMobileOnboarding";
import HomeDesktopOnboarding from "../../components/HomeDesktopOnboarding";
import ScrollToTop from "../../components/ScrollToTop";

const algoliaClient = algoliasearch('DSWB3NXLUY', '959f1b50ecd093c1de513294bd97b4bf');

const screenWidth = Dimensions.get('window').width;

const HighPerplexityHome: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);
  const [tokensCreated, setTokensCreated] = useState(null);
  const [userDoc, setUserDoc] = useState();
  const [fullUserDoc, setFullUserDoc] = useState();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);
  const [hasOnboarded, setHasOnboarded] = useState(true);
  const [updateToggle, setUpdateToggle] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const scrollRef = useRef(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const CustomConfigure = ({searchQuery}: any) => <Configure query={searchQuery}/>;

  const toggleOnboardingStatus = async () => {
    try {
      // @ts-ignore
      const userRef = doc(db, "users", userDoc);
      await updateDoc(userRef, {homeScreenOnboardedStatus: true});
    } catch (error) {
      console.error("Error updating onboarded status: ", error);
    }
    setModalVisible(false);
    setHasOnboarded(true);
  }

  const scrollToTop = () => {
    if (Platform.OS === 'web') {
      window.scrollTo({top: 0, behavior: 'smooth'});
    } else {
      //@ts-ignore
      scrollRef.current.scrollTo({x: 0, y: 0, animated: true});
    }
  };

  const handleScroll = (event: any) => {
    const yOffset = Platform.OS === 'web' ? event.nativeEvent.contentOffset.y : event.nativeEvent.contentOffset.y;
    setShowScrollToTop(yOffset > 100);
  };

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  useEffect(() => {
    const handleWebScroll = () => {
      if (Platform.OS === 'web') {
        const yOffset = window.scrollY;
        setShowScrollToTop(yOffset > 100);
      }
    };

    if (Platform.OS === 'web') {
      window.addEventListener('scroll', handleWebScroll);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('scroll', handleWebScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      setModalVisible(false);
    } else {
      if (hasOnboarded) {
        setModalVisible(false);
      } else {
        setModalVisible(true);
      }
    }
  }, [hasOnboarded]);

  useEffect(() => {
    if (tokensCreated === null) return;
    (async () => {
      if (!tokensCreated) {
        const authTokenValue = await auth?.currentUser?.getIdTokenResult();
        // @ts-ignore
        const userRef = doc(db, "users", userDoc);
        // @ts-ignore
        await updateDoc(userRef, {currentJWT: authTokenValue.token});

        const response = await fetch("https://highperplexity-token-manager-mjp27ovqaq-uc.a.run.app", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "firebase_token_value": authTokenValue?.token,
          }),
        });

        await response.json();
      }
    })();
  }, [tokensCreated]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const userSnap = await getDocs(collection(db, "users"));
        const matchingDoc = userSnap.docs.find(doc => auth?.currentUser?.uid === doc.data().uid);

        if (matchingDoc) {
          // @ts-ignore
          setUserDoc(matchingDoc?.id);
          // @ts-ignore
          setFullUserDoc(matchingDoc?.data());
          if (matchingDoc?.data()?.homeScreenOnboardedStatus === true) {
            setHasOnboarded(true);
          } else {
            setHasOnboarded(false);
          }
          // @ts-ignore
          if (matchingDoc?.data()?.tokenAdmin) {
            // @ts-ignore
            setTokensCreated(true);
          } else {
            if (matchingDoc?.data()?.tokenAdmin === 0) {
              // @ts-ignore
              setTokensCreated(true);
            } else {
              // @ts-ignore
              setTokensCreated(false);
            }
          }
        }

        setUpdateToggle(!updateToggle);
        setLoading(false);
      })()
    }, [])
  );

  if (loading || !fullUserDoc) {
    return <LoadingScreen navStatus={navStatus}
                          setNavStatus={setNavStatus}
                          navigation={navigation}
                          closeDrawerMenu={closeDrawerMenu}/>;
  }

  if (!hasOnboarded) {
    if (Platform.OS !== 'web') {
      return <HomeMobileOnboarding toggleOnboardingStatus={toggleOnboardingStatus}/>;
    }
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <InstantSearch
        indexName="highPerplexity"
        searchClient={algoliaClient}
      >
        <Index indexName="highPerplexity">
          <View style={{flex: 1}}>
            <ScrollView
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>}
              ref={scrollRef}
              onScroll={handleScroll}
              scrollEventThrottle={16}
            >
              <Pressable
                style={{flex: 1, alignItems: 'center'}}
                sx={{minHeight: [vh(100)], cursor: 'default'}}
                onPress={closeDrawerMenu}
              >
                <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
                <HomeDesktopOnboarding
                  currentScreen={currentScreen}
                  setCurrentScreen={setCurrentScreen}
                  modalVisible={modalVisible}
                  setModalVisible={setModalVisible}
                  toggleOnboardingStatus={toggleOnboardingStatus}
                />
                <HowItWorksSection setCurrentScreen={setCurrentScreen} setHasOnboarded={setHasOnboarded}/>
                <View style={{flexDirection: 'row', marginBottom: 10}}>
                  <View style={{marginRight: screenWidth < 768 ? 10 : 20}}>
                    <CustomSortBy
                      defaultRefinement="highPerplexity_popular"
                      items={[
                        {value: 'highPerplexity_popular', label: 'Most Popular'},
                        {value: 'highPerplexity', label: 'Most Relevant (To Filter)'},
                        {value: 'highPerplexity_timestamp_desc', label: 'Newest'},
                        {value: 'highPerplexity_timestamp_asc', label: 'Oldest'},
                      ]}
                    />
                  </View>
                  <View>
                    <CustomSearchFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
                  </View>
                </View>
                <CustomConfigure searchQuery={searchQuery}/>
                {//@ts-ignore
                  <CustomInfiniteHits searchQuery={searchQuery} navigation={navigation}/>}
              </Pressable>
            </ScrollView>
          </View>
        </Index>
      </InstantSearch>
      <ScrollToTop showScrollToTop={showScrollToTop} scrollToTop={scrollToTop}/>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

export default HighPerplexityHome;
