import {useEffect, useState} from 'react';
import {getAuth, onAuthStateChanged, User} from 'firebase/auth';

export function useAuthentication() {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(undefined);
      }
      setLoading(false);
    });
  }, []);

  return {user, loading};
}
