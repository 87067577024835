import {useEffect} from 'react';
import {Platform} from 'react-native';

const useAdSenseScript = (navigation: any) => {
  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    const runScript = () => {
      const existingScript = document.querySelector('#adsense-script');
      if (existingScript) {
        return;
      }

      const script = document.createElement('script');
      script.id = 'adsense-script';
      script.async = true;
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2101222156599423';
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    };

    runScript();

    const unsubscribe = navigation.addListener('focus', runScript);

    return () => {
      navigation.removeListener('focus', runScript);
    };
  }, [navigation]);
};

export default useAdSenseScript;
