import React, {useState} from 'react';
import {TextInput, View} from 'react-native';
import {HelperText} from 'react-native-paper';

const CustomTextInput = ({
                           label,
                           value,
                           onChangeText,
                           editable = true,
                           placeholder = '',
                           inputType = '',
                         }: any) => {
  let initialHeight = 40;
  if (inputType === 'variable' || inputType === 'customPrompt') initialHeight = 80;
  if (inputType === 'content' || inputType === 'description') initialHeight = 120;

  const multipleLines = (inputType === 'content' || inputType === 'description' || inputType === 'variable' || inputType === 'customPrompt');

  const [inputHeight, setInputHeight] = useState(initialHeight);

  const handleContentSizeChange = (event: any) => {
    const newHeight = Math.max(initialHeight, event.nativeEvent.contentSize.height + 10);
    if (Math.abs(inputHeight - newHeight) > 10) {
      setInputHeight(newHeight);
    }
  };

  const inputStyle = {
    width: '100%',
    minHeight: inputHeight,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#666',
    fontSize: 15,
    lineHeight: 24,
    backgroundColor: 'transparent',
    color: '#fff',
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowOffset: {width: 0, height: 1},
    textShadowRadius: 2,
  };

  return (
    <View style={{marginBottom: 12}}>
      {!!label && (
        <View>
          <HelperText type="info" visible={true} style={{color: '#ccc'}}>
            {label}
          </HelperText>
        </View>
      )}
      <TextInput
        value={value}
        style={inputStyle}
        editable={editable}
        multiline={multipleLines}
        onChangeText={onChangeText}
        onContentSizeChange={handleContentSizeChange}
        placeholder={placeholder}
        placeholderTextColor="rgba(255, 255, 255, 0.6)"
      />
    </View>
  );
};

export default CustomTextInput;
