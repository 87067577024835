import React, {FC, useCallback, useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Linking,
  Platform,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TextInput
} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {StackScreenProps} from "@react-navigation/stack";
import {getAuth} from 'firebase/auth';
import {collection, doc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../config/firebase";
import useUnits from "rxn-units";
import {Entypo, Feather, FontAwesome} from '@expo/vector-icons';
import {LinearGradient} from 'expo-linear-gradient';
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import EarnTokens from "../../components/User/EarnTokens";

const SettingsScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const auth = getAuth();
  const [refreshing, setRefreshing] = useState(false);
  const [trophyCount, setTrophyCount] = useState();
  const [accessLevel, setAccessLevel] = useState();
  const [loading, setLoading] = useState(false);
  const [expirationDays, setExpirationDays] = useState(null);
  const [openAIKey, setOpenAIKey] = useState(null);
  const [gpt3Available, setGpt3Available] = useState(null);
  const [imagesAvailable, setImagesAvailable] = useState(null);
  const [gpt4Available, setGpt4Available] = useState(null);
  const [apiKeyEntered, setApiKeyEntered] = useState(false);
  const {user} = useAuthentication();
  const [userDoc, setUserDoc] = useState('');
  const [keyTestLoading, setKeyTestLoading] = useState(false);
  const [clearingKey, setClearingKey] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const handleOpenAILinkClick = () => {
    if (Platform.OS == 'web') {
      window.open('https://platform.openai.com/account/api-keys', '_blank');
    } else {
      Linking.openURL('https://platform.openai.com/account/api-keys');
    }
  }

  useEffect(() => {
    if (!user) return;

    (async () => {
      const userQuery = query(collection(db, "users"), where("uid", "==", user.uid));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        setUserDoc(doc.id);

        setGpt3Available(doc.data().gpt3UserAPI ?? false);
        setImagesAvailable(doc.data().imagesUserAPI ?? false);
        setGpt4Available(doc.data().gpt4UserAPI ?? false);
        setApiKeyEntered(doc.data().UserProvidedAPI ?? false);
        //@ts-ignore
        setOpenAIKey(doc.data().UserProvidedAPI ? true : null);
      } else {
        setOpenAIKey(null);
        setGpt3Available(null);
        setImagesAvailable(null);
        setGpt4Available(null);
        setApiKeyEntered(false);
      }
    })();
  }, [user]);

  useEffect(() => {
    if (!openAIKey || openAIKey === true) return;
    (async () => {
      await testAPIKeyAccess();
    })();
  }, [openAIKey]);

  const handleOpenAIKeySubmit = (text: any) => {
    setOpenAIKey(text);
    setApiKeyEntered(true);
  };

  const handleOpenAIKeyClear = async () => {
    setClearingKey(true);
    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      clear: true,
    };

    try {
      const response = await fetch(
        "https://highperplexity-test-api-key-middleware-mjp27ovqaq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      await response.json();

      setOpenAIKey(null);
      setGpt3Available(null);
      setImagesAvailable(null);
      setGpt4Available(null);
      setApiKeyEntered(false);
    } catch (error) {
      console.error("Error clearing API key:", error);
    }
    setClearingKey(false);
  };

  const testAPIKeyAccess = async () => {
    setKeyTestLoading(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      api_key: openAIKey,
    };

    try {
      const response = await fetch('https://highperplexity-test-api-key-middleware-mjp27ovqaq-uc.a.run.app', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      //@ts-ignore
      setGpt3Available(data?.gpt3Available);
      //@ts-ignore
      setImagesAvailable(data?.imagesAvailable);
      //@ts-ignore
      setGpt4Available(data?.gpt4Available);
    } catch (error) {
      console.error("Error while testing API key:", error);
    } finally {
      setKeyTestLoading(false);
    }
  };

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === auth.currentUser?.uid) {
          setTrophyCount(doc.data().trophyCount || 0);
          //@ts-ignore
          setAccessLevel(doc.data().accessLevel ? `highPerplexity ${doc.data().accessLevel.charAt(0).toUpperCase() + doc.data().accessLevel.slice(1)}` : 'highPerplexity Basic');

          if (doc.data().accessLevelExpiration) {
            const currentDate = new Date();
            const expDate = new Date(doc.data().accessLevelExpiration);
            //@ts-ignore
            const diffInMilliseconds = expDate - currentDate;
            //@ts-ignore
            setExpirationDays(Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)));
          } else {
            setExpirationDays(null);
          }
        }
      });
      setLoading(false);
    };
    fetchUserData();
  }, []);

  if (loading || !accessLevel || !userDoc) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>is loading...</Text>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <View style={styles.contentContainer}>
            <View style={{marginBottom: 10}}>
              <Text style={{color: '#FFF', fontSize: 30}}>
                Your Settings
              </Text>
            </View>
            <View style={{maxWidth: 500, paddingHorizontal: 20}}>
              <View style={styles.userInfoContainer}>
                <View style={styles.userInfoItem}>
                  <FontAwesome name="key" size={25} color="#1E90FF" style={styles.userInfoIcon}/>
                  <View>
                    <Text style={styles.userInfoText}>
                      Account: {accessLevel}
                    </Text>
                    {(expirationDays !== null) &&
                      <Text style={{color: '#FFF', fontSize: 15}}>
                        Expires in {expirationDays} days
                      </Text>
                    }
                  </View>
                </View>
                {accessLevel === 'highPerplexity Basic' && (
                  <Pressable
                    onPress={() => navigation.navigate('Store')}
                    style={({pressed}) => [
                      styles.upgradeButton,
                      {backgroundColor: pressed ? '#4a4a4a' : '#3c3c3c'},
                    ]}
                  >
                    <FontAwesome name="arrow-up" size={18} color="#1E90FF"/>
                    <Text style={styles.upgradeLinkText}>Upgrade for more features</Text>
                  </Pressable>
                )}
                <View style={styles.userInfoItem}>
                  <FontAwesome name="trophy" size={25} color="#FFD700" style={styles.userInfoIcon}/>
                  <Text style={{fontSize: 18, color: '#FFF'}}>
                    Trophies Owned: {trophyCount}
                  </Text>
                </View>
              </View>
              <View style={styles.apiKeyContainer}>
                <View style={styles.apiKeyHeader}>
                  <Feather name="key" size={25} color="#32CD32" style={styles.userInfoIcon}/>
                  <Text style={styles.apiKeyHeaderText}>Use Your Own OpenAI Key</Text>
                </View>
                {!apiKeyEntered && (
                  <Text style={styles.apiKeyInfoText}>
                    Unlock 0 token cost for all response types available to your OpenAI key (GPT-3, Images, and GPT-4).
                  </Text>
                )}
                {openAIKey === null ? (
                  <>
                    <View style={styles.apiKeyInputContainer}>
                      <TextInput
                        style={styles.apiKeyInput}
                        placeholder="Paste your OpenAI Key"
                        placeholderTextColor="#B3B3B3"
                        onChangeText={(text) => handleOpenAIKeySubmit(text)}
                      />
                    </View>
                    <Pressable onPress={handleOpenAILinkClick} style={({pressed}) => [
                      styles.openAILink,
                      {backgroundColor: pressed ? '#6495ED33' : '#6495ED11'},
                    ]}>
                      <Feather name="external-link" size={18} color="#6495ED" style={styles.openAILinkIcon}/>
                      <Text style={styles.openAILinkText}>
                        Don't have an OpenAI key? Create one for free at OpenAI!
                      </Text>
                    </Pressable>
                  </>
                ) : (
                  <View style={styles.apiKeySubmittedContainer}>
                    <Text style={styles.apiKeySubmittedText}>
                      API key saved. For security, you won't see be able to it again. To use another key, clear the
                      current one.
                    </Text>
                    <View style={styles.clearButtonContainer}>
                      {(!keyTestLoading && !clearingKey) &&
                        <Pressable
                          style={({pressed}) => [
                            styles.apiKeyClearButton,
                            {backgroundColor: pressed ? '#B22222' : '#DC143C'},
                          ]}
                          onPress={handleOpenAIKeyClear}
                        >
                          <Feather name="x" size={18} color="#FFF"/>
                          <Text style={styles.apiKeyClearButtonText}>Clear OpenAI Key</Text>
                        </Pressable>
                      }
                    </View>
                    <Text style={styles.apiKeyTestExplanation}>
                      highPerplexity automatically configures your OpenAI key to unlock 0 token cost on all features
                      available to your key. If you have recently unlocked new features through OpenAI (such as GPT-4
                      access), clear the existing key and enter a new one.
                    </Text>
                    {(keyTestLoading || clearingKey) &&
                      <View style={styles.loadingContainer}>
                        <ActivityIndicator size="small" color="#FFF"/>
                        <Text style={styles.keyLoadingText}>
                          {clearingKey ? "Clearing OpenAI Key..." : "Syncing Your OpenAI Key..."}
                        </Text>
                      </View>
                    }
                    <View style={styles.apiKeyAccessIndicators}>
                      <View style={styles.apiKeyAccessIndicatorItem}>
                        <Feather name="edit" size={25}
                                 color={gpt3Available === null ? "#888" : gpt3Available ? "#32CD32" : "#B22222"}/>
                        <Text
                          style={[
                            styles.apiKeyAccessIndicatorLabel,
                            {color: gpt3Available === null ? "#888" : gpt3Available ? "#32CD32" : "#B22222"},
                          ]}
                        >
                          GPT-3
                        </Text>
                        {(keyTestLoading || clearingKey) ? (
                          <ActivityIndicator size="small" color="#FFF" style={{marginTop: 4}}/>
                        ) : gpt3Available === null ? (
                          <Text
                            style={[
                              styles.apiKeyAccessIndicatorLabel,
                              {color: "#888"},
                            ]}
                          >
                            (Not Tested)
                          </Text>
                        ) : (
                          <Feather
                            name={gpt3Available ? "check" : "x"}
                            size={18}
                            color={gpt3Available ? "#32CD32" : "#B22222"}
                          />
                        )}
                      </View>
                      <View style={styles.apiKeyAccessIndicatorItem}>
                        <Feather
                          name="image"
                          size={25}
                          color={imagesAvailable === null ? "#888" : imagesAvailable ? "#32CD32" : "#B22222"}
                        />
                        <Text
                          style={[
                            styles.apiKeyAccessIndicatorLabel,
                            {color: imagesAvailable === null ? "#888" : imagesAvailable ? "#32CD32" : "#B22222"},
                          ]}
                        >
                          Images
                        </Text>
                        {(keyTestLoading || clearingKey) ? (
                          <ActivityIndicator size="small" color="#FFF" style={{marginTop: 4}}/>
                        ) : imagesAvailable === null ? (
                          <Text
                            style={[
                              styles.apiKeyAccessIndicatorLabel,
                              {color: "#888"},
                            ]}
                          >
                            (Not Tested)
                          </Text>
                        ) : (
                          <Feather
                            name={imagesAvailable ? "check" : "x"}
                            size={18}
                            color={imagesAvailable ? "#32CD32" : "#B22222"}
                          />
                        )}
                      </View>
                      <View style={styles.apiKeyAccessIndicatorItem}>
                        <Feather
                          name="activity"
                          size={25}
                          color={gpt4Available === null ? "#888" : gpt4Available ? "#32CD32" : "#B22222"}
                        />
                        <Text
                          style={[
                            styles.apiKeyAccessIndicatorLabel,
                            {color: gpt4Available === null ? "#888" : gpt4Available ? "#32CD32" : "#B22222"},
                          ]}
                        >
                          GPT-4
                        </Text>
                        {(keyTestLoading || clearingKey) ? (
                          <ActivityIndicator size="small" color="#FFF" style={{marginTop: 4}}/>
                        ) : gpt4Available === null ? (
                          <Text
                            style={[
                              styles.apiKeyAccessIndicatorLabel,
                              {color: "#888"},
                            ]}
                          >
                            (Not Tested)
                          </Text>
                        ) : (
                          <Feather
                            name={gpt4Available ? "check" : "x"}
                            size={18}
                            color={gpt4Available ? "#32CD32" : "#B22222"}
                          />
                        )}
                      </View>
                    </View>
                  </View>
                )}
              </View>
              {/*<EarnTokens/>*/}
              <View style={styles.buttonContainer}>
                <View style={styles.buttonWrapper}>
                  <Pressable
                    onPress={() =>
                      window.open(
                        'https://apps.apple.com/us/app/highperplexity-custom-prompts/id6445970944',
                        '_blank'
                      )
                    }
                  >
                    <LinearGradient colors={['#4a4a4a', '#3c3c3c', '#2c2c2c']} style={styles.button}>
                      <FontAwesome name="apple" size={25} color="#FFF"/>
                      <Text style={styles.buttonText}>Available on the App Store</Text>
                    </LinearGradient>
                  </Pressable>
                </View>
                <View style={styles.buttonWrapper}>
                  <Pressable
                    onPress={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.highPerplexity.highPerplexity',
                        '_blank'
                      )
                    }
                  >
                    <LinearGradient colors={['#4a4a4a', '#3c3c3c', '#2c2c2c']} style={styles.button}>
                      <Entypo name="google-play" size={25} color="#FFF"/>
                      <Text style={styles.buttonText}>Available on Google Play</Text>
                    </LinearGradient>
                  </Pressable>
                </View>
              </View>
              <View style={{marginTop: 10, marginHorizontal: 'auto'}}>
                <Text style={{color: '#FFF', fontSize: 16, marginBottom: 20, textAlign: 'left'}}>
                  highPerplexity user settings are still in development. However, to comply with various rules and
                  regulations, here is a button that will delete your highPerplexity account:
                </Text>
              </View>
              <View style={{alignItems: 'center', marginHorizontal: 'auto'}}>
                <Pressable
                  onPress={() => setShowDeleteConfirm(true)}
                  style={({pressed}) => [
                    {
                      backgroundColor: pressed ? '#B22222' : '#DC143C',
                      borderColor: '#FFF',
                      borderWidth: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 12,
                      paddingVertical: 7,
                      paddingHorizontal: 9,
                      minHeight: 50,
                      marginBottom: 15,
                    },
                  ]}
                >
                  <FontAwesome name="trash" size={25} color="#FFF"/>
                  <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 10, color: '#FFF'}}>
                    Delete Account
                  </Text>
                </Pressable>
              </View>
              {showDeleteConfirm && <View style={{marginTop: 10, marginHorizontal: 'auto'}}>
                <View style={{marginBottom: 20}}>
                  <Text style={{color: '#FFF', fontSize: 16, textAlign: 'center'}}>
                    Are you sure you want to delete your account?
                  </Text>
                </View>
                <View style={{width: '100%', alignItems: 'center', marginBottom: 20}}>
                  <Pressable style={({pressed}) => [{
                    backgroundColor: pressed ? '#B22222' : '#DC143C',
                    borderColor: '#FFF',
                    borderWidth: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 12,
                    paddingVertical: 7,
                    paddingHorizontal: 9,
                    minHeight: 50,
                  },]}
                             onPress={() => {
                               auth?.currentUser?.delete()
                                 .then(() => console.log('deleted highPerplexity account!'))
                                 .catch(error => console.log(error));
                             }}>
                    <Text style={{color: '#FFF', fontWeight: 'bold', fontSize: 18}}>
                      Yes, delete account
                    </Text>
                  </Pressable>
                </View>
              </View>
              }
            </View>
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#202124',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  buttonContainer: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginTop: 25,
    marginHorizontal: 'auto',
    alignItems: 'center',
  },
  buttonWrapper: {
    paddingHorizontal: 10,
    marginBottom: 15,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    minHeight: 50,
    minWidth: 235,
  },
  buttonText: {
    color: '#FFF',
    marginLeft: 10,
    textAlign: 'center',
    flexShrink: 1,
  },
  userInfoContainer: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 10,
  },
  userInfoItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
    backgroundColor: '#2A2D36',
    borderRadius: 10,
    padding: 20,
    marginTop: 20,
  },
  userInfoIcon: {
    marginRight: 15,
  },
  userInfoText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    color: '#FFF',
    fontSize: 24,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  upgradeButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginLeft: 10,
    marginTop: 5,
  },
  upgradeLinkText: {
    color: '#1E90FF',
    marginLeft: 5,
  },
  apiKeyContainer: {
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 10,
    backgroundColor: '#2A2D36',
    padding: 20,
  },
  apiKeyHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  apiKeyHeaderText: {
    color: '#FFF',
    fontSize: 18,
    marginLeft: 10,
    fontWeight: 'bold',
  },
  apiKeyInfoText: {
    color: '#FFF',
    fontSize: 15,
    textAlign: 'left',
    marginBottom: 15,
  },
  apiKeyInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  apiKeyInput: {
    flex: 1,
    borderColor: '#FFF',
    borderWidth: 1,
    borderRadius: 5,
    color: '#FFF',
    paddingHorizontal: 5,
    paddingVertical: 3,
    fontSize: 14,
  },
  apiKeySubmittedContainer: {
    marginBottom: 10,
  },
  apiKeySubmittedText: {
    color: '#FFF',
    fontSize: 15,
    textAlign: 'left',
    fontWeight: '500',
  },
  clearButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  },
  apiKeyClearButton: {
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderWidth: 1,
    borderColor: '#DC143C',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  apiKeyClearButtonText: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 5,
  },
  apiKeyTestExplanation: {
    color: '#FFF',
    fontSize: 15,
    marginBottom: 15,
    textAlign: 'left',
    fontWeight: '500',
  },
  apiKeyTestButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 15,
  },
  apiKeyTestButton: {
    backgroundColor: '#3c3c3c',
    borderColor: '#FFF',
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    paddingVertical: 7,
    paddingHorizontal: 9,
  },
  apiKeyTestButtonText: {
    fontWeight: 'bold',
    fontSize: 18,
    marginLeft: 10,
    color: '#FFF',
  },
  apiKeyAccessIndicators: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  apiKeyAccessIndicatorItem: {
    alignItems: 'center',
  },
  apiKeyAccessIndicatorLabel: {
    fontSize: 12,
    color: '#8F9BB3',
    fontWeight: '500',
  },
  apiKeyIndicatorsExplanation: {
    color: '#FFF',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 5,
  },
  loadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  keyLoadingText: {
    marginLeft: 10,
    color: '#FFF',
    fontSize: 16,
  },
  openAILink: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    backgroundColor: '#6495ED11'
  },
  openAILinkIcon: {
    marginRight: 6,
  },
  openAILinkText: {
    fontSize: 14,
    color: '#6495ED',
  },
});

export default SettingsScreen;
