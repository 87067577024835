import React, {FC, useCallback, useEffect, useState} from 'react';
import {Text} from 'react-native-paper';
import {ActivityIndicator, RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../config/firebase";
import PromptCard from "../../components/PromptCard";
import {useFocusEffect} from "@react-navigation/native";

const ViewPromptsByPatternScreen: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [searchResultPrompts, setSearchResultPrompts] = useState([]);
  const [trendName, setTrendName] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [filterTags, setFilterTags] = useState({});
  const [filterActiveToggle, setFilterActiveToggle] = useState({});
  const [itemCount, setItemCount] = useState(20);
  const [loading, setLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      // @ts-ignore
      setTrendName(decodeURI(route?.params?.trendName));
    }, [route?.params?.trendName])
  );

  const onRefresh = useCallback(() => {
    if (!trendName) return;
    setRefreshing(true);
    if (trendName === 'newest') {
      (async () => {
        const querySnapshot = await getDocs(
          query(
            collection(db, "prompts"),
            where("deleted", "==", false)
          )
        );
        const prompts = querySnapshot.docs.map(doc => doc.data());
        const tempNewestPrompts = [...prompts];
        const tempFilterTagsObj = {};
        const tempFilterActiveObj = {};

        tempNewestPrompts.sort(sortNewestArray);

        for (const prompt of prompts) {
          // @ts-ignore
          if (prompt?.tags?.length) {
            // @ts-ignore
            for (const tag of prompt.tags) {
              // @ts-ignore
              if (!tempFilterTagsObj[tag]) tempFilterTagsObj[tag] = [];
              // @ts-ignore
              if (!tempFilterActiveObj[tag]) tempFilterActiveObj[tag] = false;
              // @ts-ignore
              tempFilterTagsObj[tag].push(prompt.promptId);
            }
          }
        }

        const sortedFilterTagsObj = Object.entries(tempFilterTagsObj)
          .sort((a: any, b: any) => b[1].length - a[1].length)
          .reduce((obj, [key, value]) => {
            // @ts-ignore
            obj[key] = value;
            return obj;
          }, {});

        // @ts-ignore
        setSearchResultPrompts(tempNewestPrompts);
        // @ts-ignore
        setFilterTags(sortedFilterTagsObj);
        // @ts-ignore
        setFilterActiveToggle(tempFilterActiveObj);

        setRefreshing(false);
      })();
    }
    if (trendName === 'popular' || trendName === 'all') {
      (async () => {
        const querySnapshot = await getDocs(
          query(
            collection(db, "prompts"),
            where("deleted", "==", false)
          )
        );
        const prompts = querySnapshot.docs.map(doc => doc.data());
        const tempMostPopularPrompts = [...prompts];
        const tempFilterTagsObj = {};
        const tempFilterActiveObj = {};

        tempMostPopularPrompts.sort(sortMostPopularArray);

        for (const prompt of prompts) {
          if (prompt?.tags?.length) {
            for (const tag of prompt.tags) {
              // @ts-ignore
              if (!tempFilterTagsObj[tag]) tempFilterTagsObj[tag] = [];
              // @ts-ignore
              if (!tempFilterActiveObj[tag]) tempFilterActiveObj[tag] = false;
              // @ts-ignore
              tempFilterTagsObj[tag].push(prompt.promptId);
            }
          }
        }

        const sortedFilterTagsObj = Object.entries(tempFilterTagsObj)
          .sort((a: any, b: any) => b[1].length - a[1].length)
          .reduce((obj, [key, value]) => {
            // @ts-ignore
            obj[key] = value;
            return obj;
          }, {});

        // @ts-ignore
        setSearchResultPrompts(tempMostPopularPrompts);
        // @ts-ignore
        setFilterTags(sortedFilterTagsObj);
        // @ts-ignore
        setFilterActiveToggle(tempFilterActiveObj);

        setRefreshing(false);
      })();
    }
  }, []);

  function sortNewestArray(a: any, b: any) {
    return b.timestamp - a.timestamp;
  }

  function sortMostPopularArray(a: any, b: any) {
    const getTotalRating = (prompt: any) => {
      if (!prompt.ratings || !Array.isArray(prompt.ratings)) {
        return 0;
      }
      return prompt.ratings.reduce((total: any, ratingObj: any) => total + (ratingObj.rating || 0), 0);
    };

    return getTotalRating(b) - getTotalRating(a);
  }

  useEffect(() => {
    if (!trendName) return;
    if (trendName === 'newest') {
      (async () => {
        const querySnapshot = await getDocs(
          query(
            collection(db, "prompts"),
            where("deleted", "==", false)
          )
        );
        const prompts = querySnapshot.docs.map(doc => doc.data());
        const tempNewestPrompts = [...prompts];
        const tempFilterTagsObj = {};
        const tempFilterActiveObj = {};

        tempNewestPrompts.sort(sortNewestArray);

        for (const prompt of prompts) {
          // @ts-ignore
          if (prompt?.tags?.length) {
            // @ts-ignore
            for (const tag of prompt.tags) {
              // @ts-ignore
              if (!tempFilterTagsObj[tag]) tempFilterTagsObj[tag] = [];
              // @ts-ignore
              if (!tempFilterActiveObj[tag]) tempFilterActiveObj[tag] = false;
              // @ts-ignore
              tempFilterTagsObj[tag].push(prompt.promptId);
            }
          }
        }

        const sortedFilterTagsObj = Object.entries(tempFilterTagsObj)
          .sort((a: any, b: any) => b[1].length - a[1].length)
          .reduce((obj, [key, value]) => {
            // @ts-ignore
            obj[key] = value;
            return obj;
          }, {});

        // @ts-ignore
        setSearchResultPrompts(tempNewestPrompts);
        // @ts-ignore
        setFilterTags(sortedFilterTagsObj);
        // @ts-ignore
        setFilterActiveToggle(tempFilterActiveObj);

        setLoading(false);
      })();
    }
    if (trendName === 'popular' || trendName === 'all') {
      (async () => {
        const querySnapshot = await getDocs(
          query(
            collection(db, "prompts"),
            where("deleted", "==", false)
          )
        );
        const prompts = querySnapshot.docs.map(doc => doc.data());
        const tempMostPopularPrompts = [...prompts];
        const tempFilterTagsObj = {};
        const tempFilterActiveObj = {};

        tempMostPopularPrompts.sort(sortMostPopularArray);

        for (const prompt of prompts) {
          if (prompt?.tags?.length) {
            for (const tag of prompt.tags) {
              // @ts-ignore
              if (!tempFilterTagsObj[tag]) tempFilterTagsObj[tag] = [];
              // @ts-ignore
              if (!tempFilterActiveObj[tag]) tempFilterActiveObj[tag] = false;
              // @ts-ignore
              tempFilterTagsObj[tag].push(prompt.promptId);
            }
          }
        }

        const sortedFilterTagsObj = Object.entries(tempFilterTagsObj)
          .sort((a: any, b: any) => b[1].length - a[1].length)
          .reduce((obj, [key, value]) => {
            // @ts-ignore
            obj[key] = value;
            return obj;
          }, {});

        // @ts-ignore
        setSearchResultPrompts(tempMostPopularPrompts);
        // @ts-ignore
        setFilterTags(sortedFilterTagsObj);
        // @ts-ignore
        setFilterActiveToggle(tempFilterActiveObj);

        setLoading(false);
      })();
    }
  }, [trendName]);

  useEffect(() => {
    const tempActiveToggle = {...filterActiveToggle};
    // @ts-ignore
    const tempActiveFilters = [];

    Object.keys(tempActiveToggle).forEach(key => {
      // @ts-ignore
      if (tempActiveToggle[key]) {
        tempActiveFilters.push(key);
      }
    });
    // @ts-ignore
    setActiveFilters(tempActiveFilters);
  }, [filterActiveToggle]);

  const isMatchingFilter = (promptTags: any) => {
    // @ts-ignore
    return promptTags.some(tag => activeFilters.includes(tag));
  };

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          {/*<View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}*/}
          {/*      sx={{marginBottom: [20, 20, 25]}}>*/}
          {/*  <Text style={{color: '#FFF', fontSize: 30, marginRight: 8}}>*/}
          {/*    Happening Now on*/}
          {/*  </Text>*/}
          {/*  <Text style={{*/}
          {/*    borderWidth: 1,*/}
          {/*    borderColor: '#FFF',*/}
          {/*    color: '#FFF',*/}
          {/*    fontSize: 20,*/}
          {/*    borderRadius: 999,*/}
          {/*    paddingTop: 8,*/}
          {/*    paddingBottom: 8,*/}
          {/*    paddingLeft: 8,*/}
          {/*    paddingRight: 8,*/}
          {/*  }}>*/}
          {/*    hP*/}
          {/*  </Text>*/}
          {/*</View>*/}
          {/*<View>*/}
          {/*  {loading ? (*/}
          {/*    <View style={{marginVertical: 15}}>*/}
          {/*      <ActivityIndicator size="large" color="#C8BDD9"/>*/}
          {/*    </View>*/}
          {/*  ) : (*/}
          {/*    <View sx={{*/}
          {/*      flexDirection: 'row',*/}
          {/*      justifyContent: 'center',*/}
          {/*      alignItems: ['center', 'center', 'flex-start'],*/}
          {/*      flexWrap: 'wrap',*/}
          {/*      maxWidth: 1450,*/}
          {/*      marginBottom: 25,*/}
          {/*    }}>*/}
          {/*      {Object.keys(filterTags)?.map((filterKey: any, index: number) => {*/}
          {/*          if (index >= 9) return;*/}
          {/*          return <Pressable key={filterKey}*/}
          {/*                            onPress={() => {*/}
          {/*                              // @ts-ignore*/}
          {/*                              const tempFilterObj = {...filterActiveToggle};*/}
          {/*                              // @ts-ignore*/}
          {/*                              tempFilterObj[filterKey] = !(tempFilterObj[filterKey]);*/}
          {/*                              setFilterActiveToggle(tempFilterObj);*/}
          {/*                            }}*/}
          {/*                            style={{*/}
          {/*                              flexDirection: 'row',*/}
          {/*                              borderWidth: 1,*/}
          {/*                              borderColor: '#FFF',*/}
          {/*                              paddingTop: 5,*/}
          {/*                              paddingBottom: 5,*/}
          {/*                              paddingLeft: 10,*/}
          {/*                              paddingRight: 10,*/}
          {/*                              borderRadius: 20,*/}
          {/*                              // @ts-ignore*/}
          {/*                              backgroundColor: filterActiveToggle[filterKey] ? '#FFF' : 'transparent',*/}
          {/*                              marginBottom: 10,*/}
          {/*                            }}*/}
          {/*                            sx={{*/}
          {/*                              marginRight: [10, 0, 10],*/}
          {/*                              flexBasis: ['auto', '25%', '25%'],*/}
          {/*                              justifyContent: 'center',*/}
          {/*                              flexGrow: 0,*/}
          {/*                              flexShrink: 0,*/}
          {/*                            }}>*/}
          {/*            <View>*/}
          {/*              <Text style={{*/}
          {/*                // @ts-ignore*/}
          {/*                color: filterActiveToggle[filterKey] ? '#000' : '#FFF',*/}
          {/*                marginRight: 5,*/}
          {/*              }}>*/}
          {/*                {filterKey}*/}
          {/*              </Text>*/}
          {/*            </View>*/}
          {/*            <View>*/}
          {/*              <Text style={{*/}
          {/*                // @ts-ignore*/}
          {/*                color: filterActiveToggle[filterKey] ? '#000' : '#FFF',*/}
          {/*              }}>*/}
          {/*                ({// @ts-ignore*/}
          {/*                filterTags[filterKey].length})*/}
          {/*              </Text>*/}
          {/*            </View>*/}
          {/*          </Pressable>*/}
          {/*        }*/}
          {/*      )}*/}
          {/*    </View>*/}
          {/*  )}*/}
          {/*</View>*/}
          <View sx={{
            marginBottom: [20, 20, 12],
            flexDirection: ['column', 'column', 'row'],
            justifyContent: ['center', 'center', 'flex-start'],
            alignItems: ['center', 'center', 'flex-start'],
          }}>
            <Text style={{color: '#FFF', fontSize: 25, textTransform: 'capitalize'}}>
              {trendName} Prompts
            </Text>
          </View>
          <View>
            {loading ? (
              <View style={{marginVertical: 15}}>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            ) : (
              <View sx={{
                flexDirection: ['column', 'column', 'row'],
                justifyContent: 'center',
                alignItems: ['center', 'center', 'flex-start'],
                flexWrap: 'wrap',
                maxWidth: 1450,
              }}>
                {searchResultPrompts?.slice(0, itemCount).map((prompt: any) => { // Update this line
                    let matchesActiveTag = isMatchingFilter(prompt.tags);
                    if (matchesActiveTag || !activeFilters.length) {
                      return <PromptCard key={prompt.promptId}
                                         promptId={prompt.promptId}
                                         navigation={navigation}
                                         promptImage={prompt?.promptImage?.imageURL}
                                         promptTitle={prompt.title}
                                         promptUserName={prompt.userName}
                                         promptRatings={prompt.ratings}
                                         promptComments={prompt.comments?.length}/>;
                    }
                  }
                )}
              </View>
            )}
          </View>
          {itemCount < searchResultPrompts.length && (
            <View style={{alignItems: 'center', marginBottom: 20}}>
              <Pressable onPress={() => setItemCount(itemCount + 20)} style={{
                borderWidth: 1,
                borderColor: '#FFF',
                borderRadius: 15,
                paddingHorizontal: 10,
                paddingVertical: 5,
              }}>
                <Text style={{fontSize: 24, color: '#FFF'}}>
                  Load More
                </Text>
              </Pressable>
            </View>
          )}
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  cardTitleContainer: {
    backgroundColor: '#222236',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  cardTitleText: {
    color: '#FFF',
  },
  cardBackgroundImage: {
    flex: 1,
    resizeMode: "cover",
    width: "100%",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
});

export default ViewPromptsByPatternScreen;
