import React, {useCallback, useState} from 'react';
import {Dimensions, Image, ScrollView, StyleSheet, Text, View} from 'react-native';
import {Pressable} from 'dripsy';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import {defaultImageArray} from '../utils/misc/defaultImageData';
import {connectHits} from "react-instantsearch-native";
import {AntDesign, MaterialCommunityIcons} from "@expo/vector-icons";

const Hits = ({hits, searchInput, clearInput}: any) => {
  const navigation = useNavigation();
  const [randomImage, setRandomImage] = useState();

  useFocusEffect(
    useCallback(() => {
      const randomIndex = Math.floor(Math.random() * defaultImageArray.length);
      //@ts-ignore
      setRandomImage(defaultImageArray[randomIndex]);
    }, [])
  );

  const isMobile = () => {
    const screenWidth = Dimensions.get('window').width;
    return screenWidth <= 768;
  };

  const truncateAndAddEllipsis = (str: any, maxLength: any) => {
    const deviceMaxLength = isMobile() ? maxLength : 40;
    if (str.length > deviceMaxLength) {
      return str.substring(0, deviceMaxLength) + '...';
    }
    return str;
  };

  if (searchInput.length < 2) {
    return null;
  }

  if (hits.length === 0) {
    return (
      <View style={styles.seeAllResultsButton}>
        <Text style={styles.seeAllResultsText}>No Results Found...</Text>
      </View>
    );
  }

  return (
    <ScrollView>
      {//@ts-ignore
        hits.slice(0, 5).map((hit) => {
          const imageSrc = hit?.promptImage?.imageURL || randomImage;

          let totalPoints = 0;
          if (hit.ratings?.length) {
            hit.ratings.forEach((rating: any) => totalPoints += rating.rating);
          }

          return (
            <Pressable
              key={hit.objectID}
              onPress={() => {
                //@ts-ignore
                navigation.navigate('New View Prompt', {promptId: hit.promptId});
                clearInput();
              }}
              style={styles.hitContainer}
            >
              <Image source={{uri: imageSrc}} style={styles.thumbnailImage} resizeMode="cover"/>
              <View>
                <Text style={styles.hitTitle}>{truncateAndAddEllipsis(hit.title, 30)}</Text>
                <View style={styles.hitRating}>
                  <AntDesign name="staro" size={14} color="#FFF"/>
                  <Text style={styles.hitRatingText}>{totalPoints}</Text>
                  <MaterialCommunityIcons name="account" size={16} color="#FFF" style={{marginLeft: 6}}/>
                  <Text style={styles.hitUsername}>{hit.userName}</Text>
                </View>
              </View>
            </Pressable>
          );
        })}
      {hits.length >= 6 && (
        <Pressable
          onPress={() => {
            //@ts-ignore
            navigation.navigate("Search Results", {searchInput});
            clearInput();
          }}
          style={styles.seeAllResultsButton}
        >
          <Text style={styles.seeAllResultsText}>See All Results...</Text>
        </Pressable>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  hitContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#444',
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginVertical: 4,
  },
  hitTitle: {
    color: '#FFF',
    fontSize: 16,
    marginBottom: 6,
  },
  hitRating: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hitRatingText: {
    color: '#FFF',
    fontSize: 14,
    marginLeft: 3,
  },
  thumbnailImage: {
    width: 50,
    height: 50,
    marginRight: 10,
    borderRadius: 5,
  },
  seeAllResultsButton: {
    backgroundColor: '#333',
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginVertical: 4,
    alignItems: 'center',
  },
  seeAllResultsText: {
    color: '#FFF',
    fontSize: 16,
  },
  noResultsContainer: {
    paddingVertical: 10,
    alignItems: 'center',
  },
  noResultsText: {
    color: '#FFF',
    fontSize: 16,
  },
  hitUsername: {
    color: '#FFF',
    fontSize: 12,
    marginLeft: 3,
  },
});

export default connectHits(Hits);
