import React from 'react';
import {Button, Pressable, StyleSheet, Text, View} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import {getAuth} from "firebase/auth";

export default function ShowImagePromptContent({
                                          hideOriginalImagePrompt,
                                          setHideOriginalImagePrompt,
                                          generating,
                                          setGenerating,
                                          canEditPrompt,
                                          setCanEditPrompt,
                                          setImageLoading,
                                          setIsBeforePromptRun,
                                          setIsBeforeImagePromptRun,
                                          userDoc,
                                          promptContent,
                                          image,
                                          setImage,
                                          setImageHistory,
                                          setImageLoadingMessage,
                                        }: any) {
  const auth = getAuth();

  const addToImageHistory = (newImage: any) => {
    setImageHistory((prevState: any) => {
      // Check if the new image is already in the history
      if (prevState.includes(newImage)) {
        return prevState;
      }
      return [...prevState, newImage];
    });
  };

  const handleGenerateImages = async (imageData?: any) => {
    if (!auth.currentUser) return;
    setImageLoadingMessage('');
    setGenerating(true);
    setImageLoading(true);
    setIsBeforePromptRun(true);
    setIsBeforeImagePromptRun(false);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      prompt: promptContent,
    };

    if (imageData) {
      // @ts-ignore
      requestBody.input_image_url = imageData;
    }

    try {
      const response = await fetch('https://highperplexity-dalle-middleware-mjp27ovqaq-uc.a.run.app', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data && data.response) {
        if (image) {
          addToImageHistory(image);
        }
        const newImage = `data:image/png;base64,${data.response.b64_json}`;
        setImage(newImage);
        addToImageHistory(newImage);
      } else {
        console.log('Unexpected response format');
      }
    } catch (error) {
      console.error('Error generating images:', error);
    }
    setImageLoading(false);
    setCanEditPrompt(false);
  };

  return (
    <>
      <View style={{marginBottom: 10}}>
        <Pressable
          style={({pressed}) => [
            {
              backgroundColor: pressed ? "#3D6DCC" : "transparent",
              borderColor: "#fff",
              borderWidth: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
              borderRadius: 5,
            },
          ]}
          onPress={() => setHideOriginalImagePrompt(!hideOriginalImagePrompt)}
        >
          <Text style={{color: "#fff", marginRight: 5}}>
            {hideOriginalImagePrompt ? "Show Image Prompt Content" : "Hide Image Prompt Content"}
          </Text>
          <Ionicons name={hideOriginalImagePrompt ? "md-eye-outline" : "md-eye-off-outline"} size={20} color="#FFF"/>
        </Pressable>
      </View>
      {
        !generating && canEditPrompt && (
          <Button
            title="Create Image"
            onPress={() => handleGenerateImages()}
            //@ts-ignore
            buttonStyle={styles.generateButton}
          />
        )
      }
    </>
  );
}

const styles = StyleSheet.create({
  generateButton: {
    backgroundColor: "#4E8FFF",
    borderRadius: 5,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginBottom: 20,
  },
});
