import React from 'react';
import {Dimensions, Pressable, Text} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {useSx, View} from 'dripsy';

const screenWidth = Dimensions.get('window').width;

const PromptDetailsToggle = ({isDescriptionVisible, setIsDescriptionVisible}: any) => {
  const sx = useSx();

  return (
    <View sx={{
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: 10,
    }}>
      <Pressable
        onPress={() => setIsDescriptionVisible(true)}
        style={[sx({
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 5,
          padding: 10,
          width: [150, 200, 250],
          backgroundColor: isDescriptionVisible ? '#444444' : '#2a2a2a',
          marginRight: 10,
        })]}
      >
        <Ionicons
          name="md-book-sharp"
          size={30}
          color="#ccc"
          style={sx({margin: 10})}
        />
        <Text style={sx({color: '#ccc', fontSize: 16})}>{screenWidth < 768 ? 'Description' : 'Prompt Description'}</Text>
      </Pressable>
      <Pressable
        onPress={() => setIsDescriptionVisible(false)}
        style={sx({
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 5,
          padding: 10,
          width: [150, 200, 250],
          backgroundColor: isDescriptionVisible ? '#2a2a2a' : '#444444'
        })}
      >
        <Ionicons
          name="md-create"
          size={30}
          color="#ccc"
          style={sx({margin: 10})}
        />
        <Text
          style={sx({color: '#ccc', fontSize: 16})}>{screenWidth < 768 ? 'Content' : 'Prompt Content'}</Text>
      </Pressable>
    </View>
  );
}

export default PromptDetailsToggle;
