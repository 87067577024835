import React, {FC, useCallback, useState} from 'react';
import {RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {Link} from "@react-navigation/native";

const TermsOfServiceScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <View style={{width: '100%', paddingTop: 20, alignItems: 'center'}}
                sx={{paddingLeft: [20, 20, 40], paddingRight: [20, 20, 40]}}>
            <View style={[styles.headerLogoContainer]}>
              <Text style={styles.headerLogoText}>
                hP
              </Text>
            </View>
            <Text style={styles.termsText}>
              highPerplexity Website, Smartphone Application, and ChatGPT Extension Terms and Conditions of Use
            </Text>
            <Text style={styles.termsText}>Last updated: April 13, 2023</Text>
            <Text style={styles.termsText}>
              1. Terms
            </Text>
            <Text style={styles.termsText}>
              By accessing highPerplexity, accessible from highPerplexity.com or the highPerplexity smartphone
              application
              or the highPerplexity ChatGPT extension, you are agreeing to be bound by these Website Terms and
              Conditions
              of Use and agree that you are responsible for the agreement with any applicable local laws. If you
              disagree
              with any of these terms, you are prohibited from accessing this site. The materials contained in this
              Website are protected by copyright and trade mark law.
            </Text>
            <Text style={styles.termsText}>
              2. Use License
            </Text>
            <Text style={styles.termsText}>
              Permission is granted to temporarily download one copy of the materials on highPerplexity.com's Website
              for
              personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title,
              and under this license you may not:
            </Text>
            <Text style={styles.termsText}>
              - modify or copy the materials;
            </Text>
            <Text style={styles.termsText}>
              - use the materials for any commercial purpose or for any public display;
            </Text>
            <Text style={styles.termsText}>
              - attempt to reverse engineer any software contained on highPerplexity.com's Website;
            </Text>
            <Text style={styles.termsText}>
              - remove any copyright or other proprietary notations from the materials; or
            </Text>
            <Text style={styles.termsText}>
              - transfer the materials to another person or "mirror" the materials on any other server.
            </Text>
            <Text style={styles.termsText}>
              This will let highPerplexity.com to terminate upon violations of any of these restrictions. Upon
              termination, your viewing right will also be terminated and you should destroy any downloaded materials in
              your possession whether it is printed or electronic format.
            </Text>
            <Text style={styles.termsText}>
              3. Disclaimer
            </Text>
            <Text style={styles.termsText}>
              All the materials on highPerplexity.com’s Website are provided "as is". highPerplexity.com makes no
              warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore,
              highPerplexity.com does not make any representations concerning the accuracy or reliability of the use of
              the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
            </Text>
            <Text style={styles.termsText}>
              4. Limitations
            </Text>
            <Text style={styles.termsText}>
              highPerplexity.com or its suppliers will not be hold accountable for any damages that will arise with the
              use or inability to use the materials on highPerplexity.com’s Website, even if highPerplexity.com or an
              authorize representative of this Website has been notified, orally or written, of the possibility of such
              damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for
              incidental damages, these limitations may not apply to you.
            </Text>
            <Text style={styles.termsText}>
              5. Revisions and Errata
            </Text>
            <Text style={styles.termsText}>
              The materials appearing on highPerplexity.com’s Website may include technical, typographical, or
              photographic errors. highPerplexity.com will not promise that any of the materials in this Website are
              accurate, complete, or current. highPerplexity.com may change the materials contained on its Website at
              any
              time without notice. highPerplexity.com does not make any commitment to update the materials.
            </Text>
            <Text style={styles.termsText}>
              6. Links
            </Text>
            <Text style={styles.termsText}>
              highPerplexity.com has not reviewed all of the sites linked to its Website and is not responsible for the
              contents of any such linked site. The presence of any link does not imply endorsement by
              highPerplexity.com
              of the site. The use of any linked website is at the user’s own risk.
            </Text>
            <Text style={styles.termsText}>
              7. Site Terms of Use Modifications
            </Text>
            <Text style={styles.termsText}>
              highPerplexity.com may revise these Terms of Use for its Website at any time without prior notice. By
              using
              this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
            </Text>
            <Text style={styles.termsText}>
              8. Your Privacy
            </Text>
            <Text style={styles.termsText}>
              Please read our <Link to={{screen: 'Privacy'}}><Text style={{textDecorationLine: 'underline'}}>Privacy
              Policy</Text></Link>.
            </Text>
            <Text style={styles.termsText}>
              9. Content Moderation
            </Text>
            <Text style={styles.termsText}>
              Explicit, offensive, or vulgar content will not be allowed in any way. These terms will be defined by the
              highPerplexity content moderation team and include any vulgar, offensive, or sexual content. Content and
              accounts that are in violation of this rule will be removed from the platform within 24 hours. Users also
              have the option to report, filter, and ignore content that they find offensive.
            </Text>
            <Text style={styles.termsText}>
              10. Governing Law
            </Text>
            <Text style={styles.termsText}>
              Any claim related to highPerplexity.com's Website shall be governed by the laws of the United States
              without
              regards to its conflict of law provisions.
            </Text>
            <Text style={styles.termsText}>
              11. highPerplexity Pro Features and AI Service Availability
            </Text>
            <Text style={styles.termsText}>
              While we strive to provide continuous access to highPerplexity Pro features and the AI services and APIs
              we
              utilize, we cannot guarantee their uninterrupted availability. These services may be intermittently
              unavailable without prior notice due to various factors, such as maintenance, technical issues, or other
              reasons. There is no guarantee that highPerplexity Pro or any of the AI services and APIs will be
              accessible
              at any particular moment - even if you have paid for a subscription to those services. Additionally, we
              reserve the right to apply quotas, limit access, or revoke usage of the services without warning at any
              time. By using highPerplexity Pro or any related AI services and APIs, you acknowledge and accept the
              potential for such limitations and interruptions.
            </Text>
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  headerLogoContainer: {
    borderWidth: 1,
    borderColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 80,
    minHeight: 80,
    borderRadius: 80 / 2,
    marginBottom: 20,
  },
  headerLogoText: {
    color: '#FFF',
    fontSize: 35,
  },
  termsText: {
    color: '#FFF',
    maxWidth: 1000,
    textAlign: 'justify',
    fontSize: 18,
    width: '100%',
    marginBottom: 20
  },
});

export default TermsOfServiceScreen;
