import React from 'react';
import {Text, StyleSheet, View} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import {SimpleAccordion} from 'react-native-simple-accordion';

const accentColor = '#DDD';

const styles = StyleSheet.create({
  accordionContainer: {
    marginTop: 10,
  },
  accordionHeader: {
    height: 'auto',
    flexDirection: "row",
    backgroundColor: "#393939",
    padding: 16,
    alignItems: "center",
  },
  accordionHeaderText: {
    color: accentColor,
  },
  accordionTitle: {
    color: '#FFF',
    fontSize: 22,
    marginLeft: 10,
  },
  accordionContent: {
    borderRadius: 5,
    padding: 8,
  },
  accordionContentText: {
    color: accentColor,
    marginBottom: 15,
    fontSize: 14,
    textAlign: 'justify',
  },
  accordionContentContainer: {
    paddingTop: 10,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#444',
  },
});

interface PluginAccordionProps {
  title: string;
  content: string;
  icon: string;
}

const PluginAccordion: React.FC<PluginAccordionProps> = ({title, content, icon}) => {
  return (
    <View style={styles.accordionContainer}>
      <SimpleAccordion
        //@ts-ignore
        title={
          <View style={styles.accordionHeader}>
            <FontAwesome
              //@ts-ignore
              name={icon} size={25} color="#FFF"/>
            <Text style={styles.accordionTitle}>{title}</Text>
          </View>
        }
        viewContainerStyle={styles.accordionContentContainer}
        bannerStyle={styles.accordionHeader}
        titleStyle={styles.accordionHeaderText}
        arrowColor={accentColor}
        viewInside={
          <View style={styles.accordionContent}>
            <Text style={styles.accordionContentText}>{content}</Text>
          </View>
        }
      />
    </View>
  );
};

export default PluginAccordion;
