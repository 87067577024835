import React from 'react';
import {Button, Modal, View} from 'react-native';
import {Portal} from 'react-native-paper';
import {Text} from "dripsy";
import {MaterialCommunityIcons} from "@expo/vector-icons";

const TooltipModal = ({
                        tooltipVisible,
                        setTooltipVisible,
                        tooltipId,
                      }: any) => {


  const renderTooltipContent = () => {
    switch (tooltipId) {
      // @ts-ignore
      case 'promptContent':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              This is the prompt you will be running. It may contain variables, which are words or phrases that are
              surrounded by %| and |% symbols. To customize the prompt, you can either tap in the box to make edits
              manually, or you can update the variables in the section below the prompt.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              For example, if the prompt says "Tell me a story about %|TOPIC|%," you can fill in the value for %|TOPIC|%
              in the section below the prompt. If you fill it in with "space travel," the AI will tell you a story about
              space travel.
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptVariables':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              Variables are what make highPerplexity prompts so fun and powerful. They allow you to create prompts that
              can be customized with different values, making them versatile and adaptable to different scenarios.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              To use a variable in a prompt, simply fill out the text input for that variable in the "Prompt Variables"
              section. For instance, if the prompt is: "tell me a story about %|TOPIC|%" and you fill in "space travel"
              as the value for %|TOPIC|%, the AI will tell you a story about space travel.
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptTokens':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              highPerplexity uses a token system to run prompts. Tokens are required to generate prompts, but they are
              free and can be obtained by watching ads. There is no limit to the number of ads you can watch to get more
              tokens. highPerplexity Pro users can run all prompt types at no token cost!
            </Text>
          </View>
        );
      // @ts-ignore
      case 'tokenCost':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              The token cost for running prompts depends on the type of prompt and your user level. Here's a breakdown
              of the token costs:
            </Text>
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>
              <MaterialCommunityIcons name="robot" size={16} color="#f0f0f3" style={{marginRight: 8}}/>
              <Text style={{color: '#f0f0f3', fontSize: 16, lineHeight: 24}}>
                GPT-3 prompts: 1 token
              </Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>
              <MaterialCommunityIcons name="image" size={16} color="#f0f0f3" style={{marginRight: 8}}/>
              <Text style={{color: '#f0f0f3', fontSize: 16, lineHeight: 24}}>
                Image prompts: 2 tokens
              </Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>
              <MaterialCommunityIcons name="brain" size={16} color="#f0f0f3" style={{marginRight: 8}}/>
              <Text style={{color: '#f0f0f3', fontSize: 16, lineHeight: 24}}>
                GPT-4 prompts: 2 tokens
              </Text>
            </View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              highPerplexity Pro accounts can run all prompt types at no cost!
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptDescription':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              The prompt description provides an overview of the prompt you are about to run. While it is not the actual
              prompt text, it should serve as an informative guide to help you understand the purpose and functionality
              of the prompt. A well-crafted prompt description should include details about the prompt and examples of
              input values for the variables.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              By offering a thorough description, users will be able to make the most of the customization features and
              tailor the prompt to suit their specific needs.
            </Text>
          </View>
        );
      // @ts-ignore
      case 'customPrompt':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              Writing a custom input means you that you aren't utilizing the prompt's predefined variables. Instead, you
              can ask this highPerplexity prompt any question or give it any command that you want! Make sure that the
              request or command is relevant to the content of this specific prompt.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              For example, you can write a custom input like "Write a short story about a time-traveling detective" or
              "What are the benefits of a healthy diet?" Once you've written your custom prompt, press "Run Prompt" to
              get your personalized response from highPerplexity.
            </Text>
          </View>
        );
      default:
        return null;
    }
  };

  return (
    <Portal>
      <Modal
        visible={tooltipVisible}
        onRequestClose={() => setTooltipVisible(false)}
        transparent={true}
        animationType="slide"
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            padding: 20,
          }}
        >
          <View
            style={{
              backgroundColor: '#2c2c2e',
              borderRadius: 10,
              padding: 20,
              width: '100%',
              maxWidth: 400,
            }}
          >
            {renderTooltipContent()}
            <Button onPress={() => setTooltipVisible(false)} title="Got it!"/>
          </View>
        </View>
      </Modal>
    </Portal>
  );
};

export default TooltipModal;
