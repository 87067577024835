import React from 'react';
import {View, Text, StyleSheet} from 'react-native';

const ViewPromptTitle = ({matchingPrompt}: any) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {// @ts-ignore
          matchingPrompt?.title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 750,
    marginBottom: 10,
  },
  text: {
    color: '#FFF',
    fontSize: 25,
    textAlign: 'center',
  },
});

export default ViewPromptTitle;
