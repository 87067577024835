import React from 'react';
import {StyleSheet, Pressable, Platform, Text} from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';

interface ScrollToTopProps {
  showScrollToTop: boolean;
  scrollToTop: () => void;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({showScrollToTop, scrollToTop}) => {
  return (
    <Pressable
      onPress={scrollToTop}
      style={[
        styles.scrollToTopButtonStyle,
        {opacity: showScrollToTop ? 0.75 : 0},
      ]}
    >
      <MaterialIcons
        name="arrow-upward"
        size={Platform.OS === 'web' ? 25 : 20}
        color="#FFFFFF"
      />
      <Text style={styles.scrollToTopButtonTextStyle}>
        Top
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  scrollToTopButtonStyle: {
    //@ts-ignore
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    bottom: Platform.OS === 'web' ? 30 : 15,
    right: Platform.OS === 'web' ? 30 : 15,
    backgroundColor: '#3a3a3a',
    width: Platform.OS === 'web' ? 65 : 60,
    height: Platform.OS === 'web' ? 65 : 60,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.75s ease',
  },
  scrollToTopButtonTextStyle: {
    color: '#fff',
    fontSize: Platform.OS === 'web' ? 15 : 13,
  },
});

export default ScrollToTop;
