import React, {useState} from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {Entypo} from '@expo/vector-icons';
import {Pressable} from 'react-native';

const ConversationCard = ({conversation, onView}: any) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHoverIn = () => setIsHovered(true);
  const handleHoverOut = () => setIsHovered(false);

  return (
    <Pressable
      style={[styles.card, isHovered && styles.cardHover]}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      onPress={onView}
    >
      <View style={styles.iconContainer}>
        <Entypo name="chat" size={30} color="#fff"/>
      </View>
      <View style={styles.content}>
        <Text style={styles.label}>Conversation:</Text>
        <Text style={styles.title}>{conversation.name}</Text>
      </View>
      <View style={styles.actions}>
        <Entypo name="eye" size={20} color="#fff"/>
        <Text style={styles.actionText}>View</Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  card: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#424242',
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
  },
  iconContainer: {
    marginRight: 15,
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#fff',
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
  },
  actionButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionText: {
    color: '#fff',
    fontSize: 14,
    marginHorizontal: 5,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#bbb',
    marginBottom: 2,
  },
  cardHover: {
    backgroundColor: '#535353',
  },
});

export default ConversationCard;
