import React, {useEffect, useRef} from 'react';
import {View, StyleSheet, Animated, Easing} from 'react-native';

const TypingIndicator = () => {
  const dot1Anim = useRef(new Animated.Value(0)).current;
  const dot2Anim = useRef(new Animated.Value(0)).current;
  const dot3Anim = useRef(new Animated.Value(0)).current;

  const startAnimation = (dotAnim: Animated.Value) => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(dotAnim, {
          toValue: -4,
          duration: 150,
          easing: Easing.inOut(Easing.quad),
          useNativeDriver: true,
        }),
        Animated.timing(dotAnim, {
          toValue: 0,
          duration: 150,
          easing: Easing.inOut(Easing.quad),
          useNativeDriver: true,
        }),
      ]),
    ).start();
  };

  useEffect(() => {
    startAnimation(dot1Anim);
    setTimeout(() => {
      startAnimation(dot2Anim);
    }, 200);
    setTimeout(() => {
      startAnimation(dot3Anim);
    }, 400);
  }, []);

  return (
    <View style={styles.container}>
      <Animated.View
        style={[
          styles.dot,
          {
            transform: [{translateY: dot1Anim}],
          },
        ]}
      />
      <Animated.View
        style={[
          styles.dot,
          {
            transform: [{translateY: dot2Anim}],
          },
        ]}
      />
      <Animated.View
        style={[
          styles.dot,
          {
            transform: [{translateY: dot3Anim}],
          },
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: '#6876C5',
    marginHorizontal: 2,
  },
});

export default TypingIndicator;
