import React, {FC, useCallback, useEffect, useState} from 'react';
import {Platform, RefreshControl, SafeAreaView, StyleSheet} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {Pressable, View} from 'dripsy';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {db} from "../../config/firebase";
import {collection, getDocs} from "firebase/firestore";
import {StackScreenProps} from "@react-navigation/stack";
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import useUnits from "rxn-units";
import {useFocusEffect} from '@react-navigation/native';
import LoadingScreen from "../../components/User/LoadingScreen";
import DeletedScreen from "../../components/User/DeletedScreen";
import PromptActions from "../../components/Common/PromptActions";
import ViewPromptDesktopOnboarding from "../../components/ViewPromptDesktopOnboarding";
import ViewPromptMobileOnboarding from "../../components/ViewPromptMobileOnboarding";
import ViewPromptTitle from "../../components/Common/ViewPromptTitle";
import CustomPromptInfoButton from "../../components/Common/CustomPromptInfoButton";
import PromptTrophyInformation from "../../components/Common/PromptTrophyInformation";
import PromptImage from "../../components/Common/PromptImage";
import RatingSection from "../../components/Common/PromptRatingSection";
import PromptDescriptionSection from "../../components/Common/PromptDescriptionSection";
import PromptTooltipModal from "../../components/Common/PromptTooltipModal";
import PromptContentSection from "../../components/Common/PromptContentSection";
import PromptInputMethodSelector from "../../components/Common/PromptInputMethodSelector";
import RunPromptButton from "../../components/Common/RunPromptButton";
import PromptTokenInfo from "../../components/Common/PromptTokenInfo";
import SavePromptButton from "../../components/Common/SavePromptButton";
import PromptAIResponse from "../../components/Common/PromptAIResponse";
import PromptAIConversation from "../../components/Common/PromptAIConversation";
import PromptImageHeader from "../../components/Common/PromptImageHeader";
import ShowImagePromptContent from "../../components/Common/ShowImagePromptContent";
import PromptImageView from "../../components/Common/PromptImageView";
import ImageHistory from "../../components/Common/ImageHistory";
import PromptCommentSection from "../../components/Common/PromptCommentSection";
import PromptDetailsToggle from "../../components/Common/PromptDetailsToggle";
import {imageLoadingMessages} from "../../utils/misc/promptLoadingMessages";

const NewViewPromptScreen: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const {vh} = useUnits();
  const {user} = useAuthentication();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [navStatus, setNavStatus] = useState('closed');
  const [modalVisible, setModalVisible] = useState(false);
  const [hasOnboarded, setHasOnboarded] = useState(true);
  const [userDoc, setUserDoc] = useState();
  const [fullUserDoc, setFullUserDoc] = useState();
  const [gpt3UserAPI, setGpt3UserAPI] = useState(false);
  const [gpt4UserAPI, setGpt4UserAPI] = useState(false);
  const [imagesUserAPI, setImagesUserAPI] = useState(false);
  const [remainingTokens, setRemainingTokens] = useState(0);
  const [mySavedPrompts, setMySavedPrompts] = useState();
  const [userUpvotedComments, setUserUpvotedComments] = useState();
  const [promptId, setPromptId] = useState(route?.params?.promptId);
  const [promptDocId, setPromptDocId] = useState();
  const [matchingPrompt, setMatchingPrompt] = useState();
  const [trophyCount, setTrophyCount] = useState();
  const [originalPromptContent, setOriginalPromptContent] = useState();
  const [promptContent, setPromptContent] = useState();
  const [userInput, setUserInput] = useState('');
  const [promptComments, setPromptComments] = useState([]);
  const [promptVariables, setPromptVariables] = useState([]);
  const [promptImage, setPromptImage] = useState();
  const [currentScreen, setCurrentScreen] = useState(0);
  const [promptRating, setPromptRating] = useState(0);
  const [totalPromptRating, setTotalPromptRating] = useState(0);
  const [totalPromptVotes, setTotalPromptVotes] = useState(0);
  const [allPromptRatings, setAllPromptRatings] = useState();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);
  const [isBeforePromptRun, setIsBeforePromptRun] = useState(true);
  const [isBeforeImagePromptRun, setIsBeforeImagePromptRun] = useState(true);
  const [messages, setMessages] = useState([]);
  const [hasConvoBeenSaved, setHasConvoBeenSaved] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [image, setImage] = useState('');
  const [canEditPrompt, setCanEditPrompt] = useState(true);
  const [imageHistory, setImageHistory] = useState([]);
  const [tokenCost, setTokenCost] = useState();
  const [responseType, setResponseType] = useState('gpt');
  const [isGPT4, setIsGPT4] = useState(false);
  const [isPromptCreator, setIsPromptCreator] = useState(false);
  const [isAlreadySaved, setIsAlreadySaved] = useState(false);
  const [GPTStream, setGPTStream] = useState('');
  const [fetchingGPT, setFetchingGPT] = useState(false);
  const [isStartConvoActive, setIsStartConvoActive] = useState(false);
  const [isResponseSaved, setIsResponseSaved] = useState(false);
  const [GPTMessage, setGPTMessage] = useState('');
  const [logConvoId, setLogConvoId] = useState(null);
  const [hideOriginalImagePrompt, setHideOriginalImagePrompt] = useState(false);
  const [imageloadingMessage, setImageLoadingMessage] = useState('');
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(true);
  const [customPromptInput, setCustomPromptInput] = useState('');
  const [inputMethod, setInputMethod] = useState('custom');

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const changeLoadingMessage = () => {
    const randomIndex = Math.floor(Math.random() * imageLoadingMessages.length);
    setImageLoadingMessage(imageLoadingMessages[randomIndex]);
  };

  useEffect(() => {
    setPromptId(route?.params?.promptId);
  }, [route?.params?.promptId]);

  useFocusEffect(
    useCallback(() => {
      setIsStartConvoActive(false);
      setIsBeforePromptRun(true);
      setIsBeforeImagePromptRun(true);
      setGPTStream('');
      setGPTMessage('');
      setMessages([]);
      setHasConvoBeenSaved(false);
      setIsGPT4(false);
      setHideOriginalImagePrompt(false);
      setLogConvoId(null);
      setIsDescriptionVisible(true);
      setCustomPromptInput("");
      setInputMethod("custom");
      setRefreshing(false);
      setNavStatus('closed');
      setModalVisible(false);
      setImageHistory([]);
      setResponseType('gpt');
      (async () => {
        if (!user) return;

        setLoading(true);

        const fetchUserData = async () => {
          const userSnapshot = await getDocs(collection(db, 'users'));
          userSnapshot.forEach((doc) => {
            if (doc.data().uid === user?.uid) {
              updateUserState(doc);
            }
          });
        };

        const updateUserState = (doc: any) => {
          setUserDoc(doc.id);
          //@ts-ignore
          setFullUserDoc(doc.data());

          setGpt3UserAPI(!!doc.data().gpt3UserAPI);
          setGpt4UserAPI(!!doc.data().gpt4UserAPI);
          setImagesUserAPI(!!doc.data().imagesUserAPI);

          setRemainingTokens(doc.data().tokenAdmin || 0);
          setMySavedPrompts(doc.data().savedPrompts);
          setUserUpvotedComments(doc.data().commentUpvotes);
          //@ts-ignore
          setHasOnboarded(!!doc.data().promptViewOnboardedStatus);
        };

        const fetchData = async () => {
          const querySnapshot = await getDocs(collection(db, 'prompts'));
          const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
          if (matchingDoc) {
            //@ts-ignore
            setPromptDocId(matchingDoc.id);
            //@ts-ignore
            setMatchingPrompt(matchingDoc.data());
            setTrophyCount(matchingDoc.data().trophyCount ? matchingDoc.data().trophyCount : 0);
            setOriginalPromptContent(matchingDoc.data().content);
            setPromptContent(matchingDoc.data().content);
            setUserInput(matchingDoc.data().content);
            setPromptComments(matchingDoc.data().comments?.length ? matchingDoc.data().comments : []);
            const tempVariableArray = matchingDoc.data().variables.map((variable: any) => ({
              id: Date.now(),
              name: variable,
              value: '',
            }));
            setPromptVariables(tempVariableArray);
            setPromptImage(matchingDoc?.data()?.promptImage?.imageURL);
          }
        };

        const fetchPromptRatings = async () => {
          const querySnapshot = await getDocs(collection(db, 'promptRatings'));
          const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
          if (matchingDoc) {
            setAllPromptRatings(matchingDoc?.data()?.ratings || []);
          } else {
            //@ts-ignore
            setAllPromptRatings([]);
          }
        };

        const fetchComments = async () => {
          const querySnapshot = await getDocs(collection(db, 'comments'));
          const tempAllCommentsArray = querySnapshot.docs.map((doc) => ({...doc.data(), docId: doc.id}));

          tempAllCommentsArray.forEach((commentElem) => {
            //@ts-ignore
            commentElem['replyActive'] = false;
            //@ts-ignore
            commentElem['allRepliesActive'] = false;
          });
        };

        await fetchComments();
        await fetchUserData();
        await fetchData();
        await fetchPromptRatings();
        setLoading(false);
      })();

      return () => {
      };
    }, [user, promptId])
  );

  useEffect(() => {
    if (!allPromptRatings) return;

    // Find the rating object that matches the user's UID
    // @ts-ignore
    const userRatingObj = allPromptRatings.find((ratingObj: any) => ratingObj.userID === user?.uid);

    // If a matching rating object is found, update the promptRating state
    if (userRatingObj) {
      setPromptRating(userRatingObj?.rating || 0);
    } else {
      setPromptRating(0);
    }
    let totalRating = 0;
    // @ts-ignore
    allPromptRatings.forEach((promptRating: any) => {
      totalRating = totalRating += promptRating.rating;
    });
    setTotalPromptRating(totalRating);
    // @ts-ignore
    setTotalPromptVotes(allPromptRatings?.length);
  }, [allPromptRatings]);

  useEffect(() => {
    if (hasOnboarded === null) return;
    if (Platform.OS !== 'web') {
      setModalVisible(false);
    } else {
      setModalVisible(!hasOnboarded);
    }
  }, [hasOnboarded]);

  useEffect(() => {
    if (!responseType || !fullUserDoc) return;
    if (responseType.toLowerCase() === 'image') {
      //@ts-ignore
      setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.imagesUserAPI === true)
        ? 0 : 2);
    }
    if (responseType.toLowerCase() === 'gpt') {
      if (isGPT4) {
        //@ts-ignore
        setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt4UserAPI === true)
          ? 0 : 2);
      } else {
        //@ts-ignore
        setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt3UserAPI === true)
          ? 0 : 1);
      }
    }
  }, [responseType, fullUserDoc]);

  useEffect(() => {
    if (isGPT4) {
      //@ts-ignore
      setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt4UserAPI === true)
        ? 0 : 2);
    } else {
      //@ts-ignore
      setTokenCost((fullUserDoc?.accessLevel === 'pro' || fullUserDoc?.accessLevel === 'admin' || fullUserDoc?.accessLevel === 'superuser' || fullUserDoc?.accessLevel === 'friend' || fullUserDoc?.gpt3UserAPI === true)
        ? 0 : 1);
    }
  }, [isGPT4]);

  useEffect(() => {
    // @ts-ignore
    const isCreator = fullUserDoc?.uid === matchingPrompt?.postedById;
    setIsPromptCreator(isCreator);
  }, [fullUserDoc, matchingPrompt]);

  useEffect(() => {
    // @ts-ignore
    setIsAlreadySaved(mySavedPrompts?.includes(promptId));
  }, [mySavedPrompts, promptId]);

  function customReplace(string: string, search: any, replace: any) {
    return string.split(search).join(replace);
  }

  useEffect(() => {
    let tempOriginalContent = originalPromptContent;
    promptVariables.forEach(variable => {
      // @ts-ignore
      if (tempOriginalContent?.includes(variable.name)) {
        // @ts-ignore
        tempOriginalContent = customReplace(tempOriginalContent, variable.name, (variable.value || variable.name));
      }
    });
    setPromptContent(tempOriginalContent);
    // @ts-ignore
    setUserInput(tempOriginalContent);
  }, [promptVariables]);

  useEffect(() => {
    if (!imageLoading) return;

    changeLoadingMessage();
    const interval = setInterval(changeLoadingMessage, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [imageLoading]);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  if (loading) {
    return <LoadingScreen navStatus={navStatus}
                          setNavStatus={setNavStatus}
                          navigation={navigation}
                          closeDrawerMenu={closeDrawerMenu}/>;
  }

  //@ts-ignore
  if (matchingPrompt?.deleted) {
    return <DeletedScreen navStatus={navStatus}
                          setNavStatus={setNavStatus}
                          navigation={navigation}
                          closeDrawerMenu={closeDrawerMenu}/>;
  }

  if (!hasOnboarded) {
    if (Platform.OS !== 'web') {
      return <ViewPromptMobileOnboarding
        setModalVisible={setModalVisible}
        setHasOnboarded={setHasOnboarded}
        userDoc={userDoc}
      />
    }
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <View style={{flex: 1}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <ViewPromptDesktopOnboarding modalVisible={modalVisible}
                                         setModalVisible={setModalVisible}
                                         setHasOnboarded={setHasOnboarded}
                                         userDoc={userDoc}
                                         currentScreen={currentScreen}
                                         setCurrentScreen={setCurrentScreen}/>
            <View style={styles.contentContainer}>
              <ViewPromptTitle matchingPrompt={matchingPrompt}/>
              <CustomPromptInfoButton setCurrentScreen={setCurrentScreen} setHasOnboarded={setHasOnboarded}/>
              <PromptTrophyInformation fullUserDoc={fullUserDoc}
                                       promptId={promptId}
                                       userDoc={userDoc}
                                       trophyCount={trophyCount}
                                       setTrophyCount={setTrophyCount}
                                       matchingPrompt={matchingPrompt}/>
              <PromptImage promptImage={promptImage}/>
              <PromptActions matchingPrompt={matchingPrompt}
                             fullUserDoc={fullUserDoc}
                             navigation={navigation}
                             promptDocId={promptDocId}
                             userDoc={userDoc}
                             promptId={promptId}/>
              <RatingSection promptId={promptId}
                             promptRating={promptRating}
                             totalPromptRating={totalPromptRating}
                             totalPromptVotes={totalPromptVotes}
                             setPromptRating={setPromptRating}
                             userDoc={userDoc}
                             setAllPromptRatings={setAllPromptRatings}
                             matchingPrompt={matchingPrompt}/>
              <PromptDetailsToggle isDescriptionVisible={isDescriptionVisible}
                                   setIsDescriptionVisible={setIsDescriptionVisible}
              />
              {isDescriptionVisible && <PromptDescriptionSection matchingPrompt={matchingPrompt}
                                                                 setTooltipId={setTooltipId}
                                                                 setTooltipVisible={setTooltipVisible}/>
              }
              <PromptTooltipModal tooltipVisible={tooltipVisible}
                                  setTooltipVisible={setTooltipVisible}
                                  tooltipId={tooltipId}/>
              {(isBeforePromptRun && isBeforeImagePromptRun) &&
                <>
                  {!isDescriptionVisible && <PromptContentSection promptContent={promptContent}
                                                                  setPromptContent={setPromptContent}
                                                                  setUserInput={setUserInput}
                                                                  setTooltipId={setTooltipId}
                                                                  setTooltipVisible={setTooltipVisible}
                                                                  matchingPrompt={matchingPrompt}
                                                                  promptId={promptId}/>
                  }
                  <View style={{backgroundColor: '#1a1a1a', borderRadius: 10, padding: 20, width: '100%'}}>
                    <PromptInputMethodSelector promptVariables={promptVariables}
                                               setPromptVariables={setPromptVariables}
                                               setTooltipId={setTooltipId}
                                               setTooltipVisible={setTooltipVisible}
                                               customPromptInput={customPromptInput}
                                               setCustomPromptInput={setCustomPromptInput}
                                               inputMethod={inputMethod}
                                               setInputMethod={setInputMethod}
                    />
                    <RunPromptButton fullUserDoc={fullUserDoc}
                                     userInput={userInput}
                                     setUserInput={setUserInput}
                                     remainingTokens={remainingTokens}
                                     setRemainingTokens={setRemainingTokens}
                                     messages={messages}
                                     setMessages={setMessages}
                                     userDoc={userDoc}
                                     gpt3UserAPI={gpt3UserAPI}
                                     gpt4UserAPI={gpt4UserAPI}
                                     imagesUserAPI={imagesUserAPI}
                                     setHasConvoBeenSaved={setHasConvoBeenSaved}
                                     setImageLoading={setImageLoading}
                                     setGenerating={setGenerating}
                                     promptContent={promptContent}
                                     image={image}
                                     setImage={setImage}
                                     setCanEditPrompt={setCanEditPrompt}
                                     setImageHistory={setImageHistory}
                                     responseType={responseType}
                                     setResponseType={setResponseType}
                                     isGPT4={isGPT4}
                                     setIsGPT4={setIsGPT4}
                                     logConvoId={logConvoId}
                                     setLogConvoId={setLogConvoId}
                                     tokenCost={tokenCost}
                                     isBeforePromptRun={isBeforePromptRun}
                                     setIsBeforePromptRun={setIsBeforePromptRun}
                                     isBeforeImagePromptRun={isBeforeImagePromptRun}
                                     setIsBeforeImagePromptRun={setIsBeforeImagePromptRun}
                                     customPromptInput={customPromptInput}
                                     setCustomPromptInput={setCustomPromptInput}
                                     inputMethod={inputMethod}
                                     promptId={promptId}
                    />
                  </View>
                  <PromptTokenInfo tokenCost={tokenCost}
                                   remainingTokens={remainingTokens}
                                   setTooltipId={setTooltipId}
                                   setTooltipVisible={setTooltipVisible}
                  />
                  <SavePromptButton isPromptCreator={isPromptCreator}
                                    userDoc={userDoc}
                                    promptId={promptId}
                                    isAlreadySaved={isAlreadySaved}
                                    setIsAlreadySaved={setIsAlreadySaved}
                  />
                  <PromptAIResponse GPTStream={GPTStream}
                                    fetchingGPT={fetchingGPT}
                                    isStartConvoActive={isStartConvoActive}
                                    isResponseSaved={isResponseSaved}
                                    setIsResponseSaved={setIsResponseSaved}
                                    promptContent={promptContent}
                                    fullUserDoc={fullUserDoc}
                                    promptDocId={promptDocId}
                                    promptId={promptId}
                                    GPTMessage={GPTMessage}
                                    matchingPrompt={matchingPrompt}
                                    promptImage={promptImage}
                                    userDoc={userDoc}
                  />
                </>
              }
              {!isBeforePromptRun &&
                <PromptAIConversation isBeforePromptRun={isBeforePromptRun}
                                      messages={messages}
                                      setMessages={setMessages}
                                      tokenCost={tokenCost}
                                      remainingTokens={remainingTokens}
                                      hasConvoBeenSaved={hasConvoBeenSaved}
                                      fetchingGPT={fetchingGPT}
                                      userInput={userInput}
                                      setUserInput={setUserInput}
                                      isGPT4={isGPT4}
                                      setFetchingGPT={setFetchingGPT}
                                      setIsBeforePromptRun={setIsBeforePromptRun}
                                      userDoc={userDoc}
                                      logConvoId={logConvoId}
                                      setLogConvoId={setLogConvoId}
                                      gpt3UserAPI={gpt3UserAPI}
                                      gpt4UserAPI={gpt4UserAPI}
                                      setHasConvoBeenSaved={setHasConvoBeenSaved}
                                      setRemainingTokens={setRemainingTokens}
                                      fullUserDoc={fullUserDoc}
                />
              }
              {!isBeforeImagePromptRun &&
                <View sx={{width: ['85%', '90%', '100%'], alignItems: 'center', marginTop: 20}}>
                  <View sx={{alignItems: 'center', marginBottom: 20}}>
                    <PromptImageHeader hideOriginalImagePrompt={hideOriginalImagePrompt}
                                       generating={generating}
                                       canEditPrompt={canEditPrompt}
                                       promptContent={promptContent}
                                       setPromptContent={setPromptContent}
                    />
                    <ShowImagePromptContent hideOriginalImagePrompt={hideOriginalImagePrompt}
                                            setHideOriginalImagePrompt={setHideOriginalImagePrompt}
                                            generating={generating}
                                            setGenerating={setGenerating}
                                            canEditPrompt={canEditPrompt}
                                            setCanEditPrompt={setCanEditPrompt}
                                            setImageLoading={setImageLoading}
                                            setIsBeforePromptRun={setIsBeforePromptRun}
                                            setIsBeforeImagePromptRun={setIsBeforeImagePromptRun}
                                            userDoc={userDoc}
                                            promptContent={promptContent}
                                            image={image}
                                            setImage={setImage}
                                            setImageHistory={setImageHistory}
                                            setImageLoadingMessage={setImageLoadingMessage}
                    />
                    <PromptImageView imageLoading={imageLoading}
                                     imageloadingMessage={imageloadingMessage}
                                     image={image}
                    />
                    <ImageHistory imageLoading={imageLoading}
                                  imageHistory={imageHistory}
                                  tokenCost={tokenCost}
                                  remainingTokens={remainingTokens}
                                  image={image}
                                  setImage={setImage}
                                  setImageHistory={setImageHistory}
                                  setImageLoadingMessage={setImageLoadingMessage}
                                  setGenerating={setGenerating}
                                  setImageLoading={setImageLoading}
                                  setIsBeforePromptRun={setIsBeforePromptRun}
                                  setIsBeforeImagePromptRun={setIsBeforeImagePromptRun}
                                  userDoc={userDoc}
                                  promptContent={promptContent}
                                  setCanEditPrompt={setCanEditPrompt}
                    />
                  </View>
                </View>
              }
              <PromptCommentSection promptId={promptId}
                                    promptComments={promptComments}
                                    userDoc={userDoc}
                                    fullUserDoc={fullUserDoc}
                                    promptDocId={promptDocId}
                                    setPromptComments={setPromptComments}
                                    userUpvotedComments={userUpvotedComments}
                                    setUserUpvotedComments={setUserUpvotedComments}
              />
            </View>
          </Pressable>
        </ScrollView>
      </View>
      <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#202124',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
});

export default NewViewPromptScreen;
