import React, {FC, useCallback, useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Button,
  Image,
  Modal,
  Platform,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TextInput,
  useWindowDimensions
} from 'react-native';
import {Pressable, Text, useSx, View} from 'dripsy';
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import {db} from "../../config/firebase";
import {collection, getDocs} from "firebase/firestore";
import {StackScreenProps} from "@react-navigation/stack";
import * as Clipboard from 'expo-clipboard';
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import {getAuth} from 'firebase/auth';
import useUnits from "rxn-units";
import {Link, useFocusEffect} from '@react-navigation/native';
import {Feather, Ionicons} from '@expo/vector-icons';
import {defaultImageArray} from "../../utils/misc/defaultImageData";
import {HelperText, IconButton, Portal} from "react-native-paper";
import {CommentList} from "../../components/CommentList";
import PromptDetailsToggle from "../../components/Common/PromptDetailsToggle";

const ViewPromptPreAuthScreen: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const {vh} = useUnits();
  const [promptId, setPromptId] = useState();
  const [navStatus, setNavStatus] = useState('closed');
  const [matchingPrompt, setMatchingPrompt] = useState();
  const [mySavedPrompts, setMySavedPrompts] = useState();
  const [userDoc, setUserDoc] = useState('');
  const [fullUserDoc, setFullUserDoc] = useState();
  const [userUpvotedComments, setUserUpvotedComments] = useState();
  const [promptVariables, setPromptVariables] = useState([]);
  const [promptComments, setPromptComments] = useState([]);
  const [promptLeaveComment, setPromptLeaveComment] = useState('');
  const [promptUpvotes, setPromptUpvotes] = useState(0);
  const [promptHasUpvoted, setPromptHasUpvoted] = useState(false);
  const [originalPromptContent, setOriginalPromptContent] = useState();
  const [promptContent, setPromptContent] = useState();
  const customSX = useSx();
  const {user} = useAuthentication();
  const [isAlreadySaved, setIsAlreadySaved] = useState(false);
  const [isPromptCreator, setIsPromptCreator] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [promptDocId, setPromptDocId] = useState();
  const [promptImage, setPromptImage] = useState();
  const [GPTResponse, setGPTResponse] = useState([]);
  const [GPTStream, setGPTStream] = useState('');
  const [commentReplyValues, setCommentReplyValues] = useState([]);
  const [parentComments, setParentComments] = useState([]);
  const [childComments, setChildComments] = useState([]);
  const [fetchingGPT, setFetchingGPT] = useState(false);
  const auth = getAuth();
  const [hasVerifiedEmail, setHasVerifiedEmail] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [isSaveResponseActive, setIsSaveResponseActive] = useState(false);
  const [isResponseSaved, setIsResponseSaved] = useState(false);
  const [GPTMessage, setGPTMessage] = useState();
  const [cancelActive, setCancelActive] = useState(false);
  const [remainingTokens, setRemainingTokens] = useState(0);
  const [promptRating, setPromptRating] = useState(0);
  const [isSaveRatingActive, setIsSaveRatingActive] = useState(false);
  const [totalPromptRating, setTotalPromptRating] = useState(0);
  const [totalPromptVotes, setTotalPromptVotes] = useState(0);
  const [allPromptRatings, setAllPromptRatings] = useState();
  const [isSavingRating, setIsSavingRating] = useState(false);
  const [isLeavingComment, setIsLeavingComment] = useState(false);
  const [isLeavingCommentReply, setIsLeavingCommentReply] = useState(false);
  const [isUpvotingComment, setIsUpvotingComment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputHeight, setInputHeight] = useState();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipId, setTooltipId] = useState(null);
  const [showPromptDescription, setShowPromptDescription] = useState(true);
  const [randomImage, setRandomImage] = useState();
  const playgroundPromptId = 'b148c38f-0cc3-435d-8ccc-93e59fe028e3';
  const [showPromptContent, setShowPromptContent] = useState(true);
  const [isMobileTruncated, setIsMobileTruncated] = useState(true);
  const [isMobileContentTruncated, setIsMobileContentTruncated] = useState(true);
  const [userInput, setUserInput] = useState('');
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(true)

  const windowDimensions = useWindowDimensions();

  useFocusEffect(
    useCallback(() => {
      setPromptId(route?.params?.promptId);

      const randomIndex = Math.floor(Math.random() * defaultImageArray.length);
      // @ts-ignore
      setRandomImage(defaultImageArray[randomIndex]);
    }, [route?.params?.promptId])
  );

  const showTooltip = (id: any) => {
    setTooltipId(id);
    setTooltipVisible(true);
  };

  const renderTooltipContent = () => {
    switch (tooltipId) {
      // @ts-ignore
      case 'promptContent':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              This is the prompt you will be running. It may contain variables, which are words or phrases that are
              surrounded by %| and |% symbols. To customize the prompt, you can either tap in the box to make edits
              manually, or you can update the variables in the section below the prompt.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              For example, if the prompt says "Tell me a story about %|TOPIC|%," you can fill in the value for %|TOPIC|%
              in the section below the prompt. If you fill it in with "space travel," the AI will tell you a story about
              space travel.
            </Text>
          </View>
        );
      // @ts-ignore
      case 'promptDescription':
        return (
          <View>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              The prompt description provides an overview of the prompt you are about to run. While it is not the actual
              prompt text, it should serve as an informative guide to help you understand the purpose and functionality
              of the prompt. A well-crafted prompt description should include details about the prompt and examples of
              input values for the variables.
            </Text>
            <Text
              style={{
                color: '#f0f0f3',
                fontSize: 16,
                lineHeight: 24,
                marginBottom: 20,
              }}
            >
              By offering a thorough description, users will be able to make the most of the customization features and
              tailor the prompt to suit their specific needs.
            </Text>
          </View>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      const screenWidth = windowDimensions.width;

      if (screenWidth < 768) {
        // @ts-ignore
        setInputHeight(200);
      } else {
        // @ts-ignore
        setInputHeight(300);
      }
    };

    updateHeight();
  }, [windowDimensions]);

  const inputStyle = {
    height: inputHeight,
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ccc',
    fontSize: 16,
    lineHeight: 24,
    backgroundColor: '#333',
    color: '#fff',
  };

  const onRefresh = useCallback(() => {
    if (!user) return;
    setRefreshing(true);
    (async () => {
      const querySnapshot = await getDocs(collection(db, "prompts"));
      const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
      if (matchingDoc) {
        const {content, comments, promptUpvotes, variables} = matchingDoc.data();
        // @ts-ignore
        setPromptDocId(matchingDoc.id);
        // @ts-ignore
        setMatchingPrompt(matchingDoc.data());
        setOriginalPromptContent(content);
        setPromptContent(content);
        setPromptComments(comments.length ? comments : []);
        setPromptUpvotes(promptUpvotes?.length || 0);
        const tempVariableArray = variables.map((variable: any) => ({id: Date.now(), name: variable, value: ''}));
        setPromptVariables(tempVariableArray);
        setPromptImage(matchingDoc?.data()?.promptImage?.imageURL);
      }
    })();
    (async () => {
      const querySnapshot = await getDocs(collection(db, "comments"));
      const tempAllCommentsArray = querySnapshot.docs.map(doc => ({...doc.data(), docId: doc.id}));

      tempAllCommentsArray.forEach(commentElem => {
        // @ts-ignore
        commentElem['replyActive'] = false;
        // @ts-ignore
        commentElem['allRepliesActive'] = false;
      });
      // @ts-ignore
      setAllComments(tempAllCommentsArray?.length ? tempAllCommentsArray : []);
    })();
    (async () => {
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === user?.uid) {
          const {savedPrompts, commentUpvotes, promptUpvotes} = doc.data();
          setUserDoc(doc.id);
          // @ts-ignore
          setFullUserDoc(doc.data());
          setRemainingTokens(doc?.data()?.tokenAdmin || 0);
          setMySavedPrompts(savedPrompts);
          setUserUpvotedComments(commentUpvotes);
          if (promptUpvotes?.length) {
            promptUpvotes.forEach((upvote: any) => {
              if (promptId === upvote.promptId) {
                setPromptHasUpvoted(true);
              }
            });
          }
        }
      });
    })();
    (async () => {
      const querySnapshot = await getDocs(collection(db, "promptRatings"));
      const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
      if (matchingDoc) {
        setAllPromptRatings(matchingDoc.data().ratings);
      }
    })();
    // @ts-ignore
    setHasVerifiedEmail(auth?.currentUser?.emailVerified);

    const tempPromptComments = [...promptComments];
    // @ts-ignore
    const tempParentCommentsArray = [];
    // @ts-ignore
    const tempChildCommentsArray = [];
    tempPromptComments.forEach(comment => {
      // @ts-ignore
      if (comment?.parentCommentId) {
        tempChildCommentsArray.push(comment);
      } else {
        tempParentCommentsArray.push(comment);
      }
    });
    // @ts-ignore
    setParentComments(tempParentCommentsArray?.length ? tempParentCommentsArray : []);
    // @ts-ignore
    setChildComments(tempChildCommentsArray?.length ? tempChildCommentsArray : []);

    setRefreshing(false);
  }, []);

  const copyToClipboard = async () => {
    if (promptContent) await Clipboard.setStringAsync(promptContent);
  };

  const getUpvoteCount = (commentId: string) => {
    // @ts-ignore
    const comment = allComments.find(c => c.docId === commentId);
    // @ts-ignore
    return (comment?.commentUpvotes?.length) || 0;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "prompts"));
      const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
      if (matchingDoc) {
        const {content, comments, promptUpvotes, variables} = matchingDoc.data();
        // @ts-ignore
        setPromptDocId(matchingDoc.id);
        // @ts-ignore
        setMatchingPrompt(matchingDoc.data());
        setOriginalPromptContent(content);
        setPromptContent(content);
        setPromptComments(comments?.length ? comments : []);
        setPromptUpvotes(promptUpvotes?.length || 0);
        const tempVariableArray = variables.map((variable: any) => ({id: Date.now(), name: variable, value: ''}));
        setPromptVariables(tempVariableArray);
        setPromptImage(matchingDoc?.data()?.promptImage?.imageURL);
        setLoading(false);
      }
    })();
  }, [promptId]);

  useEffect(() => {
    (async () => {
      const querySnapshot = await getDocs(collection(db, "comments"));
      const tempAllCommentsArray = querySnapshot.docs.map(doc => ({...doc.data(), docId: doc.id}));

      tempAllCommentsArray.forEach(commentElem => {
        // @ts-ignore
        commentElem['replyActive'] = false;
        // @ts-ignore
        commentElem['allRepliesActive'] = false;
      });
      // @ts-ignore
      setAllComments(tempAllCommentsArray?.length ? tempAllCommentsArray : []);
    })();
  }, [promptId]);

  useEffect(() => {
    if (!user) return;
    (async () => {
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === user?.uid) {
          const {savedPrompts, commentUpvotes, promptUpvotes} = doc.data();
          setUserDoc(doc.id);
          // @ts-ignore
          setFullUserDoc(doc.data());
          setRemainingTokens(doc?.data()?.tokenAdmin || 0);
          setMySavedPrompts(savedPrompts);
          setUserUpvotedComments(commentUpvotes);
          if (promptUpvotes?.length) {
            promptUpvotes.forEach((upvote: any) => {
              if (promptId === upvote.promptId) {
                setPromptHasUpvoted(true);
              }
            });
          }
        }
      });
    })();
  }, [user]);

  useEffect(() => {
    // @ts-ignore
    setIsAlreadySaved(mySavedPrompts?.includes(promptId));
  }, [mySavedPrompts, promptId]);

  function customReplace(string: string, search: any, replace: any) {
    return string.split(search).join(replace);
  }

  useEffect(() => {
    let tempOriginalContent = originalPromptContent;
    promptVariables.forEach(variable => {
      // @ts-ignore
      if (tempOriginalContent?.includes(variable.name)) {
        // @ts-ignore
        tempOriginalContent = customReplace(tempOriginalContent, variable.name, (variable.value || variable.name));
      }
    });
    setPromptContent(tempOriginalContent);
  }, [promptVariables]);

  useEffect(() => {
    if (!auth?.currentUser) return;
    // @ts-ignore
    setHasVerifiedEmail(auth?.currentUser?.emailVerified);
  }, [auth]);

  useEffect(() => {
    // @ts-ignore
    const isCreator = fullUserDoc?.uid === matchingPrompt?.postedById;
    setIsPromptCreator(isCreator);
  }, [fullUserDoc, matchingPrompt]);

  // @ts-ignore
  let GPTStreamInterval;

  useEffect(() => {
    if (!GPTResponse?.length) {
      // @ts-ignore
      clearInterval(GPTStreamInterval)
      setCancelActive(false);
      return;
    }
    setCancelActive(true);
    let wordCounter = 0;
    let tempGPTResponseString = '';
    GPTStreamInterval = setInterval(() => {
      if (wordCounter === GPTResponse.length - 1) {
        // @ts-ignore
        clearInterval(GPTStreamInterval);
        setCancelActive(false);
      }
      tempGPTResponseString = `${tempGPTResponseString} ${GPTResponse[wordCounter]}`.trimStart();
      setGPTStream(tempGPTResponseString);
      wordCounter++;
    }, 90);
    // @ts-ignore
    return () => clearInterval(GPTStreamInterval);
  }, [GPTResponse]);

  useEffect(() => {
    // @ts-ignore
    if (!promptComments?.length) {
      setParentComments([]);
      setChildComments([]);
      return;
    }
    const tempPromptComments = [...promptComments];
    // @ts-ignore
    const tempParentCommentsArray = [];
    // @ts-ignore
    const tempChildCommentsArray = [];
    tempPromptComments?.forEach(comment => {
      // @ts-ignore
      if (comment?.parentCommentId) {
        tempChildCommentsArray.push(comment);
      } else {
        tempParentCommentsArray.push(comment);
      }
    });
    // @ts-ignore
    setParentComments(tempParentCommentsArray?.length ? tempParentCommentsArray : []);
    // @ts-ignore
    setChildComments(tempChildCommentsArray?.length ? tempChildCommentsArray : []);
  }, [allComments, promptComments, promptId]);

  useEffect(() => {
    if (!promptId) return;
    (async () => {
      const querySnapshot = await getDocs(collection(db, "promptRatings"));
      const matchingDoc = querySnapshot.docs.find((doc) => promptId === doc.data().promptId);
      if (matchingDoc) {
        setAllPromptRatings(matchingDoc.data().ratings);
      }
    })();
  }, [promptId]);

  useEffect(() => {
    if (!allPromptRatings) return;

    // Find the rating object that matches the user's UID
    // @ts-ignore
    const userRatingObj = allPromptRatings.find((ratingObj: any) => ratingObj.userID === user?.uid);

    // If a matching rating object is found, update the promptRating state
    if (userRatingObj) setPromptRating(userRatingObj.rating);
    let totalRating = 0;
    // @ts-ignore
    allPromptRatings.forEach((promptRating: any) => {
      totalRating = totalRating += promptRating.rating;
    });
    setTotalPromptRating(totalRating);
    // @ts-ignore
    setTotalPromptVotes(allPromptRatings?.length);
  }, [allPromptRatings]);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  if (loading) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>is loading...</Text>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }
  // @ts-ignore
  if (matchingPrompt?.deleted) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>Sorry, this prompt is no longer available...</Text>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <View style={styles.contentContainer}>
            <View style={{maxWidth: 750, marginBottom: 4}}>
              <Text style={{color: '#FFF', fontSize: 25, textAlign: 'center'}}>
                {// @ts-ignore
                  matchingPrompt?.title}
              </Text>
            </View>
            <View style={{marginBottom: 15, alignItems: 'center'}}>
              <Image source={{uri: promptImage || randomImage}}
                     style={[{width: 400, borderRadius: 10},
                       customSX({maxWidth: [315, '90%', '100%'], height: [240, 300, 300]})]}/>
            </View>
            <View style={{marginBottom: 12}}>
              <Link to={{
                screen: 'User Prompts', params: {// @ts-ignore
                  userName: matchingPrompt?.postedById
                }
              }}
                    style={{
                      flexDirection: 'row',
                      borderColor: '#FFF',
                      borderWidth: 1,
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 10
                    }}>
                <View>
                  <Text style={{color: '#FFF', marginRight: 6}}>
                    Posted by:
                  </Text>
                </View>
                <View>
                  <Text style={{color: '#FFF'}}>
                    {// @ts-ignore
                      matchingPrompt?.userName}
                  </Text>
                </View>
              </Link>
            </View>
            <View style={{marginTop: 5, marginBottom: 15}}>
              <Pressable
                onPress={() => navigation.navigate('Welcome')}
                style={({pressed}) => [
                  {
                    backgroundColor: pressed ? "#444" : "#333",
                    borderColor: "#ccc",
                    borderWidth: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingVertical: 8,
                    paddingHorizontal: 20,
                    borderRadius: 8,
                    shadowColor: "#000",
                    shadowOffset: {width: 0, height: 4},
                    shadowOpacity: 0.3,
                    shadowRadius: 4,
                    elevation: 4,
                  },
                ]}
              >
                <Text style={{color: "#ccc", fontSize: 16, fontWeight: "bold"}}>
                  Sign In To Rate Prompt
                </Text>
              </Pressable>
            </View>
            {(promptId !== playgroundPromptId) &&
              <View style={{marginBottom: 5, flexDirection: 'row'}}>
                <View style={{
                  borderWidth: 1,
                  borderColor: '#FFF',
                  borderRadius: 15,
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  marginRight: 15,
                }}>
                  <Text style={{color: '#FFF'}}>
                    Total Points: {totalPromptRating || 'None'}
                  </Text>
                </View>
                <View style={{
                  borderWidth: 1,
                  borderColor: '#FFF',
                  borderRadius: 15,
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                }}>
                  <Text style={{color: '#FFF'}}>
                    Average
                    Rating: {(totalPromptRating && totalPromptVotes) ? (totalPromptRating / totalPromptVotes).toFixed(2) : 'None'}
                  </Text>
                </View>
              </View>
            }
            <PromptDetailsToggle isDescriptionVisible={isDescriptionVisible}
                                 setIsDescriptionVisible={setIsDescriptionVisible}
            />
            {(isDescriptionVisible) &&
              //@ts-ignore
              (showPromptDescription && matchingPrompt?.description) &&
              <>
                {Platform.OS === 'web' ?
                  <View style={{
                    backgroundColor: '#2a2a2a',
                    borderRadius: 5,
                    padding: 15,
                    marginTop: 8,
                  }}
                        sx={{width: [300, 400, 600]}}
                  >
                    <View style={{alignItems: 'center'}}>
                      <Ionicons name="md-book-sharp" size={60} color="#ccc"/>
                    </View>
                    <Text style={{
                      color: '#ccc',
                      fontSize: 14,
                      fontStyle: 'italic',
                      marginBottom: 8,
                    }}>
                      This section provides a description of this prompt, not the prompt itself. Click the tooltip icon
                      for more info.
                    </Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
                      <HelperText type="info" visible={true}
                                  style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
                        Prompt Description
                      </HelperText>
                      <IconButton
                        icon="information-outline"
                        size={20}
                        style={{margin: 0}}
                        onPress={() => showTooltip('promptDescription')}
                        theme={{colors: {primary: "#ccc"}}}
                      />
                    </View>
                    <TextInput
                      value={
                        //@ts-ignore
                        matchingPrompt?.description?.replace(/\\n/g, "\n") ||
                        "The prompt author did not provide a description for this prompt. Please tap the Show Full Prompt Content button to get a better idea of how to use it!"
                      }
                      style={{
                        ...inputStyle,
                        borderColor: "#ccc",
                        fontSize: 16,
                        lineHeight: 24,
                      }}
                      editable={false}
                      multiline={true}
                    />
                  </View>
                  :
                  <View style={{
                    backgroundColor: '#2a2a2a',
                    borderRadius: 5,
                    padding: 10,
                    marginTop: 8,
                  }}
                        sx={{width: [300, 400, 600]}}
                  >
                    <View style={{alignItems: 'center'}}>
                      <Ionicons name="md-book-sharp" size={60} color="#ccc"/>
                    </View>
                    <Text style={{
                      color: '#ccc',
                      fontSize: 14,
                      fontStyle: 'italic',
                      marginBottom: 8,
                    }}>
                      This section provides a description of the prompt you are about to run, not the prompt itself.
                      Tap the tooltip icon for more info.
                    </Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
                      <HelperText type="info" visible={true}
                                  style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
                        Prompt Description
                      </HelperText>
                      <IconButton
                        icon="information-outline"
                        size={20}
                        style={{margin: 0}}
                        onPress={() => showTooltip('promptDescription')}
                        theme={{colors: {primary: "#ccc"}}}
                      />
                    </View>
                    <TextInput
                      value={
                        isMobileTruncated ?
                          // @ts-ignore
                          (matchingPrompt?.description?.replace(/\\n/g, "\n").substring(0, 300) + '...') :
                          // @ts-ignore
                          (matchingPrompt?.description?.replace(/\\n/g, "\n") ||
                            "The prompt author did not provide a description for this prompt. Please tap the Show Full Prompt Content button to get a better idea of how to use it!")
                      }
                      style={{
                        ...inputStyle,
                        borderColor: "#ccc",
                        fontSize: 16,
                        lineHeight: 24,
                        height: 'auto',
                      }}
                      editable={false}
                      multiline={true}
                    />
                  </View>
                }
              </>
            }
            {// @ts-ignore
              (isDescriptionVisible && matchingPrompt?.description) &&
              <View style={{marginTop: 10, marginBottom: 5}}>
                {Platform.OS === 'web' ?
                  <Pressable
                    style={({pressed}) => [
                      {
                        backgroundColor: pressed ? "#4a4a4a" : "transparent",
                        borderColor: "#ccc",
                        borderWidth: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        borderRadius: 5,
                        shadowColor: "#000",
                        shadowOffset: {width: 0, height: 1},
                        shadowOpacity: 0.2,
                        shadowRadius: 1,
                        elevation: 2,
                      },
                    ]}
                    onPress={() => setShowPromptDescription(!showPromptDescription)}
                  >
                    <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
                      {showPromptDescription ? "Hide Prompt Description" : "Show Prompt Description"}
                    </Text>
                    <Ionicons name={showPromptDescription ? "md-book-outline" : "md-book-sharp"} size={20}
                              color="#FFF"/>
                  </Pressable>
                  :
                  <Pressable
                    style={({pressed}) => [
                      {
                        backgroundColor: pressed ? "#4a4a4a" : "transparent",
                        borderColor: "#ccc",
                        borderWidth: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        borderRadius: 5,
                        shadowColor: "#000",
                        shadowOffset: {width: 0, height: 1},
                        shadowOpacity: 0.2,
                        shadowRadius: 1,
                        elevation: 2,
                      },
                    ]}
                    onPress={() => setIsMobileTruncated(!isMobileTruncated)}
                  >
                    <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
                      {isMobileTruncated ? "Show Full Prompt Description" : "Collapse Prompt Description"}
                    </Text>
                    <Ionicons
                      name={isMobileTruncated ? "md-expand" : "md-contract"}
                      size={20}
                      color="#FFF"
                    />
                  </Pressable>
                }
              </View>
            }
            <View style={{marginBottom: 12}}>
              {(!isDescriptionVisible && showPromptContent && promptId !== playgroundPromptId) &&
                <>
                  {Platform.OS === 'web' ?
                    <View style={{
                      backgroundColor: '#2a2a2a',
                      borderRadius: 5,
                      padding: 15,
                      marginTop: 8,
                    }}
                          sx={{width: [300, 400, 600]}}>
                      <View style={{alignItems: 'center'}}>
                        <Ionicons name="md-create" size={60} color="#ccc"/>
                      </View>
                      <Text style={{
                        color: '#ccc',
                        fontSize: 14,
                        fontStyle: 'italic',
                        marginBottom: 8,
                      }}>
                        This section contains the prompt that you are about to run. Configure the variables before
                        running the prompt or click the tooltip icon for more info.
                      </Text>
                      <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
                        <HelperText type="info" visible={true}
                                    style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
                          Prompt Content <Text style={{fontSize: 14, fontWeight: 'normal'}}>(Editable)</Text>
                        </HelperText>
                        <IconButton
                          icon="information-outline"
                          size={20}
                          style={{margin: 0}}
                          onPress={() => showTooltip('promptContent')}
                          theme={{colors: {primary: "#ccc"}}}
                        />
                      </View>
                      <TextInput
                        value={promptContent}
                        style={{
                          ...inputStyle,
                          borderColor: "#ccc",
                          fontSize: 16,
                          lineHeight: 24,
                        }}
                        editable={true}
                        multiline={true}
                        onChangeText={(text: any) => {
                          // @ts-ignore
                          setPromptContent(text);
                          setUserInput(text);
                        }}
                      />
                    </View>
                    :
                    <View style={{
                      backgroundColor: '#2a2a2a',
                      borderRadius: 5,
                      padding: 10,
                      marginTop: 8,
                    }}
                          sx={{width: [300, 400, 500]}}>
                      <View style={{alignItems: 'center'}}>
                        <Ionicons name="md-create" size={60} color="#ccc"/>
                      </View>
                      <Text style={{
                        color: '#ccc',
                        fontSize: 14,
                        fontStyle: 'italic',
                        marginBottom: 8,
                      }}>
                        This section contains the prompt that you are about to run. Configure the variables before
                        running the prompt or tap the tooltip icon for more info.
                      </Text>
                      <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 2}}>
                        <HelperText type="info" visible={true}
                                    style={{color: '#ccc', fontSize: 20, fontWeight: 'bold'}}>
                          Prompt Content <Text style={{fontSize: 14, fontWeight: 'normal'}}>(Editable)</Text>
                        </HelperText>
                        <IconButton
                          icon="information-outline"
                          size={20}
                          style={{margin: 0}}
                          onPress={() => showTooltip('promptContent')}
                          theme={{colors: {primary: "#ccc"}}}
                        />
                      </View>
                      <TextInput
                        value={
                          isMobileContentTruncated ?
                            // @ts-ignore
                            (promptContent.replace(/\\n/g, "\n").substring(0, 300) + '...') :
                            // @ts-ignore
                            (promptContent.replace(/\\n/g, "\n"))
                        }
                        style={{
                          ...inputStyle,
                          borderColor: "#ccc",
                          fontSize: 16,
                          lineHeight: 24,
                          height: 'auto',
                        }}
                        editable={true}
                        multiline={true}
                        onChangeText={(text: any) => {
                          // @ts-ignore
                          setPromptContent(text);
                          setUserInput(text);
                        }}
                      />
                    </View>
                  }
                </>
              }
              <Portal>
                <Modal
                  visible={tooltipVisible}
                  onRequestClose={() => setTooltipVisible(false)}
                  transparent={true}
                  animationType="slide"
                >
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      padding: 20,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: '#2c2c2e',
                        borderRadius: 10,
                        padding: 20,
                        width: '100%',
                        maxWidth: 400,
                      }}
                    >
                      {renderTooltipContent()}
                      <Button
                        onPress={() => setTooltipVisible(false)}
                        title="Got it!"
                      />
                    </View>
                  </View>
                </Modal>
              </Portal>
              {(!isDescriptionVisible && promptId !== playgroundPromptId) &&
                <View style={{marginTop: 5, marginBottom: 10, flexDirection: 'row', justifyContent: 'center'}}>
                  {Platform.OS === 'web' ?
                    <Pressable
                      onPress={() => setShowPromptContent(!showPromptContent)}
                      style={({pressed}) => [
                        {
                          backgroundColor: pressed ? "#4a4a4a" : "transparent",
                          borderColor: "#ccc",
                          borderWidth: 1,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 10,
                          borderRadius: 5,
                          shadowColor: "#000",
                          shadowOffset: {width: 0, height: 1},
                          shadowOpacity: 0.2,
                          shadowRadius: 1,
                          elevation: 2,
                        },
                      ]}
                    >
                      <Text style={{color: "#fff", fontSize: 16, marginRight: 5}}>
                        {showPromptContent ? "Hide Prompt Content" : "Show Prompt Content"}
                      </Text>
                      <Feather name={showPromptContent ? "file-minus" : "file-plus"} size={20} color="#FFF"
                               style={{marginLeft: 5}}/>
                    </Pressable>
                    :
                    <Pressable
                      style={({pressed}) => [
                        {
                          backgroundColor: pressed ? "#4a4a4a" : "transparent",
                          borderColor: "#ccc",
                          borderWidth: 1,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 10,
                          borderRadius: 5,
                          shadowColor: "#000",
                          shadowOffset: {width: 0, height: 1},
                          shadowOpacity: 0.2,
                          shadowRadius: 1,
                          elevation: 2,
                        },
                      ]}
                      onPress={() => setIsMobileContentTruncated(!isMobileContentTruncated)}
                    >
                      <Text style={{color: "#fff", marginRight: 5, fontSize: 16}}>
                        {isMobileContentTruncated ? "Show Full Prompt Content" : "Collapse Prompt Content"}
                      </Text>
                      <Ionicons
                        name={isMobileContentTruncated ? "md-expand" : "md-contract"}
                        size={20}
                        color="#FFF"
                      />
                    </Pressable>
                  }
                </View>
              }
            </View>
            <View style={{marginBottom: 10}}>
              <Pressable
                onPress={() => navigation.navigate('Welcome')}
                style={({pressed}) => [
                  {
                    backgroundColor: pressed ? "#6A9AE2" : "#3273DC",
                    borderColor: "#fff",
                    borderWidth: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingVertical: 12,
                    paddingHorizontal: 30,
                    borderRadius: 8,
                    shadowColor: "#000",
                    shadowOffset: {width: 0, height: 4},
                    shadowOpacity: 0.3,
                    shadowRadius: 4,
                    elevation: 4,
                  },
                ]}
              >
                <Text style={{color: "#fff", fontSize: 18, fontWeight: "bold"}}>
                  Sign In To Run Prompt
                </Text>
              </Pressable>
            </View>
            {!!GPTStream &&
              <View style={[{
                marginBottom: 12,
                borderWidth: 1,
                borderColor: '#FFF',
                borderRadius: 10,
                padding: 10,
              }, customSX({width: [300, 450, 600]})]}>
                <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 8}}>
                  {!fetchingGPT ? "AI Response" : "Waiting on AI Response"}
                </Text>
                <Text style={{color: '#FFF'}}>
                  {GPTStream}
                </Text>
              </View>
            }
            {(promptId !== playgroundPromptId && !!promptComments.length) &&
              <View style={{marginTop: 20}}>
                <Text style={{fontSize: 24, fontWeight: 'bold', color: '#fff', marginBottom: 10, textAlign: 'center'}}>
                  Comments
                </Text>
                <CommentList
                  promptComments={promptComments}
                  //@ts-ignore
                  leaveComment={() => {
                    return false
                  }}
                  //@ts-ignore
                  leaveCommentReply={() => {
                    return false
                  }}
                  hasBeenUpvoted={() => {
                    return false
                  }}
                  upvoteComment={() => {
                    return false
                  }}
                  //@ts-ignore
                  reportComment={() => {
                    return false
                  }}
                  preAuth={true}
                />
              </View>
            }
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#202124',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  sliderContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  slider: {
    width: 200,
    height: 40,
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    color: '#FFF',
    fontSize: 24,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
});

export default ViewPromptPreAuthScreen;
