import React, {FC, useCallback, useEffect, useState} from 'react';
import {ActivityIndicator, Button, Image, RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, Text, useSx, View} from 'dripsy';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {db} from "../../config/firebase";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {StackScreenProps} from "@react-navigation/stack";
import {TextInput} from "react-native-paper";
import * as Clipboard from 'expo-clipboard';
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import useUnits from "rxn-units";
import {defaultImageArray} from "../../utils/misc/defaultImageData";
import {useFocusEffect} from '@react-navigation/native';

const ViewSavedResponse: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const {vh} = useUnits();
  const [responseId, setResponseId] = useState();
  const [navStatus, setNavStatus] = useState('closed');
  const [matchingPrompt, setMatchingPrompt] = useState();
  const [promptContent, setPromptContent] = useState();
  const customSX = useSx();
  const {user} = useAuthentication();
  const [promptDocId, setPromptDocId] = useState();
  const [promptImage, setPromptImage] = useState();
  const [GPTResponse, setGPTResponse] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [isSavedByUser, setIsSavedByUser] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [promptTitle, setPromptTitle] = useState();
  const [editPromptTitle, setEditPromptTitle] = useState();
  const [loading, setLoading] = useState(true);
  const [showTrueDelete, setShowTrueDelete] = useState(false);
  const [randomImage, setRandomImage] = useState();

  useFocusEffect(
    useCallback(() => {
      setResponseId(route?.params?.responseId);
      setShowTrueDelete(false);
      const randomIndex = Math.floor(Math.random() * defaultImageArray.length);
      // @ts-ignore
      setRandomImage(defaultImageArray[randomIndex]);
    }, [route?.params?.responseId])
  );

  const onRefresh = useCallback(() => {
    if (!user) return;
    setRefreshing(true);
    (async () => {
      const querySnapshot = await getDocs(collection(db, "savedResponses"));
      const matchingDoc = querySnapshot.docs.find((doc) => responseId === doc.id);
      if (matchingDoc) {
        // @ts-ignore
        setPromptDocId(matchingDoc.id);
        // @ts-ignore
        setMatchingPrompt(matchingDoc.data());
        setPromptContent(matchingDoc.data().promptContent);
        setPromptImage(matchingDoc?.data()?.promptImage);
        setPromptTitle(matchingDoc?.data().promptName);
        setGPTResponse(matchingDoc?.data().responseContent);
        setEditPromptTitle(matchingDoc?.data().promptName);
      }
      setRefreshing(false);
    })();
  }, []);

  const copyToClipboard = async () => {
    if (promptContent) await Clipboard.setStringAsync(promptContent);
  };

  const saveResponseTitle = async () => {
    const tempEditTitleValue = editPromptTitle
    setPromptTitle(tempEditTitleValue);
    // @ts-ignore
    const promptRef = doc(db, "savedResponses", promptDocId);
    await updateDoc(promptRef, {promptName: tempEditTitleValue});
    setIsEditingTitle(false);
  };

  useFocusEffect(
    useCallback(() => {
      const fetchData = async () => {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, "savedResponses"));
        const matchingDoc = querySnapshot.docs.find((doc) => responseId === doc.id);
        if (matchingDoc) {
          // @ts-ignore
          setPromptDocId(matchingDoc.id);
          // @ts-ignore
          setMatchingPrompt(matchingDoc.data());
          setPromptContent(matchingDoc.data().promptContent);
          setPromptImage(matchingDoc?.data()?.promptImage);
          setPromptTitle(matchingDoc?.data().promptName);
          setGPTResponse(matchingDoc?.data().responseContent);
          setEditPromptTitle(matchingDoc?.data().promptName);
          setLoading(false);
        }
      };
      fetchData();
    }, [responseId])
  );

  useEffect(() => {
    if (!matchingPrompt || !user) return;
    // @ts-ignore
    if (matchingPrompt?.savedById === user?.uid) {
      setIsSavedByUser(true);
    }
  }, [matchingPrompt, user]);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  const deleteSavedResponse = async () => {
    // @ts-ignore
    const promptRef = doc(db, "savedResponses", promptDocId);
    // @ts-ignore
    await updateDoc(promptRef, {deleted: true});

    navigation.navigate("highPerplexity")
  }

  if (loading) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>is loading...</Text>
                <ActivityIndicator size="large" color="#C8BDD9"/>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  // @ts-ignore
  if (matchingPrompt?.deleted) {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
        <ScrollView refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
        }>
          <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
            <View style={styles.contentContainer}>
              <View style={styles.container}>
                <View style={styles.logoContainer}>
                  <View style={styles.logoCircle}>
                    <Text style={styles.logoText}>hP</Text>
                  </View>
                </View>
                <Text style={styles.loadingText}>Sorry, this prompt is no longer available...</Text>
              </View>
            </View>
          </Pressable>
        </ScrollView>
      </SafeAreaView>
    );
  }

  // @ts-ignore
  if (user?.uid !== matchingPrompt?.savedById) {
    return <SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
          <View style={styles.contentContainer}>
            <Text style={{color: '#FFF', textAlign: 'center', fontSize: 25}}>
              Sorry, saved prompt responses can only be viewed by the user that saved them.
            </Text>
          </View>
        </Pressable>
      </ScrollView>
    </SafeAreaView>
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <View style={styles.contentContainer}>
            {isSavedByUser &&
              <View>
                <View style={{marginBottom: 8}}>
                  {!isEditingTitle ?
                    <Button title="Edit Response Title" onPress={() => setIsEditingTitle(true)}/>
                    :
                    <Button title="Cancel Edit Title" onPress={() => setIsEditingTitle(false)}/>
                  }
                </View>
              </View>
            }
            {isEditingTitle && <View style={{marginBottom: 8}}>
              <TextInput
                label="Edit Prompt Title"
                value={editPromptTitle}
                style={customSX({width: [200, 300, 400], height: 100})}
                editable={true}
                multiline={true}
                // @ts-ignore
                onChangeText={text => setEditPromptTitle(text)}
              />
            </View>
            }
            {isEditingTitle &&
              <View style={{marginBottom: 15}}>
                <Button title="Save Response Title" onPress={saveResponseTitle}/>
              </View>
            }
            <View style={{maxWidth: 750, marginBottom: 4}}>
              <Text style={{color: '#FFF', fontSize: 25, textAlign: 'center'}}>
                {promptTitle}
              </Text>
            </View>
            <View style={{marginBottom: 15, alignItems: 'center'}}>
              <Image source={{uri: promptImage || randomImage}}
                     style={[{width: 400, borderRadius: 10},
                       customSX({maxWidth: [315, '90%', '100%'], height: [240, 300, 300]})]}/>
            </View>
            <View style={{marginBottom: 15}}>
              <Pressable style={{
                backgroundColor: 'red',
                paddingHorizontal: 8,
                paddingVertical: 4,
                borderRadius: 8,
              }}
                         onPress={() => setShowTrueDelete(true)}>
                <Text style={{color: '#FFF', fontSize: 16}}>
                  Delete Saved Response
                </Text>
              </Pressable>
            </View>
            {showTrueDelete &&
              <View style={{marginBottom: 15}}>
                <Pressable style={{
                  backgroundColor: 'red',
                  paddingHorizontal: 10,
                  paddingVertical: 6,
                  borderRadius: 8,
                }}
                           onPress={deleteSavedResponse}>
                  <Text style={{color: '#FFF', fontSize: 20}}>
                    Tap To Confirm Delete
                  </Text>
                </Pressable>
              </View>
            }
            <View style={[{
              marginBottom: 12,
              borderWidth: 1,
              borderColor: '#FFF',
              borderRadius: 10,
              padding: 10,
            }, customSX({width: [300, 450, 600]})]}>
              <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 12, fontSize: 20}}>
                Prompt Content
              </Text>
              <Text style={{color: '#FFF'}}>
                {promptContent}
              </Text>
            </View>
            <View>
              <View style={{marginBottom: 10}}>
                <Button title="Copy Prompt To Clipboard" onPress={copyToClipboard}/>
              </View>
            </View>
            {!!GPTResponse &&
              <View style={[{
                marginBottom: 25,
                borderWidth: 1,
                borderColor: '#FFF',
                borderRadius: 10,
                padding: 10,
              }, customSX({width: [300, 450, 600]})]}>
                <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 12, fontSize: 20}}>
                  AI Response
                </Text>
                <Text style={{color: '#FFF'}}>
                  {GPTResponse}
                </Text>
              </View>
            }
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#202124',
    cursor: 'default',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    position: 'relative',
  },
  logoContainer: {
    marginBottom: 10,
  },
  logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    color: '#FFF',
    fontSize: 24,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
  },
});

export default ViewSavedResponse;
