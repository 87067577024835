import React, {FC, useState} from 'react';
import {Text} from 'react-native-paper';
import {SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import Header from "../../components/User/Header";
import DrawerMenu from "../../components/User/DrawerMenu";
import {Configure, InstantSearch} from 'react-instantsearch-native';
import algoliasearch from 'algoliasearch/lite';
import PromptCardHits from "../../components/PromptCardHits";

const searchClient = algoliasearch('DSWB3NXLUY', '9b525590ee55105d6f863cf735e78d0c');

const SearchResultsScreen: FC<StackScreenProps<any>> = ({route, navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const searchInput = route?.params?.searchInput;

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <View style={{marginBottom: 15}}>
            <Text style={{color: '#FFF', fontSize: 24, marginBottom: 6}}>
              Search Results For:
            </Text>
            <Text style={{color: '#FFF', fontSize: 24, textAlign: 'center'}}>
              {searchInput}
            </Text>
          </View>
          <InstantSearch searchClient={searchClient} indexName="highPerplexity">
            <Configure hitsPerPage={1000} query={searchInput}/>
            <PromptCardHits
              //@ts-ignore
              navigation={navigation}/>
          </InstantSearch>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
});

export default SearchResultsScreen;
