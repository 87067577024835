import React from 'react';
import {ActivityIndicator, Image, StyleSheet, Text} from 'react-native';
import {View} from "dripsy";

const styles = StyleSheet.create({
  imageLoadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#fff',
    borderStyle: 'dashed',
    padding: 10,
  },
  loadingText: {
    color: '#FFF',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default function PromptImageView({
                                          imageLoading,
                                          imageloadingMessage,
                                          image,
                                        }: any) {

  return (
    <>
      {imageLoading ? (
        <View style={[styles.imageLoadingContainer]} sx={{height: [300, 400, 500], width: [300, 400, 500]}}>
          <Text style={styles.loadingText}>{imageloadingMessage}</Text>
          <ActivityIndicator size="large" color="#4E8FFF"/>
        </View>
      ) : (
        !!image && (
          <View sx={{height: [300, 400, 500], width: [300, 400, 500]}}>
            <Image
              source={{uri: image}}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 5,
              }}
              resizeMode="contain"
            />
          </View>
        )
      )}
    </>
  );
}
