import React from 'react';
import {View} from 'dripsy';
import {connectHits} from 'react-instantsearch-native';
import PromptCard from './PromptCard';

const PromptCardHits = ({hits, navigation}: any) => {
  if (hits.length === 0) {
    return null;
  }

  return (
    <View
      sx={{
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'center',
        alignItems: ['center', 'center', 'flex-start'],
        flexWrap: 'wrap',
        maxWidth: 1450,
      }}>
      {hits.map((hit: any) => (
        <PromptCard
          key={hit.promptId}
          promptId={hit.promptId}
          navigation={navigation}
          promptImage={hit?.promptImage?.imageURL}
          promptTitle={hit.title}
          promptUserName={hit.userName}
          promptRatings={hit.ratings}
          promptComments={hit.comments?.length}
        />
      ))}
    </View>
  );
};

export default connectHits(PromptCardHits);
