import React, {useState} from 'react';
import {Text, View} from 'react-native';
import {CommentList} from "../CommentList";
import CommentInput from "../CommentInput";
import {collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where} from "firebase/firestore";
import {db} from "../../config/firebase";
import uuid from "react-native-uuid";
import {getAuth} from "firebase/auth";
import {useAuthentication} from "../../utils/hooks/useAuthentication";

export default function PromptCommentSection({
                                          promptId,
                                          promptComments,
                                          userDoc,
                                          fullUserDoc,
                                          promptDocId,
                                          setPromptComments,
                                          userUpvotedComments,
                                          setUserUpvotedComments,
                                        }: any) {
  const [isLeavingComment, setIsLeavingComment] = useState(false);
  const [isLeavingCommentReply, setIsLeavingCommentReply] = useState(false);
  const [commentReplyValues, setCommentReplyValues] = useState([]);
  const [isUpvotingComment, setIsUpvotingComment] = useState(false);
  const auth = getAuth();
  const {user} = useAuthentication();

  const playgroundPromptId = 'b148c38f-0cc3-435d-8ccc-93e59fe028e3';

  // @ts-ignore
  const hasBeenUpvoted = comment => userUpvotedComments?.some(upvotedComment => upvotedComment.commentId === comment.commentId);

  const leaveComment = async (comment: any) => {
    if (!comment) return;

    if (isLeavingComment) return;
    setIsLeavingComment(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    // @ts-ignore
    const userRef = doc(db, "users", userDoc);
    // @ts-ignore
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    const response = await fetch("https://highperplexity-comment-and-reply-mjp27ovqaq-uc.a.run.app", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "firebase_token_value": authTokenValue?.token,
        "comment_type": "parent",
        "content": comment,
        "user_uid": auth?.currentUser?.uid,
        "user_email": auth?.currentUser?.email,
        // @ts-ignore
        "user_name": fullUserDoc?.userName,
        "comment_id": uuid.v4(),
        "prompt_id": promptId,
        "prompt_doc_id": promptDocId,
        "user_doc": userDoc,
        "timestamp": new Date().getTime(),
      }),
    });

    await response.json();

    const promptsSnapshot = await getDocs(collection(db, "prompts"));
    const matchingDoc = promptsSnapshot.docs.find((doc) => promptId === doc.data().promptId);
    if (matchingDoc) {
      setPromptComments(matchingDoc?.data()?.comments?.length ? matchingDoc?.data()?.comments : []);
    }

    const querySnapshot = await getDocs(collection(db, "comments"));
    const tempAllCommentsArray = querySnapshot.docs.map(doc => ({...doc.data(), docId: doc.id}));

    tempAllCommentsArray.forEach(commentElem => {
      // @ts-ignore
      commentElem['replyActive'] = false;
      // @ts-ignore
      commentElem['allRepliesActive'] = false;
    });

    setIsLeavingComment(false);
  };

  const leaveCommentReply = async (parentCommentId: string, commentText: string, index: number) => {
    if (!commentText) return;

    if (isLeavingCommentReply) return;
    setIsLeavingCommentReply(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    // @ts-ignore
    const userRef = doc(db, "users", userDoc);
    // @ts-ignore
    await updateDoc(userRef, {currentJWT: authTokenValue.token});

    const response = await fetch("https://highperplexity-comment-and-reply-mjp27ovqaq-uc.a.run.app", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "firebase_token_value": authTokenValue?.token,
        "comment_type": "child",
        "content": commentText,
        "user_uid": auth?.currentUser?.uid,
        "user_email": auth?.currentUser?.email,
        // @ts-ignore
        "user_name": fullUserDoc?.userName,
        "comment_id": uuid.v4(),
        "prompt_id": promptId,
        "prompt_doc_id": promptDocId,
        "parent_comment_id": parentCommentId,
        "user_doc": userDoc,
        "timestamp": new Date().getTime(),
      }),
    });

    await response.json();

    const promptsSnapshot = await getDocs(collection(db, "prompts"));
    const matchingDoc = promptsSnapshot.docs.find((doc) => promptId === doc.data().promptId);
    if (matchingDoc) {
      setPromptComments(matchingDoc?.data()?.comments?.length ? matchingDoc?.data()?.comments : []);
    }

    const querySnapshot = await getDocs(collection(db, "comments"));
    const tempAllCommentsArray = querySnapshot.docs.map(doc => ({...doc.data(), docId: doc.id}));

    tempAllCommentsArray.forEach(commentElem => {
      // @ts-ignore
      commentElem['replyActive'] = false;
      // @ts-ignore
      commentElem['allRepliesActive'] = false;
    });

    const tempCommentReplies = [...commentReplyValues];
    // @ts-ignore
    tempCommentReplies[index] = '';
    setCommentReplyValues(tempCommentReplies);
    setIsLeavingCommentReply(false);
  }

  const upvoteComment = async (comment: any, upvoteType: string) => {
    if (isUpvotingComment) return;

    setIsUpvotingComment(true);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      timestamp: new Date().getTime(),
      comment_id: comment.commentId,
      user_uid: auth?.currentUser?.uid,
      user_email: auth?.currentUser?.email,
      // @ts-ignore
      user_name: fullUserDoc?.userName,
      user_doc: userDoc,
      upvote_type: upvoteType,
      prompt_id: promptDocId,
    };

    const response = await fetch("https://highperplexity-comment-upvotes-mjp27ovqaq-uc.a.run.app", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    await response.json();

    const [promptsSnapshot, querySnapshot] = await Promise.all([
      getDocs(collection(db, "prompts")),
      getDocs(collection(db, "comments")),
    ]);

    const matchingDoc = promptsSnapshot.docs.find(doc => promptId === doc.data().promptId);
    if (matchingDoc) setPromptComments(matchingDoc?.data()?.comments?.length ? matchingDoc.data().comments : []);

    const tempAllCommentsArray = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      docId: doc.id,
      replyActive: false,
      allRepliesActive: false,
    }));

    const usersQuery = query(collection(db, "users"), where("uid", "==", user?.uid));
    const userSnapshot = await getDocs(usersQuery);
    const matchedUserDoc = userSnapshot.docs[0];

    if (matchedUserDoc) setUserUpvotedComments(matchedUserDoc.data().commentUpvotes);

    setIsUpvotingComment(false);
  };

  async function reportComment(commentID: any) {
    try {
      const reportedCommentsCollection = collection(db, 'reportedComments');
      const commentDocRef = doc(reportedCommentsCollection, commentID);
      const commentSnapshot = await getDoc(commentDocRef);

      if (commentSnapshot.exists()) {
        const commentData = commentSnapshot.data();
        const timesReported = commentData.timesReported;
        const reportedBy = commentData.reportedBy || [];

        // @ts-ignore
        if (reportedBy.includes(user?.uid)) {
          // @ts-ignore
          console.log(`User has already reported comment.`);
          return;
        }

        reportedBy.push(user?.uid);
        await updateDoc(commentDocRef, {
          timesReported: timesReported + 1,
          reportedBy: reportedBy,
        });
      } else {
        await setDoc(commentDocRef, {
          timesReported: 1,
          reportedBy: [user?.uid],
        });
      }

      console.log(`Comment reported by user successfully.`);
    } catch (error) {
      console.error(`Error reporting comment ${commentID} by user ${user?.uid}:`, error);
    }
  }

  return (
    <>
      {promptId !== playgroundPromptId &&
        <View style={{marginTop: 20}}>
          <Text style={{fontSize: 24, fontWeight: 'bold', color: '#fff', marginBottom: 10, textAlign: 'center'}}>
            Comments
          </Text>
          {promptComments.length === 0 && (
            <Text style={{fontSize: 16, color: '#888', marginBottom: 10}}>
              No comments yet. Be the first to comment!
            </Text>
          )}
          <CommentList
            promptComments={promptComments}
            //@ts-ignore
            leaveComment={leaveComment}
            //@ts-ignore
            leaveCommentReply={leaveCommentReply}
            hasBeenUpvoted={hasBeenUpvoted}
            upvoteComment={upvoteComment}
            reportComment={reportComment}
            preAuth={false}
          />
          <CommentInput onLeaveComment={leaveComment}/>
        </View>
      }
    </>
  );
}
