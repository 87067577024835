import * as React from 'react';
import {ThemeProvider} from 'react-native-elements';
import './config/firebase';
import RootNavigation from './navigation';
import {DripsyProvider} from 'dripsy';
import {StatusBar} from "react-native";
import {Provider as PaperProvider} from 'react-native-paper';

const theme = {
  colors: {
    text: '#000', background: '#202124', primary: '#FFF',
  },
  fonts: {
    body: 'system-ui, sans-serif', heading: '"Avenir Next", sans-serif',
  },
  spacing: [10, 12, 14],
}

export default function App() {
  return (<DripsyProvider theme={theme}>
    <ThemeProvider>
      <PaperProvider>
        <StatusBar
          backgroundColor="#202124"
          barStyle="light-content"
        />
        <RootNavigation/>
      </PaperProvider>
    </ThemeProvider>
  </DripsyProvider>);
}
