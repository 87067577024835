import React, {FC, useCallback, useEffect, useState} from 'react';
import {
  ActivityIndicator, Dimensions, RefreshControl, SafeAreaView, ScrollView, StyleSheet, Platform
} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import Header from '../../components/User/Header';
import DrawerMenu from '../../components/User/DrawerMenu';
import {getAuth} from "firebase/auth";
import {db} from "../../config/firebase";
import * as Linking from "expo-linking";
import {collection, doc, getDocs, updateDoc} from "firebase/firestore";
import {useAuthentication} from "../../utils/hooks/useAuthentication";
import ProductList from "../../components/User/ProductList";
import PaymentModal from "../../components/User/PaymentModal";
import {FontAwesome} from "@expo/vector-icons";
import TabButtons from "../../components/User/TabButtons";
import EarnTokens from "../../components/User/EarnTokens";

const screenWidth = Dimensions.get('window').width;

const StoreScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);
  const [products, setProducts] = useState([]);
  const auth = getAuth();
  const [userDoc, setUserDoc] = useState();
  const {user} = useAuthentication();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [trophyCount, setTrophyCount] = useState();
  const [accessLevel, setAccessLevel] = useState();
  const [expirationDays, setExpirationDays] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('store');

  const handleTabPress = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === auth.currentUser?.uid) {
          setTrophyCount(doc.data().trophyCount || 0);
          //@ts-ignore
          setAccessLevel(doc.data().accessLevel ? `highPerplexity ${doc.data().accessLevel.charAt(0).toUpperCase() + doc.data().accessLevel.slice(1)}` : 'highPerplexity Basic');

          if (doc.data().accessLevelExpiration) {
            const currentDate = new Date();
            const expDate = new Date(doc.data().accessLevelExpiration);
            //@ts-ignore
            const diffInMilliseconds = expDate - currentDate;
            //@ts-ignore
            setExpirationDays(Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)));
          } else {
            setExpirationDays(null);
          }
        }
      });
      setLoading(false);
    };
    fetchUserData();
  }, []);

  const stripePaymentUrls = {
    'prod_PPtbbgsfbDioTc': `https://buy.stripe.com/eVa7wg22YfWs7yE4gj?client_reference_id=${user?.uid}`,
    'prod_PPteQzEfkuh1FC': `https://buy.stripe.com/00g4k4ePKfWs7yE3ce?client_reference_id=${user?.uid}`,
  };

  const handleSelectProduct = (product: any) => {
    // @ts-ignore
    const stripeUrl = stripePaymentUrls[product.id];
    if (stripeUrl) {
      if (Platform.OS === 'web') {
        window.open(stripeUrl, '_blank');
      } else {
        Linking.openURL(stripeUrl).catch(err => {
          console.error('Failed to open URL:', err);
        });
      }
    } else {
      console.error('No Stripe URL found for product:', product.id);
    }
  };

  useEffect(() => {
    if (!user) return;
    (async () => {
      const userSnapshot = await getDocs(collection(db, "users"));
      userSnapshot.forEach((doc) => {
        if (doc.data().uid === user?.uid) {
          // @ts-ignore
          setUserDoc(doc.id);
        }
      });
    })();
  }, [user]);

  useEffect(() => {
    if (!userDoc || !auth) return;
    (async () => {
      const authTokenValue = await auth?.currentUser?.getIdTokenResult();
      // @ts-ignore
      const userRef = doc(db, "users", userDoc);
      // @ts-ignore
      await updateDoc(userRef, {currentJWT: authTokenValue.token});

      const response = await fetch("https://highperplexity-stripe-manager-mjp27ovqaq-uc.a.run.app", {
        method: 'POST', headers: {
          Accept: 'application/json', 'Content-Type': 'application/json',
        }, body: JSON.stringify({
          "firebase_token_value": authTokenValue?.token,
        }),
      });

      const data = await response.json();
      setProducts(data.products);
    })();
  }, [userDoc, auth]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  };

  if (loading || !accessLevel || !userDoc) {
    return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>}>
        <Pressable style={styles.loadingContainer} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
          <View style={styles.loadingContentContainer}>
            <View style={styles.loadingContainer}>
              <View style={styles.logoContainer}>
                <View style={styles.logoCircle}>
                  <Text style={styles.logoText}>hP</Text>
                </View>
              </View>
              <Text style={styles.loadingText}>is loading...</Text>
              <ActivityIndicator size="large" color="#C8BDD9"/>
            </View>
          </View>
        </Pressable>
      </ScrollView>
    </SafeAreaView>);
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>}
      >
        <Pressable
          style={styles.container}
          sx={{minHeight: [vh(100)]}}
          onPress={closeDrawerMenu}
        >
          <Header
            navStatus={navStatus}
            setNavStatus={setNavStatus}
            navigation={navigation}
          />
          <View style={styles.contentContainer}>
            <Text style={styles.title}>Welcome to the highPerplexity Store</Text>
            <TabButtons activeTab={activeTab}
                        setActiveTab={setActiveTab}/>
            {activeTab === 'store' && <ProductList products={products} onSelectProduct={handleSelectProduct}
                                                   selectedProduct={selectedProduct}/>}
            {activeTab === 'accountInfo' && <View style={styles.userInfoContainer}>
              {/*<View style={styles.userInfoItem}>*/}
              {/*  <FontAwesome name="key" size={25} color="#1E90FF" style={styles.userInfoIcon}/>*/}
              {/*  <View>*/}
              {/*    <Text style={styles.userInfoText}>*/}
              {/*      Account: {accessLevel}*/}
              {/*    </Text>*/}
              {/*    {(expirationDays !== null) &&*/}
              {/*      <Text style={{color: '#FFF', fontSize: 15}}>*/}
              {/*        Expires in {expirationDays} days*/}
              {/*      </Text>*/}
              {/*    }*/}
              {/*  </View>*/}
              {/*</View>*/}
              <View style={styles.userInfoItem}>
                <FontAwesome name="trophy" size={25} color="#FFD700" style={styles.userInfoIcon}/>
                <Text style={{fontSize: 18, color: '#FFF'}}>
                  Trophies Owned: {trophyCount}
                </Text>
              </View>
            </View>}
            {/*<EarnTokens/>*/}
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    <PaymentModal selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct}/>
  </SafeAreaView>);
};

const styles = StyleSheet.create({
  container: {
    flex: 1, alignItems: 'center', backgroundColor: '#202124', cursor: 'default',
  }, contentContainer: {
    alignItems: 'center', paddingVertical: 20, paddingHorizontal: 10,
  }, title: {
    color: '#FFF', fontSize: screenWidth < 768 ? 25 : 32, fontWeight: 'bold', marginBottom: 10, textAlign: 'center',
  }, description: {
    flexDirection: 'row', fontSize: 18, textAlign: 'center', marginBottom: 20, alignItems: 'center', width: '80%',
  }, logoContainer: {
    marginBottom: 10,
  }, logoCircle: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#FFF',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  }, logoText: {
    color: '#FFF', fontSize: 24,
  }, loadingText: {
    color: '#FFF', fontSize: 18, marginBottom: 10, textAlign: 'center',
  }, loadingContainer: {
    flex: 1, alignItems: 'center', backgroundColor: '#202124', cursor: 'default',
  }, loadingContentContainer: {
    flex: 1, alignItems: 'center', position: 'relative',
  }, userInfoContainer: {
    flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: 10,
  }, userInfoItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
    backgroundColor: '#2A2D36',
    borderRadius: 10,
    padding: 20,
    marginTop: 10,
  }, userInfoIcon: {
    marginRight: 15,
  }, userInfoText: {
    color: '#FFF', fontSize: 18, marginBottom: 10,
  }, accountInfoHeader: {
    color: '#FFF', fontSize: 24, fontWeight: 'bold', marginTop: 20, marginBottom: 10, textAlign: 'center',
  },
});

export default StoreScreen;

