import React, {FC, useCallback, useState} from 'react';
import {Platform, RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import useUnits from 'rxn-units';
import {SimpleAccordion} from 'react-native-simple-accordion';
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import * as Linking from 'expo-linking';
import {Entypo, FontAwesome} from "@expo/vector-icons";

const accentColor = '#DDD';

const AboutScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const handleChromeExtensionClick = () => {
    if (Platform.OS == 'web') {
      window.open('https://openai.com/blog/chatgpt-plugins', '_blank');
    } else {
      Linking.openURL('https://openai.com/blog/chatgpt-plugins');
    }
  }

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
    <View style={{flex: 1}}>
      <ScrollView refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>
      }>
        <Pressable style={styles.container} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
          <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
          <View style={{width: '100%', paddingTop: 20, alignItems: 'center'}}
                sx={{paddingLeft: [20, 20, 40], paddingRight: [20, 20, 40]}}>
            <View style={[styles.headerLogoContainer]}>
              <Text style={styles.headerLogoText}>
                hP
              </Text>
            </View>
            {(Platform.OS == 'web') &&
              <View style={{flexDirection: 'row', marginBottom: 15}}>
                <Pressable
                  onPress={() => window.open('https://apps.apple.com/us/app/highperplexity-custom-prompts/id6445970944', '_blank')}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: '#FFF',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginRight: 15,
                  }}>
                  <View style={{marginRight: 10}}>
                    <Text style={{color: '#FFF'}}>
                      <FontAwesome name="apple" size={25} color="#FFF"/>
                    </Text>
                  </View>
                  <View>
                    <View>
                      <Text style={{color: '#FFF', textAlign: 'center'}}>
                        Available on the
                      </Text>
                    </View>
                    <View>
                      <Text style={{color: '#FFF', textAlign: 'center'}}>
                        App Store
                      </Text>
                    </View>
                  </View>
                </Pressable>
                <Pressable
                  onPress={() => window.open('https://play.google.com/store/apps/details?id=com.highPerplexity.highPerplexity', '_blank')}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: '#FFF',
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  <View style={{marginRight: 10}}>
                    <Text style={{color: '#FFF'}}>
                      <Entypo name="google-play" size={25} color="#FFF"/>
                    </Text>
                  </View>
                  <View>
                    <View>
                      <Text style={{color: '#FFF', textAlign: 'center'}}>
                        Available on
                      </Text>
                    </View>
                    <View>
                      <Text style={{color: '#FFF', textAlign: 'center'}}>
                        Google Play
                      </Text>
                    </View>
                  </View>
                </Pressable>
              </View>
            }
            <Text style={styles.aboutText}>
              Welcome to highPerplexity, the ultimate platform for designing, exchanging, and rating customizable AI
              prompts!
            </Text>
            <Text style={styles.aboutText}>
              We're here to help you tap into the vast potential of AI with powerful tools that make creating and
              sharing
              tailor-made prompts a breeze. Discover the exciting possibilities with highPerplexity:
            </Text>
            <Text style={styles.aboutText}>
              - Create and customize AI prompts with ease: Design personalized prompts for any purpose or topic,
              fine-tuning them to meet your specific needs.
            </Text>
            <Text style={styles.aboutText}>
              - Experience seamless ChatGPT integration: Run your custom, discovered, or shared prompts directly within
              the ChatGPT interface for a smooth experience.
            </Text>
            <Text style={styles.aboutText}>
              - Collaborate and grow together: Share your prompts with the highPerplexity community, learn from the
              expertise of others, and join a thriving ecosystem centered around creative AI applications.
            </Text>
            <Text style={styles.aboutText}>
              - Discover and rate exceptional prompts: Our rating and commenting system makes it easy to find the best
              AI
              prompts, while your feedback helps others navigate the world of AI creativity.
            </Text>
            <Text style={styles.aboutText}>
              And that's not all! We're developing a handy <Pressable onPress={handleChromeExtensionClick}>
              <Text style={{
                color: '#FFF',
                fontSize: 18,
                textDecorationLine: 'underline',
              }}>
                ChatGPT Plugin
              </Text>
            </Pressable>, allowing you to use your created or saved prompts directly within the ChatGPT interface.
            </Text>
            <Text style={styles.aboutText}>
              Ready to explore the power of highPerplexity? Dive into our step-by-step guide (with an interactive
              example!) by clicking the button below or keeping reading to check out our FAQ.
            </Text>
            <Pressable
              onPress={() => navigation.navigate('How It Works')}
              sx={{marginBottom: 10, borderRadius: 5, backgroundColor: '#C1C1C1', padding: 10}}
            >
              <Text sx={{fontSize: 16, color: '#25232A'}}>Learn More</Text>
            </Pressable>
          </View>
          <View style={{marginBottom: 10}}>
            <Text style={{color: '#FFF', fontSize: 30}}>
              FAQs
            </Text>
          </View>
          <View style={{marginBottom: 30}}
                sx={{paddingLeft: [20, 20, 40], paddingRight: [20, 20, 40]}}>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      highPerplexity is a web, Android, and iOS app that lets users create, run, and share customizable
                      prompts containing variables that can be replaced with different values each time they are
                      executed.
                      The app encourages creativity, collaboration, and learning by enabling users to experiment with
                      unique prompt configurations and refine their prompt engineering skills.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      The name highPerplexity is derived from the concept of perplexity in language modeling. Perplexity
                      is a metric used to assess how well a language model predicts the probability of a sequence of
                      words. A "high perplexity" score indicates that the model has greater difficulty in predicting the
                      words in a given sequence, implying higher uncertainty or surprise. In practical terms, having
                      high
                      perplexity makes it more challenging for an AI to determine whether a particular text has been
                      generated by another AI. However, other factors, such as writing quality and style, idea
                      coherence,
                      and specific linguistic patterns or errors can also be utilized to identify AI-generated content.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      highPerplexity offers a valuable service by streamlining the process of creating and sharing
                      customizable prompts and fostering a sense of community and collaboration among its users. It
                      provides an accessible platform for users to learn, develop, and refine their prompt engineering
                      skills, which can be applied to various popular AI-powered applications.
                    </Text>
                  </View>
                }
                title={"What is highPerplexity and what does the name mean?"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      Creating custom prompts on highPerplexity is a breeze! Here's an example to demonstrate how you
                      can
                      design a customizable highPerplexity prompt for ChatGPT, which generates 10 ideas for a new book
                      in
                      a user-defined genre:
                    </Text>
                    <Text style={[styles.accordionContentText, {fontStyle: 'italic'}]}>
                      Imagine you're an author brainstorming ideas for a new %|GENRE|% book. List 10 captivating ideas
                      for
                      this book, providing a detailed and specific description for each.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      In this example, %|GENRE|% serves as a highPerplexity variable. Users can effortlessly replace the
                      value of %|GENRE|% with their preferred genre when they access this prompt on the highPerplexity
                      platform.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      highPerplexity supports the creation of prompts with multiple custom variables, allowing you to
                      design prompts as simple or complex as needed for any imaginable task. Share your prompts with
                      others and explore the boundless possibilities of customizable prompts!
                    </Text>
                  </View>
                }
                title={"How can I create a customizable prompt in highPerplexity?"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      Custom variables in highPerplexity are designated words or phrases within a prompt that are
                      intended
                      to be replaced with different values when the prompt is executed. These variables allow users to
                      tailor prompts to their specific needs or preferences. Here's an example to illustrate:
                    </Text>
                    <Text style={[styles.accordionContentText, {fontStyle: 'italic'}]}>
                      Imagine you're an author brainstorming ideas for a new %|GENRE|% book. List 10 captivating ideas
                      for
                      this book, providing a detailed and specific description for each.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      In this sample prompt, %|GENRE|% functions as a highPerplexity custom variable. Users can
                      seamlessly
                      swap the value of %|GENRE|% with their chosen genre when they interact with this prompt on the
                      highPerplexity platform.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      With highPerplexity, prompts can include any number of custom variables, giving you the freedom to
                      create and share prompts with varying levels of complexity for an array of tasks. Explore the
                      endless possibilities and enjoy the flexibility offered by custom highPerplexity variables!
                    </Text>
                  </View>
                }
                title={"What are highPerplexity custom variables?"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      Absolutely! highPerplexity is designed to work seamlessly with ChatGPT, allowing users to obtain
                      responses to their prompts directly from ChatGPT itself. This integration not only provides a
                      convenient way to generate AI-powered responses to your custom prompts but also enhances the
                      overall
                      user experience by leveraging the advanced capabilities of ChatGPT. Enjoy crafting unique prompts
                      and watch as ChatGPT brings your ideas to life!
                    </Text>
                  </View>
                }
                title={"Can I use ChatGPT on highPerplexity?"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      The highPerplexity <Pressable onPress={handleChromeExtensionClick}>
                      <Text style={{
                        color: '#FFF',
                        fontSize: 14,
                        textDecorationLine: 'underline',
                      }}>
                        ChatGPT Plugin
                      </Text>
                    </Pressable> is currently in development. It will be a free, user-friendly tool that enables
                      highPerplexity users to access and interact with their created and saved prompts directly within
                      the
                      ChatGPT User Interface.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      I've also made it super easy to copy prompts from highPerplexity so they can be pasted directly
                      into
                      ChatGPT or other generative AI platforms!
                    </Text>
                  </View>
                }
                title={"What is the highPerplexity ChatGPT Extension?"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
            <View style={{marginBottom: 10, maxWidth: 500}}>
              <SimpleAccordion
                viewInside={
                  <View>
                    <Text style={styles.accordionContentText}>
                      My name's Nathan and I built highPerplexity because I'm fascinated with all of the super cool AI
                      products that are coming out. My goal here is to just use my skills to build something that I hope
                      the community enjoys and finds useful. This wasn't built by a big tech company. It wasn't even
                      built
                      by a scrappy startup. I'm just one guy who wanted to build a cool thing that was kinda/sorta
                      AI-related.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      I built highPerplexity using React Native Web - so the iOS, Android, and web versions all share a
                      single codebase! Using this approach presented some unique challenges, but I ended up being really
                      happy with how this experiment turned out.
                    </Text>
                    <Text style={styles.accordionContentText}>
                      I am also in the process of building the highPerplexity <Pressable
                      onPress={handleChromeExtensionClick}>
                      <Text style={{
                        color: '#FFF',
                        fontSize: 14,
                        textDecorationLine: 'underline',
                      }}>
                        ChatGPT Plugin
                      </Text>
                    </Pressable>!
                    </Text>
                  </View>
                }
                title={"Who built highPerplexity and why?"}
                viewContainerStyle={styles.accordionContentContainer}
                bannerStyle={styles.accordionHeader}
                titleStyle={styles.accordionHeaderText}
                arrowColor={accentColor}
              />
            </View>
          </View>
        </Pressable>
      </ScrollView>
    </View>
    <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
  </SafeAreaView>);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    cursor: 'default',
  },
  headerLogoContainer: {
    borderWidth: 1,
    borderColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 80,
    minHeight: 80,
    borderRadius: 80 / 2,
    marginBottom: 20,
  },
  headerLogoText: {
    color: '#FFF',
    fontSize: 35,
  },
  aboutText: {
    color: '#FFF',
    maxWidth: 1000,
    textAlign: 'justify',
    fontSize: 18,
    width: '100%',
    marginBottom: 20
  },
  accordionHeader: {
    height: 'auto',
    flexDirection: "row",
    backgroundColor: "#333",
    padding: 16,
    alignItems: "center",
  },
  accordionHeaderText: {
    color: accentColor,
  },
  accordionContentContainer: {
    paddingTop: 10,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#444',
  },
  accordionContentText: {
    color: accentColor,
    marginBottom: 15,
    fontSize: 14,
    textAlign: 'justify',
  },
});

export default AboutScreen;
