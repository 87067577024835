import React, {useCallback, useState} from 'react';
import {StyleSheet} from 'react-native';
import {Pressable, View} from 'dripsy';
import {Card, Text} from 'react-native-paper';
import {AntDesign, FontAwesome} from '@expo/vector-icons';
import {defaultImageArray} from "../utils/misc/defaultImageData";
import {useFocusEffect} from '@react-navigation/native';

const PromptCard = (props: {
  promptId: string,
  navigation: any,
  promptImage: any,
  promptTitle: any,
  promptUserName: string,
  promptRatings: any,
  promptComments: string,
  matchesActiveTag?: boolean
}) => {
  const [randomImage, setRandomImage] = useState();

  useFocusEffect(
    useCallback(() => {
      const randomIndex = Math.floor(Math.random() * defaultImageArray.length);
      // @ts-ignore
      setRandomImage(defaultImageArray[randomIndex]);
    }, [])
  );

  let totalPoints = 0;

  if (props.promptRatings?.length) props.promptRatings.forEach((rating: any) => totalPoints += rating.rating);

  const truncateAndAddEllipsis = (text: any, maxLength: any) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength).trim() + '...';
    }
    return text;
  };

  return (<Pressable
    sx={{marginRight: [0, 0, 20], marginBottom: 15, width: [300, 400, 300]}}
    onPress={() => props.navigation.navigate('New View Prompt', {promptId: props.promptId})}
  >
    <Card style={{backgroundColor: '#25232A'}}>
      <Card.Content style={{marginBottom: 10}}>
        <Text variant="titleSmall" style={{fontWeight: '700', color: '#FFF'}}>
          {truncateAndAddEllipsis(props.promptTitle, 28)}
        </Text>
      </Card.Content>
      <Card.Cover source={{uri: props?.promptImage || randomImage}}/>
      <Card.Content style={{marginTop: 10, flexDirection: 'row', justifyContent: 'space-between'}}>
        <View style={styles.cardTextContainer}>
          <Text variant="bodySmall" style={{color: '#FFF'}}>
            {props.promptUserName}
          </Text>
        </View>
        <View style={styles.cardTextContainer}>
          <View style={styles.cardItemContainer}>
            <AntDesign name="staro" style={{marginRight: 5}} size={14} color="#FFF"/>
          </View>
          <View style={styles.cardItemContainer}>
            <Text variant="bodySmall" style={{color: '#FFF'}}>
              {totalPoints}
            </Text>
          </View>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center', height: 20}}>
          <View style={[styles.cardItemContainer, {marginRight: 6}]}>
            <FontAwesome name="comment" size={14} color="#C8BDD9"/>
          </View>
          <View style={styles.cardItemContainer}>
            <Text variant="bodySmall" style={{color: '#FFF'}}>{props.promptComments || 0}</Text>
          </View>
        </View>
      </Card.Content>
    </Card>
  </Pressable>);
}

const styles = StyleSheet.create({
  cardTextContainer: {
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
  },
  cardItemContainer: {
    height: '100%',
    justifyContent: 'center',
  },
});

export default PromptCard;
