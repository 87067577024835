import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Dimensions, Platform, RefreshControl, SafeAreaView, ScrollView, StyleSheet} from 'react-native';
import {Pressable, Text, View} from 'dripsy';
import {StackScreenProps} from '@react-navigation/stack';
import {Link} from "@react-navigation/native";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {Input} from "react-native-elements";
import Icon from "react-native-vector-icons/FontAwesome";
import * as Linking from 'expo-linking';
import useUnits from "rxn-units";
import {Entypo, FontAwesome} from "@expo/vector-icons";
import {LinearGradient} from 'expo-linear-gradient';
import {Configure, Index, InstantSearch} from "react-instantsearch-native";
import algoliasearch from 'algoliasearch';
import Header from "../../components/Auth/Header";
import DrawerMenu from "../../components/Auth/DrawerMenu";
import CustomSortBy from "../../components/CustomSortBy";
import CustomSearchFilter from "../../components/CustomSearchFilter";
import CustomInfiniteHits from "../../components/CustomInfiniteHits";
import ScrollToTop from "../../components/ScrollToTop";

const algoliaClient = algoliasearch('DSWB3NXLUY', '959f1b50ecd093c1de513294bd97b4bf');

const screenWidth = Dimensions.get('window').width;

const WelcomeScreen: FC<StackScreenProps<any>> = ({navigation}) => {
  const auth = getAuth();
  const [value, setValue] = useState({
    email: '',
    password: '',
    error: ''
  });
  const {vh} = useUnits();
  const [navStatus, setNavStatus] = useState('closed');
  const [refreshing, setRefreshing] = useState(false);
  const scrollRef = useRef(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const CustomConfigure = ({searchQuery}: any) => <Configure query={searchQuery}/>;

  const handleRedditLinkClick = () => {
    if (Platform.OS == 'web') {
      window.open('https://www.reddit.com/r/highPerplexity/', '_blank');
    } else {
      Linking.openURL('https://www.reddit.com/r/highPerplexity/');
    }
  }

  async function signIn() {
    if (value.email === '' || value.password === '') {
      setValue({
        ...value,
        error: 'Email and password are mandatory.'
      })
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, value.email.trim(), value.password);
    } catch (error: any) {
      setValue({
        ...value,
        error: error.message,
      })
    }
  }

  const scrollToTop = () => {
    if (Platform.OS === 'web') {
      window.scrollTo({top: 0, behavior: 'smooth'});
    } else {
      //@ts-ignore
      scrollRef.current.scrollTo({x: 0, y: 0, animated: true});
    }
  };

  const handleScroll = (event: any) => {
    const yOffset = Platform.OS === 'web' ? event.nativeEvent.contentOffset.y : event.nativeEvent.contentOffset.y;
    setShowScrollToTop(yOffset > 200);
  };

  const closeDrawerMenu = () => {
    if (navStatus !== 'closed') setNavStatus('closed');
  }

  useEffect(() => {
    const handleWebScroll = () => {
      if (Platform.OS === 'web') {
        const yOffset = window.scrollY;
        setShowScrollToTop(yOffset > 200);
      }
    };

    if (Platform.OS === 'web') {
      window.addEventListener('scroll', handleWebScroll);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('scroll', handleWebScroll);
      }
    };
  }, []);

  return (<SafeAreaView style={{flex: 1, backgroundColor: '#202124'}}>
      <View style={{flex: 1}}>
        <ScrollView
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh}/>}
          ref={scrollRef}
          onScroll={handleScroll}
          scrollEventThrottle={16}
        >
          <Pressable style={styles.containerOne} sx={{minHeight: [vh(100)]}} onPress={closeDrawerMenu}>
            <Header navStatus={navStatus} setNavStatus={setNavStatus} navigation={navigation}/>
            <View style={styles.logoContainer}>
              <Text style={styles.logoText}>hP</Text>
            </View>
            <View style={styles.subheaderContainer}>
              <Text style={styles.subheaderText} sx={{fontSize: [15, 18, 22]}}>
                Make Prompts. Rate Prompts. Do Whatever.
              </Text>
            </View>
            {!!value.error && (
              <View style={styles.error}>
                <Text>{value.error}</Text>
              </View>
            )}
            <View style={styles.controls}>
              <Input
                placeholder="Email"
                value={value.email}
                onChangeText={(text) => setValue({...value, email: text})}
                style={styles.control}
                inputContainerStyle={{borderBottomColor: "#FFF"}}
                placeholderTextColor="#FFF"
                leftIcon={
                  <Icon name="envelope" size={16} color="#FFF"/>
                }
              />
              <Input
                placeholder="Password"
                value={value.password}
                onChangeText={(text) => setValue({...value, password: text})}
                style={styles.control}
                inputContainerStyle={{borderBottomColor: "#FFF"}}
                placeholderTextColor="#FFF"
                secureTextEntry={true}
                leftIcon={
                  <Icon name="key" size={16} color="#FFF"/>
                }
              />
            </View>
            <View style={{alignItems: "center", marginVertical: 10}}>
              <Pressable onPress={() => navigation.navigate("Forgot Password")}>
                <Text style={{color: "#FFF", textDecorationLine: "underline"}}>
                  Forgot Password?
                </Text>
              </Pressable>
            </View>
            <View style={styles.buttonContainer}>
              <Pressable
                style={[styles.signInButton, styles.button]}
                onPress={signIn}
              >
                <Text style={{color: "#E5E5E8"}}>Sign In</Text>
              </Pressable>
              <Link
                to={{screen: "Sign Up"}}
                style={[styles.signUpButton, styles.button]}
              >
                <Text style={{color: "#E5E5E8"}}>Sign Up</Text>
              </Link>
            </View>
            <View
              sx={{flexDirection: ["column", "column", "row"]}}
              style={{marginTop: 30, marginBottom: 25}}
            >
              <Pressable
                sx={{marginRight: [0, 0, 15]}}
                style={styles.ctaButton}
                onPress={() => navigation.navigate("About")}
              >
                <LinearGradient
                  colors={["#737781", "#545a65"]}
                  style={styles.gradientBackground}
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 0}}
                >
                  <Text style={styles.buttonText}>About highPerplexity</Text>
                </LinearGradient>
              </Pressable>
              <Pressable
                sx={{marginTop: [12, 12, 0], marginRight: [0, 0, 15]}}
                style={styles.ctaButton}
                onPress={() => navigation.navigate("Browse")}
              >
                <LinearGradient
                  colors={["#737781", "#545a65"]}
                  style={styles.gradientBackground}
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 0}}
                >
                  <Text style={styles.buttonText}>Browse Prompts</Text>
                </LinearGradient>
              </Pressable>
              <Pressable
                sx={{marginTop: [12, 12, 0], marginRight: [0, 0, 15]}}
                style={styles.ctaButton}
                onPress={handleRedditLinkClick}
              >
                <LinearGradient
                  colors={["#737781", "#545a65"]}
                  style={styles.gradientBackground}
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 0}}
                >
                  <Text style={styles.buttonText}>r/highPerplexity</Text>
                </LinearGradient>
              </Pressable>
            </View>
            {Platform.OS == "web" && (
              <View
                style={{flexDirection: "row", marginBottom: 15, alignItems: "center"}}
              >
                <Pressable
                  onPress={() =>
                    window.open(
                      "https://apps.apple.com/us/app/highperplexity-custom-prompts/id6445970944",
                      "_blank"
                    )
                  }
                  style={styles.appStoreButton}
                >
                  <LinearGradient
                    colors={["#4C4C4C", "#2C2C2C"]}
                    style={styles.ctaGradientBackground}
                    start={{x: 0, y: 0}}
                    end={{x: 1, y: 0}}
                  >
                    <FontAwesome name="apple" size={25} color="#FFF"/>
                    <View style={{marginLeft: 10}}>
                      <Text style={styles.storeText}>Available on the</Text>
                      <Text style={styles.storeText}>App Store</Text>
                    </View>
                  </LinearGradient>
                </Pressable>
                <Pressable
                  onPress={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.highPerplexity.highPerplexity",
                      "_blank"
                    )
                  }
                  style={styles.googlePlayButton}
                >
                  <LinearGradient
                    colors={["#4C4C4C", "#2C2C2C"]}
                    style={styles.ctaGradientBackground}
                    start={{x: 0, y: 0}}
                    end={{x: 1, y: 0}}
                  >
                    <Entypo name="google-play" size={25} color="#FFF"/>
                    <View style={{marginLeft: 10}}>
                      <Text style={styles.storeText}>Available on</Text>
                      <Text style={styles.storeText}>Google Play</Text>
                    </View>
                  </LinearGradient>
                </Pressable>
              </View>
            )}
          </Pressable>
          <View style={{flex: 1}}>
            <InstantSearch
              indexName="highPerplexity"
              searchClient={algoliaClient}
            >
              <Index indexName="highPerplexity">
                <View style={{flex: 1}}>
                  <Pressable
                    style={{flex: 1, alignItems: 'center'}}
                    sx={{minHeight: [vh(100)], cursor: 'default'}}
                    onPress={closeDrawerMenu}
                  >
                    <View style={{flexDirection: 'row', marginBottom: 10}}>
                      <View style={{marginRight: screenWidth < 768 ? 10 : 20}}>
                        <CustomSortBy
                          defaultRefinement="highPerplexity_popular"
                          items={[
                            {value: 'highPerplexity_popular', label: 'Most Popular'},
                            {value: 'highPerplexity', label: 'Most Relevant (To Filter)'},
                            {value: 'highPerplexity_timestamp_desc', label: 'Newest'},
                            {value: 'highPerplexity_timestamp_asc', label: 'Oldest'},
                          ]}
                        />
                      </View>
                      <View>
                        <CustomSearchFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
                      </View>
                    </View>
                    <CustomConfigure searchQuery={searchQuery}/>
                    {//@ts-ignore
                      <CustomInfiniteHits searchQuery={searchQuery} navigation={navigation}/>}
                  </Pressable>
                </View>
              </Index>
            </InstantSearch>
          </View>
        </ScrollView>
        <ScrollToTop showScrollToTop={showScrollToTop} scrollToTop={scrollToTop}/>
      </View>
      <DrawerMenu navStatus={navStatus} setNavStatus={setNavStatus}/>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  containerOne: {
    backgroundColor: '#202124',
    alignItems: 'center',
    cursor: 'default',
  },
  containerTwo: {
    backgroundColor: '#202124',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'default',
  },
  logoContainer: {
    borderWidth: 1,
    borderColor: '#FFF',
    width: 90,
    height: 90,
    borderRadius: 90 / 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  logoText: {
    color: '#FFF',
    fontSize: 40,
  },
  subheaderContainer: {
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  subheaderText: {
    color: '#FFF',
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: 10,
    flexDirection: 'row',
  },
  signInButton: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
    marginRight: 12,
  },
  signUpButton: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
  },
  controls: {
    width: 250,
  },
  control: {
    color: '#FFF',
  },
  error: {
    marginTop: 10,
    padding: 10,
    color: '#fff',
    backgroundColor: '#D54826FF',
  },
  cardTitleContainer: {
    backgroundColor: '#222236',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  cardTitleText: {
    color: '#FFF',
  },
  cardBackgroundImage: {
    flex: 1,
    resizeMode: "cover",
    width: "100%",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  button: {
    borderWidth: 1,
    borderColor: "#E5E5E8",
    borderRadius: 5,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 5,
  },
  ctaButton: {
    borderRadius: 10,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  gradientBackground: {
    padding: 25,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: "#FFF",
    textAlign: "center",
    fontSize: 16,
  },
  appStoreButton: {
    borderRadius: 10,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginRight: 15,
    minWidth: 175,
  },
  googlePlayButton: {
    borderRadius: 10,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    minWidth: 175,
  },
  ctaGradientBackground: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  storeText: {
    color: "#FFF",
    textAlign: "center",
    fontSize: 14,
  },
});

export default WelcomeScreen;
