import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

const SimulatedGPTResponse = ({response}: any) => {
  const words = response.split(' ');
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const frameRef = useRef();

  useEffect(() => {
    const updateResponse = () => {
      setCurrentWordIndex((prevIndex) => {
        if (prevIndex < words.length) {
          //@ts-ignore
          frameRef.current = requestAnimationFrame(updateResponse);
          return prevIndex + 1;
        } else {
          //@ts-ignore
          cancelAnimationFrame(frameRef.current);
          return prevIndex;
        }
      });
    };

    //@ts-ignore
    frameRef.current = requestAnimationFrame(updateResponse);

    //@ts-ignore
    return () => cancelAnimationFrame(frameRef.current);
  }, [words]);

  return (
    <View style={styles.container}>
      <Text style={styles.response}>{words.slice(0, currentWordIndex).join(' ')}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#555',
    padding: 10,
    borderRadius: 5,
    marginBottom: 15,
  },
  response: {
    color: '#FFF',
    fontFamily: 'Courier',
    fontSize: 15,
    textAlign: 'left',
  },
});

export default SimulatedGPTResponse;
