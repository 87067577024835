import React, {useState} from 'react';
import {Pressable, Text, View} from 'react-native';
import {Link} from '@react-navigation/native';
import {useAuthentication} from '../../utils/hooks/useAuthentication';
import {arrayUnion, collection, doc, getDoc, setDoc, updateDoc} from 'firebase/firestore';
import {db} from '../../config/firebase';

const PromptActions = ({
                         matchingPrompt,
                         fullUserDoc,
                         navigation,
                         promptDocId,
                         userDoc,
                         promptId,
                       }: any) => {
  const [showTrueDelete, setShowTrueDelete] = useState(false);
  const [showTrueReport, setShowTrueReport] = useState(false);
  const {user} = useAuthentication();

  const deletePrompt = async () => {
    const promptRef = doc(db, 'prompts', promptDocId);
    await updateDoc(promptRef, {deleted: true});
    fetch("https://highperplexity-algolia-indexing-middleware-mjp27ovqaq-uc.a.run.app/?update=true&trigger=prompt-create");
    navigation.navigate('highPerplexity');
  };

  const reportPrompt = async () => {
    try {
      // Update the user's reportedPrompts field
      const userRef = doc(db, "users", userDoc);
      await updateDoc(userRef, {
        reportedPrompts: arrayUnion(promptId)
      });

      // Update or create a document in the reportedPrompts collection
      const reportedPromptsCollection = collection(db, 'reportedPrompts');
      const promptDocRef = doc(reportedPromptsCollection, promptId);
      const promptSnapshot = await getDoc(promptDocRef);

      if (promptSnapshot.exists()) {
        const promptData = promptSnapshot.data();
        const timesReported = promptData.timesReported;
        const reportedBy = promptData.reportedBy || [];

        if (reportedBy.includes(userDoc)) {
          console.log(`User has already reported prompt.`);
          return;
        }

        reportedBy.push(userDoc);
        await updateDoc(promptDocRef, {
          timesReported: timesReported + 1,
          reportedBy: reportedBy,
        });
      } else {
        await setDoc(promptDocRef, {
          timesReported: 1,
          reportedBy: [userDoc],
        });
      }

      console.log(`Prompt reported by user successfully.`);
      navigation.navigate("highPerplexity");
    } catch (error) {
      console.error(`Error reporting prompt ${promptId} by user ${userDoc}:`, error);
    }
  };

  return (
    <>
      <View style={{marginBottom: 12, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Link
          to={{
            screen: 'User Prompts',
            params: {userName: matchingPrompt?.postedById},
          }}
          style={{
            flexDirection: 'row',
            borderColor: '#FFF',
            borderWidth: 1,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 10,
          }}
        >
          <View>
            <Text style={{color: '#FFF', marginRight: 6}}>Posted by:</Text>
          </View>
          <View>
            <Text style={{color: '#FFF'}}>{matchingPrompt?.userName}</Text>
          </View>
        </Link>
        {(matchingPrompt?.postedById === user?.uid || fullUserDoc.accessLevel === 'superuser') && (
          <View style={{marginLeft: 8}}>
            <Pressable
              style={{
                borderWidth: 1,
                borderColor: 'red',
                paddingHorizontal: 8,
                paddingVertical: 4,
                borderRadius: 8,
              }}
              onPress={() => setShowTrueDelete(true)}
            >
              <Text style={{color: 'red', fontSize: 16}}>Delete Prompt</Text>
            </Pressable>
          </View>
        )}
        {(matchingPrompt?.postedById !== user?.uid || fullUserDoc.accessLevel === 'superuser') && (
          <View style={{marginLeft: 8}}>
            <Pressable
              style={{
                borderWidth: 1,
                borderColor: 'red',
                paddingHorizontal: 8,
                paddingVertical: 4,
                borderRadius: 8,
              }}
              onPress={() => setShowTrueReport(true)}
            >
              <Text style={{color: 'red', fontSize: 16}}>Report</Text>
            </Pressable>
          </View>
        )}
      </View>
      {showTrueDelete && (
        <View>
          <Pressable
            style={{
              backgroundColor: 'red',
              paddingHorizontal: 10,
              paddingVertical: 6,
              borderRadius: 8,
            }}
            onPress={deletePrompt}
          >
            <Text style={{color: '#FFF', fontSize: 20}}>Tap To Confirm Delete</Text>
          </Pressable>
        </View>
      )}
      {showTrueReport && (
        <View>
          <Pressable
            style={{
              backgroundColor: 'red',
              paddingHorizontal: 10,
              paddingVertical: 6,
              borderRadius: 8,
            }}
            onPress={reportPrompt}
          >
            <Text style={{color: '#FFF', fontSize: 20}}>Tap To Confirm Report</Text>
          </Pressable>
        </View>
      )}
    </>
  );
};

export default PromptActions;
