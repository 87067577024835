import React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

interface TabButtonProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const TabButtons: React.FC<TabButtonProps> = ({activeTab, setActiveTab}) => {
  const handleTabPress = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <View style={styles.buttonContainer}>
      <Pressable
        style={[
          styles.tabButton,
          activeTab === 'store' ? styles.activeTabButton : null,
          {marginRight: 8},
        ]}
        onPress={() => handleTabPress('store')}
      >
        <Text style={styles.buttonText}>Store</Text>
      </Pressable>
      <Pressable
        style={[
          styles.tabButton,
          activeTab === 'accountInfo' ? styles.activeTabButton : null,
        ]}
        onPress={() => handleTabPress('accountInfo')}
      >
        <Text style={styles.buttonText}>Account Information</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  tabButton: {
    backgroundColor: '#2A2D36',
    borderRadius: 5,
    padding: 10,
    minWidth: 100,
    alignItems: 'center',
  },
  activeTabButton: {
    backgroundColor: '#3F51B5',
  },
  buttonText: {
    color: '#FFF',
    fontSize: 18,
  },
});

export default TabButtons;
