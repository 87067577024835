import React from 'react';
import {Modal, Pressable, StyleSheet, Text, View} from 'react-native';
import {FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons} from '@expo/vector-icons';

const HomeDesktopOnboarding = ({
                             currentScreen,
                             setCurrentScreen,
                             modalVisible,
                             setModalVisible,
                             toggleOnboardingStatus
                           }: any) => {

  const desktopOnboardingScreens = [
    (
      <>
        <View
          style={{
            borderWidth: 2,
            borderColor: '#FFFFFF',
            borderRadius: 50,
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 15,
          }}
        >
          <Text style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 35}}>
            hP
          </Text>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Discover highPerplexity
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Are you ready to embark on a journey of endless creativity and inspiration?
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            highPerplexity is here to provide you with a vast collection of AI-powered prompts and tools to let your
            creative spirit soar!
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Click next to discover the features of the highPerplexity platform.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <MaterialCommunityIcons name="home-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            What Can I Do Here?
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Let's start with the home screen since that's where you'll land when you're done here! The home
            screen showcases highPerplexity's most popular prompts, newest prompts, and some of the most popular
            prompt tags.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            You can run all of these prompts using both GPT-4 and text-to-image capabilities, giving you a wide range of
            possibilities.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <MaterialCommunityIcons name="rocket-launch-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Create and Explore Prompts
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            highPerplexity lets you create your own customizable prompts or explore the ones created by others. Run the
            prompts using our powerful GPT-4 and text-to-image capabilities, which are at the core of our platform.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Browse through popular prompts and unleash your creativity with cutting-edge AI tools. To create your own
            prompt, click the "plus" button at the top right of any screen or access the creation tool from the main
            menu.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="ios-chatbubbles-outline" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Engage with the Community
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            highPerplexity is more than just super cool AI tools - it's also a social platform! You can rate and
            comment on prompts to share your opinions and insights with others.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Engage in meaningful conversations, provide feedback, and learn from each other's experiences.
            Together, we can create a thriving community of AI enthusiasts!
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <FontAwesome5 name="coins" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            highPerplexity Tokens
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            highPerplexity operates on a token system. Tokens are used to access features and run AI-powered tools.
            Running prompts with GPT-3 costs 1 token while GPT-4 and image responses both cost 2 tokens.
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            You can earn tokens for free by watching ads on the settings screen. There's no limit to the number of free
            tokens you can earn, so you can fully explore the platform without any barriers!
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <MaterialIcons name="star" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            highPerplexity Pro Accounts
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Upgrade to highPerplexity Pro for an enhanced experience! For only $7.99/month, you'll enjoy
            unlimited prompt responses of all types without spending any tokens. Ever!
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Unlock the full potential of highPerplexity and make the most out of your AI-driven journey!
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View style={{alignItems: 'center', marginBottom: 15}}>
          <Ionicons name="ios-save" size={96} color="#FFFFFF"/>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Save and Revisit Conversations
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            With highPerplexity, you can save both your text and image conversations, allowing you to revisit them later
            and pick up where you left off. Never lose track of your ideas or discussions again!
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Keep your creativity flowing and stay inspired as you continue to explore the world of AI.
          </Text>
        </View>
      </>
    ),
    (
      <>
        <View
          style={{
            borderWidth: 2,
            borderColor: '#FFFFFF',
            borderRadius: 50,
            paddingHorizontal: 20,
            paddingVertical: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 15,
          }}
        >
          <Text style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: 35}}>
            hP
          </Text>
        </View>
        <View style={{paddingHorizontal: 15, marginBottom: 15}}>
          <Text
            style={{
              color: '#FFFFFF',
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Thank you for exploring highPerplexity!
          </Text>
        </View>
        <View style={{paddingHorizontal: 15}}>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left', marginBottom: 8}}>
            Now that you've learned about the features of highPerplexity, it's time to dive in and experience the power
            of GPT-4 and text-to-image AI!
          </Text>
          <Text style={{color: '#FFFFFF', fontSize: 16, textAlign: 'left'}}>
            Don't hesitate to experiment, create, and share your ideas with the community. Enjoy your journey with
            highPerplexity!
          </Text>
        </View>
      </>
    ),
  ];

  const handleBackButtonClick = () => {
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (currentScreen < desktopOnboardingScreens.length - 1) {
      setCurrentScreen(currentScreen + 1);
    } else {
      toggleOnboardingStatus();
      setModalVisible(false);
    }
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          {desktopOnboardingScreens[currentScreen]}
          <View
            style={[
              styles.buttonContainer,
              currentScreen === desktopOnboardingScreens.length - 1
                ? styles.buttonContainerLastScreen
                : styles.buttonContainerStandard,
            ]}
          >
            {currentScreen === 0 && (
              <Pressable
                style={styles.button}
                onPress={async () => {
                  await toggleOnboardingStatus();
                  setModalVisible(false);
                }}
              >
                <Ionicons name="close-outline" size={24} color="#FFFFFF"/>
                <Text style={styles.buttonText}>Skip</Text>
              </Pressable>
            )}

            {currentScreen > 0 && (
              <Pressable style={styles.button} onPress={handleBackButtonClick}>
                <Ionicons name="arrow-back" size={24} color="#FFFFFF"/>
                <Text style={styles.buttonText}>Back</Text>
              </Pressable>
            )}

            {currentScreen < desktopOnboardingScreens.length - 1 && (
              <Pressable style={styles.button} onPress={handleNextButtonClick}>
                <Text style={[styles.buttonText, {marginRight: 4}]}>Next</Text>
                <Ionicons name="arrow-forward" size={24} color="#FFFFFF"/>
              </Pressable>
            )}

            {currentScreen === desktopOnboardingScreens.length - 1 && (
              <Pressable
                style={styles.button}
                onPress={async () => {
                  await toggleOnboardingStatus();
                  setModalVisible(false);
                }}
              >
                <Ionicons name="checkmark-outline" size={24} color="#FFFFFF"/>
                <Text style={styles.buttonText}>Finished!</Text>
              </Pressable>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    maxWidth: 800,
    margin: 20,
    backgroundColor: '#202124',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  buttonContainerStandard: {
    justifyContent: 'space-between',
  },
  buttonContainerLastScreen: {
    justifyContent: 'center',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#3a3a3a',
    padding: 10,
    borderRadius: 5,
    marginLeft: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 16,
    marginLeft: 5,
  },
});

export default HomeDesktopOnboarding;
