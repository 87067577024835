import React, {useCallback, useState} from 'react';
import {Image, View} from 'react-native';
import {useSx} from "dripsy";
import {defaultImageArray} from "../../utils/misc/defaultImageData";
import {useFocusEffect} from "@react-navigation/native";

const PromptImage = ({promptImage}: any) => {
  const customSX = useSx();
  const [randomImage, setRandomImage] = useState();

  useFocusEffect(
    useCallback(() => {
      const randomIndex = Math.floor(Math.random() * defaultImageArray.length);
      // @ts-ignore
      setRandomImage(defaultImageArray[randomIndex]);
    }, [])
  );

  return (
    <View style={{marginBottom: 15, alignItems: 'center'}}>
      <Image
        source={{uri: promptImage || randomImage}}
        style={[
          {width: 400, borderRadius: 10},
          customSX({maxWidth: [315, '90%', '100%'], height: [240, 300, 300]}),
        ]}
      />
    </View>
  );
};

export default PromptImage;
