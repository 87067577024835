import React from 'react';
import {Pressable, Text, View} from 'dripsy';
import {MaterialIcons} from '@expo/vector-icons';
import {useNavigation} from '@react-navigation/native';
import {Dimensions} from "react-native";

const screenWidth = Dimensions.get('window').width;

const HowItWorksSection = ({setCurrentScreen, setHasOnboarded}: any) => {
  const navigation = useNavigation();
  const iconSize = screenWidth < 768 ? 36 : 48;

  const handlePress = () => {
    setCurrentScreen(0);
    setHasOnboarded(false);
  };

  return (
    <View sx={{alignItems: 'center', marginBottom: 30}}>
      <Text sx={{fontSize: [24, 28, 32], color: 'white', marginBottom: 10}}>Welcome To highPerplexity</Text>
      <Text sx={{fontSize: [14, 16, 18], color: 'white', textAlign: 'center', marginBottom: 15}}>
        A platform for creating, discovering, sharing, and rating customizable AI prompts
      </Text>
      <Pressable
        onPress={handlePress}
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderWidth: 1,
          borderColor: '#FFF',
          paddingHorizontal: 10,
          paddingVertical: 5,
          borderRadius: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      >
        <MaterialIcons name="slideshow" size={16} color="#FFF" style={{marginRight: 5}}/>
        <Text style={{color: '#FFF', textAlign: 'center', fontSize: 14}}>View New User Information</Text>
      </Pressable>
      {/*<View sx={{flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap', marginTop: 20}}>*/}
      {/*  <View sx={{alignItems: 'center', margin: 10, marginHorizontal: screenWidth < 768 ? 10 : 20}}>*/}
      {/*    <MaterialIcons name="create" size={iconSize} color="#FFF"/>*/}
      {/*    <Text sx={{fontSize: 14, color: 'white', textAlign: 'center'}}>Create and Customize</Text>*/}
      {/*  </View>*/}
      {/*  <View sx={{alignItems: 'center', margin: 10, marginHorizontal: screenWidth < 768 ? 10 : 20}}>*/}
      {/*    <MaterialIcons name="chat" size={iconSize} color="#FFF"/>*/}
      {/*    <Text sx={{fontSize: 14, color: 'white', textAlign: 'center'}}>ChatGPT Integration</Text>*/}
      {/*  </View>*/}
      {/*  <View sx={{alignItems: 'center', margin: 10, marginHorizontal: screenWidth < 768 ? 10 : 20}}>*/}
      {/*    <MaterialIcons name="group" size={iconSize} color="#FFF"/>*/}
      {/*    <Text sx={{fontSize: 14, color: 'white', textAlign: 'center'}}>Collaborate and Grow</Text>*/}
      {/*  </View>*/}
      {/*  <View sx={{alignItems: 'center', margin: 10, marginHorizontal: screenWidth < 768 ? 10 : 20}}>*/}
      {/*    <MaterialIcons name="star" size={iconSize} color="#FFF"/>*/}
      {/*    <Text sx={{fontSize: 14, color: 'white', textAlign: 'center'}}>Discover and Rate</Text>*/}
      {/*  </View>*/}
      {/*</View>*/}
    </View>
  );
};

export default HowItWorksSection;
