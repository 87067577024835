import React, {useState} from 'react';
import {Text, Pressable, StyleSheet, Image} from 'react-native';
import {View} from "dripsy";
import {Ionicons} from '@expo/vector-icons';
import {Card, Paragraph, Title} from "react-native-paper";
import {LinearGradient} from "expo-linear-gradient";
import {useSx} from "dripsy";
import {addDoc, collection, doc, updateDoc} from "firebase/firestore";
import {db} from "../../config/firebase";
import {getAuth} from "firebase/auth";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import uuid from "react-native-uuid";

export default function ImageHistory({
                                       imageLoading,
                                       imageHistory,
                                       tokenCost,
                                       remainingTokens,
                                       image,
                                       setImage,
                                       setImageHistory,
                                       setImageLoadingMessage,
                                       setGenerating,
                                       setImageLoading,
                                       setIsBeforePromptRun,
                                       setIsBeforeImagePromptRun,
                                       userDoc,
                                       promptContent,
                                       setCanEditPrompt,
                                     }: any) {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const customSX = useSx();
  const auth = getAuth();

  const removeImageFromHistory = (imageToRemove: any) => {
    setImageHistory((prevImageHistory: any) => {
      return prevImageHistory.filter((image: any) => image !== imageToRemove);
    });
  };

  const addToImageHistory = (newImage: any) => {
    setImageHistory((prevState: any) => {
      // Check if the new image is already in the history
      if (prevState.includes(newImage)) {
        return prevState;
      }
      return [...prevState, newImage];
    });
  };

  const handleGenerateImages = async (imageData?: any) => {
    if (!auth.currentUser) return;
    setImageLoadingMessage('');
    setGenerating(true);
    setImageLoading(true);
    setIsBeforePromptRun(true);
    setIsBeforeImagePromptRun(false);

    const authTokenValue = await auth?.currentUser?.getIdTokenResult();
    const userRef = doc(db, "users", userDoc);
    await updateDoc(userRef, {currentJWT: authTokenValue?.token});

    const requestBody = {
      firebase_token_value: authTokenValue?.token,
      prompt: promptContent,
    };

    if (imageData) {
      // @ts-ignore
      requestBody.input_image_url = imageData;
    }

    try {
      const response = await fetch('https://highperplexity-dalle-middleware-mjp27ovqaq-uc.a.run.app', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data && data.response) {
        if (image) {
          addToImageHistory(image);
        }
        const newImage = `data:image/png;base64,${data.response.b64_json}`;
        setImage(newImage);
        addToImageHistory(newImage);
      } else {
        console.log('Unexpected response format');
      }
    } catch (error) {
      console.error('Error generating images:', error);
    }
    setImageLoading(false);
    setCanEditPrompt(false);
  };

  const handleCreateVariation = async () => {
    if (image) {
      addToImageHistory(image);
      await handleGenerateImages(image);
      setIsSaved(false);
    }
  };

  const handleSaveConversation = async () => {
    if (!auth.currentUser || !userDoc) return;

    setIsSaving(true);

    const storage = getStorage();
    const imageFileDirectory = `${auth.currentUser.uid}/`;
    const imagePrefix = 'image_conversation_';
    const imageExtension = '.png';

    const saveImageToStorage = async (base64Image: any, index: any) => {
      const timestamp = new Date().getTime();
      const imageFileName = `${imagePrefix}${timestamp}_${index}${imageExtension}`;
      const imageFullName = `${imageFileDirectory}${imageFileName}`;
      const storageRef = ref(storage, imageFullName);

      const response = await fetch(base64Image);
      const imageBlob = await response.blob();
      await uploadBytes(storageRef, imageBlob);

      return await getDownloadURL(storageRef);
    };

    const imageUrls = await Promise.all(
      imageHistory.map((base64Image: any, index: any) => saveImageToStorage(base64Image, index))
    );

    const conversationData = {
      userId: auth.currentUser.uid,
      prompt: promptContent,
      imageHistory: imageUrls,
      mainImage: imageUrls[imageUrls.length - 1],
      createdAt: new Date(),
      deleted: false,
      convoId: uuid.v4(),
    };

    const conversationRef = collection(db, "imageConversations");
    await addDoc(conversationRef, conversationData);

    setIsSaving(false);
    setIsSaved(true);
  };

  const handleArrowClick = (direction: "left" | "right") => {
    if (imageHistory.length === 0) return;
    //@ts-ignore
    const currentIndex = imageHistory.indexOf(image);
    const newIndex =
      direction === "left"
        ? (currentIndex - 1 + imageHistory.length) % imageHistory.length
        : (currentIndex + 1) % imageHistory.length;
    setImage(imageHistory[newIndex]);
  };

  const ImageLimitMessage = () => (
    <View style={styles.imageLimitMessageContainer}>
      <Card style={styles.imageLimitMessageCard}>
        <Card.Content style={styles.imageLimitMessageContent}>
          <Ionicons name="alert-circle" size={24} color="#FF6347"/>
          <View style={styles.imageLimitMessageTextContainer}>
            <Title style={styles.imageLimitMessageTitle}>Limit Reached</Title>
            <Paragraph style={styles.imageLimitMessageText}>
              Image conversations may only contain up to 6 images at this time. To create another variation, remove an
              existing one first.
            </Paragraph>
          </View>
        </Card.Content>
      </Card>
    </View>
  );

  const CreateVariationButton = ({onPress}: any) => (
    <Pressable onPress={onPress} disabled={isSaving}>
      <LinearGradient
        colors={['#2a87ff', '#1a1f42']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={styles.gradientButton}
      >
        <Ionicons name="play" size={24} color="#ffffff"/>
        <Text style={styles.gradientButtonText}>Create New Variation</Text>
      </LinearGradient>
    </Pressable>
  );

  const SaveConversationButton = ({onPress}: any) => (
    <Pressable onPress={onPress} style={styles.saveGradientButton} disabled={(isSaving || isSaved)}>
      <LinearGradient
        colors={['#8a3ab9', '#4a0072']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={styles.gradient}
      >
        <Ionicons name="save" size={20} color="#ffffff"/>
        <Text style={styles.saveGradientButtonText}>
          {isSaving ? "Saving..." : isSaved ? "Saved" : "Save Image Conversation"}
        </Text>
      </LinearGradient>
    </Pressable>
  );

  const ImageHistoryItem = ({image, onClick, canRemove, index, isActive}: any) => {
    return (
      <View style={styles.imageHistoryItem}>
        <Pressable onPress={() => onClick(image)}>
          <Image
            source={{uri: image}}
            style={[isActive ? styles.activeImage : styles.image,
              customSX({width: [250, 330, 420], height: [250, 330, 420]})]}
            resizeMode="contain"
          />
          {!isActive && (
            <View style={styles.inactiveOverlay}/>
          )}
        </Pressable>
        {canRemove && !isActive && (
          <Pressable
            onPress={() => removeImageFromHistory(image)}
            style={styles.deleteImage}
          >
            <Ionicons
              name="close"
              size={12}
              color="#FFF"
            />
          </Pressable>
        )}
      </View>
    );
  };

  return (
    <>
      {imageHistory.length > 0 && (
        <>
          {!imageLoading &&
            <View style={styles.buttonsContainer}>
              {(imageHistory.length >= 6) ?
                <ImageLimitMessage/>
                :
                <View style={{marginTop: 10}}>
                  <Text style={{color: '#FFF', textAlign: 'center', marginBottom: 10}}>
                    Token Cost: {tokenCost}
                  </Text>
                  <Text style={{color: '#FFF', textAlign: 'center'}}>
                    Remaining Tokens: {remainingTokens}
                  </Text>
                  <CreateVariationButton onPress={handleCreateVariation}/>
                </View>
              }
              <SaveConversationButton onPress={handleSaveConversation}/>
              {/*<ResetConversationButton onPress={resetConversation}/>*/}
            </View>
          }
          <View style={styles.imageHistoryContainer} sx={{width: [270, 360, 450], alignItems: 'center'}}>
            <Text style={styles.imageHistoryTitle}>Image History</Text>
            <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 5
              }}>
                <Pressable onPress={() => handleArrowClick("right")} style={styles.arrowButton}>
                  <Ionicons
                    name="chevron-back"
                    size={35}
                    color="#FFFFFF"
                  />
                </Pressable>
                <Pressable onPress={() => handleArrowClick("left")} style={styles.arrowButton}>
                  <Ionicons
                    name="chevron-forward"
                    size={35}
                    color="#FFFFFF"
                  />
                </Pressable>
              </View>
              <View style={styles.imageHistoryScrollView}>
                {[...imageHistory].reverse().map((historyImage, index) => (
                  <ImageHistoryItem
                    key={index}
                    image={historyImage}
                    onClick={(clickedImage: any) => setImage(clickedImage)}
                    canRemove={imageHistory.length > 1}
                    index={index}
                    isActive={historyImage === image}
                  />
                ))}
              </View>
            </View>
          </View>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
  },
  imageHistoryContainer: {
    marginTop: 15,
    backgroundColor: "#303134",
    borderRadius: 5,
    paddingVertical: 15,
    paddingHorizontal: 35,
  },
  imageHistoryTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FFFFFF",
    marginBottom: 15,
    textAlign: 'center',
  },
  arrowButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5,
    borderWidth: 1,
    borderColor: '#FFFFFF',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  imageHistoryScrollView: {
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
  }, imageLimitMessageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  },
  imageLimitMessageCard: {
    backgroundColor: '#1a1f42',
    borderColor: '#2a87ff',
    borderWidth: 1,
  },
  imageLimitMessageContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageLimitMessageTextContainer: {
    marginLeft: 10,
    flex: 1,
  },
  imageLimitMessageTitle: {
    color: '#FF6347',
  },
  imageLimitMessageText: {
    color: '#ffffff',
  },
  gradientButton: {
    borderRadius: 30,
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#2a87ff',
    marginTop: 15,
    marginBottom: 10,
  },
  gradientButtonText: {
    fontSize: 18,
    color: '#ffffff',
    marginLeft: 8,
    fontWeight: 'bold',
  },
  saveGradientButton: {
    borderRadius: 30,
    overflow: 'hidden',
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#8a3ab9',
  },
  gradient: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 30,
  },
  saveGradientButtonText: {
    color: '#ffffff',
    marginLeft: 8,
    fontSize: 16,
    fontWeight: '600',
  }, resetGradientButton: {
    borderRadius: 30,
    overflow: 'hidden',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 5,
    borderWidth: 1,
    borderColor: '#4a4a4a',
  }, imageHistoryItem: {
    position: "relative",
    marginVertical: 10,
  },
  inactiveOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: 5,
  },
  image: {
    borderRadius: 5,
  },
  activeImage: {
    borderRadius: 5,
    borderWidth: 3,
    borderColor: '#007AFF',
    shadowColor: '#007AFF',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  deleteImage: {
    position: "absolute",
    top: -5,
    right: -5,
    backgroundColor: "#FF6B6B",
    borderRadius: 50,
    width: 20,
    height: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
