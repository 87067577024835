import React, {useCallback, useState} from 'react';
import {StyleSheet} from 'react-native';
import {Pressable} from 'dripsy';
import {Card, Text} from 'react-native-paper';
import {defaultImageArray} from "../utils/misc/defaultImageData";
import {useFocusEffect} from "@react-navigation/native";

const ResponseCard = (props: {
  promptId: string,
  responseDocId: string,
  navigation: any,
  promptImage: any,
  promptTitle: any,
  matchesActiveTag?: boolean
}) => {
  const [randomImage, setRandomImage] = useState();

  useFocusEffect(
    useCallback(() => {
      const randomIndex = Math.floor(Math.random() * defaultImageArray.length);
      // @ts-ignore
      setRandomImage(defaultImageArray[randomIndex]);
    }, [])
  );

  return (<Pressable
    sx={{marginRight: [0, 0, 20], marginBottom: 15, width: [300, 400, 300]}}
    onPress={() => props.navigation.navigate('View Response', {responseId: props.responseDocId})}
  >
    <Card style={{backgroundColor: '#25232A'}}>
      <Card.Content style={{marginBottom: 10}}>
        <Text variant="titleSmall" style={{fontWeight: '700', color: '#FFF'}}>
          {props.promptTitle}
        </Text>
      </Card.Content>
      <Card.Cover source={{uri: props?.promptImage || randomImage}}/>
    </Card>
  </Pressable>);
}

const styles = StyleSheet.create({
  cardTextContainer: {
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
  },
  cardItemContainer: {
    height: '100%',
    justifyContent: 'center',
  },
});

export default ResponseCard;
